/**
 * Defines the slice, reducers, and actions for putting up the app bar.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

export interface UserAppBarState {
  hasUnreadNotifications: boolean;
}

const initialState: UserAppBarState = {
  hasUnreadNotifications: false,
};

export const userAppBarSlice = createSlice({
  name: "userAppBar",
  initialState,
  reducers: {
    clearAppBarState: (state) => {
      state.hasUnreadNotifications = false;
    },
    setAppBarHasUnreadNotifications: (state, action: PayloadAction<boolean>) => {
      state.hasUnreadNotifications = action.payload;
    },
  },
});

export const { clearAppBarState, setAppBarHasUnreadNotifications } = userAppBarSlice.actions;

export const selectUserAppBar = (state: RootState) => state.userAppBar as UserAppBarState;

export default userAppBarSlice.reducer;
