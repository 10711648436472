import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SuccessResponse } from "../generated/graphql";
import { AppSnackbar } from "./appSnackbar";
import { useAppDispatch } from "../store/hooks";
import { showSnackbarError, showSnackbarSuccess } from "./appSnackbarSlice";
import { ObjectWithName } from "../util/demoTypes";

interface Props {
  label: string;
  data: ObjectWithName[];
  chosenId: string;
  onChange: (id: string) => Promise<SuccessResponse>;
  disabled?: boolean;
}

export const SelectContentByName: React.FC<Props> = ({ label, data, chosenId, onChange, disabled }) => {
  const dispatch = useAppDispatch();

  return (
    <FormControl>
      <InputLabel id={`demo-${label}-select-label`}>{label}</InputLabel>
      <Select
        disabled={disabled}
        labelId={`demo-${label}-select-label`}
        id={`demo-${label}-select`}
        value={chosenId}
        label={label}
        onChange={(e) => {
          onChange(e.target.value).then((response) => {
            if (response.success) {
              dispatch(showSnackbarSuccess(`${label} saved`));
            } else {
              dispatch(showSnackbarError(response.error));
            }
          });
        }}
      >
        {data.map((c) => {
          return (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          );
        })}
      </Select>
      <AppSnackbar />
    </FormControl>
  );
};
