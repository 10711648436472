import React from "react";
import { ConfirmDestructiveActionDialog } from "../../../components/dialogs/confirmDestructiveActionDialog";

interface Props {
  open: boolean;
  closeDialog: (confirmed: boolean) => void;
}

export const ConfirmRemoveSectionDialog: React.FC<Props> = ({ open, closeDialog }) => {
  return (
    <ConfirmDestructiveActionDialog
      open={open}
      message="If you remove this section, you will lose any edits you made. This cannot be undone."
      yesText="Remove Section"
      closeDialog={closeDialog}
    />
  );
};
