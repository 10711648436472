import React from "react";
import { useParams } from "react-router-dom";
import { useUserCloseQuery } from "../../../generated/graphql";
import { requestUpdateClose } from "../../../generated/graphqlWrappers";
import { TeamContentPageLayout } from "./teamContent/teamContentPageLayout";

interface Params {
  teamId: string;
  closeId: string;
}

export const TeamClosePage: React.FC = () => {
  const { teamId, closeId } = useParams<Params>();
  return (
    <TeamContentPageLayout
      teamId={teamId}
      contentType="Close"
      chosen="Closes"
      contentId={closeId}
      useUserContentQuery={useUserCloseQuery}
      requestUpdateContent={requestUpdateClose}
    />
  );
};
