import {
  SharedDemosForUserQuery,
  SiteDemoTemplateQuery,
  UserCloseForDemoQuery,
  UserConversationForDemoQuery,
  UserConversationsForDemoQuery,
  UserDemoQuery,
  UserDemosQuery,
  UserDemoTemplatesForTeamQuery,
  UserFeatureSetForDemoQuery,
  UserFeatureSetsForDemoQuery,
  UserOpeningForDemoQuery,
} from "../generated/graphql";

export interface DemoContent {
  __typename?: string;
  id: string;
  name: string;
  body: string;
  bulletedBody: string;
  whatToShow: string;
  forTeamVersion: number;
  showBulleted: boolean;
  [x: string]: any;
}

export interface DemoContentIdName {
  __typename?: string;
  id: string;
  name: string;
}

export interface SiteDataIdDisplayName {
  __typename?: string;
  id: string;
  displayName: string;
}

export const demoContentTypeIdKey = (contentType: string, contentId: string) => {
  return `${contentType}-${contentId}`;
};

export const demoContentKey = (dc: DemoContent) => {
  return demoContentTypeIdKey(dc.__typename!, dc.id);
};

export const demoContentKeyVersioned = (dc: DemoContent) => {
  return `${demoContentKey(dc)}-${dc.forTeamVersion}`;
};

export const contentTypeFromKey = (key: string) => {
  return key.split("-")[0];
};

export const contentIdFromKey = (key: string) => {
  return key.split("-")[1];
};

export const isRemovableFromDemo = (contentType: string) => {
  return contentType === "FeatureSetForDemo" || contentType === "ConversationForDemo";
};

export const emptyDemoContent: DemoContent = {
  __typename: "",
  id: "",
  name: "",
  body: "",
  bulletedBody: "",
  whatToShow: "",
  forTeamVersion: 0,
  showBulleted: false,
};

export type DemoContentType = "Opening" | "Close" | "FeatureSet" | "Conversation";
export const urlPathForContentType = (contentType: DemoContentType): string => {
  // handle featureSet -> feature-set and lower case everything else
  return contentType.replace("FeatureSet", "feature-set").toLowerCase();
};

export const labelForContentType = (contentType: DemoContentType): string => {
  // handle FeatureSet -> Feature
  return contentType.replace("FeatureSet", "Feature");
};

export const forTeamTypeName = (typename: string): string => {
  return typename.replace("ForDemo", "ForTeam");
};

export const forTeamPropertyName = (typename: string): string => {
  const typeName = typename.replace("ForDemo", "ForTeam");
  return typeName[0].toLowerCase() + typeName.substring(1);
};

export const teamContentIsNewer = (content: DemoContent): boolean => {
  const teamContent = content[forTeamPropertyName(content.__typename!)];
  if (teamContent && "version" in teamContent) {
    return content.forTeamVersion < teamContent.version;
  }
  return false;
};

export const updateToTeamVersion = (content: DemoContent) => {
  const teamContent = content[forTeamPropertyName(content.__typename!)];
  if (teamContent && "version" in teamContent) {
    content.forTeamVersion = teamContent.version;
  }
};

export const updateToTeamVersionAndCopyFromTeam = (content: DemoContent, teamContent: DemoContent) => {
  content.forTeamVersion = teamContent.version;
  content.name = teamContent.name;
  content.whatToShow = teamContent.whatToShow;
  content.body = teamContent.body;
  content.bulletedBody = teamContent.bulletedBody;
};

export interface ObjectWithName {
  id: string;
  name: string;
  [x: string]: any;
}

export type UserDemoQueryData = Pick<UserDemoQuery, "userDemo">;
export type UserDemoData = UserDemosQuery["userDemos"];
export type UserTeamDemoTemplateData = UserDemoTemplatesForTeamQuery["userDemoTemplatesForTeam"];

export type SharedUserDemoData = SharedDemosForUserQuery["sharedDemosForUser"];

export type UserFeatureSetsForDemoQueryData = UserFeatureSetsForDemoQuery["userFeatureSetsForDemo"];
export type UserFeatureSetForDemoQueryData = Pick<UserFeatureSetForDemoQuery, "userFeatureSetForDemo">;

export type UserConversationsForDemoQueryData = UserConversationsForDemoQuery["userConversationsForDemo"];
export type UserConversationForDemoQueryData = Pick<UserConversationForDemoQuery, "userConversationForDemo">;

export type UserOpeningForDemoQueryData = Pick<UserOpeningForDemoQuery, "userOpeningForDemo">;
export type UserCloseForDemoQueryData = Pick<UserCloseForDemoQuery, "userCloseForDemo">;

export type TemplateFeatureSetQueryData = SiteDemoTemplateQuery["siteDemoTemplate"]["demoTemplateFeatureSets"];
export type TemplateConversationQueryData = SiteDemoTemplateQuery["siteDemoTemplate"]["demoTemplateConversations"];

export type TemplateTeamFeatureSetQueryData = SiteDemoTemplateQuery["siteDemoTemplate"]["team"]["featureSets"];
export type TemplateTeamConversationQueryData = SiteDemoTemplateQuery["siteDemoTemplate"]["team"]["conversations"];

export type DemoTemplateQueryData = SiteDemoTemplateQuery["siteDemoTemplate"];
