import React from "react";
import { TextField } from "@mui/material";

interface Props {
  nameState: [string, React.Dispatch<React.SetStateAction<string>>];
}

export const ResourceCategoryEditor: React.FC<Props> = ({ nameState }) => {
  const [name, setName] = nameState;

  return (
    <React.Fragment>
      <TextField
        value={name}
        label="Name"
        variant="outlined"
        required
        sx={{ width: 350 }}
        color="primary"
        onChange={(e) => {
          setName(e.target.value);
        }}
      ></TextField>
    </React.Fragment>
  );
};
