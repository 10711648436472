import React from "react";

import { Box, Drawer, Stack } from "@mui/material";
import { AppLogo } from "../appLogo";
import { UserAppBar } from "../userAppBar";

interface Props {
  sidebar: React.ReactNode;
  rightDrawerWidth?: number;
}

export const LayoutLeftNav: React.FC<Props> = ({ children, sidebar, rightDrawerWidth }) => {
  const leftDrawerWidth = 240;

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <Drawer
          variant="permanent"
          sx={{
            width: leftDrawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: leftDrawerWidth, boxSizing: "border-box" },
          }}
        >
          <AppLogo />
          {sidebar}
        </Drawer>
        <Stack sx={{ flexGrow: 1, ml: 3, mt: 1, mr: `${(rightDrawerWidth ?? 0) + 24}px` }}>
          <UserAppBar />
          <Box component="main" sx={{ flexGrow: 1, mt: 3 }} justifyContent="center">
            {children}
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};
