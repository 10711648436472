import React from "react";
import { Paper, Stack, TextField } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { demoContentKey, demoContentKeyVersioned } from "../../../util/demoTypes";

import {
  editChosenContentBody,
  editChosenContentBulletedBody,
  editChosenContentName,
  editChosenContentWhatToShow,
  editDemoDescription,
  editDemoName,
  selectViewDemo,
  setScriptMode,
} from "./viewDemoSlice";
import { scriptModeForTab } from "./viewDemoSliceUtils";
import { TabbedContentEditor } from "../../../components/tabbedContentEditor";
import { cumulativeWordCountForContentKey, demoStateChosenContent, tabForScriptMode } from "./viewDemoBaseReduxUtils";

interface Props {
  name: string;
}

const sectionName = (contentType?: string): string => {
  switch (contentType) {
    case "FeatureSetForDemo":
      return "Feature";
    case "ConversationForDemo":
      return "Conversation";
    case "OpeningForDemo":
      return "Opening";
    case "CloseForDemo":
      return "Close";
  }
  return "";
};

export const DemoContentEditView: React.FC<Props> = ({ name }) => {
  const viewDemoState = useAppSelector(selectViewDemo);
  const dispatch = useAppDispatch();

  const content = demoStateChosenContent(viewDemoState);

  return (
    <Stack direction="column" spacing={2} sx={{ px: 2 }}>
      <Paper sx={{ p: 2 }}>
        <Stack direction="column" sx={{ minWidth: 250 }} spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              value={viewDemoState.demoName}
              label="Demo name"
              variant="outlined"
              required={true}
              color={viewDemoState.demoName.length === 0 ? "error" : "primary"}
              fullWidth
              inputProps={{ maxLength: 255 }}
              onChange={(e) => dispatch(editDemoName(e.target.value))}
            />
            <TextField
              value={viewDemoState.demoDescription}
              label="Demo description"
              variant="outlined"
              fullWidth
              multiline={true}
              rows={2}
              color="primary"
              inputProps={{ maxLength: 255 }}
              onChange={(e) => dispatch(editDemoDescription(e.target.value))}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              value={content.name}
              label={`${sectionName(content.__typename)} Name`}
              variant="outlined"
              required={true}
              color={content.name.length === 0 ? "error" : "primary"}
              fullWidth
              inputProps={{ maxLength: 255 }}
              onChange={(e) => dispatch(editChosenContentName(e.target.value))}
            />
            <TextField
              value={content.whatToShow}
              label="What to show"
              variant="outlined"
              color="primary"
              fullWidth
              inputProps={{ maxLength: 255 }}
              onChange={(e) => dispatch(editChosenContentWhatToShow(e.target.value))}
            />
          </Stack>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <TabbedContentEditor
            contentKey={demoContentKeyVersioned(content)}
            body={content.body}
            bodyChanged={(newBody: string, wordCount: number) => {
              dispatch(editChosenContentBody({ bodyJSON: newBody, wordCount }));
            }}
            bulletedBody={content.bulletedBody}
            bulletedBodyChanged={(newBulletedBody: string) => {
              dispatch(editChosenContentBulletedBody(newBulletedBody));
            }}
            tab={tabForScriptMode(viewDemoState)}
            tabChanged={(t) => {
              dispatch(setScriptMode(scriptModeForTab(t)));
            }}
            cumulativeWordCount={cumulativeWordCountForContentKey(viewDemoState, demoContentKey(content))}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};
