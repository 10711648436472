import React from "react";

import { Button, DialogTitle, TextField } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { clearNewDemoState, selectNewDemo, setDescription, setName } from "./newDemoSlice";
import { Box } from "@mui/system";

interface Props {
  next: () => void;
  cancel: () => void;
}

export const NewDemoName: React.FC<Props> = ({ next, cancel }) => {
  const newDemoState = useAppSelector(selectNewDemo);
  const dispatch = useAppDispatch();

  const nextDisabled = () => {
    return newDemoState.name === "";
  };

  const onNext = () => {
    if (!nextDisabled()) {
      next();
    }
  };

  const onCancel = () => {
    dispatch(clearNewDemoState());
    cancel();
  };

  return (
    <React.Fragment>
      <DialogTitle>Create a New Demo</DialogTitle>
      <Box flexDirection="column" sx={{ my: 2, mx: 2, display: "flex", gap: 2 }}>
        <TextField
          onChange={(e) => dispatch(setName(e.target.value))}
          value={newDemoState.name}
          label="Opportunity Name"
          variant="outlined"
          sx={{ minWidth: 350 }}
          required
          color="primary"
          inputProps={{ maxLength: 255 }}
        />
        <TextField
          onChange={(e) => dispatch(setDescription(e.target.value))}
          value={newDemoState.description}
          label="Description"
          variant="outlined"
          fullWidth
          multiline={true}
          rows={3}
          color="primary"
          inputProps={{ maxLength: 255 }}
        />
        <Box flexDirection="row" sx={{ my: 2, display: "flex", gap: 2, justifyContent: "flex-end" }}>
          <Button size="large" variant="outlined" color="error" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="large" variant="contained" color="primary" disabled={nextDisabled()} onClick={onNext}>
            Next
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};
