import { ReactionBarState } from "../../../components/reactions/reactionBarSlice";
import { DemoContentIdName } from "../../../util/demoTypes";
import { escapeForJSONString } from "../../../util/stringUtils";
import { demoConversations, demoFeatureSets, ChosenReaction } from "./viewDemoBaseReduxUtils";
import { ViewDemoState } from "./viewDemoSlice";

const getTeamContentIdsNotIncluded = (
  teamContent: DemoContentIdName[],
  teamContentInDemo: DemoContentIdName[]
): DemoContentIdName[] => {
  return teamContent.filter((tc) => teamContentInDemo.findIndex((ic) => ic.id === tc.id) < 0);
};

export const getTeamFeatureSetsNotIncludedInDemo = (
  teamFeatureSets: DemoContentIdName[],
  viewDemoState: ViewDemoState
): DemoContentIdName[] => {
  const teamFeatureSetsInDemo = demoFeatureSets(viewDemoState)
    .filter((c) => c.featureSetForTeam)
    .map((c) => {
      return { ...c.featureSetForTeam!, name: "" };
    });
  return getTeamContentIdsNotIncluded(teamFeatureSets, teamFeatureSetsInDemo);
};

export const getTeamConversationsNotIncludedInDemo = (
  teamConversations: DemoContentIdName[],
  viewDemoState: ViewDemoState
): DemoContentIdName[] => {
  const converationsForDemo = demoConversations(viewDemoState)
    .filter((c) => c.conversationForTeam)
    .map((c) => {
      return { ...c.conversationForTeam!, name: "" };
    });
  return getTeamContentIdsNotIncluded(teamConversations, converationsForDemo);
};

const indexOfDraggableContent = (viewDemoState: ViewDemoState, contentType: string, contentId: string) => {
  return viewDemoState.draggableParts.findIndex((edc) => {
    return edc.content.__typename === contentType && edc.content.id === contentId;
  });
};

const getReactionContentName = (viewDemoState: ViewDemoState, reaction: ChosenReaction): string | undefined => {
  if (reaction.contentType === "OpeningForDemo") {
    return viewDemoState.opening.content.name;
  } else if (reaction.contentType === "CloseForDemo") {
    return viewDemoState.close.content.name;
  } else {
    const index = indexOfDraggableContent(viewDemoState, reaction.contentType, reaction.contentId);
    if (index >= 0) {
      return viewDemoState.draggableParts[index].content.name;
    }
  }
  return undefined;
};

const getReactionName = (reactionBarState: ReactionBarState, reaction: ChosenReaction): string => {
  const rIndex = reactionBarState.reactionButtons.findIndex((r) => r.id === reaction.reactionId);
  if (rIndex >= 0) {
    return reactionBarState.reactionButtons[rIndex].name;
  }
  return "unknown reaction";
};

const reactionDescription = (
  viewDemoState: ViewDemoState,
  reactionBarState: ReactionBarState,
  reaction: ChosenReaction
): string | undefined => {
  const contentName = getReactionContentName(viewDemoState, reaction);
  if (contentName) {
    const reactionName = getReactionName(reactionBarState, reaction);
    return `"${reactionName}" reaction to "${contentName}"`;
  }
  return undefined;
};

const sortedReactionsByDemoOrder = (viewDemoState: ViewDemoState): ChosenReaction[] => {
  let sortedReactions = [...viewDemoState.chosenReactions];
  sortedReactions.sort((a, b) => {
    if (a.contentType === "OpeningForDemo" || b.contentType === "CloseForDemo") {
      return -1;
    } else if (a.contentType === "CloseForDemo" || b.contentType === "OpeningForDemo") {
      return 1;
    } else {
      const aIndex = indexOfDraggableContent(viewDemoState, a.contentType, a.contentId);
      const bIndex = indexOfDraggableContent(viewDemoState, b.contentType, b.contentId);
      return aIndex - bIndex;
    }
  });
  return sortedReactions;
};

const boldContentJson = (s: string): string => {
  return `{
    "type": "paragraph",
    "content": [
      {
        "type": "text",
        "marks": [{ "type": "bold" }],
        "text": "${escapeForJSONString(s)}"
      }
    ]
  },
  { "type": "paragraph" }`;
};

export const createInitialBodyForDemoFeedback = (
  viewDemoState: ViewDemoState,
  reactionBarState: ReactionBarState
): string => {
  const sortedReactions = sortedReactionsByDemoOrder(viewDemoState);

  const reactionExplanation = sortedReactions.map((r) => {
    const desc = reactionDescription(viewDemoState, reactionBarState, r);
    if (desc) {
      return boldContentJson(`Explain ${desc}`);
    }
    return "";
  });
  const contentJSON = [...reactionExplanation.filter((s) => s !== ""), boldContentJson("Other feedback")].join(",");

  return `
  {
    "type": "doc",
    "content": [
      ${contentJSON}
    ]
  }  
  `;
};
