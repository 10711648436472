import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ObjectTableButtonData } from "../objectArrayTable/objectArrayTable";
import { ObjectTableCellButton } from "../objectArrayTable/objectTableCellButton";

interface Props {
  demo: { id: string; name: string; description: string };
  buttons?: (demoId: string) => ObjectTableButtonData[];
  onLaunchDemo: (demoId: number) => void;
}

export const DemoNameCell: React.FC<Props> = ({ demo, buttons, onLaunchDemo }) => {
  const [hideButtons, setHideButtons] = useState(true);

  return (
    <Stack
      direction="row"
      display="flex"
      sx={{ flexGrow: 1 }}
      onMouseOver={() => {
        setHideButtons(false);
      }}
      onMouseOut={() => {
        setHideButtons(true);
      }}
    >
      <Stack display="flex" sx={{ flexGrow: 1 }} onClick={() => onLaunchDemo(parseInt(demo.id))}>
        <Typography variant="body1">{demo.name}</Typography>
        <Typography variant="body2" color="darkgray">
          {demo.description}
        </Typography>
      </Stack>
      <Stack display="flex" direction="row" visibility={hideButtons ? "hidden" : "visible"}>
        {buttons ? (
          buttons(demo.id).map((b) => <ObjectTableCellButton key={b.text} objectId={demo.id} button={b} />)
        ) : (
          <React.Fragment />
        )}
      </Stack>
    </Stack>
  );
};
