import { Button, Stack, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { SortColumnDescriptor } from "../demoTable/demoTableSortUtils";
import { TableColumnHeader } from "./objectArrayTable";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export interface TableHeaderButton {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

interface Props {
  title: TableColumnHeader | TableColumnHeader[];
  buttons?: TableHeaderButton[];
  buttonColIndex?: number;
  onClick?: (colIndex: number) => void;
  headerSort?: SortColumnDescriptor;
}

export const ObjectTableHeader: React.FC<Props> = ({ title, buttons, buttonColIndex, onClick, headerSort }) => {
  let reversedButtons = buttons ? [...buttons] : [];
  reversedButtons.reverse();

  const titleColumns = (
    header: TableColumnHeader | TableColumnHeader[],
    index?: number,
    includeButtons?: boolean
  ): ReactNode[] => {
    if (Array.isArray(header)) {
      return header.flatMap((h, i, list) => titleColumns(h, i, i === (buttonColIndex ?? list.length - 1)));
    }
    return [
      <TableCell key={`${index ?? 0}`} onClick={() => (onClick ? onClick(index ?? 0) : 0)}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h6">{header}</Typography>
          {headerSort && headerSort.index === index ? (
            headerSort.ascending ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )
          ) : (
            <React.Fragment />
          )}
          <Typography variant="h6" flexGrow={1}></Typography>
          {includeButtons ?? true ? (
            reversedButtons.map((b) => {
              return (
                <Button
                  key={b.text}
                  size="small"
                  variant="outlined"
                  color="primary"
                  disabled={b.disabled ?? false}
                  style={{ float: "right", marginLeft: 4 }}
                  onClick={b.onClick}
                >
                  {b.text}
                </Button>
              );
            })
          ) : (
            <React.Fragment />
          )}
        </Stack>
      </TableCell>,
    ];
  };

  return (
    <TableHead>
      <TableRow style={{ backgroundColor: "#eff4fb" }}>{titleColumns(title)}</TableRow>
    </TableHead>
  );
};
