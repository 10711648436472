import React, { useState } from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { Autocomplete, Box, Button, Dialog, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { useUserDemoSharesAndTeamQuery } from "../../../generated/graphql";
import { MessageDialog } from "../../../components/dialogs/messageDialog";
import { red } from "@mui/material/colors";
import { requestShareUserDemo } from "../../../generated/graphqlWrappers";
import { showSnackbarSuccess } from "../../../components/appSnackbarSlice";
import { useAppDispatch } from "../../../store/hooks";

interface Props {
  demoId: number;
  teamId: number;
  open: boolean;
  onClose: () => void;
}

declare namespace Intl {
  class ListFormat {
    constructor(locales?: string);
    public format: (items: string[]) => string;
  }
}

export const ShareDemoDialog: React.FC<Props> = ({ demoId, teamId, open, onClose }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [chosenUser, setChosenUser] = useState(-1);
  const [autoCompleteKey, setAutoCompleteKey] = useState(0);

  const dispatch = useAppDispatch();

  const { data, error, loading, refetch } = useUserDemoSharesAndTeamQuery({
    variables: {
      demoId,
      teamId,
    },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <MessageDialog open={open} title={"Error"} message={error.message} close={onClose} />;
  }
  if (loading || !data) {
    return <React.Fragment />;
  }

  const onShare = async (client: ApolloClient<object>) => {
    setErrorMessage("");
    if (chosenUser >= 0) {
      await requestShareUserDemo(
        client,
        { shareWithUserId: chosenUser, demoId },
        () => {
          const userName = data.userTeam.users.find((u) => parseInt(u.id) === chosenUser)?.displayName;
          if (userName) {
            dispatch(showSnackbarSuccess("Shared with " + userName));
          }
          setChosenUser(-1);
          setAutoCompleteKey(autoCompleteKey + 1);
          refetch();
        },
        (error) => {
          setErrorMessage(error);
        }
      );
    }
  };

  const teamMembersNotSharedWith = data.userTeam.users.filter(
    (u) => u.id !== data.userAccount.id && data.userDemoShares.findIndex((share) => share.user.id === u.id) < 0
  );

  const listFormatter = new Intl.ListFormat("en");
  const sharedUsers = listFormatter.format(data.userDemoShares.map((share) => share.user.displayName));

  return (
    <ApolloConsumer>
      {(client) => (
        <Dialog onClose={() => onClose()} open={open}>
          <DialogTitle>Share {data.userDemo.name}</DialogTitle>
          <Stack spacing={2} sx={{ m: 1 }}>
            <Stack spacing={2} direction="row">
              <Autocomplete
                key={autoCompleteKey}
                autoHighlight
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={teamMembersNotSharedWith.length === 0}
                options={teamMembersNotSharedWith.map((u) => {
                  return { label: u.displayName, id: u.id };
                })}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Team Member" />}
                onChange={(option, value) => {
                  if (value) {
                    setChosenUser(parseInt(value.id));
                  } else {
                    setChosenUser(-1);
                  }
                }}
              />
              <Button
                sx={{ m: 2 }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  onShare(client);
                }}
                disabled={teamMembersNotSharedWith.length === 0 || chosenUser === -1}
              >
                Share
              </Button>
            </Stack>
            {data.userDemoShares.length === 0 ? (
              <Typography sx={{ m: 1 }}>Not currently shared</Typography>
            ) : (
              <Typography sx={{ m: 1, width: 400, maxWidth: 400 }}>Already Shared with: {sharedUsers}</Typography>
            )}
          </Stack>
          {errorMessage !== "" ? (
            <Typography color={red[900]} sx={{ m: 1 }}>
              {errorMessage}
            </Typography>
          ) : (
            <React.Fragment />
          )}
          <Box flexDirection="row" sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
            <Button
              sx={{ m: 2 }}
              variant="contained"
              color="primary"
              onClick={() => {
                onClose();
              }}
            >
              Done
            </Button>
          </Box>
        </Dialog>
      )}
    </ApolloConsumer>
  );
};
