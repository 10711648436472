import React, { useState } from "react";
import { useActive, useCommands } from "@remirror/react";
import { Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { ColorPickerPopover } from "../dialogs/colorPickerPopover";

export const FormatMenu: React.FC = () => {
  const { bold, italic, underline, bulletList, orderedList, textColor, textHighlight, heading } = useActive();
  const {
    toggleBold,
    toggleItalic,
    toggleUnderline,
    toggleBulletList,
    toggleOrderedList,
    setTextHighlight,
    setTextColor,
    toggleHeading,
    leftAlign,
    centerAlign,
    rightAlign,
    undo,
    redo,
  } = useCommands();

  const [colorPickerIsOpen, setColorPickerIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const value = Array<string>().concat(
    bold() ? ["bold"] : [],
    italic() ? ["italic"] : [],
    underline() ? ["underline"] : [],
    bulletList() ? ["bulletList"] : [],
    orderedList() ? ["orderedList"] : [],
    textHighlight() ? ["textHighlight"] : [],
    textColor() ? ["textColor"] : [],
    heading() ? ["heading"] : []
  );

  const pickColor = (target: HTMLButtonElement) => {
    setAnchorEl(target);
    setColorPickerIsOpen(true);
  };

  const leftAlignActive = () => {
    if (leftAlign.active && rightAlign.active && centerAlign.active) {
      return leftAlign.active() || (!rightAlign.active() && !centerAlign.active());
    }
    return false;
  };

  const buttonGroups = [
    [
      {
        value: "undo",
        tooltip: "Undo",
        icon: <UndoIcon />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          undo();
        },
        disabled: !undo.enabled(),
        selected: false,
      },
      {
        value: "redo",
        tooltip: "Redo",
        icon: <RedoIcon />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          redo();
        },
        disabled: !redo.enabled(),
        selected: false,
      },
    ],
    [
      {
        value: "bold",
        tooltip: "Bold",
        icon: <FormatBoldIcon />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          toggleBold();
        },
        disabled: !toggleBold.enabled(),
        selected: bold(),
      },
      {
        value: "italic",
        tooltip: "Italicize",
        icon: <FormatItalicIcon />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          toggleItalic();
        },
        disabled: !toggleItalic.enabled(),
        selected: italic(),
      },
      {
        value: "underline",
        tooltip: "Underline",
        icon: <FormatUnderlinedIcon />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          toggleUnderline();
        },
        disabled: !toggleUnderline.enabled(),
        selected: underline(),
      },
    ],
    [
      {
        value: "textColor",
        tooltip: "Set text color",
        icon: [<FormatColorTextIcon key="b" />, <ArrowDropDownIcon key="ba" />],
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          pickColor(event.currentTarget);
        },
        disabled: false,
        selected: textColor(),
      },
      {
        value: "textHighlight",
        tooltip: "Set text background color",
        icon: [<FormatColorFillIcon key="b" />, <ArrowDropDownIcon key="ba" />],
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          pickColor(event.currentTarget);
        },
        disabled: false,
        selected: textHighlight(),
      },
    ],
    [
      {
        value: "h1",
        tooltip: "Header level 1",
        icon: <Typography sx={{ fontWeight: "bold", fontSize: "1.3em" }}>H1</Typography>,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          toggleHeading({ level: 1 });
        },
        disabled: !toggleHeading.enabled,
        selected: heading({ level: 1 }),
      },
      {
        value: "h2",
        tooltip: "Header level 2",
        icon: <Typography sx={{ fontWeight: "bold", fontSize: "1.1em" }}>H2</Typography>,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          toggleHeading({ level: 2 });
        },
        disabled: !toggleHeading.enabled,
        selected: heading({ level: 2 }),
      },
      {
        value: "h3",
        tooltip: "Header level 3",
        icon: <Typography sx={{ fontWeight: "bold", fontSize: "0.9em" }}>H3</Typography>,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          toggleHeading({ level: 3 });
        },
        disabled: !toggleHeading.enabled,
        selected: heading({ level: 3 }),
      },
    ],
    [
      {
        value: "left",
        tooltip: "Left align",
        icon: <FormatAlignLeftIcon />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          leftAlign();
        },
        disabled: !leftAlign.enabled,
        selected: leftAlignActive(),
      },
      {
        value: "center",
        tooltip: "Center align",
        icon: <FormatAlignCenterIcon />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          centerAlign();
        },
        disabled: !centerAlign.enabled,
        selected: centerAlign.active ? centerAlign.active() : false,
      },
      {
        value: "right",
        tooltip: "Right align",
        icon: <FormatAlignRightIcon />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          rightAlign();
        },
        disabled: !rightAlign.enabled,
        selected: rightAlign.active ? rightAlign.active() : false,
      },
    ],
    [
      {
        value: "bulletList",
        tooltip: "Bulleted list",
        icon: <FormatListBulletedIcon />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          toggleBulletList();
        },
        disabled: !toggleBulletList.enabled(),
        selected: bulletList(),
      },
      {
        value: "orderedList",
        tooltip: "Numbered list",
        icon: <FormatListNumberedIcon />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          toggleOrderedList();
        },
        disabled: !toggleOrderedList.enabled(),
        selected: orderedList(),
      },
    ],
  ];

  const handleColorPicked = (color: string) => {
    if (!anchorEl) {
      return;
    }
    if (anchorEl.id === "textColor") {
      setTextColor(color);
    } else if (anchorEl.id === "textHighlight") {
      setTextHighlight(color);
    }
    setColorPickerIsOpen(false);
  };

  return (
    <Stack direction="row" sx={{ flexGrow: 1 }} spacing={1}>
      {buttonGroups.map((bg, i) => {
        return (
          <ToggleButtonGroup key={`key-${i}`} value={value}>
            {bg.map((b) => {
              return (
                <Tooltip title={b.tooltip} key={b.value}>
                  <ToggleButton
                    id={b.value}
                    key={b.value}
                    value={b.value}
                    size="small"
                    onClick={(event) => {
                      b.onClick(event);
                    }}
                    disabled={b.disabled}
                    selected={b.selected}
                  >
                    {b.icon}
                  </ToggleButton>
                </Tooltip>
              );
            })}
          </ToggleButtonGroup>
        );
      })}
      <ColorPickerPopover
        id="cp"
        open={colorPickerIsOpen}
        chosenColor={""}
        anchorEl={anchorEl}
        onSelect={(color) => {
          handleColorPicked(color);
        }}
        handleClose={() => {
          setColorPickerIsOpen(false);
        }}
      />
    </Stack>
  );
};
