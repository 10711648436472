import { Button, Stack } from "@mui/material";
import React from "react";

interface Props {
  objectId: number;
  saveIsDisabled: boolean;
  onSave: () => void;
  onSaveAndClose: () => void;
  onClose: (objectId: number) => void;
  contentIsChanged: boolean;
}

export const EditorSaveButtons: React.FC<Props> = ({
  objectId,
  saveIsDisabled,
  onSave,
  onSaveAndClose,
  onClose,
  contentIsChanged,
}) => (
  <Stack direction="row" spacing={2}>
    <Button
      variant="outlined"
      color="error"
      onClick={() => {
        onClose(objectId);
      }}
    >
      Cancel
    </Button>
    <Button variant="outlined" color="primary" disabled={saveIsDisabled || !contentIsChanged} onClick={onSave}>
      Save
    </Button>
    <Button variant="contained" color="primary" disabled={saveIsDisabled} onClick={onSaveAndClose}>
      {contentIsChanged ? "Save and Close" : "Close"}
    </Button>
  </Stack>
);
