import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserSharedDemoQuery } from "../../../generated/graphql";
import { RootState } from "../../../store/store";
import {
  chooseReactionForContentBase,
  clearViewDemoBaseState,
  DemoScriptMode,
  demoStateEditsSuccessfullySentBase,
  DemoViewMode,
  initializeViewDemoBaseState,
  initialViewDemoBaseState,
  setChosenKeyBase,
  setLastTappedDemoNavBase,
  setScriptModeBase,
  toggleRightDrawerBase,
  unChooseReactionForContentBase,
  updateReactionFeedbackBase,
  ViewDemoBaseState,
  ChosenReaction,
  setErrorMessageBase,
  demoTimerTickBase,
  setDemoPlayStateBase,
  DemoPlayState,
} from "./viewDemoBaseReduxUtils";

interface ViewSharedDemoState extends ViewDemoBaseState {}

const initialState: ViewSharedDemoState = {
  ...initialViewDemoBaseState,
};

export const viewSharedDemoSlice = createSlice({
  name: "viewSharedDemo",
  initialState,
  reducers: {
    clearViewDemoState: (state) => {
      clearViewDemoBaseState(state);
      state.viewMode = DemoViewMode.VIEW;
    },
    initializeViewDemoState: (state, action: PayloadAction<UserSharedDemoQuery>) => {
      initializeViewDemoBaseState(state, action.payload.userSharedDemo);
    },
    setChosenKey: (state, action: PayloadAction<string>) => {
      setChosenKeyBase(state, action);
    },
    setLastTappedDemoNav: (state, action: PayloadAction<string>) => {
      setLastTappedDemoNavBase(state, action);
    },
    chooseReactionForContent: (state, action: PayloadAction<ChosenReaction>) => {
      chooseReactionForContentBase(state, action);
    },
    unChooseReactionForContent: (state, action: PayloadAction<ChosenReaction>) => {
      unChooseReactionForContentBase(state, action);
    },
    updateReactionFeedback: (state, action: PayloadAction<ChosenReaction>) => {
      updateReactionFeedbackBase(state, action);
    },
    demoStateEditsSuccessfullySent: (state) => {
      demoStateEditsSuccessfullySentBase(state);
    },
    setScriptMode: (state, action: PayloadAction<DemoScriptMode>) => {
      setScriptModeBase(state, action);
    },
    toggleRightDrawer: (state) => {
      toggleRightDrawerBase(state);
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      setErrorMessageBase(state, action);
    },
    setDemoPlayState: (state, action: PayloadAction<DemoPlayState>) => {
      setDemoPlayStateBase(state, action);
    },
    demoTimerTick: (state) => {
      demoTimerTickBase(state);
    },
  },
});

export const {
  clearViewDemoState,
  setChosenKey,
  initializeViewDemoState,
  setLastTappedDemoNav,
  chooseReactionForContent,
  unChooseReactionForContent,
  updateReactionFeedback,
  demoStateEditsSuccessfullySent,
  setScriptMode,
  toggleRightDrawer,
  setErrorMessage,
  setDemoPlayState,
  demoTimerTick,
} = viewSharedDemoSlice.actions;

export const selectViewSharedDemo = (state: RootState) => state.viewSharedDemo as ViewSharedDemoState;

export default viewSharedDemoSlice.reducer;
