import React from "react";
import { Stack, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { editContentScriptMode, selectViewDemo } from "./viewDemoSlice";
import { DemoScriptMode, demoStateEditableContentForKey } from "./viewDemoBaseReduxUtils";

interface Props {
  contentKey: string;
}

export const DemoPresentationScriptViewModeSelector: React.FC<Props> = ({ contentKey }) => {
  const viewDemoState = useAppSelector(selectViewDemo);
  const dispatch = useAppDispatch();

  const content = demoStateEditableContentForKey(viewDemoState, contentKey);

  return (
    <Stack alignItems="center">
      <ToggleButtonGroup
        value={content.content.showBulleted ? DemoScriptMode.BULLETED : DemoScriptMode.FULL}
        sx={{ mt: 2 }}
        orientation="vertical"
      >
        <ToggleButton
          value={DemoScriptMode.FULL}
          size="small"
          onClick={() => {
            dispatch(editContentScriptMode({ contentKey, scriptMode: DemoScriptMode.FULL }));
          }}
        >
          <Tooltip title="Scripted">
            <ArticleIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          value={DemoScriptMode.BULLETED}
          size="small"
          onClick={() => {
            dispatch(editContentScriptMode({ contentKey, scriptMode: DemoScriptMode.BULLETED }));
          }}
        >
          <Tooltip title="Outlined">
            <FormatListBulletedIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
