import React from "react";
import { AppLayout } from "./layouts/appLayout";

interface Props {
  chosen?: string;
}

export const AppLoadingPage: React.FC<Props> = ({ chosen }) => {
  return <AppLayout chosen={chosen} />;
};
