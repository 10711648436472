import React, { useState } from "react";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Paper, Tab } from "@mui/material";

import { AppLayout } from "../../components/layouts/appLayout";
import { ApolloConsumer } from "@apollo/client";
import { AppSnackbar } from "../../components/appSnackbar";
import { MySharedDemosTable } from "./sharedDemos/mySharedDemosTable";
import { TeamLibraryDemosForUserTable } from "./sharedDemos/teamLibraryDemosForUserTable";

export const SharedDemosPage: React.FC = () => {
  const [tabIndex, setTabIndex] = useState("1");

  const handleTabChange = (event: any, newValue: string) => {
    setTabIndex(newValue);
  };

  return (
    <AppLayout chosen={"Shared Demos"}>
      <Paper sx={{ p: 2 }}>
        <ApolloConsumer>
          {(client) => (
            <React.Fragment>
              <TabContext value={tabIndex}>
                <TabList onChange={handleTabChange} aria-label="shared demo tabs">
                  <Tab label="Shared with Me" value="1" />
                  <Tab label="Team Library" value="2" />
                </TabList>

                <TabPanel value="1">
                  <MySharedDemosTable />
                </TabPanel>

                <TabPanel value="2">
                  <TeamLibraryDemosForUserTable />
                </TabPanel>
              </TabContext>
            </React.Fragment>
          )}
        </ApolloConsumer>
      </Paper>
      <AppSnackbar />
    </AppLayout>
  );
};
