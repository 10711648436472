export const arrayWithElementMoved = <T>(objArray: T[], index: number, delta: number): T[] | undefined => {
  let objArrayCopy = objArray.map((c) => {
    return { ...c };
  });

  if (index < 0 || index + delta < 0 || index + delta > objArrayCopy.length - 1) {
    return;
  }
  const tfs = objArrayCopy[index];
  objArrayCopy.splice(index, 1);
  objArrayCopy.splice(index + delta, 0, tfs);
  return objArrayCopy;
};

export const objectIdsFromArrayNotInOtherArray = (array: any[], otherArray: any[]): any[] => {
  return array.filter((arrayElement: any) => {
    return (
      otherArray.findIndex((otherArrayElement: any) => {
        return arrayElement.id === otherArrayElement.id;
      }) < 0
    );
  });
};
