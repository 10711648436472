import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { closeSnackbar, selectSnackbar } from "./appSnackbarSlice";

export const AppSnackbar: React.FC = () => {
  const snackbarState = useAppSelector(selectSnackbar);
  const dispatch = useAppDispatch();

  const onClose = (_event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snackbarState.open}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <Alert variant="filled" severity={snackbarState.severity} sx={{ width: "100%" }}>
        {snackbarState.text}
      </Alert>
    </Snackbar>
  );
};
