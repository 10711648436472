import React from "react";
import { IconButton, List, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import { UserNotification } from "../../generated/graphql";
import { NotificationsFilterMode } from "./notificationsFilter";
import CircleIcon from "@mui/icons-material/Circle";
import CheckIcon from "@mui/icons-material/Check";

interface Props {
  notifications: UserNotification[];
  dense?: boolean;
  simple?: boolean;
  filter?: NotificationsFilterMode;
  onClickNotification?: (n: UserNotification) => void;
}

export const NotificationList: React.FC<Props> = ({ notifications, dense, simple, filter, onClickNotification }) => {
  const showAll = (filter ?? NotificationsFilterMode.ALL) === NotificationsFilterMode.ALL;
  if (!showAll && !notifications.some((n) => !n.wasRead)) {
    return <Typography sx={{ fontStyle: "italic" }}>No unread notifications</Typography>;
  }
  return (
    <List dense={true}>
      {notifications.map((n: any) => {
        const emptyFeedback = n.feedbackBody === undefined || n.feedbackBody === "";
        return showAll || !n.wasRead ? (
          <ListItem key={n.id} sx={{ cursor: "default", display: "flex" }}>
            <Stack direction="row">
              <Stack sx={{ flexGrow: 1 }}>
                <Typography sx={{ lineHeight: dense ? 0.8 : 1.2, fontWeight: n.wasRead ? undefined : "bold" }}>
                  {n.body}
                </Typography>
                {simple ? (
                  <React.Fragment />
                ) : (
                  <Typography
                    sx={{
                      lineHeight: dense ? 0.8 : 1.2,
                      p: 1,
                      fontStyle: emptyFeedback ? "italic" : undefined,
                      border: "1px solid black",
                      backgroundColor: "#ffeeaa",
                    }}
                  >
                    {emptyFeedback ? "No feedback" : n.feedbackBody}
                  </Typography>
                )}
              </Stack>
              {simple ? (
                <React.Fragment />
              ) : (
                <Stack>
                  {n.wasRead ? (
                    <Tooltip title="Mark as unread">
                      <IconButton
                        onClick={() => {
                          if (onClickNotification) {
                            onClickNotification(n);
                          }
                        }}
                      >
                        <CircleIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Mark as read">
                      <IconButton
                        onClick={() => {
                          if (onClickNotification) {
                            onClickNotification(n);
                          }
                        }}
                      >
                        <CheckIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              )}
            </Stack>
          </ListItem>
        ) : (
          <React.Fragment key={n.id} />
        );
      })}
    </List>
  );
};
