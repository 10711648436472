import React, { useState } from "react";

import { Box, Button, Checkbox, Chip, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useParams } from "react-router";
import { AppErrorPage } from "../../components/appError";
import { SiteAdminOrgLayout } from "../../components/layouts/siteAdminOrgLayout";
import { SiteAdminAppLoadingPage } from "../../components/siteAdmin/siteAdminAppLoadingPage";
import { useAddSiteUserTeamMutation, useSiteUserQuery } from "../../generated/graphql";
import { ObjectArrayTable } from "../../components/objectArrayTable/objectArrayTable";
import { ContentPickerDialog } from "../../components/dialogs/contentPickerDialog";
import { SiteDataIdDisplayName } from "../../util/demoTypes";
import { gqlMutateWithHook } from "../../util/gqlHelpers";
import { isValidEmail } from "../../util/dataValidators";
import { AppSnackbar } from "../../components/appSnackbar";
import { useAppDispatch } from "../../store/hooks";
import { showSnackbarError, showSnackbarSuccess } from "../../components/appSnackbarSlice";
import { PaperWithTitle } from "../../components/paperWithTitle";
import { Breadcrumb } from "../../components/breadcrumb";
import {
  requestMailInvite,
  requestRemoveSiteUserTeam,
  requestUpdateSiteUser,
  requestUpdateSiteUserTeamAdmin,
} from "../../generated/graphqlWrappers";
import { ApolloClient, ApolloConsumer } from "@apollo/client";

interface Params {
  userId: string;
}

export const EditUserSiteAdminPage: React.FC = () => {
  const { userId } = useParams<Params>();

  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);

  const [lastSavedEmail, setLastSavedEmail] = useState("");
  const [lastSavedDisplayName, setLastSavedDisplayName] = useState("");
  const [lastSavedIsOrgAdmin, setLastSavedIsOrgAdmin] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const [teamPickerOpen, setTeamPickerOpen] = useState(false);

  const [addSiteUserTeam] = useAddSiteUserTeamMutation();

  const dispatch = useAppDispatch();

  const { data, error, loading, refetch } = useSiteUserQuery({
    variables: { userId: parseInt(userId) },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminAppLoadingPage />;
  }

  if (!initialized) {
    setEmail(data.siteUser.email);
    setDisplayName(data.siteUser.displayName);
    setIsOrgAdmin(data.siteUser.isOrgAdmin);

    setLastSavedEmail(data.siteUser.email);
    setLastSavedDisplayName(data.siteUser.displayName);
    setLastSavedIsOrgAdmin(data.siteUser.isOrgAdmin);

    setInitialized(true);
  }

  const getTeamsNotIncluded = (): SiteDataIdDisplayName[] => {
    return data.siteUser.organization.teams.filter(
      (orgTeam) => data.siteUser.teams.findIndex((team) => orgTeam.id === team.id) < 0
    );
  };

  const teamPickerOnClose = async (team?: SiteDataIdDisplayName): Promise<void> => {
    if (team) {
      const message = `Error adding team`;
      await gqlMutateWithHook(
        addSiteUserTeam,
        { userId: parseInt(data.siteUser.id), teamId: parseInt(team.id) },
        "addSiteUserTeam",
        () => {
          refetch();
        },
        () => {
          dispatch(showSnackbarError(message));
          return message;
        }
      );
    }
    setTeamPickerOpen(false);
  };

  const removeCell = async (client: ApolloClient<object>, teamId: string) => {
    const message = `Error removing team from user`;

    return await requestRemoveSiteUserTeam(
      client,
      {
        userId: parseInt(data.siteUser.id),
        teamId: parseInt(teamId),
      },
      () => {
        refetch();
      },
      () => {
        dispatch(showSnackbarError(message));
        return message;
      }
    );
  };

  const changeTeamAdmin = async (client: ApolloClient<object>, teamId: string, isTeamAdmin: boolean) => {
    const message = `Error making this user a team admin`;

    return await requestUpdateSiteUserTeamAdmin(
      client,
      {
        userId: parseInt(data.siteUser.id),
        teamId: parseInt(teamId),
        isTeamAdmin,
      },
      () => {
        refetch();
      },
      () => {
        dispatch(showSnackbarError(message));
        return message;
      }
    );
  };

  const sendInvitation = async (client: ApolloClient<object>) => {
    if (data.siteUser.inviteCode) {
      requestMailInvite(
        client,
        { inviteCode: data.siteUser.inviteCode },
        () => {
          dispatch(showSnackbarSuccess("Invitation sent"));
        },
        (err) => {
          dispatch(showSnackbarError(err));
        }
      );
    }
  };

  const save = async (client: ApolloClient<object>) => {
    const message = `Error saving user`;
    await requestUpdateSiteUser(
      client,
      { userId: parseInt(data.siteUser.id), displayName, email, isOrgAdmin },
      () => {
        setLastSavedEmail(email);
        setLastSavedDisplayName(displayName);
        setLastSavedIsOrgAdmin(isOrgAdmin);
        dispatch(showSnackbarSuccess("User saved"));
      },
      () => {
        dispatch(showSnackbarError(message));
        return message;
      }
    );
  };

  const revert = () => {
    setEmail(lastSavedEmail);
    setDisplayName(lastSavedDisplayName);
  };

  const dataChanged = (): boolean => {
    return email !== lastSavedEmail || displayName !== lastSavedDisplayName || isOrgAdmin !== lastSavedIsOrgAdmin;
  };

  const saveIsDisabled = (): boolean => {
    return !dataChanged() || !isValidEmail(email);
  };

  return (
    <SiteAdminOrgLayout chosen={"Users"} organizationId={data.siteUser.organization.id}>
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: data.siteUser.organization.displayName, url: `/site-admin/org/${data.siteUser.organization.id}` },
            { text: "Users", url: `/site-admin/org/${data.siteUser.organization.id}/users` },
          ]}
        />

        <PaperWithTitle title="Edit User">
          {data.siteUser.inviteCode ? (
            <Stack>
              <Typography>This user has been invited.</Typography>
              <Stack direction="row">
                <ApolloConsumer>
                  {(client) => (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ my: 2, ml: 2 }}
                      onClick={() => {
                        sendInvitation(client);
                      }}
                    >
                      Resend Invitation
                    </Button>
                  )}
                </ApolloConsumer>
              </Stack>
            </Stack>
          ) : (
            <React.Fragment />
          )}
          <TextField
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            label="E-mail"
            variant="outlined"
            sx={{ width: 350 }}
            required
            color="primary"
          />
          <TextField
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
            value={displayName}
            label="Display Name"
            variant="outlined"
            sx={{ width: 350 }}
            required
            color="primary"
          />
          <Stack direction="row" alignItems="center">
            <Checkbox
              checked={isOrgAdmin}
              onChange={(event) => {
                setIsOrgAdmin(event.target.checked);
              }}
            />
            <Typography>{data.siteUser.organization.displayName} Organization Administrator</Typography>
          </Stack>
          <Box sx={{ display: "flex" }}>
            <Button variant="outlined" color="error" sx={{ my: 2 }} disabled={!dataChanged()} onClick={revert}>
              Revert
            </Button>
            <ApolloConsumer>
              {(client) => (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ my: 2, ml: 2 }}
                  disabled={saveIsDisabled()}
                  onClick={() => {
                    save(client);
                  }}
                >
                  Save
                </Button>
              )}
            </ApolloConsumer>
          </Box>
          <ApolloConsumer>
            {(client) => {
              return (
                <ObjectArrayTable
                  data={data.siteUser.teams}
                  dataDescription="teams"
                  title="Teams"
                  cellNode={(obj) => {
                    const isTeamAdmin =
                      data.siteUser.teamAdminRoles.findIndex((adminRole) => adminRole.team.id === obj.id) >= 0;
                    return (
                      <Stack direction="row" sx={{ display: "flex" }} alignItems="center" spacing={1}>
                        {isTeamAdmin ? (
                          <Chip size="small" color={"primary"} label={"team admin"} />
                        ) : (
                          <React.Fragment />
                        )}
                        <Typography sx={{ flexGrow: 1, lineHeight: 2.2, verticalAlign: "middle" }}>
                          {obj.displayName}
                        </Typography>
                        <Tooltip title="Remove user from team" placement="top-start">
                          <IconButton
                            onClick={() => {
                              removeCell(client, obj.id);
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={
                            isTeamAdmin ? "Make this user a non-admin of this team" : "Make this user a team admin"
                          }
                          placement="top-start"
                        >
                          <IconButton
                            onClick={() => {
                              changeTeamAdmin(client, obj.id, !isTeamAdmin);
                            }}
                          >
                            <AdminPanelSettingsIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    );
                  }}
                  onClick={(id) => {}}
                  buttons={[
                    {
                      text: "Add to Team",
                      disabled: getTeamsNotIncluded().length === 0,
                      onClick: () => {
                        setTeamPickerOpen(true);
                      },
                    },
                  ]}
                />
              );
            }}
          </ApolloConsumer>
          <ContentPickerDialog
            title="Choose Team"
            items={getTeamsNotIncluded()}
            open={teamPickerOpen}
            onClose={teamPickerOnClose}
          />
        </PaperWithTitle>
      </Stack>
      <AppSnackbar />
    </SiteAdminOrgLayout>
  );
};
