import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../store/store";
import { UserDemoTemplatesForPersonaQuery } from "../../../generated/graphql";

/**
 * Defines the slice, reducers, and actions for going through the New Demo wizard.
 */

type WizardTeam = UserDemoTemplatesForPersonaQuery["userDemoTemplatesForPersona"][0]["team"];

interface NewDemoState {
  name: string;
  description: string;
  templateId?: number;
  featureSetIds?: string[];
  conversationIds?: string[];
  team?: WizardTeam;
}

const initialState: NewDemoState = {
  name: "",
  description: "",
};

const toggleIdInArray = (id: string, array: string[] | undefined): string[] => {
  if (array === undefined) {
    array = [];
  }

  const index = array.indexOf(id);
  if (index > -1) {
    array.splice(index, 1);
  } else {
    array.push(id);
  }
  return array;
};

export const newDemoSlice = createSlice({
  name: "newDemo",
  initialState,
  reducers: {
    clearNewDemoState: (state) => {
      state.name = initialState.name;
      state.description = initialState.description;
      state.templateId = undefined;
      state.team = undefined;
      state.featureSetIds = undefined;
      state.conversationIds = undefined;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setTemplateId: (state, action: PayloadAction<number>) => {
      state.templateId = action.payload;
    },
    setTeam: (state, action: PayloadAction<WizardTeam>) => {
      state.team = action.payload;
    },
    setFeatureSetIds: (state, action: PayloadAction<string[] | undefined>) => {
      state.featureSetIds = action.payload;
    },
    toggleFeatureSetId: (state, action: PayloadAction<string>) => {
      state.featureSetIds = toggleIdInArray(action.payload, state.featureSetIds);
    },
    setConversationIds: (state, action: PayloadAction<string[] | undefined>) => {
      state.conversationIds = action.payload;
    },
    toggleConversationId: (state, action: PayloadAction<string>) => {
      state.conversationIds = toggleIdInArray(action.payload, state.conversationIds);
    },
  },
});

export const {
  clearNewDemoState,
  setName,
  setDescription,
  setTemplateId,
  setTeam,
  setFeatureSetIds,
  toggleFeatureSetId,
  setConversationIds,
  toggleConversationId,
} = newDemoSlice.actions;

export const selectNewDemo = (state: RootState) => state.newDemo as NewDemoState;

export default newDemoSlice.reducer;
