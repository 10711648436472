import React from "react";

import { useHistory, useParams } from "react-router";
import { useSiteTeamResourceCategoriesQuery } from "../../../../generated/graphql";
import { ViewTeamContentSiteAdminPage } from "../../../../components/siteAdmin/viewTeamContentSiteAdmin";

interface Params {
  teamId: string;
}

export const ViewTeamResourceCategoriesSiteAdminPage: React.FC = () => {
  let { teamId } = useParams<Params>();
  const history = useHistory();

  return (
    <ViewTeamContentSiteAdminPage
      teamId={teamId}
      query={useSiteTeamResourceCategoriesQuery}
      chosen="Resource Categories"
      getObjects={(data, categoryId) => {
        return data.resourceCategories;
      }}
      dataDescription="resource categories"
      title="Resource Category"
      newContentPath="new-resource-category"
      onClick={(id) => {
        history.push(`/site-admin/resource-category/${id}`);
      }}
      rowLabelProperty="name"
    />
  );
};
