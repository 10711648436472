import React, { useState } from "react";

import { useHistory } from "react-router";
import { Paper, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { AppErrorPage } from "../../components/appError";
import { AppLoadingPage } from "../../components/appLoadingPage";

import { useUserDemosQuery } from "../../generated/graphql";
import { AppLayout } from "../../components/layouts/appLayout";
import { DemoTable } from "../../components/demoTable/demoTable";
import { NewDemoDialog } from "./newDemo/newDemoDialog";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import {
  requestDuplicateUserDemo,
  requestRemoveUserDemo,
  requestUpdateUserDemoArchive,
} from "../../generated/graphqlWrappers";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import {
  clearMyDemosState,
  initializeMyDemosState,
  removeDemo,
  demoSuccessfullyDuplicated,
  selectMyDemos,
  setDeleteConfirmOpen,
  setDemoToDelete,
  setTabIndex,
  updateDemoArchived,
  findDemo,
  changeActiveDemosSort,
  changeArchivedDemosSort,
} from "./myDemosSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ConfirmRemoveDemoDialog } from "./myDemos/confirmRemoveDemoDialog";
import { AppSnackbar } from "../../components/appSnackbar";
import { showSnackbarSuccess } from "../../components/appSnackbarSlice";
import { ObjectTableButtonData } from "../../components/objectArrayTable/objectArrayTable";
import { ShareDemoDialog } from "./myDemos/shareDemoDialog";
import { Link } from "react-router-dom";

export const MyDemosPage: React.FC = () => {
  const history = useHistory();
  const myDemosState = useAppSelector(selectMyDemos);
  const dispatch = useAppDispatch();

  const [shareDemoId, setShareDemoId] = useState(0);
  const [shareTeamId, setShareTeamId] = useState(0);

  const handleTabChange = (event: any, newValue: string) => {
    dispatch(setTabIndex(newValue));
  };

  const [newDemoDialogOpen, setNewDemoDialogOpen] = useState(false);
  const [shareDemoDialogOpen, setShareDemoDialogOpen] = useState(false);

  const { data, error, loading } = useUserDemosQuery({
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    if (myDemosState.initialized) {
      dispatch(clearMyDemosState());
    }
    return <AppLoadingPage />;
  }

  if (!myDemosState.initialized) {
    dispatch(initializeMyDemosState(data));
    return <AppLoadingPage />;
  }

  const onNewDemoDialogClose = (demoId?: number) => {
    if (demoId) {
      history.push(`/view-demo/${demoId}`);
    }
    setNewDemoDialogOpen(false);
  };

  const onShareDemoDialogClose = (demoId?: number) => {
    setShareDemoDialogOpen(false);
  };

  const onUpdateArchiveOnDemo = async (client: ApolloClient<object>, demoId: number, archived: boolean) => {
    requestUpdateUserDemoArchive(
      client,
      { demoId, archived },
      () => {
        dispatch(updateDemoArchived({ demoId: `${demoId}`, archived }));
      },
      () => {}
    );
  };

  const onDuplicateDemo = async (client: ApolloClient<object>, demoId: number, name: string) => {
    requestDuplicateUserDemo(
      client,
      { demoId, name },
      (response) => {
        if (response.idInserted) {
          dispatch(demoSuccessfullyDuplicated({ demoId: `${demoId}`, newDemoId: `${response.idInserted}`, name }));
          dispatch(showSnackbarSuccess("Successfully duplicated as " + name));
        }
      },
      () => {}
    );
  };

  const onShareDemo = async (client: ApolloClient<object>, demoId: number, teamId: number) => {
    setShareDemoId(demoId);
    setShareTeamId(teamId);
    setShareDemoDialogOpen(true);
  };

  const onRemoveDemo = async (client: ApolloClient<object>, demoId: number) => {
    requestRemoveUserDemo(
      client,
      { demoId: demoId },
      () => {
        dispatch(removeDemo(`${demoId}`));
      },
      () => {}
    );
  };

  const duplicateButton = (client: ApolloClient<object>): ObjectTableButtonData => {
    return {
      icon: <ContentCopyIcon />,
      text: "Duplicate demo",
      onClick: (demoId) => {
        const demo = findDemo(myDemosState, `${demoId}`);
        if (demo) {
          onDuplicateDemo(client, demoId, demo.name + " (copy)");
        }
      },
    };
  };

  const shareButton = (client: ApolloClient<object>): ObjectTableButtonData => {
    return {
      icon: <PersonAddIcon />,
      text: "Share demo",
      onClick: (demoId) => {
        const demo = findDemo(myDemosState, `${demoId}`);
        if (demo && demo.demoTemplate) {
          onShareDemo(client, demoId, parseInt(demo.team.id));
        }
      },
    };
  };

  const hasTemplates = data.userDemoTemplatesForPersona.length > 0;
  const teamsCanAdmin = data.userAccount.isOrgAdmin
    ? data.teams
    : data.userAccount.teamAdminRoles.map((role) => role.team);

  return (
    <AppLayout chosen={"My Demos"}>
      <Paper sx={{ p: 2 }}>
        <ApolloConsumer>
          {(client) => (
            <React.Fragment>
              <TabContext value={myDemosState.tabIndex}>
                <TabList onChange={handleTabChange} aria-label="demo tabs">
                  <Tab label="Active" value="1" />
                  <Tab label="Archive" value="2" />
                </TabList>

                <TabPanel value="1">
                  <DemoTable
                    demos={myDemosState.activeDemos}
                    dataDescription="active demos"
                    onNewDemo={() => {
                      setNewDemoDialogOpen(true);
                    }}
                    newDemoDisabled={!hasTemplates}
                    onLaunchDemo={(demoId) => {
                      history.push(`/view-demo/${demoId}`);
                    }}
                    buttons={(demoId) => {
                      return [
                        shareButton(client),
                        duplicateButton(client),
                        {
                          icon: <ArchiveOutlinedIcon />,
                          text: "Archive demo",
                          onClick: (demoId) => {
                            onUpdateArchiveOnDemo(client, demoId, true);
                          },
                        },
                      ];
                    }}
                    onHeaderClick={(colIndex) => {
                      dispatch(changeActiveDemosSort(colIndex));
                    }}
                    headerSort={myDemosState.activeDemosSortColumn}
                  />
                  {!hasTemplates ? (
                    <React.Fragment>
                      <Typography>
                        You cannot create demos because none of your teams have complete templates.
                      </Typography>
                      <Typography sx={{ mt: 2 }}>
                        {data.userAccount.teams.length > 0
                          ? [
                              "You are on the following teams:",
                              <ul>
                                {data.userAccount.teams.map((team) => (
                                  <li key={team.id}>
                                    <Link to={"/team/" + team.id + "/templates"}>{team.displayName}</Link>
                                  </li>
                                ))}
                              </ul>,
                            ]
                          : "You are not on any teams."}
                      </Typography>
                      <Typography>
                        {teamsCanAdmin.length > 0
                          ? [
                              "As an admin, you can add templates or users to these teams: ",
                              <ul>
                                {teamsCanAdmin.map((team) => (
                                  <li key={team.id}>
                                    <Link to={"/team/" + team.id + "/templates"}>{team.displayName}</Link>
                                  </li>
                                ))}
                              </ul>,
                            ]
                          : " You are not an admin of any teams, so contact your team administrator."}
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </TabPanel>
                <TabPanel value="2">
                  <DemoTable
                    demos={myDemosState.archivedDemos}
                    dataDescription="archived demos"
                    onNewDemo={() => {
                      setNewDemoDialogOpen(true);
                    }}
                    onLaunchDemo={(demoId) => {
                      history.push(`/view-demo/${demoId}`);
                    }}
                    buttons={(demoId) => {
                      return [
                        duplicateButton(client),
                        {
                          icon: <UnarchiveOutlinedIcon />,
                          text: "Unarchive demo",
                          onClick: (demoId) => {
                            onUpdateArchiveOnDemo(client, demoId, false);
                          },
                        },
                        {
                          icon: <DeleteOutlinedIcon />,
                          text: "Delete demo",
                          onClick: (demoId) => {
                            dispatch(setDemoToDelete(demoId));
                            dispatch(setDeleteConfirmOpen(true));
                          },
                        },
                      ];
                    }}
                    onHeaderClick={(colIndex) => {
                      dispatch(changeArchivedDemosSort(colIndex));
                    }}
                    headerSort={myDemosState.archivedDemosSortColumn}
                  />
                </TabPanel>
              </TabContext>
              <ConfirmRemoveDemoDialog onRemoveDemo={onRemoveDemo} />
            </React.Fragment>
          )}
        </ApolloConsumer>
      </Paper>
      <NewDemoDialog open={newDemoDialogOpen} onClose={onNewDemoDialogClose} />
      <ShareDemoDialog
        demoId={shareDemoId}
        teamId={shareTeamId}
        open={shareDemoDialogOpen}
        onClose={onShareDemoDialogClose}
      />
      <AppSnackbar />
    </AppLayout>
  );
};
