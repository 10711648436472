import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { AppSnackbar } from "../components/appSnackbar";
import { showSnackbarError, showSnackbarSuccess } from "../components/appSnackbarSlice";
import { AppLayout } from "../components/layouts/appLayout";
import { PaperWithTitle } from "../components/paperWithTitle";
import { useChangePasswordMutation } from "../generated/graphql";
import { useAppDispatch } from "../store/hooks";
import { setAccessToken, setSiteAdmin } from "../util/auth";

export const ChangePasswordPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [changePassword] = useChangePasswordMutation();

  const changePasswordDisabled = () => {
    return newPassword1 !== newPassword2 || newPassword1.length < 6;
  };

  const onChangePasswordClick = async (client: ApolloClient<object>) => {
    try {
      const response = await changePassword({ variables: { oldPassword: password, newPassword: newPassword1 } });
      if (response && response.data) {
        dispatch(showSnackbarSuccess("Password successfully changed"));
        setAccessToken(response.data.changePassword.accessToken);
        setSiteAdmin(response.data.changePassword.isSiteAdmin);
        setPassword("");
        setNewPassword1("");
        setNewPassword2("");
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(showSnackbarError(error.message));
      } else {
        dispatch(showSnackbarError("Error changing password"));
      }
    }
  };

  return (
    <AppLayout>
      <PaperWithTitle title="Change Password">
        <Stack spacing={2} alignItems="flex-start">
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            label="Current Password"
            variant="outlined"
            required
            type="password"
            color="primary"
            autoComplete="current-password"
            sx={{ width: 250 }}
          />
          <TextField
            onChange={(e) => setNewPassword1(e.target.value)}
            value={newPassword1}
            label="New Password"
            variant="outlined"
            required
            type="password"
            color="primary"
            autoComplete="new-password"
            sx={{ width: 250 }}
          />
          <TextField
            onChange={(e) => setNewPassword2(e.target.value)}
            value={newPassword2}
            label="Confirm New Password"
            variant="outlined"
            required
            type="password"
            color="primary"
            autoComplete="new-password"
            sx={{ width: 250 }}
          />
          <ApolloConsumer>
            {(client) => (
              <Button
                size="large"
                variant="contained"
                color="primary"
                disabled={changePasswordDisabled()}
                onClick={() => onChangePasswordClick(client)}
              >
                Change Password
              </Button>
            )}
          </ApolloConsumer>
        </Stack>
        <AppSnackbar />
      </PaperWithTitle>
    </AppLayout>
  );
};
