import React, { useState } from "react";
import { Stack, Tab, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { AppErrorPage } from "../../../../components/appError";
import { AppTeamLoadingPage } from "../../../../components/appTeamLoadingPage";
import { ObjectArrayTable } from "../../../../components/objectArrayTable/objectArrayTable";
import { isAdminForOrgOrTeam } from "../../../../util/adminUtils";
import { CreateContentDialog } from "../../../../components/dialogs/createContentDialog";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { SuccessResponse } from "../../../../generated/graphql";
import { DemoContentType, labelForContentType, urlPathForContentType } from "../../../../util/demoTypes";
import { AppTeamLayout } from "../../../../components/layouts/appTeamLayout";
import { Breadcrumb } from "../../../../components/breadcrumb";
import { PaperWithTitle } from "../../../../components/paperWithTitle";
import { ObjectTableCellButton } from "../../../../components/objectArrayTable/objectTableCellButton";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import { AppSnackbar } from "../../../../components/appSnackbar";
import { useAppDispatch } from "../../../../store/hooks";
import { showSnackbarError, showSnackbarSuccess } from "../../../../components/appSnackbarSlice";
import { TitleWithAdminChip } from "../../../../components/titleWithAdminChip";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import _ from "underscore";

interface Props {
  teamId: string;
  useContentListQuery: (options: { variables: { teamId: number }; fetchPolicy: "network-only" }) => any;
  requestCreateContent: (
    client: ApolloClient<object>,
    variables: { teamId: number; name: string; body: string; bulletedBody: string; whatToShow: string },
    onSuccess: () => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestArchiveContent: (
    client: ApolloClient<object>,
    variables: { teamId: number; contentId: number; archived: boolean },
    onSuccess: () => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  contentType: DemoContentType;
  chosen: string;
}

export const TeamContentTablePageLayout: React.FC<Props> = ({
  teamId,
  useContentListQuery,
  requestCreateContent,
  requestArchiveContent,
  contentType,
  chosen,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [createTeamContentDialogOpen, setCreateTeamContentDialogOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("1");
  const { data, loading, error, refetch } = useContentListQuery({
    variables: { teamId: parseInt(teamId) },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }

  if (!data || loading) {
    if (!data && currentTab !== "1") {
      setCurrentTab("1");
    }
    return <AppTeamLoadingPage teamId={teamId} />;
  }

  const isAdmin = isAdminForOrgOrTeam(data.userAccount, data.userTeam.admins);
  const teamName = data.userTeam.displayName;

  const [activeData, archivedData] = _.partition(data[`user${contentType}sForTeam`], (c) => !c.archived);

  const handleTabChange = (event: any, newValue: string) => {
    setCurrentTab(newValue);
  };

  const onChangeArchive = (client: ApolloClient<object>, contentId: number, archived: boolean) => {
    requestArchiveContent(
      client,
      { teamId: parseInt(teamId), contentId, archived: archived },
      () => {
        refetch();
        dispatch(showSnackbarSuccess(`${archived ? "Archived" : "Unarchived"} ${labelForContentType(contentType)}`));
      },
      (message) => {
        dispatch(showSnackbarError(message));
      }
    );
  };

  const onArchive = (client: ApolloClient<object>, contentId: number) => {
    onChangeArchive(client, contentId, true);
  };

  const onUnarchive = (client: ApolloClient<object>, contentId: number) => {
    onChangeArchive(client, contentId, false);
  };

  return (
    <AppTeamLayout chosen={chosen} teamId={teamId} teamName={teamName}>
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: "Home", url: "/" },
            { text: "Teams", url: `/teams` },
            { text: teamName, url: `/team/${teamId}` },
          ]}
        />
        <PaperWithTitle
          title={
            <TitleWithAdminChip
              title={`${labelForContentType(contentType)}s for ${teamName}`}
              isAdmin={isAdmin}
              isOrgAdmin={data.userAccount.isOrgAdmin}
            />
          }
        >
          <ApolloConsumer>
            {(client) => (
              <TabContext value={currentTab}>
                <TabList onChange={handleTabChange} aria-label="demo tabs">
                  <Tab label="Active" value="1" />
                  <Tab label="Archive" value="2" />
                </TabList>
                <TabPanel value="1">
                  <ObjectArrayTable
                    dataDescription={`${labelForContentType(contentType)}s`}
                    title={`${labelForContentType(contentType)}`}
                    data={activeData}
                    buttons={
                      isAdmin
                        ? [
                            {
                              text: `New ${labelForContentType(contentType)}`,
                              onClick: () => {
                                setCreateTeamContentDialogOpen(true);
                              },
                            },
                          ]
                        : []
                    }
                    cellNode={(obj: any) => (
                      <Stack direction="row" alignItems="center">
                        <Stack
                          display="flex"
                          sx={{ flexGrow: 1 }}
                          onClick={() => {
                            history.push(`/team/${teamId}/${urlPathForContentType(contentType)}/${obj.id}`);
                          }}
                        >
                          <Typography variant="body1">{obj.name}</Typography>
                        </Stack>
                        {isAdmin ? (
                          <React.Fragment>
                            {obj.numUnreadNotifications > 0 ? (
                              <ObjectTableCellButton
                                objectId={obj.id}
                                button={{
                                  text: "Unread Notifications",
                                  onClick: (id: number) => {
                                    history.push(`/team/${teamId}/${urlPathForContentType(contentType)}/${id}`);
                                  },
                                  icon: <CircleIcon color="error" />,
                                }}
                              />
                            ) : (
                              <React.Fragment />
                            )}
                            <ObjectTableCellButton
                              objectId={obj.id}
                              button={{
                                text: "Archive",
                                onClick: (id: number) => {
                                  onArchive(client, id);
                                },
                                icon: <ArchiveOutlinedIcon />,
                              }}
                            />
                          </React.Fragment>
                        ) : (
                          <React.Fragment />
                        )}
                      </Stack>
                    )}
                    onClick={(id: string) => {}}
                  />
                </TabPanel>
                <TabPanel value="2">
                  <ObjectArrayTable
                    dataDescription={`${labelForContentType(contentType)}s`}
                    title={`${labelForContentType(contentType)}`}
                    data={archivedData}
                    cellNode={(obj: any) => (
                      <Stack direction="row" alignItems="center">
                        <Stack
                          display="flex"
                          sx={{ flexGrow: 1 }}
                          onClick={() => {
                            history.push(`/team/${teamId}/${urlPathForContentType(contentType)}/${obj.id}`);
                          }}
                        >
                          <Typography variant="body1">{obj.name}</Typography>
                        </Stack>
                        {isAdmin ? (
                          <React.Fragment>
                            <ObjectTableCellButton
                              objectId={obj.id}
                              button={{
                                text: "Unarchive",
                                onClick: (id: number) => {
                                  onUnarchive(client, id);
                                },
                                icon: <UnarchiveOutlinedIcon />,
                              }}
                            />
                          </React.Fragment>
                        ) : (
                          <React.Fragment />
                        )}
                      </Stack>
                    )}
                    onClick={(id: string) => {}}
                  />
                </TabPanel>
              </TabContext>
            )}
          </ApolloConsumer>
          <CreateContentDialog
            open={createTeamContentDialogOpen}
            onClose={(added) => {
              if (added) {
                refetch();
              }
              setCreateTeamContentDialogOpen(false);
            }}
            nameLabel={`New ${labelForContentType(contentType)}`}
            buttonLabel={`Add ${labelForContentType(contentType)}`}
            onSave={async (client, name, onSuccess, onError) => {
              requestCreateContent(
                client,
                { teamId: parseInt(teamId), name, whatToShow: "", body: "", bulletedBody: "" },
                onSuccess,
                onError
              );
            }}
          />
        </PaperWithTitle>
        <AppSnackbar />
      </Stack>
    </AppTeamLayout>
  );
};
