import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";
import { UserNotification } from "../../../../generated/graphql";

/**
 * Defines the slice, reducers, and actions for managing team content notifications.
 */

export interface TeamContentNotificationState {
  initialized: boolean;
  notifications: UserNotification[];
}

const initialState: TeamContentNotificationState = {
  initialized: false,
  notifications: [],
};

export const teamContentNotificationsSlice = createSlice({
  name: "teamContentNotification",
  initialState,
  reducers: {
    initializeTeamContentNotificationState: (state, action: PayloadAction<[UserNotification]>) => {
      state.initialized = true;
      state.notifications = action.payload;
    },
    clearTeamContentNotificationState: (state) => {
      state.initialized = initialState.initialized;
      state.notifications = initialState.notifications;
    },
    notificationMarkedAsUnread: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.map((n) => {
        if (n.id === action.payload) {
          return { ...n, wasRead: false };
        }
        return n;
      });
    },
    notificationMarkedAsRead: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.map((n) => {
        if (n.id === action.payload) {
          return { ...n, wasRead: true };
        }
        return n;
      });
    },
  },
});

export const {
  initializeTeamContentNotificationState,
  clearTeamContentNotificationState,
  notificationMarkedAsUnread,
  notificationMarkedAsRead,
} = teamContentNotificationsSlice.actions;

export const selectTeamContentNotifications = (state: RootState) =>
  state.teamContentNotification as TeamContentNotificationState;

export default teamContentNotificationsSlice.reducer;
