import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { red } from "@mui/material/colors";

import { useAppSelector } from "../../../store/hooks";
import { ViewDemoContext } from "./viewDemoBaseReduxUtils";

export const DemoErrorMessage: React.FC = () => {
  const context = useContext(ViewDemoContext);
  const viewDemoState = useAppSelector(context.selectState);

  if (viewDemoState.errorMessage === "") {
    return <React.Fragment />;
  }

  return <Typography color={red[900]}>{viewDemoState.errorMessage}</Typography>;
};
