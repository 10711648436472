import React, { useEffect, useState } from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { Button, Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useHistory, useParams } from "react-router";

import { AppErrorPage } from "../../../../components/appError";
import { Breadcrumb } from "../../../../components/breadcrumb";
import { SiteAdminTeamLayout } from "../../../../components/layouts/siteAdminTeamLayout";
import { PaperWithTitle } from "../../../../components/paperWithTitle";
import { ResourceEditor } from "../../../../components/forms/resourceEditor";
import { SiteAdminTeamAppLoadingPage } from "../../../../components/siteAdmin/siteAdminTeamAppLoadingPage";
import { useSiteResourceQuery } from "../../../../generated/graphql";
import { requestUpdateSiteResource } from "../../../../generated/graphqlWrappers";
import { Prompt } from "react-router-dom";

interface Params {
  resourceId: string;
}

export const EditTeamResourceSiteAdminPage: React.FC = () => {
  let history = useHistory();
  let { resourceId } = useParams<Params>();

  let [initialized, setInitialized] = useState(false);
  let [name, setName] = useState("");
  let [body, setBody] = useState("");
  let [categoryId, setCategoryId] = useState(-1);
  let [errorMessage, setErrorMessage] = useState("");
  const [contentIsChanged, setContentIsChanged] = useState(false);

  useEffect(() => {
    if (contentIsChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [contentIsChanged]);

  const { data, error, loading } = useSiteResourceQuery({
    variables: { resourceId: parseInt(resourceId) },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminTeamAppLoadingPage />;
  }

  const resource = data.siteResource;
  const team = resource.resourceCategory.team;

  if (!initialized) {
    setCategoryId(parseInt(resource.resourceCategory.id));
    setName(resource.name);
    setBody(resource.body);
    setInitialized(true);
    setContentIsChanged(false);
    return <SiteAdminTeamAppLoadingPage />;
  }

  const saveIsDisabled = () => {
    return name === "";
  };

  const onClose = () => {
    history.push(`/site-admin/team/${team.id}/resources`);
  };

  const onSaveAndClose = async (client: ApolloClient<object>) => {
    await save(client, onClose);
  };

  const save = async (client: ApolloClient<object>, onSuccess?: () => void) => {
    setErrorMessage("");
    await requestUpdateSiteResource(
      client,
      { resourceId: parseInt(resourceId), teamId: parseInt(team.id), name, body, categoryId },
      () => {
        setContentIsChanged(false);
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        setErrorMessage("Error creating Resource");
      }
    );
  };

  return (
    <SiteAdminTeamLayout chosen="Resources" teamId={team.id}>
      <Prompt when={contentIsChanged} message="Are you sure you want to leave? You have unsaved changes." />
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: team.organization.displayName, url: `/site-admin/org/${team.organization.id}` },
            { text: team.displayName, url: `/site-admin/team/${team.id}` },
            { text: "Resources", url: `/site-admin/team/${team.id}/resources` },
          ]}
        />
        <PaperWithTitle title="Edit Resource">
          <ResourceEditor
            nameState={[name, setName]}
            bodyState={[body, setBody]}
            categoryIdState={[categoryId, setCategoryId]}
            categories={team.resourceCategories}
            setContentIsChanged={setContentIsChanged}
          />
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <ApolloConsumer>
              {(client) => (
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={saveIsDisabled() || !contentIsChanged}
                  onClick={() => {
                    save(client);
                  }}
                >
                  Save
                </Button>
              )}
            </ApolloConsumer>
            <ApolloConsumer>
              {(client) => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={saveIsDisabled()}
                  onClick={() => {
                    onSaveAndClose(client);
                  }}
                >
                  {contentIsChanged ? "Save and Close" : "Close"}
                </Button>
              )}
            </ApolloConsumer>
          </Stack>
          {errorMessage === "" ? <React.Fragment /> : <Typography color={red[900]}>{errorMessage}</Typography>}
        </PaperWithTitle>
      </Stack>
    </SiteAdminTeamLayout>
  );
};
