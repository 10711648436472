import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectedResourceCategories, selectResources, setCategoryIndex, setTeamIndex } from "../resourcesSlice";

export const ResourcesFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const resourcesState = useAppSelector(selectResources);
  const selectedCategories = selectedResourceCategories(resourcesState);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {
        // If there is only one team, no need to show the Drop-down
        resourcesState.data.length > 1 ? (
          <FormControl>
            <InputLabel id={`filter-team-label`}>Team</InputLabel>
            <Select
              labelId={`filter-team-label`}
              id={`filter-select-team`}
              value={resourcesState.teamIndex}
              label="Team"
              onChange={(e) => {
                dispatch(setTeamIndex(e.target.value as number));
              }}
            >
              {resourcesState.data.map((team, i) => {
                return (
                  <MenuItem key={team.id} value={i}>
                    {team.displayName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : (
          <React.Fragment />
        )
      }
      {selectedCategories.length > 0 ? (
        <FormControl>
          <InputLabel id={`filter-category-label`}>Category</InputLabel>
          <Select
            labelId={`filter-category-label`}
            id={`filter-select-category`}
            value={resourcesState.categoryIndex}
            label="Category"
            onChange={(e) => {
              dispatch(setCategoryIndex(e.target.value as number));
            }}
          >
            {selectedCategories.map((category, i) => {
              return (
                <MenuItem key={category.id} value={i}>
                  {category.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <React.Fragment />
      )}
    </Stack>
  );
};
