import React from "react";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import { selectViewDemoTemplate, setTemplateConversations } from "../store/viewDemoTemplateSlice";
import { DemoContentCell } from "./demoContentCell";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { showSnackbarError } from "../../../../components/appSnackbarSlice";
import { SuccessResponse } from "../../../../generated/graphql";
import { ApolloClient, ApolloConsumer } from "@apollo/client";

interface Props {
  demoTemplateId: number;
  obj: any;
  readOnly: boolean;
  requestUpdateDemoTemplateConversationRecommended: (
    client: ApolloClient<object>,
    variables: { demoTemplateConversationId: number; recommended: boolean },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestRemoveDemoTemplateConversation: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; demoTemplateConversationId: number },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
}

export const ConversationCell: React.FC<Props> = ({
  demoTemplateId,
  obj,
  readOnly,
  requestUpdateDemoTemplateConversationRecommended,
  requestRemoveDemoTemplateConversation,
}) => {
  const dispatch = useAppDispatch();
  const viewDemoTemplateState = useAppSelector(selectViewDemoTemplate);

  const reportError = (message: string) => {
    dispatch(showSnackbarError(message));
  };

  const updateConversationRecommended = async (
    client: ApolloClient<object>,
    demoTemplateConversationId: string,
    recommended: boolean
  ) => {
    const message = `Error updating conversation recommended`;
    const index = viewDemoTemplateState.templateConversations.findIndex((tc) => tc.id === demoTemplateConversationId);

    if (index < 0) {
      reportError(message);
      return;
    }
    let templateConversationsCopy = viewDemoTemplateState.templateConversations.map((tc) =>
      tc.id === demoTemplateConversationId
        ? {
            __typename: tc.__typename,
            id: tc.id,
            recommended,
            order: tc.order,
            conversation: tc.conversation,
          }
        : tc
    );
    return await requestUpdateDemoTemplateConversationRecommended(
      client,
      {
        demoTemplateConversationId: parseInt(demoTemplateConversationId),
        recommended,
      },
      () => {
        dispatch(setTemplateConversations(templateConversationsCopy));
      },
      () => {
        reportError(message);
        return message;
      }
    );
  };

  const removeCell = async (client: ApolloClient<object>, demoTemplateConversationId: string) => {
    const message = `Error removing conversation`;
    const index = viewDemoTemplateState.templateConversations.findIndex((x) => x.id === demoTemplateConversationId);

    if (index < 0) {
      reportError(message);
      return;
    }

    return await requestRemoveDemoTemplateConversation(
      client,
      {
        demoTemplateId,
        demoTemplateConversationId: parseInt(demoTemplateConversationId),
      },
      () => {
        let templateConversationsCopy = [...viewDemoTemplateState.templateConversations];
        templateConversationsCopy.splice(index, 1);
        dispatch(setTemplateConversations(templateConversationsCopy));
      },
      () => {
        reportError(message);
        return message;
      }
    );
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <DemoContentCell
          obj={obj}
          icon={<QuestionAnswerIcon sx={{ mt: 0.5, mr: 1 }} />}
          setRecommended={(id, recommended) => updateConversationRecommended(client, id, recommended)}
          removeCell={(id) => removeCell(client, id)}
          readOnly={readOnly}
        />
      )}
    </ApolloConsumer>
  );
};
