import React, { useContext } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { mapDemoStateContent, ViewDemoContext } from "./viewDemoBaseReduxUtils";
import { useAppSelector } from "../../../store/hooks";
import { demoContentKey } from "../../../util/demoTypes";
import { ContentPresentationView } from "./contentPresentationView";

interface Props {
  name: string;
  isWide: boolean;
  showScriptMode?: boolean;
}

export const DemoPresentationView: React.FC<Props> = ({ name, isWide, showScriptMode }) => {
  const context = useContext(ViewDemoContext);
  const viewDemoState = useAppSelector(context.selectState);

  return (
    <Stack sx={{ px: 2 }} direction="column" spacing={2} className="demoPresentationView">
      <Paper sx={{ p: 2 }}>
        <Typography sx={{ my: 1 }} variant="h3" align="center">
          {viewDemoState.demoName}
        </Typography>
        {mapDemoStateContent(viewDemoState, (content) => {
          return (
            <ContentPresentationView
              content={content}
              key={demoContentKey(content)}
              scrollToTop={(top: number) => {
                window.scrollTo({ top: top - 80 });
              }}
              bodyForContent={(content) => (content.showBulleted ? content.bulletedBody : content.body)}
              isWide={isWide}
              showScriptMode={showScriptMode}
            />
          );
        })}
      </Paper>
    </Stack>
  );
};
