import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useHistory, useParams } from "react-router";

import { SiteAdminTeamLayout } from "../../../../components/layouts/siteAdminTeamLayout";
import { requestCreateSiteResourceCategory } from "../../../../generated/graphqlWrappers";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { useSiteTeamResourceCategoriesQuery } from "../../../../generated/graphql";
import { AppErrorPage } from "../../../../components/appError";
import { SiteAdminTeamAppLoadingPage } from "../../../../components/siteAdmin/siteAdminTeamAppLoadingPage";
import { ResourceCategoryEditor } from "../../../../components/forms/resourceCategoryEditor";
import { Breadcrumb } from "../../../../components/breadcrumb";
import { PaperWithTitle } from "../../../../components/paperWithTitle";

interface Params {
  teamId: string;
}

export const CreateTeamResourceCategorySiteAdminPage: React.FC = () => {
  let history = useHistory();
  const { teamId } = useParams<Params>();
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { data, error, loading } = useSiteTeamResourceCategoriesQuery({
    variables: {
      teamId: parseInt(teamId),
    },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminTeamAppLoadingPage />;
  }

  const team = data.siteTeam;

  const onClose = () => {
    history.push(`/site-admin/team/${teamId}/resource-categories`);
  };

  const saveIsDisabled = () => {
    return name === "";
  };

  const onSaveAndClose = async (client: ApolloClient<object>) => {
    await save(client, onClose);
  };

  const save = async (client: ApolloClient<object>, onSuccess: () => void) => {
    setErrorMessage("");
    await requestCreateSiteResourceCategory(
      client,
      { teamId: parseInt(teamId), name },
      () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        setErrorMessage("Error creating Resource Category");
      }
    );
  };

  return (
    <SiteAdminTeamLayout chosen="Resource Categories" teamId={teamId}>
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: team.organization.displayName, url: `/site-admin/org/${team.organization.id}` },
            { text: team.displayName, url: `/site-admin/team/${team.id}` },
            { text: "Resource Categories", url: `/site-admin/team/${team.id}/resource-categories` },
          ]}
        />
        <PaperWithTitle title="New Resource Category">
          <ResourceCategoryEditor nameState={[name, setName]} />
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <ApolloConsumer>
              {(client) => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={saveIsDisabled()}
                  onClick={() => {
                    onSaveAndClose(client);
                  }}
                >
                  Add Resource Category
                </Button>
              )}
            </ApolloConsumer>
          </Stack>
          {errorMessage === "" ? <React.Fragment /> : <Typography color={red[900]}>{errorMessage}</Typography>}
        </PaperWithTitle>
      </Stack>
    </SiteAdminTeamLayout>
  );
};
