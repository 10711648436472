import React from "react";

import { useHistory, useParams } from "react-router";
import { CreateTeamDemoContentSiteAdmin } from "../../../../components/siteAdmin/createTeamDemoContentSiteAdmin";
import { useCreateSiteFeatureSetMutation } from "../../../../generated/graphql";

interface Params {
  teamId: string;
}

export const CreateTeamFeatureSetSiteAdminPage: React.FC = () => {
  let history = useHistory();
  const { teamId } = useParams<Params>();
  const [newSiteFeatureSet] = useCreateSiteFeatureSetMutation();

  return (
    <CreateTeamDemoContentSiteAdmin
      contentType="Feature"
      teamId={teamId}
      createContent={newSiteFeatureSet}
      onClose={(teamId) => {
        history.push(`/site-admin/team/${teamId}/feature-sets`);
      }}
      resultDataProperty="createSiteFeatureSet"
    ></CreateTeamDemoContentSiteAdmin>
  );
};
