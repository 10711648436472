import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import { UserTeamDemoTemplateData } from "../../../util/demoTypes";

/**
 * Defines the slice, reducers, and actions for showing stakeholder templates in a table.
 */

export interface TeamTemplatesState {
  initialized: boolean;
  activeTab: string;
  team?: UserTeamDemoTemplateData;
}

const initialState: TeamTemplatesState = {
  initialized: false,
  activeTab: "1",
};

export const teamTemplatesSlice = createSlice({
  name: "teamTemplates",
  initialState,
  reducers: {
    clearTeamDemoTemplatesState: (state) => {
      state.initialized = false;
      state.team = initialState.team;
      state.activeTab = initialState.activeTab;
    },
    initializeTeamDemoTemplatesState: (state, action: PayloadAction<UserTeamDemoTemplateData>) => {
      state.team = { ...action.payload };
      state.initialized = true;
      state.activeTab = "1";
    },
    updateTeamDemoTemplateArchived: (state, action: PayloadAction<{ demoTemplateId: string; archived: boolean }>) => {
      if (!state.team) {
        return;
      }
      let templates = [...state.team.demoTemplates];
      const index = templates.findIndex((dt) => dt.id === action.payload.demoTemplateId);
      if (index >= 0) {
        templates.splice(index, 1, { ...templates[index], archived: action.payload.archived });
        state.team.demoTemplates = templates;
      }
    },
    demoTemplateAdded: (state, action: PayloadAction<{ templateId: string; name: string }>) => {
      if (!state.team) {
        return;
      }
      let templates = [
        ...state.team.demoTemplates,
        { id: action.payload.templateId, name: action.payload.name, archived: false },
      ];
      templates.sort((a, b) => a.name.localeCompare(b.name));
      state.team.demoTemplates = templates;
    },
    tabChanged: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
  },
});

export const {
  clearTeamDemoTemplatesState,
  initializeTeamDemoTemplatesState,
  updateTeamDemoTemplateArchived,
  demoTemplateAdded,
  tabChanged,
} = teamTemplatesSlice.actions;

export const selectTeamDemoTemplates = (state: RootState) => state.teamTemplates as TeamTemplatesState;

export default teamTemplatesSlice.reducer;
