import React from "react";
import { ApolloConsumer, ApolloClient } from "@apollo/client";
import { Button, Stack, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import {
  selectViewDemo,
  setConfirmOverwriteTemplateSectionOpen,
  setErrorMessage,
  templateContentIsOverwritten,
} from "./viewDemoSlice";
import { requestOverwriteTemplateVersion } from "./graphql/viewDemoRequests";
import { ConfirmOverwriteTemplateSectionDialog } from "./confirmOverwriteTemplateSectionDialog";
import { showSnackbarError } from "../../../components/appSnackbarSlice";
import { demoStateChosenEditableContent } from "./viewDemoBaseReduxUtils";

export const OverwriteTemplateSectionAction: React.FC = () => {
  const viewDemoState = useAppSelector(selectViewDemo);
  const dispatch = useAppDispatch();

  const overwriteTemplateContentFromDemo = async (apolloClient: ApolloClient<object>) => {
    dispatch(setErrorMessage(""));
    await requestOverwriteTemplateVersion(
      apolloClient,
      viewDemoState,
      (content) => {
        dispatch(templateContentIsOverwritten({ key: viewDemoState.chosenKey, content }));
      },
      (error) => {
        dispatch(showSnackbarError(error));
        return error;
      }
    );
  };

  const hasOverwriteAuthorization = true;
  const isEdited = demoStateChosenEditableContent(viewDemoState).isEdited;

  return (
    <ApolloConsumer>
      {(client) =>
        hasOverwriteAuthorization ? (
          <Stack sx={{ my: 1 }}>
            <Typography variant="h5">Template</Typography>
            <Button
              sx={{ my: 1 }}
              disabled={isEdited}
              color="error"
              variant="outlined"
              onClick={() => dispatch(setConfirmOverwriteTemplateSectionOpen(true))}
            >
              Overwrite Template
            </Button>
            <ConfirmOverwriteTemplateSectionDialog
              open={viewDemoState.confirmOverwriteTemplateSectionOpen}
              closeDialog={(confirmed) => {
                dispatch(setConfirmOverwriteTemplateSectionOpen(false));
                if (confirmed) {
                  overwriteTemplateContentFromDemo(client);
                }
              }}
            />
          </Stack>
        ) : (
          <React.Fragment />
        )
      }
    </ApolloConsumer>
  );
};
