import { RemirrorJSON } from "@remirror/core";
import { wordCount } from "../../util/stringUtils";
import {
  BoldExtension,
  ItalicExtension,
  UnderlineExtension,
  BulletListExtension,
  OrderedListExtension,
  TextHighlightExtension,
  TextColorExtension,
  HeadingExtension,
} from "remirror/extensions";
import { NodeFormattingExtension } from "./DWNodeFormattingExtension";

export const plainTextToEditorContent = (text: string) => {
  const lines = text.split("\n");
  return {
    content: lines.map((line) => {
      return {
        content: line === "" ? [] : [{ text: line, type: "text" }],
        type: "paragraph",
      };
    }),
    type: "doc",
  };
};

export const textEditorFormatExtentions = () => {
  return () => [
    new BoldExtension(),
    new ItalicExtension(),
    new UnderlineExtension(),
    new BulletListExtension(),
    new OrderedListExtension(),
    new TextHighlightExtension(),
    new TextColorExtension(),
    new HeadingExtension(),
    new NodeFormattingExtension(),
  ];
};

export const parseToDocument = (s: string): RemirrorJSON => {
  try {
    return JSON.parse(s);
  } catch {
    return plainTextToEditorContent(s);
  }
};

export const editorContentWordCount = (contentContainer: any): number => {
  var totalWordCount = 0;
  if (contentContainer.content && Array.isArray(contentContainer.content)) {
    for (const content of contentContainer.content) {
      totalWordCount += editorContentWordCount(content);
    }
  } else if (contentContainer.content) {
    return editorContentWordCount(contentContainer.content);
  } else if (contentContainer.text) {
    return wordCount(contentContainer.text);
  }
  return totalWordCount;
};

export const docFragmentWordCount = (docFragment: DocumentFragment): number => {
  return childWordCount(docFragment.childNodes);
};

export const childWordCount = (children: NodeListOf<ChildNode>): number => {
  var totWordCount = 0;
  for (var i = 0; i < children.length; ++i) {
    const el = children.item(i);
    if (el) {
      totWordCount += wordCount(el.nodeValue ?? "");
      totWordCount += childWordCount(el.childNodes);
    }
  }
  return totWordCount;
};

export const isChangedBody = (body: string, newBody: string): boolean => {
  if (
    body === "" &&
    newBody ===
      '{"type":"doc","content":[{"type":"paragraph","attrs":{"nodeIndent":null,"nodeTextAlignment":null,"nodeLineHeight":null,"style":""}}]}'
  ) {
    // This is the default value of the editor.  We don't want to consider this a change.
    return false;
  }
  return body !== newBody;
};
