import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentSatisfiedTwoToneIcon from "@mui/icons-material/SentimentSatisfiedTwoTone";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import SportsScoreTwoToneIcon from "@mui/icons-material/SportsScoreTwoTone";

import { UserReactionsQuery } from "../../generated/graphql";
import { RootState } from "../../store/store";
/**
 * Defines the slice, reducers, and actions for a reaction bar.
 */

export interface ReactionButton {
  id: string;
  name: string;
  iconName: string;
  chosen: boolean;
}

export interface ReactionBarState {
  isInitialized: boolean;
  reactionButtons: ReactionButton[];
}

const initialState: ReactionBarState = {
  isInitialized: false,
  reactionButtons: [],
};

export const reactionButtonIconByName = (iconName: string): JSX.Element => {
  if (iconName === "HelpOutline") {
    return <HelpOutlineIcon />;
  } else if (iconName === "ErrorOutline") {
    return <ErrorOutlineIcon />;
  } else if (iconName === "SentimentSatisfiedAlt") {
    return <SentimentSatisfiedAltIcon />;
  } else if (iconName === "SportsScoreOutlined") {
    return <SportsScoreOutlinedIcon />;
  }
  return <HelpOutlineIcon />;
};

export const reactionButtonChosenIconByName = (iconName: string): JSX.Element => {
  if (iconName === "HelpOutline") {
    return <HelpTwoToneIcon />;
  } else if (iconName === "ErrorOutline") {
    return <ErrorTwoToneIcon />;
  } else if (iconName === "SentimentSatisfiedAlt") {
    return <SentimentSatisfiedTwoToneIcon />;
  } else if (iconName === "SportsScoreOutlined") {
    return <SportsScoreTwoToneIcon />;
  }
  return <HelpOutlineIcon />;
};

export const reactionButtonIcon = (b: ReactionButton): JSX.Element => {
  if (b.chosen) {
    return React.cloneElement(reactionButtonChosenIconByName(b.iconName), { htmlColor: "rgb(25, 118, 210)" });
  } else {
    return reactionButtonIconByName(b.iconName);
  }
};

export const reactionBarSlice = createSlice({
  name: "reactionBar",
  initialState,
  reducers: {
    clearReactionBarState: (state) => {
      state.isInitialized = false;
      state.reactionButtons = [];
    },
    initializeReactionBarState: (state, action: PayloadAction<UserReactionsQuery>) => {
      state.isInitialized = true;
      const reactions = action.payload["userReactions"].map((b) => {
        return { ...b, chosen: false };
      });
      state.reactionButtons = reactions;
    },
  },
});

export const { clearReactionBarState, initializeReactionBarState } = reactionBarSlice.actions;

export const selectReactionBar = (state: RootState) => state.reactionBar as ReactionBarState;

export default reactionBarSlice.reducer;
