import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import StopIcon from "@mui/icons-material/Stop";

export const demoContentIcon = (contentType?: string) => {
  if (contentType?.startsWith("OpeningFor")) {
    return <PlayArrowIcon />;
  } else if (contentType?.startsWith("CloseFor")) {
    return <StopIcon />;
  } else if (contentType?.startsWith("FeatureSetFor")) {
    return <ViewQuiltIcon />;
  } else if (contentType?.startsWith("ConversationFor")) {
    return <QuestionAnswerIcon />;
  }
  return <QuestionAnswerIcon />;
};
