import { Chip } from "@mui/material";
import React from "react";

export const OrgAdminChip: React.FC = () => {
  return <Chip size="small" label="org admin" color="primary" />;
};

export const TeamAdminChip: React.FC = () => {
  return <Chip size="small" label="team admin" color="primary" />;
};
