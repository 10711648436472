import React from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { SuccessResponse } from "../../../generated/graphql";
import { requestAddUserDemoConversations, requestUserConversationsForDemo } from "../../../generated/graphqlWrappers";
import { DemoContentIdName, UserConversationsForDemoQueryData } from "../../../util/demoTypes";
import { MultiContentPickerDialog } from "../../../components/dialogs/multiContentPickerDialog";

interface Props {
  items: DemoContentIdName[];
  open: boolean;
  onClose: (content?: DemoContentIdName[]) => void;
  demoId: string;
  onError: (message: string) => void;
  onPicked: (data: UserConversationsForDemoQueryData) => void;
}

export const ConversationPicker: React.FC<Props> = ({ items, open, onClose, demoId, onError, onPicked }) => {
  const onPick = async (apolloClient: ApolloClient<object>, convList?: DemoContentIdName[]): Promise<void> => {
    if (convList) {
      const message = `Error adding conversation`;
      await requestAddUserDemoConversations(
        apolloClient,
        { demoId: parseInt(demoId), conversationIds: convList.map((c) => parseInt(c.id)) },
        (response: SuccessResponse) => {
          if (!response.idsInserted) {
            onError(message);
            return;
          }
          requestUserConversationsForDemo(apolloClient, { conversationIds: response.idsInserted }, onPicked, onError);
        },
        () => {
          onError(message);
          return message;
        }
      );
    }
    onClose(convList);
  };
  return (
    <ApolloConsumer>
      {(client) => (
        <MultiContentPickerDialog
          title="Select Conversations"
          items={items}
          open={open}
          onClose={(content) => onPick(client, content)}
        />
      )}
    </ApolloConsumer>
  );
};
