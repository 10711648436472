import React, { useEffect, useState } from "react";
import { Drawer, Stack, Typography } from "@mui/material";
import { Prompt, useHistory, useLocation } from "react-router-dom";

import { AppErrorPage } from "../../../../components/appError";
import { AppTeamLoadingPage } from "../../../../components/appTeamLoadingPage";
import { Breadcrumb } from "../../../../components/breadcrumb";
import { DemoContentEditor } from "../../../../components/forms/demoContentEditor";
import { AppTeamLayout } from "../../../../components/layouts/appTeamLayout";
import { PaperWithTitle } from "../../../../components/paperWithTitle";
import { SuccessResponse, UserNotification } from "../../../../generated/graphql";
import { isAdminForOrgOrTeam } from "../../../../util/adminUtils";
import { EditorSaveButtons } from "../../../../components/forms/editorSaveButtons";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { AppSnackbar } from "../../../../components/appSnackbar";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { showSnackbarError, showSnackbarSuccess } from "../../../../components/appSnackbarSlice";
import { TextEditor } from "../../../../components/textEditor/textEditor";
import { DemoContentType, labelForContentType, urlPathForContentType } from "../../../../util/demoTypes";
import { TitleWithAdminChip } from "../../../../components/titleWithAdminChip";
import { TeamContentNotificationButton } from "./teamContentNotificationButton";
import { NotificationList } from "../../../../components/notifications/notificationList";
import { NotificationsFilter, NotificationsFilterMode } from "../../../../components/notifications/notificationsFilter";
import {
  requestMarkUserNotificationAsRead,
  requestMarkUserNotificationAsUnread,
} from "../../../../generated/graphqlWrappers";
import {
  clearTeamContentNotificationState,
  initializeTeamContentNotificationState,
  notificationMarkedAsRead,
  notificationMarkedAsUnread,
  selectTeamContentNotifications,
} from "./teamContentNotificationsSlice";

interface Props {
  teamId: string;
  contentType: DemoContentType;
  contentId: string;
  chosen: string;
  useUserContentQuery: (options: {
    variables: { teamId: number; contentId: number };
    fetchPolicy: "network-only";
  }) => any;
  requestUpdateContent: (
    client: ApolloClient<object>,
    variables: {
      contentId: number;
      name: string;
      body: string;
      bulletedBody: string;
      whatToShow: string;
      archived: boolean;
    },
    onSuccess: () => void,
    onError: () => void
  ) => Promise<SuccessResponse>;
}

export const TeamContentPageLayout: React.FC<Props> = ({
  teamId,
  contentType,
  chosen,
  contentId,
  useUserContentQuery,
  requestUpdateContent,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notificationsFilterMode, setNotificationsFilterMode] = useState(NotificationsFilterMode.ALL);

  const [name, setName] = useState("");
  const [body, setBody] = useState("");
  const [bulletedBody, setBulletedBody] = useState("");
  const [whatToShow, setWhatToShow] = useState("");
  const [contentIsChanged, setContentIsChanged] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const { search } = useLocation();
  const returnToPage = new URLSearchParams(search).get("returnToPage") ?? undefined;

  const teamNotificationsState = useAppSelector(selectTeamContentNotifications);

  useEffect(() => {
    if (contentIsChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [contentIsChanged]);

  const { data, loading, error } = useUserContentQuery({
    variables: { teamId: parseInt(teamId), contentId: parseInt(contentId) },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }

  if (!data || loading) {
    if (teamNotificationsState.initialized) {
      dispatch(clearTeamContentNotificationState());
    }
    return <AppTeamLoadingPage teamId={teamId} />;
  }

  const content = data[`user${contentType}`] as any;

  if (!teamNotificationsState.initialized) {
    dispatch(initializeTeamContentNotificationState(content.notifications));
    return <AppTeamLoadingPage teamId={teamId} />;
  }

  if (!initialized) {
    setName(content.name);
    setBody(content.body);
    setBulletedBody(content.bulletedBody);
    setWhatToShow(content.whatToShow);
    setContentIsChanged(false);
    setInitialized(true);
    if (content.notifications.length > 0) {
      setNotificationsOpen(true);
    }
    return <AppTeamLoadingPage teamId={teamId} />;
  }

  const teamName = data.userTeam.displayName;
  const isAdmin = isAdminForOrgOrTeam(data.userAccount, data.userTeam.admins);

  const onClose = () => {
    if (returnToPage) {
      history.push(returnToPage);
    } else {
      history.push(`/team/${teamId}/${urlPathForContentType(contentType)}s`);
    }
  };

  const onClickNotification = (client: ApolloClient<object>, n: UserNotification) => {
    if (n.wasRead) {
      requestMarkUserNotificationAsUnread(
        client,
        { notificationId: parseInt(n.id) },
        () => {
          dispatch(notificationMarkedAsUnread(n.id));
        },
        () => {}
      );
    } else {
      requestMarkUserNotificationAsRead(
        client,
        { notificationId: parseInt(n.id) },
        () => {
          dispatch(notificationMarkedAsRead(n.id));
        },
        () => {}
      );
    }
  };

  const onSave = async (client: ApolloClient<object>, onClose?: () => void) => {
    if (!contentIsChanged) {
      if (onClose) {
        onClose();
      }
      return;
    }
    await requestUpdateContent(
      client,
      { contentId: parseInt(contentId), name, body, bulletedBody, whatToShow, archived: content.archived },
      () => {
        setContentIsChanged(false);
        if (onClose) {
          onClose();
        } else {
          dispatch(showSnackbarSuccess(`${labelForContentType(contentType)} saved`));
        }
      },
      () => {
        dispatch(showSnackbarError(`Could not save ${labelForContentType(contentType).toLowerCase()}`));
      }
    );
  };

  const onSaveAndClose = (client: ApolloClient<object>) => {
    onSave(client, onClose);
  };

  const rightDrawerWidth = notificationsOpen ? 400 : 0;
  const animationProperties = {
    transition: "ease-out",
    transitionDuration: "50ms",
    transitionProperty: "width",
  };

  return (
    <AppTeamLayout chosen={chosen} teamId={teamId} teamName={teamName} rightDrawerWidth={rightDrawerWidth}>
      <Prompt when={contentIsChanged} message="You have unsaved changes. Are you sure you want to leave?" />
      <Stack spacing={2} sx={{ flexGrow: 1 }}>
        <Breadcrumb
          crumbs={[
            { text: "Home", url: "/" },
            { text: "Teams", url: `/teams` },
            { text: teamName, url: `/team/${teamId}` },
            {
              text: `${labelForContentType(contentType)}s`,
              url: `/team/${teamId}/${urlPathForContentType(contentType)}s`,
            },
          ]}
        />
        <PaperWithTitle
          title={
            <Stack direction="row" alignItems="center">
              <TitleWithAdminChip
                title={isAdmin ? `Edit ${labelForContentType(contentType)}` : `${content.name}`}
                isAdmin={isAdmin}
                isOrgAdmin={data.userAccount.isOrgAdmin}
              />
              <TeamContentNotificationButton
                notifications={teamNotificationsState.notifications}
                contentType={contentType}
                onClick={() => {
                  setNotificationsOpen(!notificationsOpen);
                }}
              />
            </Stack>
          }
        >
          {isAdmin ? (
            <Stack spacing={2}>
              <DemoContentEditor
                nameState={[name, setName]}
                bodyState={[body, setBody]}
                bulletedBodyState={[bulletedBody, setBulletedBody]}
                whatToShowState={[whatToShow, setWhatToShow]}
                setContentIsChanged={setContentIsChanged}
              />
              <ApolloConsumer>
                {(client) => (
                  <EditorSaveButtons
                    objectId={parseInt(teamId)}
                    saveIsDisabled={name === ""}
                    onSave={() => onSave(client)}
                    onSaveAndClose={() => onSaveAndClose(client)}
                    onClose={onClose}
                    contentIsChanged={contentIsChanged}
                  />
                )}
              </ApolloConsumer>
            </Stack>
          ) : (
            <Stack spacing={2}>
              {whatToShow !== "" ? <Typography>Show: {whatToShow}</Typography> : <React.Fragment />}
              <TextEditor
                contentKeyVersioned="key-readonly"
                initialContent={body}
                setContentJSON={(bodyJSON) => {
                  setBody(bodyJSON);
                }}
                readOnly={true}
              />
            </Stack>
          )}
        </PaperWithTitle>
      </Stack>
      <Drawer
        variant="permanent"
        anchor="right"
        sx={{
          ...animationProperties,
          flexShrink: 0,
          width: rightDrawerWidth,
          "& .MuiDrawer-paper": {
            ...animationProperties,
            width: rightDrawerWidth,
            borderLeft: notificationsOpen ? "solid 1px lightgray" : "none",
            boxSizing: "border-box",
            backgroundColor: "inherit",
          },
        }}
      >
        <Stack>
          {notificationsOpen ? (
            <React.Fragment>
              <Stack spacing={2} sx={{ flexGrow: 1, m: 2 }}>
                <PaperWithTitle title="Notifications">
                  <NotificationsFilter filter={notificationsFilterMode} onChangeMode={setNotificationsFilterMode} />
                  <ApolloConsumer>
                    {(client) => (
                      <NotificationList
                        filter={notificationsFilterMode}
                        dense={false}
                        notifications={teamNotificationsState.notifications}
                        onClickNotification={(n) => {
                          onClickNotification(client, n);
                        }}
                      />
                    )}
                  </ApolloConsumer>
                </PaperWithTitle>
              </Stack>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </Stack>
      </Drawer>
      <AppSnackbar />
    </AppTeamLayout>
  );
};
