import React, { useState } from "react";
import { Stack, TextField } from "@mui/material";
import { TabbedContentEditor } from "../tabbedContentEditor";
import { isChangedBody } from "../textEditor/textEditorUtils";

interface Props {
  nameState: [string, React.Dispatch<React.SetStateAction<string>>];
  bodyState: [string, React.Dispatch<React.SetStateAction<string>>];
  bulletedBodyState: [string, React.Dispatch<React.SetStateAction<string>>];
  whatToShowState: [string, React.Dispatch<React.SetStateAction<string>>];
  setContentIsChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DemoContentEditor: React.FC<Props> = ({
  nameState,
  bodyState,
  bulletedBodyState,
  whatToShowState,
  setContentIsChanged,
}) => {
  const [name, setName] = nameState;
  const [body, setBody] = bodyState;
  const [bulletedBody, setBulletedBody] = bulletedBodyState;
  const [whatToShow, setWhatToShow] = whatToShowState;
  const [tab, setTab] = useState("1");

  return (
    <Stack sx={{ display: "flex" }} spacing={2}>
      <TextField
        value={name}
        label="Name"
        variant="outlined"
        required
        sx={{ width: 350 }}
        color="primary"
        onChange={(e) => {
          setContentIsChanged(true);
          setName(e.target.value);
        }}
      ></TextField>
      <TextField
        value={whatToShow}
        label="What to show"
        variant="outlined"
        sx={{ width: 350 }}
        color="primary"
        onChange={(e) => {
          setContentIsChanged(true);
          setWhatToShow(e.target.value);
        }}
      ></TextField>
      <TabbedContentEditor
        body={body}
        bodyChanged={(newBody) => {
          if (isChangedBody(body, newBody)) {
            setContentIsChanged(true);
            setBody(newBody);
          }
        }}
        bulletedBody={bulletedBody}
        bulletedBodyChanged={(newBulletedBody) => {
          if (isChangedBody(bulletedBody, newBulletedBody)) {
            setContentIsChanged(true);
            setBulletedBody(newBulletedBody);
          }
        }}
        tab={tab}
        tabChanged={(t) => {
          setTab(t);
        }}
      />
    </Stack>
  );
};
