import React from "react";
import { Typography, Grid } from "@mui/material";
import { AppLayout } from "./layouts/appLayout";
import { Link, Redirect } from "react-router-dom";

interface Props {
  errorMessage: string;
}

export const AppErrorPage: React.FC<Props> = ({ errorMessage }) => {
  let message = `Error:  ${errorMessage}`;
  if (errorMessage.indexOf("not authenticated") >= 0) {
    return <Redirect to="/login" />;
  }
  if (errorMessage.indexOf("Unexpected token") >= 0) {
    message = "Could not connect to the server. Try again later.";
  }
  return (
    <AppLayout>
      <Grid container item xs={12}>
        <Typography>{message}</Typography>
        {errorMessage.indexOf("not authenticated") >= 0 ? <Link to="/login">Login</Link> : <React.Fragment />}
      </Grid>
    </AppLayout>
  );
};
