import React, { useContext } from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { chosenReactionIDsForContent, ViewDemoContext } from "./viewDemoBaseReduxUtils";
import { ReactionBar } from "../../../components/reactions/reactionBar";
import { contentIdFromKey, contentTypeFromKey } from "../../../util/demoTypes";
import {
  requestChooseUserDemoReactionforContent,
  requestUnChooseUserDemoReactionforContent,
} from "../../../generated/graphqlWrappers";

interface Props {
  contentKey: string;
}

export const DemoReactions: React.FC<Props> = ({ contentKey }) => {
  const context = useContext(ViewDemoContext);
  const viewDemoState = useAppSelector(context.selectState);
  const actions = context.actions;
  const dispatch = useAppDispatch();

  const contentType = contentTypeFromKey(contentKey);
  const contentId = parseInt(contentIdFromKey(contentKey));

  const chosenReactionIDs = chosenReactionIDsForContent(viewDemoState, contentKey);

  const onClickReaction = async (apolloClient: ApolloClient<object>, reactionId: string, choose: boolean) => {
    const errorMessage = "Error changing reaction";
    dispatch(actions.setErrorMessage(""));
    const variables = {
      demoId: parseInt(viewDemoState.demoId),
      contentType,
      contentId,
      reactionId: parseInt(reactionId),
    };
    const feedbackBody = "";
    const reactionVariables = {
      contentType,
      contentId: contentIdFromKey(contentKey),
      reactionId,
      feedbackBody,
      edited: false,
    };
    const requester = choose ? requestChooseUserDemoReactionforContent : requestUnChooseUserDemoReactionforContent;
    const reactionChosenChanger = choose ? actions.chooseReactionForContent : actions.unChooseReactionForContent;
    await requester(
      apolloClient,
      variables,
      () => {
        dispatch(reactionChosenChanger(reactionVariables));
      },
      () => {
        dispatch(actions.setErrorMessage(errorMessage));
        return errorMessage;
      }
    );
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <ReactionBar
          contentKey={contentKey}
          chosenReactionIDs={chosenReactionIDs}
          onClick={(reactionId, choose) => onClickReaction(client, reactionId, choose)}
        />
      )}
    </ApolloConsumer>
  );
};
