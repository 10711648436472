import React from "react";

import { AppTeamSidebar } from "../appTeamSidebar";
import { LayoutLeftNav } from "./layoutLeftNav";

interface Props {
  teamId: string;
  teamName: string;
  chosen?: string;
  rightDrawerWidth?: number;
}

export const AppTeamLayout: React.FC<Props> = ({ children, teamId, teamName, chosen, rightDrawerWidth }) => {
  return (
    <LayoutLeftNav
      rightDrawerWidth={rightDrawerWidth}
      sidebar={<AppTeamSidebar teamId={teamId} teamName={teamName} chosen={chosen} />}
    >
      {children}
    </LayoutLeftNav>
  );
};
