import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import { AppErrorPage } from "../../../components/appError";
import { AppLoadingPage } from "../../../components/appLoadingPage";
import { AppLayout } from "../../../components/layouts/appLayout";
import { PaperWithTitle } from "../../../components/paperWithTitle";
import { useUserContentNotificationsQuery } from "../../../generated/graphql";
import { ObjectArrayTable } from "../../../components/objectArrayTable/objectArrayTable";
import { useHistory } from "react-router-dom";
import { ObjectTableCellButton } from "../../../components/objectArrayTable/objectTableCellButton";
import { urlPathForContentType } from "../../../util/demoTypes";
import { NotificationList } from "../../../components/notifications/notificationList";
import { NotificationsFilter, NotificationsFilterMode } from "../../../components/notifications/notificationsFilter";

export const UserContentNotificationsPage: React.FC = () => {
  let history = useHistory();
  const { data, loading, error } = useUserContentNotificationsQuery({ fetchPolicy: "network-only" });
  const [openId, setOpenId] = useState("");
  const [notificationFilter, setNotificationFilter] = useState(NotificationsFilterMode.ALL);

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }

  if (loading || !data) {
    return <AppLoadingPage />;
  }

  return (
    <AppLayout>
      <PaperWithTitle title="Demo Content with Notifications">
        <NotificationsFilter filter={notificationFilter} onChangeMode={setNotificationFilter} />
        <ObjectArrayTable
          data={data.userContentNotifications.filter((content) => {
            return notificationFilter === NotificationsFilterMode.ALL || content.numUnread > 0;
          })}
          dataDescription="notifications"
          title="Notifications"
          onClick={(id) => {}}
          cellNode={(obj) => (
            <Stack direction="row">
              <Stack
                display="flex"
                sx={{ flexGrow: 1 }}
                onClick={(id) => {
                  setOpenId(openId === obj.id ? "" : obj.id);
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: obj.wasRead ? undefined : "bold" }}>
                  {obj.teamContentTitle} has {obj.numUnread} unread notification
                  {obj.numUnread === 1 ? "" : "s"}
                </Typography>
                {obj.id === openId ? (
                  <NotificationList simple={true} filter={notificationFilter} notifications={obj.notifications} />
                ) : (
                  <React.Fragment />
                )}
              </Stack>
              <Stack>
                <Stack direction="row">
                  <ObjectTableCellButton
                    objectId={obj.id}
                    button={{
                      text: openId === obj.id ? "Close" : "Expand",
                      icon: openId === obj.id ? <UnfoldLessIcon /> : <UnfoldMoreIcon />,
                      onClick: () => {
                        setOpenId(openId === obj.id ? "" : obj.id);
                      },
                    }}
                  />
                  <ObjectTableCellButton
                    objectId={obj.id}
                    button={{
                      text: "Edit",
                      icon: <EditIcon />,
                      onClick: () => {
                        history.push(
                          `/team/${obj.team.id}/${urlPathForContentType(obj.teamContentType.replace("ForTeam", ""))}/${
                            obj.teamContentId
                          }?returnToPage=${encodeURIComponent("/content-notifications")}`
                        );
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
        />
      </PaperWithTitle>
    </AppLayout>
  );
};
