import React from "react";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import { ObjectTableHeader } from "./objectTableHeader";
import { EmptyObjectTableRow } from "./emptyObjectTableRow";
import { ObjectArrayTableProps } from "./objectArrayTable";
import { Droppable } from "react-beautiful-dnd";
import { ObjectTableRowWithDnd } from "./objectTableRowWithDnd";

export const ObjectArrayTableWithDnd: React.FC<ObjectArrayTableProps> = ({
  dataDescription,
  title,
  data,
  cellNode,
  onClick,
  buttons,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label={dataDescription}>
        <ObjectTableHeader title={title} buttons={buttons} />
        <Droppable droppableId="table">
          {(provided) => (
            <TableBody
              {...provided.droppableProps}
              ref={(ref) => {
                provided.innerRef(ref);
              }}
            >
              {data.length > 0 ? (
                data.map((obj, index, array) => (
                  <ObjectTableRowWithDnd
                    obj={obj}
                    key={`${obj.__typename}-${obj.id}`}
                    index={index}
                    onClick={onClick}
                    cellNode={() => cellNode(obj, index, array)}
                  />
                ))
              ) : (
                <EmptyObjectTableRow dataDescription={dataDescription} />
              )}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </Table>
    </TableContainer>
  );
};
