import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { ObjectTableButtonData } from "./objectArrayTable";

interface Props {
  objectId: string;
  button: ObjectTableButtonData;
}

export const ObjectTableCellButton: React.FC<Props> = ({ objectId, button }) => {
  return (
    <Tooltip title={button.text}>
      <IconButton
        onClick={() => {
          button.onClick(parseInt(objectId));
        }}
      >
        {button.icon}
      </IconButton>
    </Tooltip>
  );
};
