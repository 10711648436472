import React, { useState } from "react";

import { TextField } from "@mui/material";
import { FormDialog } from "../../components/dialogs/formDialog";
import { ApolloClient, ApolloConsumer } from "@apollo/client";

interface Props {
  open: boolean;
  onClose: (added: boolean) => void;
  nameLabel: string;
  buttonLabel: string;
  onSave: (
    client: ApolloClient<object>,
    name: string,
    onSuccess: () => void,
    onError: (msg: string) => void
  ) => Promise<void>;
}

export const CreateContentDialog: React.FC<Props> = ({ open, onClose, nameLabel, buttonLabel, onSave }) => {
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const close = (added: boolean) => {
    setErrorMessage("");
    setName("");
    onClose(added);
  };

  const saveIsDisabled = () => {
    return name === "";
  };

  const saveAndClose = async (client: ApolloClient<object>) => {
    await save(client, close);
  };

  const save = async (client: ApolloClient<object>, onSuccess: (added: boolean) => void) => {
    setErrorMessage("");
    await onSave(
      client,
      name,
      () => {
        onSuccess(true);
      },
      (msg) => {
        setErrorMessage(msg);
        return msg;
      }
    );
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <FormDialog
          open={open}
          cancel={() => {
            close(false);
          }}
          errorMessage={errorMessage}
          saveAndClose={() => {
            saveAndClose(client);
          }}
          saveIsDisabled={saveIsDisabled}
          saveButtonLabel={buttonLabel}
        >
          <TextField
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            label={nameLabel}
            variant="outlined"
            sx={{ minWidth: 350 }}
            required
            color="primary"
          />
        </FormDialog>
      )}
    </ApolloConsumer>
  );
};
