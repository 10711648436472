import { useEffect, useState } from "react";
import { Button, Checkbox, Dialog, DialogTitle, List, ListItem, ListItemText, Stack } from "@mui/material";
import { labelFromPickerData, PickerData } from "./pickerData";

interface Props<T extends PickerData> {
  title: string;
  items: T[];
  open: boolean;
  onClose: (contentList?: T[]) => void;
}

export const MultiContentPickerDialog = <T extends PickerData>({ title, items, open, onClose }: Props<T>) => {
  const [checkedItemList, setCheckedItemList] = useState<T[]>([]);
  const close = (content?: T[]) => {
    onClose(content);
  };

  useEffect(() => {
    if (open) {
      setCheckedItemList([]);
    }
    // We only want to clear the list when we open the dialog
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, itemsIndex: number) => {
    if (event.target.checked) {
      setCheckedItemList([...checkedItemList, items[itemsIndex]]);
    } else {
      let newCheckedItemList = [...checkedItemList];
      const checkedIndex = newCheckedItemList.indexOf(items[itemsIndex]);
      if (checkedIndex >= 0) {
        newCheckedItemList.splice(checkedIndex, 1);
      }
      setCheckedItemList(newCheckedItemList);
    }
  };

  return (
    <Dialog onClose={() => close()} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <List sx={{ minWidth: 400 }}>
        {items.map((content, i) => {
          return (
            <ListItem key={content.id}>
              <Checkbox
                checked={checkedItemList.indexOf(content) >= 0}
                onChange={(event) => {
                  handleChange(event, i);
                }}
              />
              <ListItemText primary={labelFromPickerData(content)} />
            </ListItem>
          );
        })}
      </List>
      <Stack direction="row" spacing={2} sx={{ p: 2 }} justifyContent="flex-end">
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            close();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={checkedItemList.length === 0}
          onClick={() => {
            close(checkedItemList);
          }}
        >
          OK
        </Button>
      </Stack>
    </Dialog>
  );
};
