import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useHistory } from "react-router-dom";
import { setAccessToken } from "../util/auth";
import { useSelector } from "react-redux";
import { selectUserAppBar, setAppBarHasUnreadNotifications } from "./userAppBarSlice";
import { useUserHasUnreadNotificationsQuery } from "../generated/graphql";
import { useAppDispatch } from "../store/hooks";

export const UserAppBar: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const appBarState = useSelector(selectUserAppBar);
  const { data, loading, error } = useUserHasUnreadNotificationsQuery({ fetchPolicy: "network-only" });

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const open = Boolean(anchorEl);

  if (!error && !loading && data) {
    if (data.userAccount.hasUnreadNotifications !== appBarState.hasUnreadNotifications) {
      dispatch(setAppBarHasUnreadNotifications(data.userAccount.hasUnreadNotifications));
    }
  }

  const handleAvatarClick = (target: EventTarget & HTMLButtonElement) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAccessToken("");
    fetch("/logout", { method: "POST", credentials: "include" }).then(() => {
      history.push("/login");
    });
  };

  const changePassword = () => {
    history.push("/change-password");
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      <Tooltip title="Account settings">
        <IconButton
          onClick={(e) => {
            handleAvatarClick(e.currentTarget);
          }}
        >
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            history.push("/account");
          }}
        >
          Account
        </MenuItem>
        <MenuItem onClick={changePassword}>Change Password</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
      <Tooltip title="All Notifications">
        <IconButton
          onClick={(e) => {
            history.push("/content-notifications");
          }}
        >
          {appBarState.hasUnreadNotifications ? (
            <NotificationsActiveIcon color="error" fontSize="large" />
          ) : (
            <NotificationsIcon fontSize="large" />
          )}
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
