// This code is generated by gqlWrappersPlugin.ts. Do not edit.

import * as gql from "./graphql";
import { ApolloClient } from "@apollo/client";
import { gqlMutate, gqlQuery } from "../util/gqlHelpers";

// Calls the UpdateOpening mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateOpening(apolloClient: ApolloClient<object>, variables: gql.UpdateOpeningMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateOpeningDocument, "updateOpening", variables, onSuccess, onError);
}

// Calls the UpdateOpeningArchived mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateOpeningArchived(apolloClient: ApolloClient<object>, variables: gql.UpdateOpeningArchivedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateOpeningArchivedDocument, "updateOpeningArchived", variables, onSuccess, onError);
}

// Calls the CreateOpening mutation GQL with the variables and calls the appropriate callback
export async function requestCreateOpening(apolloClient: ApolloClient<object>, variables: gql.CreateOpeningMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateOpeningDocument, "createOpening", variables, onSuccess, onError);
}

// Calls the UpdateClose mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateClose(apolloClient: ApolloClient<object>, variables: gql.UpdateCloseMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateCloseDocument, "updateClose", variables, onSuccess, onError);
}

// Calls the UpdateCloseArchived mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateCloseArchived(apolloClient: ApolloClient<object>, variables: gql.UpdateCloseArchivedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateCloseArchivedDocument, "updateCloseArchived", variables, onSuccess, onError);
}

// Calls the CreateClose mutation GQL with the variables and calls the appropriate callback
export async function requestCreateClose(apolloClient: ApolloClient<object>, variables: gql.CreateCloseMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateCloseDocument, "createClose", variables, onSuccess, onError);
}

// Calls the UpdateConversation mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateConversation(apolloClient: ApolloClient<object>, variables: gql.UpdateConversationMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateConversationDocument, "updateConversation", variables, onSuccess, onError);
}

// Calls the UpdateConversationArchived mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateConversationArchived(apolloClient: ApolloClient<object>, variables: gql.UpdateConversationArchivedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateConversationArchivedDocument, "updateConversationArchived", variables, onSuccess, onError);
}

// Calls the CreateConversation mutation GQL with the variables and calls the appropriate callback
export async function requestCreateConversation(apolloClient: ApolloClient<object>, variables: gql.CreateConversationMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateConversationDocument, "createConversation", variables, onSuccess, onError);
}

// Calls the UpdateFeatureSet mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateFeatureSet(apolloClient: ApolloClient<object>, variables: gql.UpdateFeatureSetMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateFeatureSetDocument, "updateFeatureSet", variables, onSuccess, onError);
}

// Calls the UpdateFeatureSetArchived mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateFeatureSetArchived(apolloClient: ApolloClient<object>, variables: gql.UpdateFeatureSetArchivedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateFeatureSetArchivedDocument, "updateFeatureSetArchived", variables, onSuccess, onError);
}

// Calls the CreateFeatureSet mutation GQL with the variables and calls the appropriate callback
export async function requestCreateFeatureSet(apolloClient: ApolloClient<object>, variables: gql.CreateFeatureSetMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateFeatureSetDocument, "createFeatureSet", variables, onSuccess, onError);
}

// Calls the AddUserToTeamAsAdmin mutation GQL with the variables and calls the appropriate callback
export async function requestAddUserToTeamAsAdmin(apolloClient: ApolloClient<object>, variables: gql.AddUserToTeamAsAdminMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddUserToTeamAsAdminDocument, "addUserToTeamAsAdmin", variables, onSuccess, onError);
}

// Calls the RemoveUserFromTeamAsAdmin mutation GQL with the variables and calls the appropriate callback
export async function requestRemoveUserFromTeamAsAdmin(apolloClient: ApolloClient<object>, variables: gql.RemoveUserFromTeamAsAdminMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RemoveUserFromTeamAsAdminDocument, "removeUserFromTeamAsAdmin", variables, onSuccess, onError);
}

// Calls the UpdateTeamAsAdmin mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateTeamAsAdmin(apolloClient: ApolloClient<object>, variables: gql.UpdateTeamAsAdminMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateTeamAsAdminDocument, "updateTeamAsAdmin", variables, onSuccess, onError);
}

// Calls the CreateTeamAsOrgAdmin mutation GQL with the variables and calls the appropriate callback
export async function requestCreateTeamAsOrgAdmin(apolloClient: ApolloClient<object>, variables: gql.CreateTeamAsOrgAdminMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateTeamAsOrgAdminDocument, "createTeamAsOrgAdmin", variables, onSuccess, onError);
}

// Calls the CreateSiteClose mutation GQL with the variables and calls the appropriate callback
export async function requestCreateSiteClose(apolloClient: ApolloClient<object>, variables: gql.CreateSiteCloseMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateSiteCloseDocument, "createSiteClose", variables, onSuccess, onError);
}

// Calls the CreateSiteOpening mutation GQL with the variables and calls the appropriate callback
export async function requestCreateSiteOpening(apolloClient: ApolloClient<object>, variables: gql.CreateSiteOpeningMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateSiteOpeningDocument, "createSiteOpening", variables, onSuccess, onError);
}

// Calls the CreateSiteFeatureSet mutation GQL with the variables and calls the appropriate callback
export async function requestCreateSiteFeatureSet(apolloClient: ApolloClient<object>, variables: gql.CreateSiteFeatureSetMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateSiteFeatureSetDocument, "createSiteFeatureSet", variables, onSuccess, onError);
}

// Calls the CreateSiteConversation mutation GQL with the variables and calls the appropriate callback
export async function requestCreateSiteConversation(apolloClient: ApolloClient<object>, variables: gql.CreateSiteConversationMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateSiteConversationDocument, "createSiteConversation", variables, onSuccess, onError);
}

// Calls the UpdateSiteClose mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteClose(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteCloseMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteCloseDocument, "updateSiteClose", variables, onSuccess, onError);
}

// Calls the UpdateSiteFeatureSet mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteFeatureSet(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteFeatureSetMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteFeatureSetDocument, "updateSiteFeatureSet", variables, onSuccess, onError);
}

// Calls the UpdateSiteOpening mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteOpening(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteOpeningMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteOpeningDocument, "updateSiteOpening", variables, onSuccess, onError);
}

// Calls the UpdateSiteConversation mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteConversation(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteConversationMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteConversationDocument, "updateSiteConversation", variables, onSuccess, onError);
}

// Calls the SiteTeamResources query GQL with the variables and calls the appropriate callback
export async function requestSiteTeamResources(apolloClient: ApolloClient<object>, variables: gql.SiteTeamResourcesQueryVariables, onSuccess: (data: gql.SiteTeamResourcesQuery["siteTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteTeamResourcesDocument, variables, (data) => onSuccess(data["siteTeam"]), onError);
}

// Calls the SiteTeamResourceCategories query GQL with the variables and calls the appropriate callback
export async function requestSiteTeamResourceCategories(apolloClient: ApolloClient<object>, variables: gql.SiteTeamResourceCategoriesQueryVariables, onSuccess: (data: gql.SiteTeamResourceCategoriesQuery["siteTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteTeamResourceCategoriesDocument, variables, (data) => onSuccess(data["siteTeam"]), onError);
}

// Calls the SiteTeamOpenings query GQL with the variables and calls the appropriate callback
export async function requestSiteTeamOpenings(apolloClient: ApolloClient<object>, variables: gql.SiteTeamOpeningsQueryVariables, onSuccess: (data: gql.SiteTeamOpeningsQuery["siteTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteTeamOpeningsDocument, variables, (data) => onSuccess(data["siteTeam"]), onError);
}

// Calls the SiteTeamFeatureSets query GQL with the variables and calls the appropriate callback
export async function requestSiteTeamFeatureSets(apolloClient: ApolloClient<object>, variables: gql.SiteTeamFeatureSetsQueryVariables, onSuccess: (data: gql.SiteTeamFeatureSetsQuery["siteTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteTeamFeatureSetsDocument, variables, (data) => onSuccess(data["siteTeam"]), onError);
}

// Calls the SiteTeamConversations query GQL with the variables and calls the appropriate callback
export async function requestSiteTeamConversations(apolloClient: ApolloClient<object>, variables: gql.SiteTeamConversationsQueryVariables, onSuccess: (data: gql.SiteTeamConversationsQuery["siteTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteTeamConversationsDocument, variables, (data) => onSuccess(data["siteTeam"]), onError);
}

// Calls the SiteTeamCloses query GQL with the variables and calls the appropriate callback
export async function requestSiteTeamCloses(apolloClient: ApolloClient<object>, variables: gql.SiteTeamClosesQueryVariables, onSuccess: (data: gql.SiteTeamClosesQuery["siteTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteTeamClosesDocument, variables, (data) => onSuccess(data["siteTeam"]), onError);
}

// Calls the SiteConversation query GQL with the variables and calls the appropriate callback
export async function requestSiteConversation(apolloClient: ApolloClient<object>, variables: gql.SiteConversationQueryVariables, onSuccess: (data: gql.SiteConversationQuery["siteConversation"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteConversationDocument, variables, (data) => onSuccess(data["siteConversation"]), onError);
}

// Calls the SiteClose query GQL with the variables and calls the appropriate callback
export async function requestSiteClose(apolloClient: ApolloClient<object>, variables: gql.SiteCloseQueryVariables, onSuccess: (data: gql.SiteCloseQuery["siteClose"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteCloseDocument, variables, (data) => onSuccess(data["siteClose"]), onError);
}

// Calls the SiteFeatureSet query GQL with the variables and calls the appropriate callback
export async function requestSiteFeatureSet(apolloClient: ApolloClient<object>, variables: gql.SiteFeatureSetQueryVariables, onSuccess: (data: gql.SiteFeatureSetQuery["siteFeatureSet"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteFeatureSetDocument, variables, (data) => onSuccess(data["siteFeatureSet"]), onError);
}

// Calls the SiteOpening query GQL with the variables and calls the appropriate callback
export async function requestSiteOpening(apolloClient: ApolloClient<object>, variables: gql.SiteOpeningQueryVariables, onSuccess: (data: gql.SiteOpeningQuery["siteOpening"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteOpeningDocument, variables, (data) => onSuccess(data["siteOpening"]), onError);
}

// Calls the CreateSiteDemoTemplate mutation GQL with the variables and calls the appropriate callback
export async function requestCreateSiteDemoTemplate(apolloClient: ApolloClient<object>, variables: gql.CreateSiteDemoTemplateMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateSiteDemoTemplateDocument, "createSiteDemoTemplate", variables, onSuccess, onError);
}

// Calls the AddSiteDemoTemplateConversations mutation GQL with the variables and calls the appropriate callback
export async function requestAddSiteDemoTemplateConversations(apolloClient: ApolloClient<object>, variables: gql.AddSiteDemoTemplateConversationsMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddSiteDemoTemplateConversationsDocument, "addSiteDemoTemplateConversations", variables, onSuccess, onError);
}

// Calls the AddSiteDemoTemplateFeatureSets mutation GQL with the variables and calls the appropriate callback
export async function requestAddSiteDemoTemplateFeatureSets(apolloClient: ApolloClient<object>, variables: gql.AddSiteDemoTemplateFeatureSetsMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddSiteDemoTemplateFeatureSetsDocument, "addSiteDemoTemplateFeatureSets", variables, onSuccess, onError);
}

// Calls the RemoveSiteDemoTemplateConversation mutation GQL with the variables and calls the appropriate callback
export async function requestRemoveSiteDemoTemplateConversation(apolloClient: ApolloClient<object>, variables: gql.RemoveSiteDemoTemplateConversationMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RemoveSiteDemoTemplateConversationDocument, "removeSiteDemoTemplateConversation", variables, onSuccess, onError);
}

// Calls the RemoveSiteDemoTemplateFeatureSet mutation GQL with the variables and calls the appropriate callback
export async function requestRemoveSiteDemoTemplateFeatureSet(apolloClient: ApolloClient<object>, variables: gql.RemoveSiteDemoTemplateFeatureSetMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RemoveSiteDemoTemplateFeatureSetDocument, "removeSiteDemoTemplateFeatureSet", variables, onSuccess, onError);
}

// Calls the UpdateSiteDemoTemplatePersonaType mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteDemoTemplatePersonaType(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteDemoTemplatePersonaTypeMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteDemoTemplatePersonaTypeDocument, "updateSiteDemoTemplatePersonaType", variables, onSuccess, onError);
}

// Calls the UpdateSiteDemoTemplateOpening mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteDemoTemplateOpening(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteDemoTemplateOpeningMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteDemoTemplateOpeningDocument, "updateSiteDemoTemplateOpening", variables, onSuccess, onError);
}

// Calls the UpdateSiteDemoTemplateFeatureSetRecommended mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteDemoTemplateFeatureSetRecommended(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteDemoTemplateFeatureSetRecommendedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteDemoTemplateFeatureSetRecommendedDocument, "updateSiteDemoTemplateFeatureSetRecommended", variables, onSuccess, onError);
}

// Calls the UpdateSiteDemoTemplateProperties mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteDemoTemplateProperties(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteDemoTemplatePropertiesMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteDemoTemplatePropertiesDocument, "updateSiteDemoTemplateProperties", variables, onSuccess, onError);
}

// Calls the UpdateSiteDemoTemplateConversationRecommended mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteDemoTemplateConversationRecommended(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteDemoTemplateConversationRecommendedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteDemoTemplateConversationRecommendedDocument, "updateSiteDemoTemplateConversationRecommended", variables, onSuccess, onError);
}

// Calls the UpdateSiteDemoTemplateContentOrder mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteDemoTemplateContentOrder(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteDemoTemplateContentOrderMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteDemoTemplateContentOrderDocument, "updateSiteDemoTemplateContentOrder", variables, onSuccess, onError);
}

// Calls the UpdateSiteDemoTemplateClose mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteDemoTemplateClose(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteDemoTemplateCloseMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteDemoTemplateCloseDocument, "updateSiteDemoTemplateClose", variables, onSuccess, onError);
}

// Calls the SiteDemoTemplate query GQL with the variables and calls the appropriate callback
export async function requestSiteDemoTemplate(apolloClient: ApolloClient<object>, variables: gql.SiteDemoTemplateQueryVariables, onSuccess: (data: gql.SiteDemoTemplateQuery["siteDemoTemplate"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteDemoTemplateDocument, variables, (data) => onSuccess(data["siteDemoTemplate"]), onError);
}

// Calls the SiteDemoTemplateConversation query GQL with the variables and calls the appropriate callback
export async function requestSiteDemoTemplateConversation(apolloClient: ApolloClient<object>, variables: gql.SiteDemoTemplateConversationQueryVariables, onSuccess: (data: gql.SiteDemoTemplateConversationQuery["siteDemoTemplateConversation"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteDemoTemplateConversationDocument, variables, (data) => onSuccess(data["siteDemoTemplateConversation"]), onError);
}

// Calls the SiteDemoTemplateConversations query GQL with the variables and calls the appropriate callback
export async function requestSiteDemoTemplateConversations(apolloClient: ApolloClient<object>, variables: gql.SiteDemoTemplateConversationsQueryVariables, onSuccess: (data: gql.SiteDemoTemplateConversationsQuery["siteDemoTemplateConversations"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteDemoTemplateConversationsDocument, variables, (data) => onSuccess(data["siteDemoTemplateConversations"]), onError);
}

// Calls the SiteDemoTemplateFeatureSet query GQL with the variables and calls the appropriate callback
export async function requestSiteDemoTemplateFeatureSet(apolloClient: ApolloClient<object>, variables: gql.SiteDemoTemplateFeatureSetQueryVariables, onSuccess: (data: gql.SiteDemoTemplateFeatureSetQuery["siteDemoTemplateFeatureSet"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteDemoTemplateFeatureSetDocument, variables, (data) => onSuccess(data["siteDemoTemplateFeatureSet"]), onError);
}

// Calls the SiteDemoTemplateFeatureSets query GQL with the variables and calls the appropriate callback
export async function requestSiteDemoTemplateFeatureSets(apolloClient: ApolloClient<object>, variables: gql.SiteDemoTemplateFeatureSetsQueryVariables, onSuccess: (data: gql.SiteDemoTemplateFeatureSetsQuery["siteDemoTemplateFeatureSets"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteDemoTemplateFeatureSetsDocument, variables, (data) => onSuccess(data["siteDemoTemplateFeatureSets"]), onError);
}

// Calls the MailInvite mutation GQL with the variables and calls the appropriate callback
export async function requestMailInvite(apolloClient: ApolloClient<object>, variables: gql.MailInviteMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.MailInviteDocument, "mailInvite", variables, onSuccess, onError);
}

// Calls the CreateSiteOrganization mutation GQL with the variables and calls the appropriate callback
export async function requestCreateSiteOrganization(apolloClient: ApolloClient<object>, variables: gql.CreateSiteOrganizationMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateSiteOrganizationDocument, "createSiteOrganization", variables, onSuccess, onError);
}

// Calls the CreateSiteTeam mutation GQL with the variables and calls the appropriate callback
export async function requestCreateSiteTeam(apolloClient: ApolloClient<object>, variables: gql.CreateSiteTeamMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateSiteTeamDocument, "createSiteTeam", variables, onSuccess, onError);
}

// Calls the CreateSiteUser mutation GQL with the variables and calls the appropriate callback
export async function requestCreateSiteUser(apolloClient: ApolloClient<object>, variables: gql.CreateSiteUserMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateSiteUserDocument, "createSiteUser", variables, onSuccess, onError);
}

// Calls the InviteSiteUser mutation GQL with the variables and calls the appropriate callback
export async function requestInviteSiteUser(apolloClient: ApolloClient<object>, variables: gql.InviteSiteUserMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.InviteSiteUserDocument, "inviteSiteUser", variables, onSuccess, onError);
}

// Calls the AddSiteUserTeam mutation GQL with the variables and calls the appropriate callback
export async function requestAddSiteUserTeam(apolloClient: ApolloClient<object>, variables: gql.AddSiteUserTeamMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddSiteUserTeamDocument, "addSiteUserTeam", variables, onSuccess, onError);
}

// Calls the UpdateSiteUser mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteUser(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteUserMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteUserDocument, "updateSiteUser", variables, onSuccess, onError);
}

// Calls the RemoveSiteUserTeam mutation GQL with the variables and calls the appropriate callback
export async function requestRemoveSiteUserTeam(apolloClient: ApolloClient<object>, variables: gql.RemoveSiteUserTeamMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RemoveSiteUserTeamDocument, "removeSiteUserTeam", variables, onSuccess, onError);
}

// Calls the UpdateSiteUserTeamAdmin mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteUserTeamAdmin(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteUserTeamAdminMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteUserTeamAdminDocument, "updateSiteUserTeamAdmin", variables, onSuccess, onError);
}

// Calls the SiteOrganization query GQL with the variables and calls the appropriate callback
export async function requestSiteOrganization(apolloClient: ApolloClient<object>, variables: gql.SiteOrganizationQueryVariables, onSuccess: (data: gql.SiteOrganizationQuery["siteOrganization"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteOrganizationDocument, variables, (data) => onSuccess(data["siteOrganization"]), onError);
}

// Calls the SiteOrganizations query GQL with the variables and calls the appropriate callback
export async function requestSiteOrganizations(apolloClient: ApolloClient<object>, variables: gql.SiteOrganizationsQueryVariables, onSuccess: (data: gql.SiteOrganizationsQuery["siteOrganizations"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteOrganizationsDocument, variables, (data) => onSuccess(data["siteOrganizations"]), onError);
}

// Calls the SiteOrganizationUsers query GQL with the variables and calls the appropriate callback
export async function requestSiteOrganizationUsers(apolloClient: ApolloClient<object>, variables: gql.SiteOrganizationUsersQueryVariables, onSuccess: (data: gql.SiteOrganizationUsersQuery["siteOrganization"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteOrganizationUsersDocument, variables, (data) => onSuccess(data["siteOrganization"]), onError);
}

// Calls the SiteTeamUsers query GQL with the variables and calls the appropriate callback
export async function requestSiteTeamUsers(apolloClient: ApolloClient<object>, variables: gql.SiteTeamUsersQueryVariables, onSuccess: (data: gql.SiteTeamUsersQuery["siteTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteTeamUsersDocument, variables, (data) => onSuccess(data["siteTeam"]), onError);
}

// Calls the SiteUser query GQL with the variables and calls the appropriate callback
export async function requestSiteUser(apolloClient: ApolloClient<object>, variables: gql.SiteUserQueryVariables, onSuccess: (data: gql.SiteUserQuery["siteUser"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteUserDocument, variables, (data) => onSuccess(data["siteUser"]), onError);
}

// Calls the SiteTeamsForOrganization query GQL with the variables and calls the appropriate callback
export async function requestSiteTeamsForOrganization(apolloClient: ApolloClient<object>, variables: gql.SiteTeamsForOrganizationQueryVariables, onSuccess: (data: gql.SiteTeamsForOrganizationQuery["siteTeamsForOrganization"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteTeamsForOrganizationDocument, variables, (data) => onSuccess(data["siteTeamsForOrganization"]), onError);
}

// Calls the SiteTeam query GQL with the variables and calls the appropriate callback
export async function requestSiteTeam(apolloClient: ApolloClient<object>, variables: gql.SiteTeamQueryVariables, onSuccess: (data: gql.SiteTeamQuery["siteTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteTeamDocument, variables, (data) => onSuccess(data["siteTeam"]), onError);
}

// Calls the CreateSiteResourceCategory mutation GQL with the variables and calls the appropriate callback
export async function requestCreateSiteResourceCategory(apolloClient: ApolloClient<object>, variables: gql.CreateSiteResourceCategoryMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateSiteResourceCategoryDocument, "createSiteResourceCategory", variables, onSuccess, onError);
}

// Calls the UpdateSiteResourceCategory mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteResourceCategory(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteResourceCategoryMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteResourceCategoryDocument, "updateSiteResourceCategory", variables, onSuccess, onError);
}

// Calls the SiteResourceCategory query GQL with the variables and calls the appropriate callback
export async function requestSiteResourceCategory(apolloClient: ApolloClient<object>, variables: gql.SiteResourceCategoryQueryVariables, onSuccess: (data: gql.SiteResourceCategoryQuery["siteResourceCategory"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteResourceCategoryDocument, variables, (data) => onSuccess(data["siteResourceCategory"]), onError);
}

// Calls the CreateSiteResource mutation GQL with the variables and calls the appropriate callback
export async function requestCreateSiteResource(apolloClient: ApolloClient<object>, variables: gql.CreateSiteResourceMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateSiteResourceDocument, "createSiteResource", variables, onSuccess, onError);
}

// Calls the UpdateSiteResource mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateSiteResource(apolloClient: ApolloClient<object>, variables: gql.UpdateSiteResourceMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateSiteResourceDocument, "updateSiteResource", variables, onSuccess, onError);
}

// Calls the SiteResource query GQL with the variables and calls the appropriate callback
export async function requestSiteResource(apolloClient: ApolloClient<object>, variables: gql.SiteResourceQueryVariables, onSuccess: (data: gql.SiteResourceQuery["siteResource"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SiteResourceDocument, variables, (data) => onSuccess(data["siteResource"]), onError);
}

// Calls the UserAccount query GQL with the variables and calls the appropriate callback
export async function requestUserAccount(apolloClient: ApolloClient<object>, variables: gql.UserAccountQueryVariables, onSuccess: (data: gql.UserAccountQuery["userAccount"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserAccountDocument, variables, (data) => onSuccess(data["userAccount"]), onError);
}

// Calls the UserForInvite query GQL with the variables and calls the appropriate callback
export async function requestUserForInvite(apolloClient: ApolloClient<object>, variables: gql.UserForInviteQueryVariables, onSuccess: (data: gql.UserForInviteQuery["userForInvite"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserForInviteDocument, variables, (data) => onSuccess(data["userForInvite"]), onError);
}

// Calls the UserHasUnreadNotifications query GQL with the variables and calls the appropriate callback
export async function requestUserHasUnreadNotifications(apolloClient: ApolloClient<object>, variables: gql.UserHasUnreadNotificationsQueryVariables, onSuccess: (data: gql.UserHasUnreadNotificationsQuery["userAccount"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserHasUnreadNotificationsDocument, variables, (data) => onSuccess(data["userAccount"]), onError);
}

// Calls the UserTeamDemoFeedback query GQL with the variables and calls the appropriate callback
export async function requestUserTeamDemoFeedback(apolloClient: ApolloClient<object>, variables: gql.UserTeamDemoFeedbackQueryVariables, onSuccess: (data: gql.UserTeamDemoFeedbackQuery["userTeamDemoFeedback"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserTeamDemoFeedbackDocument, variables, (data) => onSuccess(data["userTeamDemoFeedback"]), onError);
}

// Calls the UserDemoFeedback query GQL with the variables and calls the appropriate callback
export async function requestUserDemoFeedback(apolloClient: ApolloClient<object>, variables: gql.UserDemoFeedbackQueryVariables, onSuccess: (data: gql.UserDemoFeedbackQuery["userDemoFeedback"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserDemoFeedbackDocument, variables, (data) => onSuccess(data["userDemoFeedback"]), onError);
}

// Calls the UserFeedback query GQL with the variables and calls the appropriate callback
export async function requestUserFeedback(apolloClient: ApolloClient<object>, variables: gql.UserFeedbackQueryVariables, onSuccess: (data: gql.UserFeedbackQuery["userFeedback"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserFeedbackDocument, variables, (data) => onSuccess(data["userFeedback"]), onError);
}

// Calls the SetUserDemoFeedback mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserDemoFeedback(apolloClient: ApolloClient<object>, variables: gql.SetUserDemoFeedbackMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserDemoFeedbackDocument, "setUserDemoFeedback", variables, onSuccess, onError);
}

// Calls the CreateUserDemo mutation GQL with the variables and calls the appropriate callback
export async function requestCreateUserDemo(apolloClient: ApolloClient<object>, variables: gql.CreateUserDemoMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateUserDemoDocument, "createUserDemo", variables, onSuccess, onError);
}

// Calls the UpdateUserDemoArchive mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateUserDemoArchive(apolloClient: ApolloClient<object>, variables: gql.UpdateUserDemoArchiveMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateUserDemoArchiveDocument, "updateUserDemoArchive", variables, onSuccess, onError);
}

// Calls the UpdateUserDemoInTeamLibrary mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateUserDemoInTeamLibrary(apolloClient: ApolloClient<object>, variables: gql.UpdateUserDemoInTeamLibraryMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateUserDemoInTeamLibraryDocument, "updateUserDemoInTeamLibrary", variables, onSuccess, onError);
}

// Calls the RemoveUserDemo mutation GQL with the variables and calls the appropriate callback
export async function requestRemoveUserDemo(apolloClient: ApolloClient<object>, variables: gql.RemoveUserDemoMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RemoveUserDemoDocument, "removeUserDemo", variables, onSuccess, onError);
}

// Calls the UpdateUserDemoContent mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateUserDemoContent(apolloClient: ApolloClient<object>, variables: gql.UpdateUserDemoContentMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateUserDemoContentDocument, "updateUserDemoContent", variables, onSuccess, onError);
}

// Calls the MoveUserDemoContent mutation GQL with the variables and calls the appropriate callback
export async function requestMoveUserDemoContent(apolloClient: ApolloClient<object>, variables: gql.MoveUserDemoContentMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.MoveUserDemoContentDocument, "moveUserDemoContent", variables, onSuccess, onError);
}

// Calls the RemoveUserDemoContent mutation GQL with the variables and calls the appropriate callback
export async function requestRemoveUserDemoContent(apolloClient: ApolloClient<object>, variables: gql.RemoveUserDemoContentMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RemoveUserDemoContentDocument, "removeUserDemoContent", variables, onSuccess, onError);
}

// Calls the AddUserDemoConversations mutation GQL with the variables and calls the appropriate callback
export async function requestAddUserDemoConversations(apolloClient: ApolloClient<object>, variables: gql.AddUserDemoConversationsMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddUserDemoConversationsDocument, "addUserDemoConversations", variables, onSuccess, onError);
}

// Calls the AddUserDemoFeatureSets mutation GQL with the variables and calls the appropriate callback
export async function requestAddUserDemoFeatureSets(apolloClient: ApolloClient<object>, variables: gql.AddUserDemoFeatureSetsMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddUserDemoFeatureSetsDocument, "addUserDemoFeatureSets", variables, onSuccess, onError);
}

// Calls the IgnoreUserDemoTemplateVersionOfContent mutation GQL with the variables and calls the appropriate callback
export async function requestIgnoreUserDemoTemplateVersionOfContent(apolloClient: ApolloClient<object>, variables: gql.IgnoreUserDemoTemplateVersionOfContentMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.IgnoreUserDemoTemplateVersionOfContentDocument, "ignoreUserDemoTemplateVersionOfContent", variables, onSuccess, onError);
}

// Calls the AcceptUserDemoTemplateVersionOfContent mutation GQL with the variables and calls the appropriate callback
export async function requestAcceptUserDemoTemplateVersionOfContent(apolloClient: ApolloClient<object>, variables: gql.AcceptUserDemoTemplateVersionOfContentMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AcceptUserDemoTemplateVersionOfContentDocument, "acceptUserDemoTemplateVersionOfContent", variables, onSuccess, onError);
}

// Calls the OverwriteUserDemoTemplateVersionOfContent mutation GQL with the variables and calls the appropriate callback
export async function requestOverwriteUserDemoTemplateVersionOfContent(apolloClient: ApolloClient<object>, variables: gql.OverwriteUserDemoTemplateVersionOfContentMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.OverwriteUserDemoTemplateVersionOfContentDocument, "overwriteUserDemoTemplateVersionOfContent", variables, onSuccess, onError);
}

// Calls the ChooseUserDemoReactionforContent mutation GQL with the variables and calls the appropriate callback
export async function requestChooseUserDemoReactionforContent(apolloClient: ApolloClient<object>, variables: gql.ChooseUserDemoReactionforContentMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.ChooseUserDemoReactionforContentDocument, "chooseUserDemoReactionforContent", variables, onSuccess, onError);
}

// Calls the UnChooseUserDemoReactionforContent mutation GQL with the variables and calls the appropriate callback
export async function requestUnChooseUserDemoReactionforContent(apolloClient: ApolloClient<object>, variables: gql.UnChooseUserDemoReactionforContentMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UnChooseUserDemoReactionforContentDocument, "unChooseUserDemoReactionforContent", variables, onSuccess, onError);
}

// Calls the DuplicateUserDemo mutation GQL with the variables and calls the appropriate callback
export async function requestDuplicateUserDemo(apolloClient: ApolloClient<object>, variables: gql.DuplicateUserDemoMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.DuplicateUserDemoDocument, "duplicateUserDemo", variables, onSuccess, onError);
}

// Calls the UserDemos query GQL with the variables and calls the appropriate callback
export async function requestUserDemos(apolloClient: ApolloClient<object>, variables: gql.UserDemosQueryVariables, onSuccess: (data: gql.UserDemosQuery["userDemos"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserDemosDocument, variables, (data) => onSuccess(data["userDemos"]), onError);
}

// Calls the UserDemo query GQL with the variables and calls the appropriate callback
export async function requestUserDemo(apolloClient: ApolloClient<object>, variables: gql.UserDemoQueryVariables, onSuccess: (data: gql.UserDemoQuery["userDemo"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserDemoDocument, variables, (data) => onSuccess(data["userDemo"]), onError);
}

// Calls the UserConversationForDemo query GQL with the variables and calls the appropriate callback
export async function requestUserConversationForDemo(apolloClient: ApolloClient<object>, variables: gql.UserConversationForDemoQueryVariables, onSuccess: (data: gql.UserConversationForDemoQuery["userConversationForDemo"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserConversationForDemoDocument, variables, (data) => onSuccess(data["userConversationForDemo"]), onError);
}

// Calls the UserConversationsForDemo query GQL with the variables and calls the appropriate callback
export async function requestUserConversationsForDemo(apolloClient: ApolloClient<object>, variables: gql.UserConversationsForDemoQueryVariables, onSuccess: (data: gql.UserConversationsForDemoQuery["userConversationsForDemo"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserConversationsForDemoDocument, variables, (data) => onSuccess(data["userConversationsForDemo"]), onError);
}

// Calls the UserFeatureSetForDemo query GQL with the variables and calls the appropriate callback
export async function requestUserFeatureSetForDemo(apolloClient: ApolloClient<object>, variables: gql.UserFeatureSetForDemoQueryVariables, onSuccess: (data: gql.UserFeatureSetForDemoQuery["userFeatureSetForDemo"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserFeatureSetForDemoDocument, variables, (data) => onSuccess(data["userFeatureSetForDemo"]), onError);
}

// Calls the UserFeatureSetsForDemo query GQL with the variables and calls the appropriate callback
export async function requestUserFeatureSetsForDemo(apolloClient: ApolloClient<object>, variables: gql.UserFeatureSetsForDemoQueryVariables, onSuccess: (data: gql.UserFeatureSetsForDemoQuery["userFeatureSetsForDemo"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserFeatureSetsForDemoDocument, variables, (data) => onSuccess(data["userFeatureSetsForDemo"]), onError);
}

// Calls the UserOpeningForDemo query GQL with the variables and calls the appropriate callback
export async function requestUserOpeningForDemo(apolloClient: ApolloClient<object>, variables: gql.UserOpeningForDemoQueryVariables, onSuccess: (data: gql.UserOpeningForDemoQuery["userOpeningForDemo"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserOpeningForDemoDocument, variables, (data) => onSuccess(data["userOpeningForDemo"]), onError);
}

// Calls the UserCloseForDemo query GQL with the variables and calls the appropriate callback
export async function requestUserCloseForDemo(apolloClient: ApolloClient<object>, variables: gql.UserCloseForDemoQueryVariables, onSuccess: (data: gql.UserCloseForDemoQuery["userCloseForDemo"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserCloseForDemoDocument, variables, (data) => onSuccess(data["userCloseForDemo"]), onError);
}

// Calls the UserTeamLibraryDemos query GQL with the variables and calls the appropriate callback
export async function requestUserTeamLibraryDemos(apolloClient: ApolloClient<object>, variables: gql.UserTeamLibraryDemosQueryVariables, onSuccess: (data: gql.UserTeamLibraryDemosQuery["userTeamLibraryDemos"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserTeamLibraryDemosDocument, variables, (data) => onSuccess(data["userTeamLibraryDemos"]), onError);
}

// Calls the UserDemoSharesAndTeam query GQL with the variables and calls the appropriate callback
export async function requestUserDemoSharesAndTeam(apolloClient: ApolloClient<object>, variables: gql.UserDemoSharesAndTeamQueryVariables, onSuccess: (data: gql.UserDemoSharesAndTeamQuery["userDemoShares"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserDemoSharesAndTeamDocument, variables, (data) => onSuccess(data["userDemoShares"]), onError);
}

// Calls the SharedDemosForUser query GQL with the variables and calls the appropriate callback
export async function requestSharedDemosForUser(apolloClient: ApolloClient<object>, variables: gql.SharedDemosForUserQueryVariables, onSuccess: (data: gql.SharedDemosForUserQuery["sharedDemosForUser"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SharedDemosForUserDocument, variables, (data) => onSuccess(data["sharedDemosForUser"]), onError);
}

// Calls the UserSharedDemo query GQL with the variables and calls the appropriate callback
export async function requestUserSharedDemo(apolloClient: ApolloClient<object>, variables: gql.UserSharedDemoQueryVariables, onSuccess: (data: gql.UserSharedDemoQuery["userSharedDemo"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserSharedDemoDocument, variables, (data) => onSuccess(data["userSharedDemo"]), onError);
}

// Calls the ShareUserDemo mutation GQL with the variables and calls the appropriate callback
export async function requestShareUserDemo(apolloClient: ApolloClient<object>, variables: gql.ShareUserDemoMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.ShareUserDemoDocument, "shareUserDemo", variables, onSuccess, onError);
}

// Calls the UpdateUserDemoTemplateArchive mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateUserDemoTemplateArchive(apolloClient: ApolloClient<object>, variables: gql.UpdateUserDemoTemplateArchiveMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateUserDemoTemplateArchiveDocument, "updateUserDemoTemplateArchive", variables, onSuccess, onError);
}

// Calls the CreateDemoTemplate mutation GQL with the variables and calls the appropriate callback
export async function requestCreateDemoTemplate(apolloClient: ApolloClient<object>, variables: gql.CreateDemoTemplateMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateDemoTemplateDocument, "createDemoTemplate", variables, onSuccess, onError);
}

// Calls the AddDemoTemplateConversations mutation GQL with the variables and calls the appropriate callback
export async function requestAddDemoTemplateConversations(apolloClient: ApolloClient<object>, variables: gql.AddDemoTemplateConversationsMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddDemoTemplateConversationsDocument, "addDemoTemplateConversations", variables, onSuccess, onError);
}

// Calls the AddDemoTemplateFeatureSets mutation GQL with the variables and calls the appropriate callback
export async function requestAddDemoTemplateFeatureSets(apolloClient: ApolloClient<object>, variables: gql.AddDemoTemplateFeatureSetsMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddDemoTemplateFeatureSetsDocument, "addDemoTemplateFeatureSets", variables, onSuccess, onError);
}

// Calls the RemoveDemoTemplateConversation mutation GQL with the variables and calls the appropriate callback
export async function requestRemoveDemoTemplateConversation(apolloClient: ApolloClient<object>, variables: gql.RemoveDemoTemplateConversationMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RemoveDemoTemplateConversationDocument, "removeDemoTemplateConversation", variables, onSuccess, onError);
}

// Calls the RemoveDemoTemplateFeatureSet mutation GQL with the variables and calls the appropriate callback
export async function requestRemoveDemoTemplateFeatureSet(apolloClient: ApolloClient<object>, variables: gql.RemoveDemoTemplateFeatureSetMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RemoveDemoTemplateFeatureSetDocument, "removeDemoTemplateFeatureSet", variables, onSuccess, onError);
}

// Calls the UpdateDemoTemplateClose mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateDemoTemplateClose(apolloClient: ApolloClient<object>, variables: gql.UpdateDemoTemplateCloseMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateDemoTemplateCloseDocument, "updateDemoTemplateClose", variables, onSuccess, onError);
}

// Calls the UpdateDemoTemplateContentOrder mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateDemoTemplateContentOrder(apolloClient: ApolloClient<object>, variables: gql.UpdateDemoTemplateContentOrderMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateDemoTemplateContentOrderDocument, "updateDemoTemplateContentOrder", variables, onSuccess, onError);
}

// Calls the UpdateDemoTemplateConversationRecommended mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateDemoTemplateConversationRecommended(apolloClient: ApolloClient<object>, variables: gql.UpdateDemoTemplateConversationRecommendedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateDemoTemplateConversationRecommendedDocument, "updateDemoTemplateConversationRecommended", variables, onSuccess, onError);
}

// Calls the UpdateDemoTemplateFeatureSetRecommended mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateDemoTemplateFeatureSetRecommended(apolloClient: ApolloClient<object>, variables: gql.UpdateDemoTemplateFeatureSetRecommendedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateDemoTemplateFeatureSetRecommendedDocument, "updateDemoTemplateFeatureSetRecommended", variables, onSuccess, onError);
}

// Calls the UpdateDemoTemplateOpening mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateDemoTemplateOpening(apolloClient: ApolloClient<object>, variables: gql.UpdateDemoTemplateOpeningMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateDemoTemplateOpeningDocument, "updateDemoTemplateOpening", variables, onSuccess, onError);
}

// Calls the UpdateDemoTemplatePersonaType mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateDemoTemplatePersonaType(apolloClient: ApolloClient<object>, variables: gql.UpdateDemoTemplatePersonaTypeMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateDemoTemplatePersonaTypeDocument, "updateDemoTemplatePersonaType", variables, onSuccess, onError);
}

// Calls the UpdateDemoTemplateProperties mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateDemoTemplateProperties(apolloClient: ApolloClient<object>, variables: gql.UpdateDemoTemplatePropertiesMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateDemoTemplatePropertiesDocument, "updateDemoTemplateProperties", variables, onSuccess, onError);
}

// Calls the UserDemoTemplatesForPersona query GQL with the variables and calls the appropriate callback
export async function requestUserDemoTemplatesForPersona(apolloClient: ApolloClient<object>, variables: gql.UserDemoTemplatesForPersonaQueryVariables, onSuccess: (data: gql.UserDemoTemplatesForPersonaQuery["userDemoTemplatesForPersona"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserDemoTemplatesForPersonaDocument, variables, (data) => onSuccess(data["userDemoTemplatesForPersona"]), onError);
}

// Calls the UserConversationsForTemplate query GQL with the variables and calls the appropriate callback
export async function requestUserConversationsForTemplate(apolloClient: ApolloClient<object>, variables: gql.UserConversationsForTemplateQueryVariables, onSuccess: (data: gql.UserConversationsForTemplateQuery["userConversationsForTemplate"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserConversationsForTemplateDocument, variables, (data) => onSuccess(data["userConversationsForTemplate"]), onError);
}

// Calls the UserFeatureSetsForTemplate query GQL with the variables and calls the appropriate callback
export async function requestUserFeatureSetsForTemplate(apolloClient: ApolloClient<object>, variables: gql.UserFeatureSetsForTemplateQueryVariables, onSuccess: (data: gql.UserFeatureSetsForTemplateQuery["userFeatureSetsForTemplate"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserFeatureSetsForTemplateDocument, variables, (data) => onSuccess(data["userFeatureSetsForTemplate"]), onError);
}

// Calls the UserOpeningsForTeam query GQL with the variables and calls the appropriate callback
export async function requestUserOpeningsForTeam(apolloClient: ApolloClient<object>, variables: gql.UserOpeningsForTeamQueryVariables, onSuccess: (data: gql.UserOpeningsForTeamQuery["userOpeningsForTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserOpeningsForTeamDocument, variables, (data) => onSuccess(data["userOpeningsForTeam"]), onError);
}

// Calls the UserOpening query GQL with the variables and calls the appropriate callback
export async function requestUserOpening(apolloClient: ApolloClient<object>, variables: gql.UserOpeningQueryVariables, onSuccess: (data: gql.UserOpeningQuery["userOpening"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserOpeningDocument, variables, (data) => onSuccess(data["userOpening"]), onError);
}

// Calls the UserClosesForTeam query GQL with the variables and calls the appropriate callback
export async function requestUserClosesForTeam(apolloClient: ApolloClient<object>, variables: gql.UserClosesForTeamQueryVariables, onSuccess: (data: gql.UserClosesForTeamQuery["userClosesForTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserClosesForTeamDocument, variables, (data) => onSuccess(data["userClosesForTeam"]), onError);
}

// Calls the UserClose query GQL with the variables and calls the appropriate callback
export async function requestUserClose(apolloClient: ApolloClient<object>, variables: gql.UserCloseQueryVariables, onSuccess: (data: gql.UserCloseQuery["userClose"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserCloseDocument, variables, (data) => onSuccess(data["userClose"]), onError);
}

// Calls the UserConversationsForTeam query GQL with the variables and calls the appropriate callback
export async function requestUserConversationsForTeam(apolloClient: ApolloClient<object>, variables: gql.UserConversationsForTeamQueryVariables, onSuccess: (data: gql.UserConversationsForTeamQuery["userConversationsForTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserConversationsForTeamDocument, variables, (data) => onSuccess(data["userConversationsForTeam"]), onError);
}

// Calls the UserConversation query GQL with the variables and calls the appropriate callback
export async function requestUserConversation(apolloClient: ApolloClient<object>, variables: gql.UserConversationQueryVariables, onSuccess: (data: gql.UserConversationQuery["userConversation"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserConversationDocument, variables, (data) => onSuccess(data["userConversation"]), onError);
}

// Calls the UserFeatureSetsForTeam query GQL with the variables and calls the appropriate callback
export async function requestUserFeatureSetsForTeam(apolloClient: ApolloClient<object>, variables: gql.UserFeatureSetsForTeamQueryVariables, onSuccess: (data: gql.UserFeatureSetsForTeamQuery["userFeatureSetsForTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserFeatureSetsForTeamDocument, variables, (data) => onSuccess(data["userFeatureSetsForTeam"]), onError);
}

// Calls the UserFeatureSet query GQL with the variables and calls the appropriate callback
export async function requestUserFeatureSet(apolloClient: ApolloClient<object>, variables: gql.UserFeatureSetQueryVariables, onSuccess: (data: gql.UserFeatureSetQuery["userFeatureSet"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserFeatureSetDocument, variables, (data) => onSuccess(data["userFeatureSet"]), onError);
}

// Calls the UserDemoTemplate query GQL with the variables and calls the appropriate callback
export async function requestUserDemoTemplate(apolloClient: ApolloClient<object>, variables: gql.UserDemoTemplateQueryVariables, onSuccess: (data: gql.UserDemoTemplateQuery["userDemoTemplate"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserDemoTemplateDocument, variables, (data) => onSuccess(data["userDemoTemplate"]), onError);
}

// Calls the DemoTemplateConversation query GQL with the variables and calls the appropriate callback
export async function requestDemoTemplateConversation(apolloClient: ApolloClient<object>, variables: gql.DemoTemplateConversationQueryVariables, onSuccess: (data: gql.DemoTemplateConversationQuery["demoTemplateConversation"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.DemoTemplateConversationDocument, variables, (data) => onSuccess(data["demoTemplateConversation"]), onError);
}

// Calls the DemoTemplateConversations query GQL with the variables and calls the appropriate callback
export async function requestDemoTemplateConversations(apolloClient: ApolloClient<object>, variables: gql.DemoTemplateConversationsQueryVariables, onSuccess: (data: gql.DemoTemplateConversationsQuery["demoTemplateConversations"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.DemoTemplateConversationsDocument, variables, (data) => onSuccess(data["demoTemplateConversations"]), onError);
}

// Calls the DemoTemplateFeatureSet query GQL with the variables and calls the appropriate callback
export async function requestDemoTemplateFeatureSet(apolloClient: ApolloClient<object>, variables: gql.DemoTemplateFeatureSetQueryVariables, onSuccess: (data: gql.DemoTemplateFeatureSetQuery["demoTemplateFeatureSet"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.DemoTemplateFeatureSetDocument, variables, (data) => onSuccess(data["demoTemplateFeatureSet"]), onError);
}

// Calls the DemoTemplateFeatureSets query GQL with the variables and calls the appropriate callback
export async function requestDemoTemplateFeatureSets(apolloClient: ApolloClient<object>, variables: gql.DemoTemplateFeatureSetsQueryVariables, onSuccess: (data: gql.DemoTemplateFeatureSetsQuery["demoTemplateFeatureSets"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.DemoTemplateFeatureSetsDocument, variables, (data) => onSuccess(data["demoTemplateFeatureSets"]), onError);
}

// Calls the Login mutation GQL with the variables and calls the appropriate callback
export async function requestLogin(apolloClient: ApolloClient<object>, variables: gql.LoginMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.LoginDocument, "login", variables, onSuccess, onError);
}

// Calls the ChangePassword mutation GQL with the variables and calls the appropriate callback
export async function requestChangePassword(apolloClient: ApolloClient<object>, variables: gql.ChangePasswordMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.ChangePasswordDocument, "changePassword", variables, onSuccess, onError);
}

// Calls the RegisterWithInvitation mutation GQL with the variables and calls the appropriate callback
export async function requestRegisterWithInvitation(apolloClient: ApolloClient<object>, variables: gql.RegisterWithInvitationMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RegisterWithInvitationDocument, "registerWithInvitation", variables, onSuccess, onError);
}

// Calls the UserNotifications query GQL with the variables and calls the appropriate callback
export async function requestUserNotifications(apolloClient: ApolloClient<object>, variables: gql.UserNotificationsQueryVariables, onSuccess: (data: gql.UserNotificationsQuery["userNotifications"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserNotificationsDocument, variables, (data) => onSuccess(data["userNotifications"]), onError);
}

// Calls the UserNotification query GQL with the variables and calls the appropriate callback
export async function requestUserNotification(apolloClient: ApolloClient<object>, variables: gql.UserNotificationQueryVariables, onSuccess: (data: gql.UserNotificationQuery["userNotification"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserNotificationDocument, variables, (data) => onSuccess(data["userNotification"]), onError);
}

// Calls the UserContentNotifications query GQL with the variables and calls the appropriate callback
export async function requestUserContentNotifications(apolloClient: ApolloClient<object>, variables: gql.UserContentNotificationsQueryVariables, onSuccess: (data: gql.UserContentNotificationsQuery["userContentNotifications"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserContentNotificationsDocument, variables, (data) => onSuccess(data["userContentNotifications"]), onError);
}

// Calls the MarkUserNotificationAsRead mutation GQL with the variables and calls the appropriate callback
export async function requestMarkUserNotificationAsRead(apolloClient: ApolloClient<object>, variables: gql.MarkUserNotificationAsReadMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.MarkUserNotificationAsReadDocument, "markUserNotificationAsRead", variables, onSuccess, onError);
}

// Calls the MarkUserNotificationAsUnread mutation GQL with the variables and calls the appropriate callback
export async function requestMarkUserNotificationAsUnread(apolloClient: ApolloClient<object>, variables: gql.MarkUserNotificationAsUnreadMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.MarkUserNotificationAsUnreadDocument, "markUserNotificationAsUnread", variables, onSuccess, onError);
}

// Calls the UserReactions query GQL with the variables and calls the appropriate callback
export async function requestUserReactions(apolloClient: ApolloClient<object>, variables: gql.UserReactionsQueryVariables, onSuccess: (data: gql.UserReactionsQuery["userReactions"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserReactionsDocument, variables, (data) => onSuccess(data["userReactions"]), onError);
}

// Calls the UserResourceCategories query GQL with the variables and calls the appropriate callback
export async function requestUserResourceCategories(apolloClient: ApolloClient<object>, variables: gql.UserResourceCategoriesQueryVariables, onSuccess: (data: gql.UserResourceCategoriesQuery["userResourceCategories"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserResourceCategoriesDocument, variables, (data) => onSuccess(data["userResourceCategories"]), onError);
}

// Calls the UserResource query GQL with the variables and calls the appropriate callback
export async function requestUserResource(apolloClient: ApolloClient<object>, variables: gql.UserResourceQueryVariables, onSuccess: (data: gql.UserResourceQuery["userResource"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserResourceDocument, variables, (data) => onSuccess(data["userResource"]), onError);
}

// Calls the UserResourcesByTeam query GQL with the variables and calls the appropriate callback
export async function requestUserResourcesByTeam(apolloClient: ApolloClient<object>, variables: gql.UserResourcesByTeamQueryVariables, onSuccess: (data: gql.UserResourcesByTeamQuery["userResourcesByTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserResourcesByTeamDocument, variables, (data) => onSuccess(data["userResourcesByTeam"]), onError);
}

// Calls the UpdateResource mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateResource(apolloClient: ApolloClient<object>, variables: gql.UpdateResourceMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateResourceDocument, "updateResource", variables, onSuccess, onError);
}

// Calls the CreateResource mutation GQL with the variables and calls the appropriate callback
export async function requestCreateResource(apolloClient: ApolloClient<object>, variables: gql.CreateResourceMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateResourceDocument, "createResource", variables, onSuccess, onError);
}

// Calls the CreateResourceCategory mutation GQL with the variables and calls the appropriate callback
export async function requestCreateResourceCategory(apolloClient: ApolloClient<object>, variables: gql.CreateResourceCategoryMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateResourceCategoryDocument, "createResourceCategory", variables, onSuccess, onError);
}

// Calls the TeamsAndAccount query GQL with the variables and calls the appropriate callback
export async function requestTeamsAndAccount(apolloClient: ApolloClient<object>, variables: gql.TeamsAndAccountQueryVariables, onSuccess: (data: gql.TeamsAndAccountQuery["teams"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.TeamsAndAccountDocument, variables, (data) => onSuccess(data["teams"]), onError);
}

// Calls the UserTeam query GQL with the variables and calls the appropriate callback
export async function requestUserTeam(apolloClient: ApolloClient<object>, variables: gql.UserTeamQueryVariables, onSuccess: (data: gql.UserTeamQuery["userTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserTeamDocument, variables, (data) => onSuccess(data["userTeam"]), onError);
}

// Calls the UserTeamAdminRoles query GQL with the variables and calls the appropriate callback
export async function requestUserTeamAdminRoles(apolloClient: ApolloClient<object>, variables: gql.UserTeamAdminRolesQueryVariables, onSuccess: (data: gql.UserTeamAdminRolesQuery["userTeamAdminRoles"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserTeamAdminRolesDocument, variables, (data) => onSuccess(data["userTeamAdminRoles"]), onError);
}

// Calls the UpdateTeamAdminAsAdmin mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateTeamAdminAsAdmin(apolloClient: ApolloClient<object>, variables: gql.UpdateTeamAdminAsAdminMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateTeamAdminAsAdminDocument, "updateTeamAdminAsAdmin", variables, onSuccess, onError);
}

// Calls the UserTeamDashboard query GQL with the variables and calls the appropriate callback
export async function requestUserTeamDashboard(apolloClient: ApolloClient<object>, variables: gql.UserTeamDashboardQueryVariables, onSuccess: (data: gql.UserTeamDashboardQuery["userTeamDashboard"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserTeamDashboardDocument, variables, (data) => onSuccess(data["userTeamDashboard"]), onError);
}

// Calls the UserDemoTemplatesForTeam query GQL with the variables and calls the appropriate callback
export async function requestUserDemoTemplatesForTeam(apolloClient: ApolloClient<object>, variables: gql.UserDemoTemplatesForTeamQueryVariables, onSuccess: (data: gql.UserDemoTemplatesForTeamQuery["userDemoTemplatesForTeam"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserDemoTemplatesForTeamDocument, variables, (data) => onSuccess(data["userDemoTemplatesForTeam"]), onError);
}
