import React from "react";

import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import RemoveIcon from "@mui/icons-material/Remove";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import DragHandleIcon from "@mui/icons-material/DragHandle";

interface Props {
  obj: any;
  icon: React.ReactNode;
  setRecommended: (id: string, recommended: boolean) => void;
  removeCell: (id: string) => void;
  readOnly: boolean;
}

export const DemoContentCell: React.FC<Props> = ({ obj, icon, setRecommended, removeCell, readOnly }) => {
  const draggableId = `draggablecontent-${obj.tableIndex}`;

  return (
    <Box id={draggableId} sx={{ display: "flex" }}>
      {icon} <Typography sx={{ flexGrow: 1, lineHeight: 2.2, verticalAlign: "middle" }}>{obj.name}</Typography>
      {obj.recommended ? (
        <Tooltip title="Tap to unmark as recommended by default" placement="top-start">
          <IconButton
            disabled={readOnly}
            onClick={() => {
              setRecommended(obj.parentId, false);
            }}
          >
            <StarIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Tap to mark as recommended by default" placement="top-start">
          <IconButton
            disabled={readOnly}
            onClick={() => {
              setRecommended(obj.parentId, true);
            }}
          >
            <StarOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {readOnly ? (
        <React.Fragment />
      ) : (
        <React.Fragment>
          <Tooltip title="Tap to remove" placement="top-start">
            <IconButton
              onClick={() => {
                removeCell(obj.parentId);
              }}
            >
              <RemoveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Drag to reorder" placement="top-start">
            <IconButton>
              <DragHandleIcon className="cursor-y" />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )}
    </Box>
  );
};
