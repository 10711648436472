import React from "react";
import { QuestionDialog } from "./questionDialog";

interface Props {
  open: boolean;
  title?: string;
  message: string;
  yesText: string;
  closeDialog: (confirmed: boolean) => void;
}

export const ConfirmDestructiveActionDialog: React.FC<Props> = ({ open, title, message, yesText, closeDialog }) => {
  return (
    <QuestionDialog
      open={open}
      title={title ?? "Are you sure?"}
      message={message}
      buttons={[
        {
          key: "no",
          text: "Cancel",
          prominent: false,
          destructive: false,
          onClick: () => closeDialog(false),
        },
        {
          key: "yes",
          text: yesText,
          prominent: true,
          destructive: true,
          onClick: () => closeDialog(true),
        },
      ]}
      cancel={() => closeDialog(false)}
    />
  );
};
