import { ApolloClient, useApolloClient } from "@apollo/client";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { updateDemoProperties } from "../../../myDemosSlice";
import { requestSaveDemoByOwner } from "../../graphql/viewDemoRequests";
import { demoStateEditsSuccessfullySent, selectViewDemo, setErrorMessage } from "../../viewDemoSlice";
import { shouldSaveViewDemoState } from "../../viewDemoSliceUtils";

interface Props {
  children: (onSave: (client: ApolloClient<object>) => void) => React.ReactChild | null;
}

export const DemoSaveMenuHandler: React.FC<Props> = ({ children }) => {
  const viewDemoState = useAppSelector(selectViewDemo);
  const dispatch = useAppDispatch();
  const client = useApolloClient();

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    interval = setInterval(() => {
      if (shouldSaveViewDemoState(viewDemoState)) {
        onSave(client);
      }
    }, 3000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDemoState.editCount, viewDemoState.reactionEditCount]);

  const onSave = async (apolloClient: ApolloClient<object>) => {
    dispatch(setErrorMessage(""));
    await requestSaveDemoByOwner(
      apolloClient,
      viewDemoState,
      () => {
        dispatch(demoStateEditsSuccessfullySent());
        dispatch(
          updateDemoProperties({
            __typename: "Demo",
            id: viewDemoState.demoId,
            name: viewDemoState.demoName,
            description: viewDemoState.demoDescription,
            archived: viewDemoState.demoArchived,
            team: { id: viewDemoState.teamId, displayName: "" }, // TODO: displayName is not used, but beware ...
            modifiedDate: new Date().toISOString(),
          })
        );
      },
      (err) => dispatch(setErrorMessage(err))
    );
  };

  return <React.Fragment>{children(onSave)}</React.Fragment>;
};
