import React from "react";

import { useHistory, useParams } from "react-router";
import { useSiteTeamOpeningsQuery } from "../../../../generated/graphql";
import { ViewTeamContentSiteAdminPage } from "../../../../components/siteAdmin/viewTeamContentSiteAdmin";

interface Params {
  teamId: string;
}

export const ViewTeamOpeningsSiteAdminPage: React.FC = () => {
  const { teamId } = useParams<Params>();
  const history = useHistory();

  return (
    <ViewTeamContentSiteAdminPage
      teamId={teamId}
      query={useSiteTeamOpeningsQuery}
      chosen="Openings"
      getObjects={(data) => {
        return data.openings;
      }}
      dataDescription="openings"
      title="Opening"
      newContentPath="new-opening"
      onClick={(id) => {
        history.push(`/site-admin/opening/${id}`);
      }}
      rowLabelProperty="name"
    />
  );
};
