import React from "react";
import { ApolloConsumer, ApolloClient } from "@apollo/client";
import { Button, Stack, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { contentIdFromKey, contentTypeFromKey, isRemovableFromDemo } from "../../../util/demoTypes";
import { requestRemoveUserDemoContent } from "../../../generated/graphqlWrappers";
import { InfoPanel } from "../../../components/infoPanel";

import { removeContent, selectViewDemo, setConfirmRemoveSectionOpen, setErrorMessage } from "./viewDemoSlice";
import { ConfirmRemoveSectionDialog } from "./confirmRemoveSectionDialog";

export const RemoveSectionAction: React.FC = () => {
  const viewDemoState = useAppSelector(selectViewDemo);
  const dispatch = useAppDispatch();

  const contentType = contentTypeFromKey(viewDemoState.chosenKey);
  const contentId = parseInt(contentIdFromKey(viewDemoState.chosenKey));

  const removeContentFromDemo = async (apolloClient: ApolloClient<object>) => {
    if (!isRemovableFromDemo(contentType)) {
      return;
    }

    const errorMessage = "Error removing content";
    dispatch(setErrorMessage(""));
    await requestRemoveUserDemoContent(
      apolloClient,
      { demoId: parseInt(viewDemoState.demoId), contentType, contentId },
      () => {
        dispatch(removeContent(viewDemoState.chosenKey));
      },
      () => {
        dispatch(setErrorMessage(errorMessage));
        return errorMessage;
      }
    );
  };

  return (
    <ApolloConsumer>
      {(client) =>
        isRemovableFromDemo(contentTypeFromKey(viewDemoState.chosenKey)) ? (
          <Stack sx={{ my: 1 }}>
            <Typography variant="h5">Demo</Typography>
            <Button
              sx={{ my: 1 }}
              color="error"
              variant="outlined"
              onClick={() => dispatch(setConfirmRemoveSectionOpen(true))}
            >
              Remove Section
            </Button>
            <InfoPanel text={`If you remove this section, you will lose your edits.`} />
            <ConfirmRemoveSectionDialog
              open={viewDemoState.confirmRemoveSectionOpen}
              closeDialog={(confirmed) => {
                dispatch(setConfirmRemoveSectionOpen(false));
                if (confirmed) {
                  removeContentFromDemo(client);
                }
              }}
            />
          </Stack>
        ) : (
          <React.Fragment />
        )
      }
    </ApolloConsumer>
  );
};
