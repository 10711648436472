import React from "react";

import { useHistory, useParams } from "react-router";
import { EditTeamDemoContentSiteAdmin } from "../../../../components/siteAdmin/editTeamDemoContentSiteAdmin";
import { useSiteConversationQuery, useUpdateSiteConversationMutation } from "../../../../generated/graphql";

interface Params {
  conversationId: string;
}

export const EditTeamConversationSiteAdminPage: React.FC = () => {
  let history = useHistory();
  let { conversationId } = useParams<Params>();
  const [updateSiteConversation] = useUpdateSiteConversationMutation();

  return (
    <EditTeamDemoContentSiteAdmin
      id={parseInt(conversationId)}
      contentType="Conversation"
      idArgName="conversationId"
      query={useSiteConversationQuery}
      update={updateSiteConversation}
      onClose={(teamId) => {
        history.push(`/site-admin/team/${teamId}/conversations`);
      }}
      queryDataProperty="siteConversation"
      resultDataProperty="updateSiteConversation"
      getDemoTemplates={(data) => data.demoTemplateConversations.map((tc: any) => tc.demoTemplate)}
    ></EditTeamDemoContentSiteAdmin>
  );
};
