import React, { useContext, useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { formatSecondsAsTime } from "../../../util/dateUtils";
import { DemoPlayState, ViewDemoContext } from "./viewDemoBaseReduxUtils";

export const DemoPlayer: React.FC = () => {
  const context = useContext(ViewDemoContext);
  const viewDemoState = useAppSelector(context.selectState);
  const actions = context.actions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (viewDemoState.demoPlayState === DemoPlayState.PLAYING) {
      interval = setInterval(() => {
        dispatch(actions.demoTimerTick());
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDemoState.demoPlayState]);

  return (
    <Stack sx={{ my: 1 }} display="flex" alignItems="center">
      {viewDemoState.demoPlayState === DemoPlayState.NOTSTARTED ? (
        <Button variant="contained" onClick={() => dispatch(actions.setDemoPlayState(DemoPlayState.PLAYING))}>
          Start Demo
        </Button>
      ) : viewDemoState.demoPlayState === DemoPlayState.PLAYING ? (
        <React.Fragment>
          <Typography variant="h4">{formatSecondsAsTime(viewDemoState.demoTimerSeconds)}</Typography>
          <Stack sx={{ my: 1 }} spacing={2} direction="row" display="flex" alignItems="center">
            <Button variant="contained" onClick={() => dispatch(actions.setDemoPlayState(DemoPlayState.PAUSED))}>
              Pause
            </Button>
            <Button variant="contained" onClick={() => dispatch(actions.setDemoPlayState(DemoPlayState.ENDED))}>
              End
            </Button>
          </Stack>
        </React.Fragment>
      ) : viewDemoState.demoPlayState === DemoPlayState.PAUSED ? (
        <React.Fragment>
          <Typography variant="h4">{formatSecondsAsTime(viewDemoState.demoTimerSeconds)}</Typography>
          <Stack sx={{ my: 1 }} spacing={2} direction="row" display="flex" alignItems="center">
            <Button variant="contained" onClick={() => dispatch(actions.setDemoPlayState(DemoPlayState.PLAYING))}>
              Continue
            </Button>
          </Stack>
        </React.Fragment>
      ) : viewDemoState.demoPlayState === DemoPlayState.ENDED ? (
        <React.Fragment>
          <Typography variant="h4">{formatSecondsAsTime(viewDemoState.demoTimerSeconds)}</Typography>
          <Stack sx={{ my: 1 }} spacing={2} direction="row" display="flex" alignItems="center">
            <Button variant="outlined" onClick={() => dispatch(actions.setDemoPlayState(DemoPlayState.NOTSTARTED))}>
              Reset
            </Button>
          </Stack>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </Stack>
  );
};
