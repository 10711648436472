import React from "react";
import { useParams } from "react-router-dom";
import { useUserClosesForTeamQuery } from "../../../generated/graphql";
import { requestCreateClose, requestUpdateCloseArchived } from "../../../generated/graphqlWrappers";
import { TeamContentTablePageLayout } from "./teamContent/teamContentTablePageLayout";

interface Params {
  teamId: string;
}

export const TeamClosesPage: React.FC = () => {
  const { teamId } = useParams<Params>();
  return (
    <TeamContentTablePageLayout
      teamId={teamId}
      contentType="Close"
      chosen="Closes"
      useContentListQuery={useUserClosesForTeamQuery}
      requestCreateContent={requestCreateClose}
      requestArchiveContent={requestUpdateCloseArchived}
    />
  );
};
