import {
  compareModifiedDate,
  compareName,
  compareUserDisplayName,
} from "../../../components/demoTable/demoTableSortUtils";
import { SharedUserDemoData } from "../../../util/demoTypes";

export const sharedDemosCompareFn = (
  index: number
): ((a: SharedUserDemoData[0], b: SharedUserDemoData[0]) => number) => {
  switch (index) {
    case 0:
      return compareUserDisplayName;
    case 1:
      return compareName;
    case 2:
      return compareModifiedDate;
  }
  return compareUserDisplayName;
};
