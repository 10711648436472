import React from "react";
import { useParams } from "react-router-dom";
import { useUserConversationQuery } from "../../../generated/graphql";
import { requestUpdateConversation } from "../../../generated/graphqlWrappers";
import { TeamContentPageLayout } from "./teamContent/teamContentPageLayout";

interface Params {
  teamId: string;
  conversationId: string;
}

export const TeamConversationPage: React.FC = () => {
  const { teamId, conversationId } = useParams<Params>();
  return (
    <TeamContentPageLayout
      teamId={teamId}
      contentType="Conversation"
      chosen="Conversations"
      contentId={conversationId}
      useUserContentQuery={useUserConversationQuery}
      requestUpdateContent={requestUpdateConversation}
    />
  );
};
