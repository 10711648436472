import { IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../store/hooks";
import { reactionButtonIcon, selectReactionBar } from "./reactionBarSlice";

interface Props {
  contentKey: string;
  chosenReactionIDs: string[];
  onClick: (reactionId: string, choose: boolean) => void;
}

export const ReactionBar: React.FC<Props> = ({ contentKey, chosenReactionIDs, onClick }) => {
  const reactionBarState = useAppSelector(selectReactionBar);

  let buttonState = [];

  for (let index = 0; index < reactionBarState.reactionButtons.length; index++) {
    const b = reactionBarState.reactionButtons[index];
    const chosen = chosenReactionIDs.indexOf(b.id) >= 0;
    buttonState.push({ ...b, chosen });
  }

  return (
    <Stack direction="row" alignItems="start" sx={{ m: 1 }}>
      {buttonState.map((b) => {
        return (
          <Tooltip key={b.id} title={b.name}>
            <IconButton
              onClick={() => {
                onClick(b.id, !b.chosen);
              }}
            >
              {reactionButtonIcon(b)}
            </IconButton>
          </Tooltip>
        );
      })}
    </Stack>
  );
};
