import React from "react";
import { ApolloConsumer } from "@apollo/client";
import { Button, Divider, Menu, Stack } from "@mui/material";
import { DemoSaveAsMenuItem } from "./demo/demoSaveAsMenuItem";
import { DemoSaveMenuItem } from "./demo/demoSaveMenuItem";
import { DemoSaveMenuHandler } from "./demo/demoSaveMenuHandler";
import { DemoSaveAsMenuHandler } from "./demo/demoSaveAsMenuHandler";
import { DemoContentIdName } from "../../../../util/demoTypes";
import { AddFeatureMenuItem } from "./demo/addFeatureMenuItem";
import { AddFeatureMenuHandler } from "./demo/addFeatureMenuHandler";
import { AddConversationMenuHandler } from "./demo/addConversationMenuHandler";
import { AddConversationMenuItem } from "./demo/addConversationMenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DemoTeamLibraryMenuItem } from "./demo/demoTeamLibraryMenuItem";
import { DemoTeamLibraryMenuHandler } from "./demo/demoTeamLibraryMenuHandler";

interface Props {
  onDuplicateDemoSuccess: (newDemoId: string) => void;
  onTeamLibrarySuccess: (inTeamLibrary: boolean) => void;
  inTeamLibary: boolean;
  teamFeatureSets: DemoContentIdName[];
  teamConversations: DemoContentIdName[];
  sharedDemoVersion?: boolean;
}

export const DemoMenu: React.FC<Props> = ({
  onDuplicateDemoSuccess,
  onTeamLibrarySuccess,
  inTeamLibary,
  teamFeatureSets,
  teamConversations,
  sharedDemoVersion,
}) => {
  const [demoMenuAnchorEl, setDemoMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const demoButtonIsOpen = Boolean(demoMenuAnchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDemoMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setDemoMenuAnchorEl(null);
  };

  const showFullMenu = !(sharedDemoVersion ?? false);

  return (
    <ApolloConsumer>
      {(client) => (
        <DemoSaveMenuHandler>
          {(onSave) => (
            <DemoSaveAsMenuHandler onDuplicateDemoSuccess={onDuplicateDemoSuccess}>
              {(onSaveAs) => (
                <DemoTeamLibraryMenuHandler onTeamLibrarySuccess={onTeamLibrarySuccess}>
                  {(onTeamLibraryChange) => (
                    <AddFeatureMenuHandler teamFeatureSets={teamFeatureSets}>
                      {(addFeatureDisabled, onAddFeature) => (
                        <AddConversationMenuHandler teamConversations={teamConversations}>
                          {(addConversationDisabled, onAddConversation) => (
                            <Stack direction="row">
                              <Button
                                id="demo-button"
                                aria-controls={demoButtonIsOpen ? "demo-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={demoButtonIsOpen ? "true" : undefined}
                                onClick={handleClick}
                              >
                                Demo
                                <ArrowDropDownIcon />
                              </Button>
                              <Menu
                                id="demo-menu"
                                anchorEl={demoMenuAnchorEl}
                                open={demoButtonIsOpen}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": "demo-button",
                                }}
                              >
                                <DemoSaveMenuItem
                                  onClick={() => {
                                    handleClose();
                                    onSave(client);
                                  }}
                                />
                                <DemoSaveAsMenuItem
                                  onClick={() => {
                                    handleClose();
                                    onSaveAs(client);
                                  }}
                                />
                                {showFullMenu ? (
                                  <React.Fragment>
                                    <Divider />
                                    <DemoTeamLibraryMenuItem
                                      inTeamLibrary={inTeamLibary}
                                      onClick={() => {
                                        handleClose();
                                        onTeamLibraryChange(client);
                                      }}
                                    />
                                    <Divider />
                                    <AddFeatureMenuItem
                                      disabled={addFeatureDisabled}
                                      onClick={() => {
                                        handleClose();
                                        onAddFeature();
                                      }}
                                    />
                                    <AddConversationMenuItem
                                      disabled={addConversationDisabled}
                                      onClick={() => {
                                        handleClose();
                                        onAddConversation();
                                      }}
                                    />
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                              </Menu>
                            </Stack>
                          )}
                        </AddConversationMenuHandler>
                      )}
                    </AddFeatureMenuHandler>
                  )}
                </DemoTeamLibraryMenuHandler>
              )}
            </DemoSaveAsMenuHandler>
          )}
        </DemoSaveMenuHandler>
      )}
    </ApolloConsumer>
  );
};
