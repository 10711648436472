import React, { ChangeEvent } from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";

import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { red } from "@mui/material/colors";

import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import {
  DemoTemplateContent,
  getDemoTemplateContent,
  getTeamConversationsNotIncluded,
  getTeamFeatureSetsNotIncluded,
  canSaveTemplateProperties,
  revertTemplatePropertiesChange,
  selectViewDemoTemplate,
  setCloseId,
  setConversationPickerOpen,
  setDescription,
  setTemplatePropertiesErrorMessage,
  setFeatureSetPickerOpen,
  setLastSavedDescription,
  setLastSavedName,
  setOpeningId,
  setStakeholderType,
  setTemplateContent,
  setTemplateConversations,
  setTemplateFeatureSets,
  setName,
  canRevertTemplateProperties,
} from "../store/viewDemoTemplateSlice";

import { DemoContentId, SuccessResponse } from "../../../../generated/graphql";
import { SelectContentByName } from "../../../../components/selectContentByName";
import { DemoContentIdName, ObjectWithName } from "../../../../util/demoTypes";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { showSnackbarError, showSnackbarSuccess } from "../../../../components/appSnackbarSlice";
import { arrayWithElementMoved } from "../../../../util/arrayUtils";
import { ObjectArrayTableWithDnd } from "../../../../components/objectArrayTable/objectArrayTableWithDnD";
import { MultiContentPickerDialog } from "../../../../components/dialogs/multiContentPickerDialog";

import { DemoTemplateContentCellNode } from "./demoTemplateContentCellNode";

interface Props {
  teamId: string;
  demoTemplateId: string;
  openings: ObjectWithName[];
  closes: ObjectWithName[];
  urlRoot: string;
  readOnly?: boolean;
  requestUpdateDemoTemplateClose: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; closeId: number },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestUpdateDemoTemplateContentOrder: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; newOrderList: DemoContentId[] },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestUpdateDemoTemplateProperties: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; name: string; description: string },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestUpdateDemoTemplateOpening: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; openingId: number },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestUpdateDemoTemplatePersonaType: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; personaType: string },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestAddDemoTemplateFeatureSets: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; featureSetIds: number[] },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestAddDemoTemplateConversations: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; conversationIds: number[] },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestDemoTemplateFeatureSets: (
    client: ApolloClient<object>,
    variables: { demoTemplateFeatureSetIds: number[] },
    onSuccess: (ids: any[]) => void,
    onError: (message: string) => void
  ) => Promise<void>;
  requestDemoTemplateConversations: (
    client: ApolloClient<object>,
    variables: { demoTemplateConversationIds: number[] },
    onSuccess: (ids: any[]) => void,
    onError: (message: string) => void
  ) => Promise<void>;
  requestUpdateDemoTemplateConversationRecommended: (
    client: ApolloClient<object>,
    variables: { demoTemplateConversationId: number; recommended: boolean },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestRemoveDemoTemplateConversation: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; demoTemplateConversationId: number },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestUpdateDemoTemplateFeatureSetRecommended: (
    client: ApolloClient<object>,
    variables: { demoTemplateFeatureSetId: number; recommended: boolean },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestRemoveDemoTemplateFeatureSet: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; demoTemplateFeatureSetId: number },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
}

export const ViewDemoTemplateEditor: React.FC<Props> = ({
  teamId,
  demoTemplateId,
  openings,
  closes,
  urlRoot,
  readOnly,
  requestUpdateDemoTemplateClose,
  requestUpdateDemoTemplateContentOrder,
  requestUpdateDemoTemplateProperties,
  requestUpdateDemoTemplateOpening,
  requestUpdateDemoTemplatePersonaType,
  requestAddDemoTemplateFeatureSets,
  requestAddDemoTemplateConversations,
  requestDemoTemplateFeatureSets,
  requestDemoTemplateConversations,
  requestUpdateDemoTemplateConversationRecommended,
  requestRemoveDemoTemplateConversation,
  requestUpdateDemoTemplateFeatureSetRecommended,
  requestRemoveDemoTemplateFeatureSet,
}) => {
  const dispatch = useAppDispatch();
  const viewDemoTemplateState = useAppSelector(selectViewDemoTemplate);

  const reportError = (message: string) => {
    dispatch(showSnackbarError(message));
  };

  const onDrop = (client: ApolloClient<object>, result: DropResult) => {
    if (result.destination) {
      const draggedIndex = result.source.index;
      const droppedIndex = result.destination.index;
      moveCell(client, draggedIndex, droppedIndex - draggedIndex);
    }
  };

  const onChangeContentChoiceId = async (
    client: ApolloClient<object>,
    id: string,
    contentType: string,
    gqlUpdate: (
      client: ApolloClient<object>,
      obj: any,
      onSuccess: () => void,
      onError: () => void
    ) => Promise<SuccessResponse>,
    setter: ActionCreatorWithPayload<string, string>
  ): Promise<SuccessResponse> => {
    return await gqlUpdate(
      client,
      {
        demoTemplateId: parseInt(demoTemplateId),
        [`${contentType.toLowerCase()}Id`]: parseInt(id),
      },
      () => {
        dispatch(setter(id));
      },
      () => {
        return `Error updating ${contentType}`;
      }
    );
  };

  const onChangeOpeningId = async (client: ApolloClient<object>, openingId: string): Promise<SuccessResponse> => {
    return onChangeContentChoiceId(client, openingId, "Opening", requestUpdateDemoTemplateOpening, setOpeningId);
  };

  const onChangeCloseId = async (client: ApolloClient<object>, closeId: string): Promise<SuccessResponse> => {
    return onChangeContentChoiceId(client, closeId, "Close", requestUpdateDemoTemplateClose, setCloseId);
  };

  const onStakeholderTypeChange = async (
    client: ApolloClient<object>,
    _: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    await requestUpdateDemoTemplatePersonaType(
      client,
      { demoTemplateId: parseInt(demoTemplateId), personaType: value },
      () => {
        dispatch(setStakeholderType(value));
        dispatch(showSnackbarSuccess("Target stakeholder saved"));
      },
      () => {
        const message = `Error updating stakeholder type`;
        dispatch(showSnackbarError(message));
        return message;
      }
    );
  };

  const onTemplatePropertiesSave = async (client: ApolloClient<object>) => {
    dispatch(setTemplatePropertiesErrorMessage(""));
    await requestUpdateDemoTemplateProperties(
      client,
      {
        demoTemplateId: parseInt(demoTemplateId),
        name: viewDemoTemplateState.name,
        description: viewDemoTemplateState.description,
      },
      () => {
        dispatch(setLastSavedName(viewDemoTemplateState.name));
        dispatch(setLastSavedDescription(viewDemoTemplateState.description));
        dispatch(showSnackbarSuccess("Saved"));
      },
      () => {
        const message = `Error updating stakeholder template`;
        dispatch(setTemplatePropertiesErrorMessage(message));
        dispatch(showSnackbarError(message));
        return message;
      }
    );
  };

  const featureSetPickerOnClose = async (
    apolloClient: ApolloClient<object>,
    fsList?: DemoContentIdName[]
  ): Promise<void> => {
    if (fsList) {
      const message = `Error adding feature`;
      await requestAddDemoTemplateFeatureSets(
        apolloClient,
        {
          demoTemplateId: parseInt(demoTemplateId),
          featureSetIds: fsList.map((fs) => parseInt(fs.id)),
        },
        (response: SuccessResponse) => {
          if (!response.idsInserted) {
            dispatch(showSnackbarError(message));
            return;
          }

          requestDemoTemplateFeatureSets(
            apolloClient,
            { demoTemplateFeatureSetIds: response.idsInserted },
            (fsList) => {
              dispatch(setTemplateFeatureSets([...viewDemoTemplateState.templateFeatureSets, ...fsList]));
            },
            (message) => {
              dispatch(showSnackbarError(message));
            }
          );
        },
        () => {
          dispatch(showSnackbarError(message));
          return message;
        }
      );
    }
    dispatch(setFeatureSetPickerOpen(false));
  };

  const conversationPickerOnClose = async (
    apolloClient: ApolloClient<object>,
    convList?: DemoContentIdName[]
  ): Promise<void> => {
    if (convList) {
      const message = `Error adding conversation`;
      await requestAddDemoTemplateConversations(
        apolloClient,
        {
          demoTemplateId: parseInt(demoTemplateId),
          conversationIds: convList.map((conv) => parseInt(conv.id)),
        },
        (response: SuccessResponse) => {
          if (!response.idsInserted) {
            dispatch(showSnackbarError("Conversations saved, but got unexpected response. Try refreshing the browser"));
            return;
          }
          requestDemoTemplateConversations(
            apolloClient,
            { demoTemplateConversationIds: response.idsInserted },
            (convList) => {
              dispatch(setTemplateConversations([...viewDemoTemplateState.templateConversations, ...convList]));
            },
            (message) => {
              dispatch(showSnackbarError("Conversations saved, but could not retrieve them."));
            }
          );
        },
        () => {
          dispatch(showSnackbarError(message));
          return message;
        }
      );
    }
    dispatch(setConversationPickerOpen(false));
  };

  const moveCell = async (client: ApolloClient<object>, index: number, delta: number): Promise<void> => {
    if (index < 0) {
      reportError(`Error updating order`);
      return;
    }
    const contentCopy = arrayWithElementMoved(getDemoTemplateContent(viewDemoTemplateState), index, delta);
    if (!contentCopy) {
      reportError(`Error updating order`);
      return;
    }
    await updateContentOrder(client, contentCopy);
  };

  const updateContentOrder = async (
    client: ApolloClient<object>,
    newTemplateContent: DemoTemplateContent[]
  ): Promise<void> => {
    for (let i = 0; i < newTemplateContent.length; ++i) {
      newTemplateContent[i].order = i;
    }
    const newOrderList: DemoContentId[] = newTemplateContent.map((c) => {
      const subObject = (c as any)["featureSet"] ?? (c as any)["conversation"];
      return {
        contentType: c.__typename!,
        id: parseInt(subObject.id),
      };
    });

    // Do an optimistic update of the state so the UI looks good and undo it if the network
    // call fails.
    const undoStateTemplateContent = getDemoTemplateContent(viewDemoTemplateState);
    dispatch(setTemplateContent(newTemplateContent));

    await requestUpdateDemoTemplateContentOrder(
      client,
      {
        demoTemplateId: parseInt(demoTemplateId),
        newOrderList,
      },
      () => {},
      () => {
        // Undo optimistic update
        dispatch(setTemplateContent(undoStateTemplateContent));
        const message = `Error updating order`;
        reportError(message);
        return message;
      }
    );
  };

  const teamFeatureSetsNotIncluded = getTeamFeatureSetsNotIncluded(viewDemoTemplateState);
  const teamConversationsNotIncluded = getTeamConversationsNotIncluded(viewDemoTemplateState);
  const readOnlyUnwrapped = readOnly ?? false;

  return (
    <React.Fragment>
      <ApolloConsumer>
        {(client) => (
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="stakeholder"
                  name="radio-buttons-group"
                  value={viewDemoTemplateState.stakeholderType}
                  onChange={(_, v) => onStakeholderTypeChange(client, _, v)}
                >
                  <FormControlLabel disabled={readOnly} value="executive" control={<Radio />} label="Executive" />
                  <FormControlLabel disabled={readOnly} value="manager" control={<Radio />} label="Manager" />
                  <FormControlLabel disabled={readOnly} value="user" control={<Radio />} label="User" />
                </RadioGroup>
              </FormControl>
              <TextField
                disabled={readOnly}
                value={viewDemoTemplateState.name}
                label="Name"
                variant="outlined"
                required={true}
                fullWidth
                color={viewDemoTemplateState.name !== "" ? "primary" : "error"}
                inputProps={{ maxLength: 255 }}
                onChange={(e) => {
                  dispatch(setName(e.target.value));
                }}
              />
              <TextField
                disabled={readOnly}
                value={viewDemoTemplateState.description}
                label="Description"
                variant="outlined"
                multiline={true}
                rows={3}
                fullWidth
                color="primary"
                inputProps={{ maxLength: 255 }}
                onChange={(e) => {
                  dispatch(setDescription(e.target.value));
                }}
              />
              {!readOnly ?? false ? (
                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    color="error"
                    disabled={!canRevertTemplateProperties(viewDemoTemplateState)}
                    onClick={() => {
                      dispatch(revertTemplatePropertiesChange());
                    }}
                  >
                    Revert
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!canSaveTemplateProperties(viewDemoTemplateState)}
                    onClick={() => {
                      onTemplatePropertiesSave(client);
                    }}
                  >
                    Save
                  </Button>
                  {viewDemoTemplateState.templatePropertiesErrorMessage ? (
                    <Typography color={red[900]}>{viewDemoTemplateState.templatePropertiesErrorMessage}</Typography>
                  ) : (
                    <React.Fragment />
                  )}
                </Stack>
              ) : (
                <React.Fragment />
              )}
            </Stack>
          </Paper>
        )}
      </ApolloConsumer>
      <ApolloConsumer>
        {(client) => (
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <SelectContentByName
                  label="Opening"
                  disabled={readOnly}
                  data={openings}
                  chosenId={viewDemoTemplateState.openingId}
                  onChange={(id) => {
                    return onChangeOpeningId(client, id);
                  }}
                />
              </Stack>
              <DragDropContext
                onDragEnd={(result) => {
                  if (!readOnlyUnwrapped) {
                    onDrop(client, result);
                  }
                }}
              >
                <ObjectArrayTableWithDnd
                  data={getDemoTemplateContent(viewDemoTemplateState)}
                  dataDescription="features / conversations"
                  title={
                    <span>
                      <ViewQuiltIcon style={{ transform: "translate(2px, 5px)" }} /> Feature /{" "}
                      <QuestionAnswerIcon style={{ transform: "translate(2px, 5px)" }} /> Conversation
                    </span>
                  }
                  onClick={() => {}}
                  buttons={
                    readOnlyUnwrapped
                      ? []
                      : [
                          {
                            text: "Include Feature",
                            onClick: () => {
                              dispatch(setFeatureSetPickerOpen(true));
                            },
                            disabled: teamFeatureSetsNotIncluded.length === 0,
                          },
                          {
                            text: "Include Conversation",
                            onClick: () => {
                              dispatch(setConversationPickerOpen(true));
                            },
                            disabled: teamConversationsNotIncluded.length === 0,
                          },
                        ]
                  }
                  cellNode={(obj, index) => (
                    <DemoTemplateContentCellNode
                      demoTemplateId={demoTemplateId}
                      obj={obj}
                      index={index}
                      readOnly={readOnlyUnwrapped}
                      requestUpdateDemoTemplateFeatureSetRecommended={requestUpdateDemoTemplateFeatureSetRecommended}
                      requestRemoveDemoTemplateFeatureSet={requestRemoveDemoTemplateFeatureSet}
                      requestUpdateDemoTemplateConversationRecommended={
                        requestUpdateDemoTemplateConversationRecommended
                      }
                      requestRemoveDemoTemplateConversation={requestRemoveDemoTemplateConversation}
                    />
                  )}
                />
              </DragDropContext>
              {readOnlyUnwrapped ? (
                <React.Fragment />
              ) : (
                <Typography textAlign="right">
                  Add a new <Link to={`${urlRoot}/team/${teamId}/feature-sets`}>feature</Link> or{" "}
                  <Link to={`${urlRoot}/team/${teamId}/conversations`}>conversation</Link> to the team library
                </Typography>
              )}
              <MultiContentPickerDialog
                title="Select Features"
                items={teamFeatureSetsNotIncluded}
                open={viewDemoTemplateState.featureSetPickerOpen}
                onClose={(content) => {
                  return featureSetPickerOnClose(client, content);
                }}
              />
              <MultiContentPickerDialog
                title="Select Conversations"
                items={teamConversationsNotIncluded}
                open={viewDemoTemplateState.conversationPickerOpen}
                onClose={(content) => {
                  return conversationPickerOnClose(client, content);
                }}
              />
              <Stack direction="row" spacing={2}>
                <SelectContentByName
                  disabled={readOnly}
                  label="Close"
                  data={closes}
                  chosenId={viewDemoTemplateState.closeId}
                  onChange={(id) => {
                    return onChangeCloseId(client, id);
                  }}
                />
              </Stack>
            </Stack>
          </Paper>
        )}
      </ApolloConsumer>
    </React.Fragment>
  );
};
