import React from "react";

import { useParams } from "react-router";
import { useSiteTeamClosesQuery } from "../../../../generated/graphql";
import { ViewTeamContentSiteAdminPage } from "../../../../components/siteAdmin/viewTeamContentSiteAdmin";
import { useHistory } from "react-router-dom";

interface Params {
  teamId: string;
}

export const ViewTeamClosesSiteAdminPage: React.FC = () => {
  let { teamId } = useParams<Params>();
  const history = useHistory();

  return (
    <ViewTeamContentSiteAdminPage
      teamId={teamId}
      query={useSiteTeamClosesQuery}
      chosen="Closes"
      getObjects={(data) => {
        return data.closes;
      }}
      dataDescription="closes"
      title="Close"
      newContentPath="new-close"
      onClick={(id) => {
        history.push(`/site-admin/close/${id}`);
      }}
      rowLabelProperty="name"
    />
  );
};
