import React from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

interface Props {
  onClick: () => void;
  inTeamLibrary: boolean;
}

export const DemoTeamLibraryMenuItem: React.FC<Props> = ({ onClick, inTeamLibrary }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <LibraryBooksIcon fontSize="small" color="primary" />
      </ListItemIcon>
      <ListItemText>{inTeamLibrary ? "Remove from" : "Add to"} Team Library</ListItemText>
    </MenuItem>
  );
};
