import React from "react";

import { useHistory, useParams } from "react-router";
import { useSiteTeamFeatureSetsQuery } from "../../../../generated/graphql";
import { ViewTeamContentSiteAdminPage } from "../../../../components/siteAdmin/viewTeamContentSiteAdmin";

interface Params {
  teamId: string;
}

export const ViewTeamFeatureSetsSiteAdminPage: React.FC = () => {
  const { teamId } = useParams<Params>();
  const history = useHistory();

  return (
    <ViewTeamContentSiteAdminPage
      teamId={teamId}
      query={useSiteTeamFeatureSetsQuery}
      chosen="Features"
      getObjects={(data) => {
        return data.featureSets;
      }}
      dataDescription="features"
      title="Feature"
      newContentPath="new-feature-set"
      onClick={(id) => {
        history.push(`/site-admin/feature-set/${id}`);
      }}
      rowLabelProperty="name"
    />
  );
};
