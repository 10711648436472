import React from "react";

import { useHistory, useParams } from "react-router";
import { EditTeamDemoContentSiteAdmin } from "../../../../components/siteAdmin/editTeamDemoContentSiteAdmin";
import { useSiteFeatureSetQuery, useUpdateSiteFeatureSetMutation } from "../../../../generated/graphql";

interface Params {
  featureSetId: string;
}

export const EditTeamFeatureSetSiteAdminPage: React.FC = () => {
  let history = useHistory();
  let { featureSetId } = useParams<Params>();
  const [updateSiteFeatureSet] = useUpdateSiteFeatureSetMutation();

  return (
    <EditTeamDemoContentSiteAdmin
      id={parseInt(featureSetId)}
      contentType="Feature"
      idArgName="featureSetId"
      query={useSiteFeatureSetQuery}
      update={updateSiteFeatureSet}
      onClose={(teamId) => {
        history.push(`/site-admin/team/${teamId}/feature-sets`);
      }}
      queryDataProperty="siteFeatureSet"
      resultDataProperty="updateSiteFeatureSet"
      getDemoTemplates={(data) => data.demoTemplateFeatureSets.map((tfs: any) => tfs.demoTemplate)}
    ></EditTeamDemoContentSiteAdmin>
  );
};
