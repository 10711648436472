import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Breadcrumb } from "../../../components/breadcrumb";
import { AppLayout } from "../../../components/layouts/appLayout";
import { useParams } from "react-router-dom";
import { useUserNotificationQuery } from "../../../generated/graphql";
import { AppErrorPage } from "../../../components/appError";
import { AppLoadingPage } from "../../../components/appLoadingPage";
import { AppSnackbar } from "../../../components/appSnackbar";
import { PaperWithTitle } from "../../../components/paperWithTitle";
import { MarkNotificationAsRead } from "./markNotificationAsRead";
import { ApolloConsumer } from "@apollo/client";

interface Params {
  notificationId: string;
}

export const UserNotificationPage: React.FC = () => {
  const { notificationId } = useParams<Params>();
  const [initialized, setInitialized] = useState(false);

  const { data, loading, error } = useUserNotificationQuery({
    variables: { notificationId: parseInt(notificationId) },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <AppLoadingPage />;
  }
  if (!initialized) {
    setInitialized(true);
  }

  return (
    <AppLayout>
      <ApolloConsumer>
        {(client) => <MarkNotificationAsRead client={client} notificationId={notificationId} />}
      </ApolloConsumer>
      <Stack spacing={2}>
        <Breadcrumb crumbs={[{ text: "All Notifications", url: `/notifications` }]} />
        <PaperWithTitle title={data.userNotification.body}>
          <Typography variant="h6">Demo Feedback</Typography>
        </PaperWithTitle>
        <AppSnackbar />
      </Stack>
      <AppSnackbar />
    </AppLayout>
  );
};
