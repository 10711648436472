import React, { useContext } from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { List, Stack } from "@mui/material";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import { DemoContent, demoContentKey } from "../../../util/demoTypes";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { requestMoveUserDemoContent } from "../../../generated/graphqlWrappers";

import { DemoNavItem } from "./demoNav/demoNavItem";
import { isEditableViewDemoState, isEditableViewDemoActions } from "./viewDemoSlice";
import { draggablePartsDeepCopy, reorderedContentList } from "./viewDemoSliceUtils";
import { DemoViewModeSelector } from "./demoNav/demoViewModeSelector";
import { DemoPlayer } from "./demoPlayer";
import { WordCount } from "../../../components/textEditor/wordCount";
import { demoContentTotalWordCount, DemoViewMode, ViewDemoContext } from "./viewDemoBaseReduxUtils";

export const DemoNav: React.FC = () => {
  const context = useContext(ViewDemoContext);
  const viewDemoState = useAppSelector(context.selectState);
  const dispatch = useAppDispatch();
  const actions = context.actions;

  const isEditable = isEditableViewDemoState(viewDemoState) && isEditableViewDemoActions(actions);

  const onReorder = async (apolloClient: ApolloClient<object>, draggedIndex: number, droppedIndex: number) => {
    if (!isEditable) {
      return;
    }
    dispatch(actions.setErrorMessage(""));
    const errorMessage = "Error reordering demo content";
    const newOrderList = reorderedContentList(viewDemoState, draggedIndex, droppedIndex);

    const undoState = draggablePartsDeepCopy(viewDemoState);
    dispatch(actions.demoOrderEditsSuccessfullySent(newOrderList));
    await requestMoveUserDemoContent(
      apolloClient,
      { demoId: parseInt(viewDemoState.demoId), newOrderList },
      () => {},
      () => {
        dispatch(actions.undoDrag(undoState));
        dispatch(actions.setErrorMessage(errorMessage));
        return errorMessage;
      }
    );
  };

  const isDraggable = (content: DemoContent) => {
    if (!isEditable) {
      return false;
    }
    return content.__typename === "FeatureSetForDemo" || content.__typename === "ConversationForDemo";
  };

  const onDrop = (apolloClient: ApolloClient<object>, result: DropResult) => {
    if (result.destination) {
      onReorder(apolloClient, result.source.index, result.destination.index);
    }
  };

  const makeDemoNavItem = (content: DemoContent): React.ReactNode => {
    return (
      <DemoNavItem
        key={demoContentKey(content)}
        content={content}
        selected={viewDemoState.chosenKey === demoContentKey(content)}
        onClick={() => dispatch(actions.setLastTappedDemoNav(demoContentKey(content)))}
        isDraggable={isDraggable(content)}
      />
    );
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <React.Fragment>
          {isEditable ? <DemoViewModeSelector /> : <React.Fragment />}
          {viewDemoState.viewMode === DemoViewMode.VIEW ? <DemoPlayer /> : <React.Fragment />}
          <Stack direction="row" justifyContent="center" sx={{ my: 1 }}>
            <WordCount wordCount={demoContentTotalWordCount(viewDemoState)} bold={true} />
          </Stack>
          <List dense={true}>
            {makeDemoNavItem(viewDemoState.opening.content)}
            {isEditable ? (
              <DragDropContext onDragEnd={(result) => onDrop(client, result)}>
                <Droppable droppableId="droppable">
                  {(provided, _) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {viewDemoState.draggableParts.map((editableContent, i) => {
                        const content = editableContent.content;
                        return (
                          <Draggable key={demoContentKey(content)} draggableId={demoContentKey(content)} index={i}>
                            {(provided, _) => {
                              return (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  {makeDemoNavItem(content)}
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              viewDemoState.draggableParts.map((editableContent) => {
                const content = editableContent.content;
                return makeDemoNavItem(content);
              })
            )}
            {makeDemoNavItem(viewDemoState.close.content)}
          </List>
        </React.Fragment>
      )}
    </ApolloConsumer>
  );
};
