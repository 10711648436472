import { ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import React from "react";
import { DemoContent, demoContentKey, teamContentIsNewer } from "../../../../util/demoTypes";
import CircleIcon from "@mui/icons-material/Circle";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { demoContentIcon } from "../../../../components/demoContentIcon";

interface Props {
  content: DemoContent;
  selected: boolean;
  onClick: () => void;
  isDraggable: boolean;
}

export const DemoNavItem: React.FC<Props> = ({ content, selected, onClick, isDraggable }) => {
  return (
    <ListItem id={demoContentKey(content)} selected={selected} onClick={onClick}>
      {teamContentIsNewer(content) ? (
        <Tooltip title="There has been an update to the template version">
          <CircleIcon
            fontSize="small"
            style={{
              position: "absolute",
              cursor: "default",
              color: "#ee6666",
              top: -3,
              right: -3,
              transform: "scale(0.6, 0.6)",
            }}
          />
        </Tooltip>
      ) : (
        <React.Fragment />
      )}
      <ListItemIcon sx={{ mr: -3 }}>{demoContentIcon(content.__typename)}</ListItemIcon>
      <ListItemText
        primary={content.name}
        style={{
          cursor: isDraggable ? undefined : "default",
        }}
      />
      {isDraggable ? (
        <Tooltip title="Drag to reorder">
          <DragHandleIcon fontSize="small" className="cursor-y" htmlColor="#ccc" sx={{ ml: 1 }} />
        </Tooltip>
      ) : (
        <React.Fragment />
      )}
    </ListItem>
  );
};
