import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../../store/store";
import {
  DemoContentIdName,
  DemoTemplateQueryData,
  TemplateConversationQueryData,
  TemplateFeatureSetQueryData,
  TemplateTeamConversationQueryData,
  TemplateTeamFeatureSetQueryData,
} from "../../../../util/demoTypes";
import _ from "underscore";

/**
 * Defines the slice, reducers, and actions for administrating a stakeholder template.
 */

export interface ViewDemoTemplateState {
  initialized: boolean;
  name: string;
  lastSavedName: string;
  description: string;
  lastSavedDescription: string;
  templatePropertiesErrorMessage: string;
  stakeholderType: string;
  openingId: string;
  closeId: string;
  templateFeatureSets: TemplateFeatureSetQueryData;
  templateConversations: TemplateConversationQueryData;
  teamFeatureSets: TemplateTeamFeatureSetQueryData;
  teamConversations: TemplateTeamConversationQueryData;
  featureSetPickerOpen: boolean;
  conversationPickerOpen: boolean;

  draggedElementId: string | undefined;
  dropTargetElementId: string | undefined;
}

const initialState: ViewDemoTemplateState = {
  initialized: false,
  name: "",
  lastSavedName: "",
  description: "",
  lastSavedDescription: "",
  templatePropertiesErrorMessage: "",

  stakeholderType: "",
  openingId: "",
  closeId: "",

  templateFeatureSets: [],
  templateConversations: [],

  teamFeatureSets: [],
  teamConversations: [],

  featureSetPickerOpen: false,
  conversationPickerOpen: false,

  draggedElementId: undefined,
  dropTargetElementId: undefined,
};

export const canRevertTemplateProperties = (state: ViewDemoTemplateState) => {
  return state.lastSavedName !== state.name || state.lastSavedDescription !== state.description;
};

export const canSaveTemplateProperties = (state: ViewDemoTemplateState) => {
  return state.name !== "" && (state.lastSavedName !== state.name || state.lastSavedDescription !== state.description);
};

export const getTeamFeatureSetsNotIncluded = (state: ViewDemoTemplateState): DemoContentIdName[] => {
  return state.teamFeatureSets.filter(
    (c) => state.templateFeatureSets.map((tfs) => tfs.featureSet).findIndex((fs) => fs.id === c.id) < 0
  );
};

export const getTeamConversationsNotIncluded = (state: ViewDemoTemplateState): DemoContentIdName[] => {
  return state.teamConversations.filter(
    (c) => state.templateConversations.map((tc) => tc.conversation).findIndex((convo) => convo.id === c.id) < 0
  );
};

export type DemoTemplateContent = TemplateFeatureSetQueryData[0] | TemplateConversationQueryData[0];

export const getDemoTemplateContent = (state: ViewDemoTemplateState): DemoTemplateContent[] => {
  let content = [...state.templateFeatureSets, ...state.templateConversations];
  content.sort((lhs, rhs) => {
    return lhs.order - rhs.order;
  });
  return content;
};

export const getDemoTemplateContentIndexOf = (state: ViewDemoTemplateState, id: string, prop: string): number => {
  return getDemoTemplateContent(state).findIndex((c) => {
    const subObject = (c as any)[prop];
    if (subObject) {
      return subObject.id === id;
    }
    return false;
  });
};

export const viewDemoTemplateSlice = createSlice({
  name: "viewDemoTemplate",
  initialState,
  reducers: {
    clearViewDemoTemplateState: (state) => {
      state.initialized = initialState.initialized;
      state.name = initialState.name;
      state.lastSavedName = initialState.lastSavedName;
      state.description = initialState.description;
      state.lastSavedDescription = initialState.lastSavedDescription;
      state.templatePropertiesErrorMessage = initialState.templatePropertiesErrorMessage;
      state.stakeholderType = initialState.stakeholderType;
      state.openingId = initialState.openingId;
      state.closeId = initialState.closeId;
      state.templateFeatureSets = initialState.templateFeatureSets;
      state.templateConversations = initialState.templateConversations;
      state.teamFeatureSets = initialState.teamFeatureSets;
      state.teamConversations = initialState.teamConversations;
      state.featureSetPickerOpen = initialState.featureSetPickerOpen;
      state.conversationPickerOpen = initialState.conversationPickerOpen;
      state.draggedElementId = initialState.draggedElementId;
      state.dropTargetElementId = initialState.dropTargetElementId;
    },

    initializeViewDemoTemplateState: (state, action: PayloadAction<DemoTemplateQueryData>) => {
      state.initialized = true;
      state.name = action.payload.name;
      state.lastSavedName = state.name;
      state.description = action.payload.description;
      state.lastSavedDescription = state.description;
      state.stakeholderType = action.payload.personaType;
      state.openingId = action.payload.opening.id;
      state.closeId = action.payload.close.id;
      state.templateFeatureSets = action.payload.demoTemplateFeatureSets;
      state.templateConversations = action.payload.demoTemplateConversations;
      state.teamFeatureSets = action.payload.team.featureSets;
      state.teamConversations = action.payload.team.conversations;
    },
    setOpeningId: (state, action: PayloadAction<string>) => {
      state.openingId = action.payload;
    },
    setCloseId: (state, action: PayloadAction<string>) => {
      state.closeId = action.payload;
    },
    setStakeholderType: (state, action: PayloadAction<string>) => {
      state.stakeholderType = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setLastSavedName: (state, action: PayloadAction<string>) => {
      state.lastSavedName = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setLastSavedDescription: (state, action: PayloadAction<string>) => {
      state.lastSavedDescription = action.payload;
    },
    setTemplatePropertiesErrorMessage: (state, action: PayloadAction<string>) => {
      state.templatePropertiesErrorMessage = action.payload;
    },
    setTemplateFeatureSets: (state, action: PayloadAction<TemplateFeatureSetQueryData>) => {
      state.templateFeatureSets = action.payload;
    },
    setTemplateConversations: (state, action: PayloadAction<TemplateConversationQueryData>) => {
      state.templateConversations = action.payload;
    },
    setTemplateContent: (state, action: PayloadAction<DemoTemplateContent[]>) => {
      const [featureSets, conversations] = _.partition(
        action.payload,
        (c) => c.__typename === "DemoTemplateFeatureSetForTeam"
      );
      state.templateFeatureSets = featureSets as TemplateFeatureSetQueryData;
      state.templateConversations = conversations as TemplateConversationQueryData;
    },
    setFeatureSetPickerOpen: (state, action: PayloadAction<boolean>) => {
      state.featureSetPickerOpen = action.payload;
    },
    setConversationPickerOpen: (state, action: PayloadAction<boolean>) => {
      state.conversationPickerOpen = action.payload;
    },
    revertTemplatePropertiesChange: (state) => {
      state.name = state.lastSavedName;
      state.description = state.lastSavedDescription;
      state.templatePropertiesErrorMessage = "";
    },
  },
});

export const {
  clearViewDemoTemplateState,
  initializeViewDemoTemplateState,
  setOpeningId,
  setCloseId,
  setStakeholderType,
  setName,
  setDescription,
  setTemplatePropertiesErrorMessage,
  setLastSavedName,
  setLastSavedDescription,
  setTemplateFeatureSets,
  setTemplateConversations,
  setTemplateContent,
  setFeatureSetPickerOpen,
  setConversationPickerOpen,
  revertTemplatePropertiesChange,
} = viewDemoTemplateSlice.actions;

export const selectViewDemoTemplate = (state: RootState) => state.viewDemoTemplate as ViewDemoTemplateState;

export default viewDemoTemplateSlice.reducer;
