import React from "react";

import { ApolloClient } from "@apollo/client";
import { CreateContentDialog } from "./createContentDialog";

interface Props {
  organizationId: number;
  open: boolean;
  onClose: (added: boolean) => void;
  onSave: (
    client: ApolloClient<object>,
    organizationId: number,
    displayName: string,
    onSuccess: () => void,
    onError: (msg: string) => void
  ) => Promise<void>;
}

export const CreateTeamDialog: React.FC<Props> = ({ organizationId, open, onClose, onSave }) => {
  return (
    <CreateContentDialog
      open={open}
      onClose={onClose}
      nameLabel="Text Name"
      buttonLabel="Add Team"
      onSave={(client, name, onSuccess, onError) => {
        return onSave(client, organizationId, name, onSuccess, onError);
      }}
    />
  );
};
