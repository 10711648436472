import "remirror/styles/all.css";
import "./remirrorEditorOverrides.css";
import React, { useState } from "react";
import {
  Callout,
  CodeBlock,
  Doc,
  EditorComponent,
  MarkMap,
  Remirror,
  RemirrorRenderer,
  TextHandler,
  useRemirror,
} from "@remirror/react";
import { FormatMenu } from "./formatMenu";
import { editorContentWordCount, parseToDocument, textEditorFormatExtentions } from "./textEditorUtils";
import { Stack } from "@mui/material";
import { WordCount } from "./wordCount";
import { HeadingHandler, ParagraphHandler, TextColor, TextHighlight } from "./customTextHandlers";

interface Props {
  contentKeyVersioned: string;
  initialContent: string;
  setContentJSON: (json: string, wordCount: number) => void;
  readOnly?: boolean;
  showWordCount?: boolean | number;
  showWordCountLabel?: string;
  setWordCountForPage?: (wordCount: number) => void;
  cumulativeWordCount?: number;
}

const typeMap: MarkMap = {
  blockquote: "blockquote",
  bulletList: "ul",
  callout: Callout,
  codeBlock: CodeBlock,
  doc: Doc,
  hardBreak: "br",
  heading: HeadingHandler,
  horizontalRule: "hr",
  image: "img",
  listItem: "li",
  paragraph: ParagraphHandler,
  orderedList: "ol",
  text: TextHandler,
};

const markMap: MarkMap = {
  italic: "em",
  bold: "strong",
  code: "code",
  underline: "u",
  textHighlight: TextHighlight,
  textColor: TextColor,
};

export const TextEditor: React.FC<Props> = ({
  contentKeyVersioned,
  initialContent,
  setContentJSON,
  readOnly,
  showWordCount,
  showWordCountLabel,
  setWordCountForPage,
  cumulativeWordCount,
}) => {
  const extensions = textEditorFormatExtentions();
  const content = parseToDocument(initialContent);
  const { manager, state, setState } = useRemirror({ extensions, content });
  const [wordCount, setWordCount] = useState(0);
  const [contentKeyTracker, setContentKeyTracker] = useState(contentKeyVersioned);

  if (contentKeyVersioned !== contentKeyTracker) {
    const content = parseToDocument(initialContent);
    const newState = manager.createState({ content });
    manager.view.updateState(newState);
    setContentKeyTracker(contentKeyVersioned);
    const wordCountOnPage = editorContentWordCount(content);
    setWordCount(wordCountOnPage);
    setWordCountForPage?.(wordCountOnPage);
    return <div />;
  }

  return (
    <div className="remirror-theme">
      <Remirror
        manager={manager}
        state={state}
        onChange={(parameter) => {
          const doc = parameter.state.doc.toJSON();
          const count = editorContentWordCount(doc);
          setContentJSON(JSON.stringify(doc), count);
          setWordCount(count);
          setWordCountForPage?.(count);
          setState(parameter.state);
        }}
      >
        {readOnly ?? false ? (
          <React.Fragment>
            {showWordCount ?? true ? (
              <Stack direction="row" justifyContent="flex-end">
                <WordCount
                  wordCount={typeof showWordCount === "number" ? showWordCount : wordCount}
                  showWordCountLabel={showWordCountLabel}
                  cumulativeWordCount={cumulativeWordCount}
                />
              </Stack>
            ) : (
              <React.Fragment />
            )}

            <RemirrorRenderer
              json={parseToDocument(initialContent) as any}
              typeMap={typeMap}
              markMap={markMap}
            ></RemirrorRenderer>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Stack direction="column" alignItems="start">
              {showWordCount ?? true ? (
                <WordCount
                  wordCount={typeof showWordCount === "number" ? showWordCount : wordCount}
                  showWordCountLabel={showWordCountLabel}
                  cumulativeWordCount={cumulativeWordCount}
                />
              ) : (
                <React.Fragment />
              )}
              <FormatMenu />
            </Stack>
            <EditorComponent />
          </React.Fragment>
        )}
      </Remirror>
    </div>
  );
};
