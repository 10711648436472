import React from "react";
import { Stack } from "@mui/material";
import { AppErrorPage } from "../components/appError";
import { AppLoadingPage } from "../components/appLoadingPage";
import { AppLayout } from "../components/layouts/appLayout";
import { OrgAdminChip } from "../components/adminChips";
import { PaperWithTitle } from "../components/paperWithTitle";
import { useUserAccountQuery } from "../generated/graphql";
import { TeamsTable } from "./userApp/team/teamsTable";

export const UserAccountPage: React.FC = () => {
  const { data, loading, error } = useUserAccountQuery({ fetchPolicy: "network-only" });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }

  if (loading || !data) {
    return <AppLoadingPage />;
  }

  return (
    <AppLayout>
      <PaperWithTitle
        title={
          <Stack direction="row" alignItems="center" spacing={2}>
            <div>{data.userAccount.displayName}</div>
            {data.userAccount.isOrgAdmin ? <OrgAdminChip /> : <React.Fragment />}
          </Stack>
        }
      >
        <TeamsTable
          teams={data.userAccount.teams}
          chipForTeam={(teamId) => {
            if (data.userAccount.teamAdminRoles.findIndex((role) => role.team.id === teamId) >= 0) {
              return [{ color: "primary", label: "team admin" }];
            }
            return [];
          }}
        />
      </PaperWithTitle>
    </AppLayout>
  );
};
