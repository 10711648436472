import React from "react";

import { Chip, Stack, Typography } from "@mui/material";
import { ChipData, ObjectArrayTable } from "../../../components/objectArrayTable/objectArrayTable";
import { useHistory } from "react-router";
import { TableHeaderButton } from "../../../components/objectArrayTable/objectTableHeader";

interface TeamCell {
  id: string;
  displayName: string;
}

interface Props {
  teams: TeamCell[];
  chipForTeam?: (id: string) => ChipData[];
  buttons?: TableHeaderButton[];
}

export const TeamsTable: React.FC<Props> = ({ teams, chipForTeam, buttons }) => {
  const history = useHistory();

  return (
    <ObjectArrayTable
      dataDescription="teams"
      title="Team"
      buttons={buttons}
      data={teams}
      cellNode={(team: TeamCell) => {
        return (
          <Stack direction="row">
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              {team.displayName}
            </Typography>
            {(chipForTeam ? chipForTeam(team.id) : []).map((chip) => (
              <Chip key={chip.label} size="small" color={chip.color} label={chip.label} />
            ))}
          </Stack>
        );
      }}
      onClick={(id) => {
        history.push(`/team/${id}`);
      }}
    />
  );
};
