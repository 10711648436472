import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DemoContentId } from "../../../generated/graphql";
import type { RootState } from "../../../store/store";
import {
  DemoContent,
  UserDemoQueryData,
  demoContentKey,
  updateToTeamVersion,
  updateToTeamVersionAndCopyFromTeam,
  UserFeatureSetsForDemoQueryData,
  UserConversationsForDemoQueryData,
} from "../../../util/demoTypes";
import {
  ViewDemoBaseState,
  DemoViewMode,
  DemoScriptMode,
  DemoPlayState,
  initialViewDemoBaseState,
  clearViewDemoBaseState,
  initializeViewDemoBaseState,
  setLastTappedDemoNavBase,
  setChosenKeyBase,
  demoStateChosenEditableContent,
  demoStateEditableContentForKey,
  chooseReactionForContentBase,
  updateReactionFeedbackBase,
  unChooseReactionForContentBase,
  demoStateEditsSuccessfullySentBase,
  toggleRightDrawerBase,
  setScriptModeBase,
  ViewDemoStateActions,
  ChosenReaction,
  setErrorMessageBase,
  setDemoPlayStateBase,
  demoTimerTickBase,
  demoSuccessfullyChangedInTeamLibraryBase,
} from "./viewDemoBaseReduxUtils";
import {
  EditableDemoContent,
  draggablePartsDeepCopy,
  editableContentDeepCopy,
  makeEditable,
  updateStateWithNewContent,
} from "./viewDemoSliceUtils";

/**
 * Defines the slice, reducers, and actions for interacting with the demo
 */

export interface ViewDemoState extends ViewDemoBaseState {
  demoArchived: boolean;

  demoPropertiesEdited: boolean;
  editCount: number;

  featureSetPickerOpen: boolean;
  conversationPickerOpen: boolean;
  confirmRemoveSectionOpen: boolean;
  confirmReplaceSectionOpen: boolean;
  confirmOverwriteTemplateSectionOpen: boolean;
}

export function isEditableViewDemoState(viewDemoState: ViewDemoBaseState): viewDemoState is ViewDemoState {
  return "editCount" in viewDemoState;
}

export function isEditableViewDemoActions(actions: ViewDemoStateActions): actions is typeof viewDemoSlice["actions"] {
  return "editDemoName" in actions;
}

const initialState: ViewDemoState = {
  ...initialViewDemoBaseState,
  demoArchived: false,

  demoPropertiesEdited: false,
  editCount: 0,

  featureSetPickerOpen: false,
  conversationPickerOpen: false,
  confirmRemoveSectionOpen: false,
  confirmReplaceSectionOpen: false,
  confirmOverwriteTemplateSectionOpen: false,
};

export const viewDemoSlice = createSlice({
  name: "viewDemo",
  initialState,
  reducers: {
    clearViewDemoState: (state) => {
      clearViewDemoBaseState(state);

      state.demoArchived = initialState.demoArchived;

      state.demoPropertiesEdited = initialState.demoPropertiesEdited;
      state.editCount = initialState.editCount;

      state.featureSetPickerOpen = initialState.featureSetPickerOpen;
      state.conversationPickerOpen = initialState.conversationPickerOpen;
      state.confirmRemoveSectionOpen = initialState.confirmRemoveSectionOpen;
      state.confirmReplaceSectionOpen = initialState.confirmReplaceSectionOpen;
      state.confirmOverwriteTemplateSectionOpen = initialState.confirmOverwriteTemplateSectionOpen;
    },
    initializeViewDemoState: (state, action: PayloadAction<UserDemoQueryData>) => {
      initializeViewDemoBaseState(state, action.payload.userDemo);

      state.demoArchived = action.payload.userDemo.archived;

      state.demoPropertiesEdited = false;
      state.editCount = 0;
    },
    demoStateEditsSuccessfullySent: (state) => {
      demoStateEditsSuccessfullySentBase(state);
      state.editCount = 0;
      state.opening.isEdited = false;
      state.close.isEdited = false;
      state.demoPropertiesEdited = false;
      for (const dp of state.draggableParts) {
        dp.isEdited = false;
      }
      for (const cr of state.chosenReactions) {
        cr.edited = false;
      }
    },
    demoOrderEditsSuccessfullySent: (state, action: PayloadAction<DemoContentId[]>) => {
      let sortedDraggableParts = draggablePartsDeepCopy(state);

      for (const dp of sortedDraggableParts) {
        const order = action.payload.findIndex(
          (c) => c.id === parseInt(dp.content.id) && c.contentType === dp.content.__typename
        );
        if (order >= 0) {
          dp.content["order"] = order;
        } else {
          throw new Error("Unexpected new order list");
        }
      }

      sortedDraggableParts.sort((lhs, rhs) => {
        return (lhs.content["order"] as number) - (rhs.content["order"] as number);
      });
      state.draggableParts = sortedDraggableParts;
    },
    demoSuccessfullyChangedInTeamLibrary: (state, action: PayloadAction<boolean>) => {
      demoSuccessfullyChangedInTeamLibraryBase(state, action);
    },
    undoDrag: (state, action: PayloadAction<EditableDemoContent[]>) => {
      state.draggableParts = action.payload;
    },
    setChosenKey: (state, action: PayloadAction<string>) => {
      setChosenKeyBase(state, action);
    },
    setLastTappedDemoNav: (state, action: PayloadAction<string>) => {
      setLastTappedDemoNavBase(state, action);
    },
    editDemoName: (state, action: PayloadAction<string>) => {
      state.demoName = action.payload;
      state.demoPropertiesEdited = true;
      state.editCount += 1;
    },
    editDemoDescription: (state, action: PayloadAction<string>) => {
      state.demoDescription = action.payload;
      state.demoPropertiesEdited = true;
      state.editCount += 1;
    },
    editChosenContentName: (state, action: PayloadAction<string>) => {
      if (state.initialized) {
        const editableContent = demoStateChosenEditableContent(state);
        editableContent.content.name = action.payload;
        editableContent.isEdited = true;
        state.editCount += 1;
      }
    },
    editChosenContentWhatToShow: (state, action: PayloadAction<string>) => {
      if (state.initialized) {
        const editableContent = demoStateChosenEditableContent(state);
        editableContent.content.whatToShow = action.payload;
        editableContent.isEdited = true;
        state.editCount += 1;
      }
    },
    editChosenContentBody: (state, action: PayloadAction<{ bodyJSON: string; wordCount: number }>) => {
      if (state.initialized) {
        const editableContent = demoStateChosenEditableContent(state);
        if (editableContent.content.body !== action.payload.bodyJSON) {
          editableContent.content.body = action.payload.bodyJSON;
          editableContent.isEdited = true;
          state.wordCounts = {
            ...state.wordCounts,
            [demoContentKey(editableContent.content)]: action.payload.wordCount,
          };
          state.editCount += 1;
        }
      }
    },
    editChosenContentBulletedBody: (state, action: PayloadAction<string>) => {
      if (state.initialized) {
        const editableContent = demoStateChosenEditableContent(state);
        if (editableContent.content.bulletedBody !== action.payload) {
          editableContent.content.bulletedBody = action.payload;
          editableContent.isEdited = true;
          state.editCount += 1;
        }
      }
    },
    editContentScriptMode: (state, action: PayloadAction<{ contentKey: string; scriptMode: DemoScriptMode }>) => {
      const { contentKey, scriptMode } = action.payload;
      const editableContent = demoStateEditableContentForKey(state, contentKey);
      if (editableContent) {
        editableContent.content.showBulleted = scriptMode === DemoScriptMode.BULLETED;
        editableContent.isEdited = true;
        state.editCount += 1;
      }
    },
    addFeatureSets: (state, action: PayloadAction<UserFeatureSetsForDemoQueryData>) => {
      const fsList = action.payload;
      let newDraggableParts = draggablePartsDeepCopy(state);

      for (const fs of fsList) {
        if (
          state.draggableParts.filter((c) => c.content.id === fs.id && c.content.__typename === "FeatureSetForDemo")
            .length > 0
        ) {
          continue;
        }
        newDraggableParts.push(makeEditable(fs));
      }
      state.draggableParts = newDraggableParts;
    },
    addConversations: (state, action: PayloadAction<UserConversationsForDemoQueryData>) => {
      const convList = action.payload;
      let newDraggableParts = draggablePartsDeepCopy(state);
      for (const conv of convList) {
        if (
          state.draggableParts.filter((c) => c.content.id === conv.id && c.content.__typename === "ConversationForDemo")
            .length > 0
        ) {
          continue;
        }
        newDraggableParts.push(makeEditable(conv));
      }
      state.draggableParts = newDraggableParts;
    },
    removeContent: (state, action: PayloadAction<string>) => {
      const newDraggableParts = draggablePartsDeepCopy(state).filter(
        (dp) => demoContentKey(dp.content) !== action.payload
      );
      state.draggableParts = newDraggableParts;
      if (state.chosenKey === action.payload) {
        state.chosenKey = demoContentKey(state.opening.content);
      }
    },
    templateContentIsOverwritten: (state, action: PayloadAction<{ key: string; content: DemoContent }>) => {
      const editableContent = demoStateEditableContentForKey(state, action.payload.key);
      const newEditableContent = editableContentDeepCopy(editableContent);
      updateToTeamVersionAndCopyFromTeam(newEditableContent.content, action.payload.content);
      updateStateWithNewContent(state, newEditableContent);
    },
    ignoreTemplateVersionOfContent: (state, action: PayloadAction<string>) => {
      const editableContent = demoStateEditableContentForKey(state, action.payload);
      updateToTeamVersion(editableContent.content);
    },
    acceptTemplateVersionOfContent: (state, action: PayloadAction<{ key: string; content: DemoContent }>) => {
      const editableContent = demoStateEditableContentForKey(state, action.payload.key);
      const newEditableContent = editableContentDeepCopy(editableContent);
      updateToTeamVersionAndCopyFromTeam(newEditableContent.content, action.payload.content);
      updateStateWithNewContent(state, newEditableContent);
    },
    chooseReactionForContent: (state, action: PayloadAction<ChosenReaction>) => {
      chooseReactionForContentBase(state, action);
    },
    unChooseReactionForContent: (state, action: PayloadAction<ChosenReaction>) => {
      unChooseReactionForContentBase(state, action);
    },
    updateReactionFeedback: (state, action: PayloadAction<ChosenReaction>) => {
      updateReactionFeedbackBase(state, action);
    },
    setFeatureSetPickerOpen: (state, action: PayloadAction<boolean>) => {
      state.featureSetPickerOpen = action.payload;
    },
    setConversationPickerOpen: (state, action: PayloadAction<boolean>) => {
      state.conversationPickerOpen = action.payload;
    },
    setConfirmRemoveSectionOpen: (state, action: PayloadAction<boolean>) => {
      state.confirmRemoveSectionOpen = action.payload;
    },
    setConfirmReplaceSectionOpen: (state, action: PayloadAction<boolean>) => {
      state.confirmReplaceSectionOpen = action.payload;
    },
    setConfirmOverwriteTemplateSectionOpen: (state, action: PayloadAction<boolean>) => {
      state.confirmOverwriteTemplateSectionOpen = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      setErrorMessageBase(state, action);
    },
    setViewMode: (state, action: PayloadAction<DemoViewMode>) => {
      state.viewMode = action.payload;
    },
    setScriptMode: (state, action: PayloadAction<DemoScriptMode>) => {
      setScriptModeBase(state, action);
    },
    toggleRightDrawer: (state) => {
      toggleRightDrawerBase(state);
    },
    setDemoPlayState: (state, action: PayloadAction<DemoPlayState>) => {
      setDemoPlayStateBase(state, action);
    },
    demoTimerTick: (state) => {
      demoTimerTickBase(state);
    },
  },
});

export const {
  clearViewDemoState,
  initializeViewDemoState,
  demoStateEditsSuccessfullySent,
  demoOrderEditsSuccessfullySent,
  demoSuccessfullyChangedInTeamLibrary,
  undoDrag,
  setChosenKey,
  setLastTappedDemoNav,
  editDemoName,
  editDemoDescription,
  editChosenContentName,
  editChosenContentWhatToShow,
  editChosenContentBody,
  editChosenContentBulletedBody,
  editContentScriptMode,
  addFeatureSets,
  addConversations,
  removeContent,
  templateContentIsOverwritten,
  ignoreTemplateVersionOfContent,
  acceptTemplateVersionOfContent,
  chooseReactionForContent,
  unChooseReactionForContent,
  updateReactionFeedback,
  setFeatureSetPickerOpen,
  setConversationPickerOpen,
  setConfirmRemoveSectionOpen,
  setConfirmReplaceSectionOpen,
  setConfirmOverwriteTemplateSectionOpen,
  setErrorMessage,
  setViewMode,
  setScriptMode,
  toggleRightDrawer,
  setDemoPlayState,
  demoTimerTick,
} = viewDemoSlice.actions;

export const selectViewDemo = (state: RootState) => state.viewDemo as ViewDemoState;

export default viewDemoSlice.reducer;
