import React from "react";

import { Typography } from "@mui/material";
import { ObjectArrayTable, ObjectTableButtonData } from "../objectArrayTable/objectArrayTable";
import { formatDateMonthName } from "../../util/dateUtils";
import { DemoNameCell } from "./demoNameCell";
import { SortColumnDescriptor } from "./demoTableSortUtils";

interface Props {
  demos: { id: string; name: string; description: string; user?: { id: string; displayName: string } }[];
  showNewDemo?: boolean;
  onNewDemo: () => void;
  newDemoDisabled?: boolean;
  onLaunchDemo: (demoId: number) => void;
  dataDescription: string;
  buttons?: (demoId: string) => ObjectTableButtonData[];
  onHeaderClick?: (colIndex: number) => void;
  headerSort?: SortColumnDescriptor;
}

export const DemoTable: React.FC<Props> = ({
  demos,
  onNewDemo,
  newDemoDisabled,
  onLaunchDemo,
  dataDescription,
  buttons,
  showNewDemo,
  onHeaderClick,
  headerSort,
}) => {
  return (
    <ObjectArrayTable
      dataDescription={dataDescription}
      title={demos.length > 0 && demos[0].user ? ["Owner", "Demo", "Modified"] : ["Demo", "Modified"]}
      data={demos}
      buttons={showNewDemo ?? true ? [{ text: "New Demo", onClick: onNewDemo, disabled: newDemoDisabled }] : []}
      buttonColIndex={demos.length > 0 && demos[0].user ? 1 : 0}
      cellNode={(demo) => {
        const demoRows = [
          <DemoNameCell demo={demo} buttons={buttons} onLaunchDemo={onLaunchDemo} />,
          <Typography onClick={() => onLaunchDemo(parseInt(demo.id))} variant="body1">
            {formatDateMonthName(new Date(demo.modifiedDate))}
          </Typography>,
        ];
        if (demo.user) {
          return [
            <Typography onClick={() => onLaunchDemo(parseInt(demo.id))} variant="body1">
              {demo.user.displayName}
            </Typography>,
            ...demoRows,
          ];
        }
        return demoRows;
      }}
      onClick={() => {}}
      onHeaderClick={onHeaderClick}
      headerSort={headerSort}
      colWidths={demos.length > 0 && demos[0].user ? [15, 70, 15] : undefined}
    />
  );
};
