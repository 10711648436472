import React from "react";

import BusinessIcon from "@mui/icons-material/Business";
import { NavSidebar } from "../navSidebar";

const sidebar = [{ text: "Organizations", url: "/site-admin", icon: <BusinessIcon /> }];

interface Props {
  chosen?: string;
}

export const SiteAdminSidebar: React.FC<Props> = ({ chosen }) => {
  return <NavSidebar title="Site Admin" chosen={chosen} sidebar={sidebar} />;
};
