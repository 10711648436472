import { ApolloClient, DocumentNode } from "@apollo/client";
import { SuccessResponse } from "../generated/graphql";

export async function gqlMutateWithHook(
  gqlMutateHookFn: (obj: any) => Promise<any>,
  variables: any,
  responseProperty: string,
  onSuccess: (response: SuccessResponse) => void,
  onError: (msg: string) => string
): Promise<SuccessResponse> {
  try {
    const response = await gqlMutateHookFn({
      variables,
    });
    if (response && response.data) {
      const resultData = response.data[responseProperty];
      if (resultData.success) {
        onSuccess(resultData);
      } else {
        onError(resultData.error);
      }
      return resultData;
    } else {
      return { success: false, error: onError("") };
    }
  } catch (error) {
    console.log(error);
    return { success: false, error: onError("") };
  }
}

export const gqlQuery = async (
  apolloClient: ApolloClient<object>,
  query: DocumentNode,
  variables: any,
  onSuccess: (data: any) => void,
  onError: (err: string) => void
): Promise<void> => {
  return apolloClient
    .query({ query, variables, fetchPolicy: "network-only" })
    .then((response) => {
      if (response.error) {
        onError(response.error.message);
      } else if (!response.loading && response.data) {
        onSuccess(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
      onError("Failed to make request");
    });
};

export const gqlMutate = async (
  apolloClient: ApolloClient<object>,
  mutation: DocumentNode,
  responseProperty: string,
  variables: any,
  onSuccess: (response: SuccessResponse) => void,
  onError: (err: string) => void
): Promise<SuccessResponse> => {
  let message = "Unknown Error";
  try {
    const response = await apolloClient.mutate({ mutation, variables, fetchPolicy: "network-only" });
    if (response.errors) {
      console.log(response.errors);
      onError(response.errors[0]?.message ?? message);
    } else if (response.data) {
      if (response.data[responseProperty].success) {
        onSuccess(response.data[responseProperty]);
        return { success: true, error: "" };
      } else {
        console.log(response.data[responseProperty].error);
        onError(response.data[responseProperty].error);
        return { success: false, error: response.data[responseProperty].error };
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log(error);
      message = error.message;
    }
  }
  onError(message);
  return { success: false, error: message };
};
