import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Breadcrumb } from "../../../components/breadcrumb";
import { AppLayout } from "../../../components/layouts/appLayout";
import { PaperWithTitle } from "../../../components/paperWithTitle";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useUserResourceQuery } from "../../../generated/graphql";
import { AppErrorPage } from "../../../components/appError";
import { AppLoadingPage } from "../../../components/appLoadingPage";
import { ResourceEditor } from "../../../components/forms/resourceEditor";
import { isAdminForOrgOrTeam } from "../../../util/adminUtils";
import { TitleWithAdminChip } from "../../../components/titleWithAdminChip";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { EditorSaveButtons } from "../../../components/forms/editorSaveButtons";
import { requestUpdateResource } from "../../../generated/graphqlWrappers";
import { useAppDispatch } from "../../../store/hooks";
import { resourceUpdated } from "../resourcesSlice";
import { showSnackbarError, showSnackbarSuccess } from "../../../components/appSnackbarSlice";
import { AppSnackbar } from "../../../components/appSnackbar";
import { TextEditor } from "../../../components/textEditor/textEditor";

interface Params {
  teamId: string;
  resourceId: string;
}

export const ResourcePage: React.FC = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const { teamId, resourceId } = useParams<Params>();
  const [name, setName] = useState("");
  const [body, setBody] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [originalCategoryId, setOriginalCategoryId] = useState("0");
  const [initialized, setInitialized] = useState(false);
  const [contentIsChanged, setContentIsChanged] = useState(false);

  useEffect(() => {
    if (contentIsChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [contentIsChanged]);

  const { data, loading, error } = useUserResourceQuery({
    variables: { teamId: parseInt(teamId), resourceId: parseInt(resourceId) },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <AppLoadingPage />;
  }
  if (!initialized) {
    setName(data.userResource.name);
    setBody(data.userResource.body);
    setCategoryId(parseInt(data.userResource.resourceCategory.id));
    setOriginalCategoryId(data.userResource.resourceCategory.id);
    setContentIsChanged(false);
    setInitialized(true);
  }

  const save = (client: ApolloClient<object>, onSuccess?: () => void) => {
    requestUpdateResource(
      client,
      { teamId: parseInt(teamId), resourceId: parseInt(resourceId), name, body, categoryId },
      () => {
        dispatch(
          resourceUpdated({
            id: resourceId,
            teamId,
            name,
            categoryId: `${categoryId}`,
            originalCategoryId: `${originalCategoryId}`,
          })
        );
        setContentIsChanged(false);
        if (onSuccess) {
          onSuccess();
        }
      },
      (message) => {
        dispatch(showSnackbarError(message));
      }
    );
  };

  const isAdmin = isAdminForOrgOrTeam(data.userAccount, data.userResource.resourceCategory.team.admins);
  return (
    <AppLayout>
      <Prompt when={contentIsChanged} message="You have unsaved changes. Are you sure you want to leave?" />
      <Stack spacing={2}>
        <Breadcrumb crumbs={[{ text: "Resources", url: `/resources` }]} />
        <PaperWithTitle
          title={
            <TitleWithAdminChip
              title={isAdmin ? "Edit Resource" : data.userResource.name}
              isAdmin={isAdmin}
              isOrgAdmin={data.userAccount.isOrgAdmin}
            />
          }
        >
          {isAdmin ? (
            <React.Fragment>
              <ResourceEditor
                categories={data.userResourceCategories}
                nameState={[name, setName]}
                bodyState={[body, setBody]}
                categoryIdState={[categoryId, setCategoryId]}
                setContentIsChanged={setContentIsChanged}
              />
              <ApolloConsumer>
                {(client) => (
                  <EditorSaveButtons
                    objectId={parseInt(resourceId)}
                    saveIsDisabled={name === ""}
                    onSave={() => {
                      save(client, () => {
                        dispatch(showSnackbarSuccess("Resource updated"));
                      });
                    }}
                    onSaveAndClose={() => {
                      save(client, () => {
                        history.push("/resources");
                      });
                    }}
                    onClose={(objectId: number) => {
                      history.push("/resources");
                    }}
                    contentIsChanged={contentIsChanged}
                  />
                )}
              </ApolloConsumer>
            </React.Fragment>
          ) : (
            <Stack>
              <Typography>
                Category:{" "}
                {data.userResourceCategories.find((c) => c.id === `${categoryId}`)?.name ?? "Unknown category"}
              </Typography>
              {body !== "" ? (
                <TextEditor
                  showWordCount={false}
                  readOnly={true}
                  contentKeyVersioned="key"
                  initialContent={body}
                  setContentJSON={() => {}}
                />
              ) : (
                <Typography fontStyle="italic">(Empty body)</Typography>
              )}
            </Stack>
          )}
        </PaperWithTitle>
        <AppSnackbar />
      </Stack>
    </AppLayout>
  );
};
