import React from "react";
import { Box, Button, Dialog, DialogTitle } from "@mui/material";

interface Props {
  open: boolean;
  title: string;
  close: () => void;
}

/**
 * This dialog is to show the user some content (use children to pass)
 * @param open if true, the dialog will be shown
 * @param title text shown in the title
 * @param close a function to call if the dialog is closed. It should typically set open to false.
 */
export const ContentDialog: React.FC<Props> = ({ open, title, children, close }) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <Box flexDirection="column" sx={{ my: 2, mx: 2, display: "flex", gap: 2 }}>
        {children}
        <Box flexDirection="row" sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
          <Button variant="contained" color="primary" onClick={close}>
            OK
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
