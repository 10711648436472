import React, { useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useUserResourceCategoriesQuery } from "../../../generated/graphql";
import { ContentDialog } from "../../../components/dialogs/contentDialog";
import { TextEditor } from "../../../components/textEditor/textEditor";

interface Props {
  teamId: string;
}

export const ResourcesNav: React.FC<Props> = ({ teamId }) => {
  const [resourceDialogOpen, setResourceDialogOpen] = useState(false);
  const [resourceId, setResourceId] = useState("");
  const [resourceTitle, setResourceTitle] = useState("");
  const [resourceBody, setResourceBody] = useState("");

  const { data, loading, error } = useUserResourceCategoriesQuery({
    variables: { teamId: parseInt(teamId) },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <Typography>Error loading resources</Typography>;
  }

  return (
    <React.Fragment>
      <Typography variant="h5">Resources</Typography>
      {!loading && data ? (
        <React.Fragment>
          {data.userResourceCategories.map((c) => {
            return c.resources.length > 0 ? (
              <React.Fragment key={c.id}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`panel-${c.id}`}>
                    <Typography variant="h6">{c.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List dense={true}>
                      {c.resources.map((r) => (
                        <ListItem
                          key={r.id}
                          sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#f8f8f8" } }}
                          onClick={() => {
                            setResourceId(r.id);
                            setResourceTitle(r.name);
                            setResourceBody(r.body);
                            setResourceDialogOpen(true);
                          }}
                        >
                          <Typography>{r.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            ) : (
              <React.Fragment key={c.id} />
            );
          })}
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
      <ContentDialog
        open={resourceDialogOpen}
        title={resourceTitle}
        close={() => {
          setResourceDialogOpen(false);
        }}
      >
        <TextEditor
          contentKeyVersioned={resourceId}
          initialContent={resourceBody}
          setContentJSON={() => {}}
          readOnly={true}
          showWordCount={false}
        />
      </ContentDialog>
    </React.Fragment>
  );
};
