import React, { useState } from "react";

import { Typography } from "@mui/material";
import { SiteAdminLayout } from "../../components/layouts/siteAdminLayout";
import { AppErrorPage } from "../../components/appError";
import { useSiteOrganizationsQuery } from "../../generated/graphql";
import { useHistory } from "react-router";
import { ObjectArrayTable } from "../../components/objectArrayTable/objectArrayTable";
import { SiteAdminAppLoadingPage } from "../../components/siteAdmin/siteAdminAppLoadingPage";
import { CreateOrganizationSiteAdminDialog } from "./createOrganizationSiteAdminDialog";
import { PaperWithTitle } from "../../components/paperWithTitle";

export const OrganizationsSiteAdminPage: React.FC = () => {
  const history = useHistory();
  let [createOrgDialogOpen, setCreateOrgDialogOpen] = useState(false);

  const { data, error, loading, refetch } = useSiteOrganizationsQuery({
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminAppLoadingPage />;
  }

  return (
    <SiteAdminLayout chosen={"Organizations"}>
      <PaperWithTitle title="Organizations signed up for DemoWizard">
        <ObjectArrayTable
          data={data.siteOrganizations}
          dataDescription="organizations"
          title="Organization"
          onClick={(id) => {
            history.push(`/site-admin/org/${id}`);
          }}
          cellNode={(obj) => <Typography>{obj.displayName}</Typography>}
          buttons={[
            {
              text: "New Organization",
              onClick: () => {
                setCreateOrgDialogOpen(true);
              },
            },
          ]}
        />
      </PaperWithTitle>
      <CreateOrganizationSiteAdminDialog
        open={createOrgDialogOpen}
        onClose={(added) => {
          setCreateOrgDialogOpen(false);
          if (added) {
            refetch();
          }
        }}
      />
    </SiteAdminLayout>
  );
};
