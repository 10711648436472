import React, { useState } from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import { ObjectArrayTable } from "../../../components/objectArrayTable/objectArrayTable";
import { ConfirmDestructiveActionDialog } from "../../../components/dialogs/confirmDestructiveActionDialog";
import {
  requestAddUserToTeamAsAdmin,
  requestRemoveUserFromTeamAsAdmin,
  requestUpdateTeamAdminAsAdmin,
} from "../../../generated/graphqlWrappers";
import { useAppDispatch } from "../../../store/hooks";
import { showSnackbarError, showSnackbarSuccess } from "../../../components/appSnackbarSlice";
import { ContentPickerDialog } from "../../../components/dialogs/contentPickerDialog";
import { formatDateTimeSimple } from "../../../util/dateUtils";

interface UserData {
  id: string;
  displayName: string;
}

interface Props {
  teamId: string;
  isAdmin: boolean;
  isOrgAdmin: boolean;
  users: UserData[];
  usersNotIncluded: UserData[];
  admins: { id: string }[];
  onRefetch: () => void;
}

export const TeamUsersTable: React.FC<Props> = ({
  teamId,
  isAdmin,
  isOrgAdmin,
  users,
  usersNotIncluded,
  admins,
  onRefetch,
}) => {
  const dispatch = useAppDispatch();

  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState<UserData | undefined>(undefined);

  const [userSelectorOpen, setUserSelectorOpen] = useState(false);

  const removeUserIfConfirmed = (user: UserData) => {
    setUserToRemove(user);
    setConfirmRemoveOpen(true);
  };

  const confirmDialogClosed = (client: ApolloClient<object>, confirmed: boolean) => {
    setUserToRemove(undefined);
    setConfirmRemoveOpen(false);
    if (confirmed && userToRemove) {
      requestRemoveUserFromTeamAsAdmin(
        client,
        { teamId: parseInt(teamId), userId: parseInt(userToRemove.id) },
        () => {
          dispatch(showSnackbarSuccess("User removed from team"));
          onRefetch();
        },
        () => {
          dispatch(showSnackbarError("Error removing user from team"));
        }
      );
    }
  };

  const onUserSelected = (client: ApolloClient<object>, user?: any) => {
    setUserSelectorOpen(false);
    if (user) {
      requestAddUserToTeamAsAdmin(
        client,
        { teamId: parseInt(teamId), userId: parseInt(user.id) },
        () => {
          dispatch(showSnackbarSuccess("User added to team"));
          onRefetch();
        },
        () => {
          dispatch(showSnackbarError("Error adding user to team"));
        }
      );
    }
  };

  const changeTeamAdmin = (client: ApolloClient<object>, userId: string, isTeamAdmin: boolean) => {
    requestUpdateTeamAdminAsAdmin(
      client,
      { teamId: parseInt(teamId), userId: parseInt(userId), isTeamAdmin },
      () => {
        onRefetch();
      },
      (err) => {
        dispatch(showSnackbarError(err));
      }
    );
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <React.Fragment>
          <ObjectArrayTable
            dataDescription="members"
            colWidths={isOrgAdmin ? [65, 25, 10] : [100]}
            title={isOrgAdmin ? ["Member", "Last Login", "Demos"] : ["Member"]}
            data={users}
            buttons={
              isAdmin
                ? [
                    {
                      text: "Include member",
                      onClick: () => {
                        setUserSelectorOpen(true);
                      },
                      disabled: usersNotIncluded.length === 0,
                    },
                  ]
                : []
            }
            buttonColIndex={0}
            cellNode={(user) => {
              const isAdminCellUser = admins.findIndex((a) => a.id === user.id) >= 0;
              const adminCols = isOrgAdmin
                ? [
                    <Typography align="right">
                      {user.lastLoginDate !== null ? formatDateTimeSimple(new Date(user.lastLoginDate)) : ""}
                    </Typography>,
                    <Typography align="right">{user.numberOfDemos ?? ""}</Typography>,
                  ]
                : [];
              return [
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isAdminCellUser ? <Chip size="small" color="primary" label="team admin" /> : <React.Fragment />}
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {user.displayName}
                  </Typography>
                  {isAdmin ? (
                    <Stack direction="row" spacing={2}>
                      <Tooltip title="Remove member from team" placement="top-start">
                        <IconButton
                          onClick={() => {
                            removeUserIfConfirmed(user);
                          }}
                        >
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={
                          isAdminCellUser ? "Make this user a non-admin of this team" : "Make this user a team admin"
                        }
                        placement="top-start"
                      >
                        <IconButton
                          onClick={() => {
                            changeTeamAdmin(client, user.id, !isAdminCellUser);
                          }}
                        >
                          <AdminPanelSettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  ) : (
                    <React.Fragment />
                  )}
                </Stack>,
                ...adminCols,
              ];
            }}
            onClick={(id) => {}}
          />
          <ConfirmDestructiveActionDialog
            open={confirmRemoveOpen}
            message={`If you remove ${
              userToRemove?.displayName ?? ""
            } from this team, they will not be able to create more demos from its templates. The demos they have already made will continue working.`}
            yesText="Remove member from team"
            closeDialog={(confirmed) => {
              confirmDialogClosed(client, confirmed);
            }}
          />
          <ContentPickerDialog
            title="Select a member"
            items={usersNotIncluded}
            open={userSelectorOpen}
            onClose={(content) => {
              onUserSelected(client, content);
            }}
          />
        </React.Fragment>
      )}
    </ApolloConsumer>
  );
};
