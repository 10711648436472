import React from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { SuccessResponse } from "../../../generated/graphql";
import { requestAddUserDemoFeatureSets, requestUserFeatureSetsForDemo } from "../../../generated/graphqlWrappers";
import { DemoContentIdName, UserFeatureSetsForDemoQueryData } from "../../../util/demoTypes";
import { MultiContentPickerDialog } from "../../../components/dialogs/multiContentPickerDialog";

interface Props {
  items: DemoContentIdName[];
  open: boolean;
  onClose: (content?: DemoContentIdName[]) => void;
  demoId: string;
  onError: (message: string) => void;
  onPicked: (data: UserFeatureSetsForDemoQueryData) => void;
}

export const FeatureSetPicker: React.FC<Props> = ({ items, open, onClose, demoId, onError, onPicked }) => {
  const onPick = async (apolloClient: ApolloClient<object>, fsList?: DemoContentIdName[]): Promise<void> => {
    if (fsList) {
      const message = `Error adding feature`;
      await requestAddUserDemoFeatureSets(
        apolloClient,
        { demoId: parseInt(demoId), featureSetIds: fsList.map((fs) => parseInt(fs.id)) },
        (response: SuccessResponse) => {
          if (!response.idsInserted) {
            onError(message);
            return;
          }
          requestUserFeatureSetsForDemo(apolloClient, { featureSetIds: response.idsInserted }, onPicked, onError);
        },
        () => {
          onError(message);
          return message;
        }
      );
    }
    onClose(fsList);
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <MultiContentPickerDialog
          title="Select Features"
          items={items}
          open={open}
          onClose={(content) => onPick(client, content)}
        />
      )}
    </ApolloConsumer>
  );
};
