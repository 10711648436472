import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { UserDemosQuery } from "../../generated/graphql";
import { UserDemoData } from "../../util/demoTypes";
import {
  compareModifiedDate,
  compareName,
  compareOrder,
  SortColumnDescriptor,
} from "../../components/demoTable/demoTableSortUtils";

/**
 * Defines the slice, reducers, and actions for showing demos in a table.
 */

export interface MyDemosState {
  initialized: boolean;
  activeDemos: UserDemoData;
  archivedDemos: UserDemoData;
  tabIndex: string;
  deleteConfirmOpen: boolean;
  demoToDelete?: number;
  activeDemosSortColumn: SortColumnDescriptor;
  archivedDemosSortColumn: SortColumnDescriptor;
}

const initialState: MyDemosState = {
  initialized: false,
  activeDemos: [],
  archivedDemos: [],
  tabIndex: "1",
  deleteConfirmOpen: false,
  activeDemosSortColumn: { index: 0, ascending: true },
  archivedDemosSortColumn: { index: 0, ascending: true },
};

export const findDemo = (state: MyDemosState, demoId: string): UserDemoData[0] | undefined => {
  let demo: UserDemoData[0] | undefined = undefined;
  const activeIndex = state.activeDemos.findIndex((d) => d.id === demoId);
  if (activeIndex >= 0) {
    demo = state.activeDemos[activeIndex];
  } else {
    const archiveIndex = state.archivedDemos.findIndex((d) => d.id === demoId);
    if (archiveIndex >= 0) {
      demo = state.archivedDemos[archiveIndex];
    }
  }
  return demo;
};

const compareFn = (index: number): ((a: UserDemoData[0], b: UserDemoData[0]) => number) => {
  switch (index) {
    case 0:
      return compareName;
    case 1:
      return compareModifiedDate;
  }
  return compareName;
};

const myDemoActiveDemosSort = (state: MyDemosState) => {
  state.activeDemos = state.activeDemos.sort(
    compareOrder(compareFn(state.activeDemosSortColumn.index), state.activeDemosSortColumn.ascending)
  );
};

const myDemoArchivedDemosSort = (state: MyDemosState) => {
  state.archivedDemos = state.archivedDemos.sort(
    compareOrder(compareFn(state.archivedDemosSortColumn.index), state.archivedDemosSortColumn.ascending)
  );
};

export const myDemosSlice = createSlice({
  name: "myDemos",
  initialState,
  reducers: {
    clearMyDemosState: (state) => {
      state.initialized = false;
      state.activeDemos = initialState.activeDemos;
      state.archivedDemos = initialState.archivedDemos;
      state.tabIndex = initialState.tabIndex;
      state.deleteConfirmOpen = initialState.deleteConfirmOpen;
      state.demoToDelete = initialState.demoToDelete;
      state.activeDemosSortColumn = initialState.activeDemosSortColumn;
      state.archivedDemosSortColumn = initialState.archivedDemosSortColumn;
    },
    initializeMyDemosState: (state, action: PayloadAction<UserDemosQuery>) => {
      state.activeDemos = action.payload.userDemos.filter((demo) => !demo.archived);
      myDemoActiveDemosSort(state);
      state.archivedDemos = action.payload.userDemos.filter((demo) => demo.archived);
      myDemoArchivedDemosSort(state);
      state.initialized = true;
    },
    updateDemoArchived: (state, action: PayloadAction<{ demoId: string; archived: boolean }>) => {
      let activeDemos = [...state.activeDemos];
      let archivedDemos = [...state.archivedDemos];
      if (action.payload.archived) {
        const index = activeDemos.findIndex((d) => d.id === action.payload.demoId);
        const demos = activeDemos.splice(index, 1);
        archivedDemos = [...archivedDemos, demos[0]];
      } else {
        const index = archivedDemos.findIndex((d) => d.id === action.payload.demoId);
        const demos = archivedDemos.splice(index, 1);
        activeDemos = [...activeDemos, demos[0]];
      }
      activeDemos.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      state.activeDemos = activeDemos;
      myDemoActiveDemosSort(state);

      archivedDemos.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      state.archivedDemos = archivedDemos;
      myDemoArchivedDemosSort(state);
    },
    removeDemo: (state, action: PayloadAction<string>) => {
      let archivedDemos = [...state.archivedDemos];
      const index = archivedDemos.findIndex((d) => d.id === action.payload);
      archivedDemos.splice(index, 1);
      state.archivedDemos = archivedDemos;
    },
    demoSuccessfullyDuplicated: (state, action: PayloadAction<{ demoId: string; newDemoId: string; name: string }>) => {
      let activeDemos = [...state.activeDemos];
      const demo = findDemo(state, action.payload.demoId);
      if (demo) {
        const newDemo = {
          id: action.payload.newDemoId,
          name: action.payload.name,
          description: demo.description,
          archived: false,
          team: demo.team,
          modifiedDate: new Date().toISOString(),
        };
        state.activeDemos = [...activeDemos, newDemo];
        myDemoActiveDemosSort(state);
      }
    },
    setTabIndex: (state, action: PayloadAction<string>) => {
      state.tabIndex = action.payload;
    },
    setDeleteConfirmOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteConfirmOpen = action.payload;
    },
    setDemoToDelete: (state, action: PayloadAction<number | undefined>) => {
      state.demoToDelete = action.payload;
    },
    newDemoCreated: (state, action: PayloadAction<UserDemoData[0]>) => {
      let activeDemos = [...state.activeDemos, action.payload];
      state.activeDemos = activeDemos;
      myDemoActiveDemosSort(state);
    },
    updateDemoProperties: (state, action: PayloadAction<UserDemoData[0]>) => {
      const newDemo = action.payload;
      let activeDemos = [...state.activeDemos];
      let archivedDemos = [...state.archivedDemos];

      // Find the demo and remove it
      let demo: UserDemoData[0] | undefined = undefined;
      const activeIndex = activeDemos.findIndex((d) => d.id === newDemo.id);
      if (activeIndex >= 0) {
        demo = activeDemos.splice(activeIndex, 1)[0];
      } else {
        const archiveIndex = archivedDemos.findIndex((d) => d.id === newDemo.id);
        if (archiveIndex >= 0) {
          demo = archivedDemos.splice(archiveIndex, 1)[0];
        }
      }
      // if found, add in the new one
      if (demo) {
        newDemo.team = demo.team;
        if (newDemo.archived) {
          archivedDemos.splice(0, 0, newDemo);
          archivedDemos.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        } else {
          activeDemos.splice(0, 0, newDemo);
          activeDemos.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        }

        state.activeDemos = activeDemos;
        myDemoActiveDemosSort(state);
        state.archivedDemos = archivedDemos;
        myDemoArchivedDemosSort(state);
      }
    },
    changeActiveDemosSort: (state, action: PayloadAction<number>) => {
      if (state.activeDemosSortColumn.index === action.payload) {
        state.activeDemosSortColumn = { index: action.payload, ascending: !state.activeDemosSortColumn.ascending };
      } else {
        state.activeDemosSortColumn = { index: action.payload, ascending: true };
      }
      myDemoActiveDemosSort(state);
    },
    changeArchivedDemosSort: (state, action: PayloadAction<number>) => {
      if (state.archivedDemosSortColumn.index === action.payload) {
        state.archivedDemosSortColumn = { index: action.payload, ascending: !state.archivedDemosSortColumn.ascending };
      } else {
        state.archivedDemosSortColumn = { index: action.payload, ascending: true };
      }
      myDemoArchivedDemosSort(state);
    },
  },
});

export const {
  clearMyDemosState,
  initializeMyDemosState,
  setTabIndex,
  updateDemoArchived,
  removeDemo,
  demoSuccessfullyDuplicated,
  setDeleteConfirmOpen,
  setDemoToDelete,
  newDemoCreated,
  updateDemoProperties,
  changeActiveDemosSort,
  changeArchivedDemosSort,
} = myDemosSlice.actions;

export const selectMyDemos = (state: RootState) => state.myDemos as MyDemosState;

export default myDemosSlice.reducer;
