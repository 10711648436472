import React, { useState } from "react";

import { Chip, Stack, Typography } from "@mui/material";
import { AppErrorPage } from "../../components/appError";
import { useHistory, useParams } from "react-router";
import { useSiteOrganizationUsersQuery } from "../../generated/graphql";
import { ObjectArrayTable } from "../../components/objectArrayTable/objectArrayTable";
import { SiteAdminOrgLayout } from "../../components/layouts/siteAdminOrgLayout";
import { SiteAdminOrgAppLoadingPage } from "../../components/layouts/siteAdminOrgAppLoadingPage";
import { CreateUserSiteAdminDialog } from "./createUserSiteAdminDialog";
import { PaperWithTitle } from "../../components/paperWithTitle";
import { OrgAdminChip } from "../../components/adminChips";
import { InviteUserSiteAdminDialog } from "./InviteUserSiteAdminDialog";
import { formatDateTimeSimple } from "../../util/dateUtils";
import { AppSnackbar } from "../../components/appSnackbar";
import { useAppDispatch } from "../../store/hooks";
import { showSnackbarSuccess } from "../../components/appSnackbarSlice";

interface Params {
  organizationId: string;
}

export const ViewOrgUsersSiteAdminPage: React.FC = () => {
  let { organizationId } = useParams<Params>();

  let [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  let [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const { data, error, loading, refetch } = useSiteOrganizationUsersQuery({
    variables: {
      organizationId: parseInt(organizationId),
    },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminOrgAppLoadingPage />;
  }

  return (
    <SiteAdminOrgLayout chosen={"Users"} organizationId={organizationId}>
      <PaperWithTitle title={`Users at ${data.siteOrganization.displayName}`}>
        <ObjectArrayTable
          data={data.siteOrganization.users}
          dataDescription="users"
          colWidths={[65, 25, 10]}
          title={["User", "Last Login", "Demos"]}
          onClick={(id) => {
            history.push(`/site-admin/user/${id}`);
          }}
          cellNode={(obj) => [
            <Stack direction="row">
              <Typography sx={{ flexGrow: 1 }}>{obj.displayName}</Typography>
              {obj.isOrgAdmin ? <OrgAdminChip /> : <React.Fragment />}
              {obj.inviteCode ? (
                <Chip sx={{ ml: 1 }} size="small" label="invited" color="warning" />
              ) : (
                <React.Fragment />
              )}
            </Stack>,
            <Typography align="right">
              {obj.lastLoginDate !== null ? formatDateTimeSimple(new Date(obj.lastLoginDate)) : ""}
            </Typography>,
            <Typography align="right">{obj.numberOfDemos ?? ""}</Typography>,
          ]}
          buttonColIndex={0}
          buttons={[
            {
              text: "New User",
              onClick: () => {
                setCreateUserDialogOpen(true);
              },
            },
            {
              text: "Invite User",
              onClick: () => {
                setInviteUserDialogOpen(true);
              },
            },
          ]}
        />
        <CreateUserSiteAdminDialog
          organizationId={parseInt(data.siteOrganization.id)}
          open={createUserDialogOpen}
          onClose={(added) => {
            setCreateUserDialogOpen(false);
            if (added) {
              refetch();
            }
          }}
        />
        <InviteUserSiteAdminDialog
          organizationId={parseInt(data.siteOrganization.id)}
          open={inviteUserDialogOpen}
          onClose={(added) => {
            setInviteUserDialogOpen(false);
            if (added) {
              dispatch(showSnackbarSuccess(`Invitation successfully e-mailed`));
              refetch();
            }
          }}
        />
        <AppSnackbar />
      </PaperWithTitle>
    </SiteAdminOrgLayout>
  );
};
