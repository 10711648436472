import React from "react";
import { Typography } from "@mui/material";
import { AppErrorPage } from "../../../components/appError";
import { AppLoadingPage } from "../../../components/appLoadingPage";
import { AppLayout } from "../../../components/layouts/appLayout";
import { PaperWithTitle } from "../../../components/paperWithTitle";
import { useUserNotificationsQuery } from "../../../generated/graphql";
import { ObjectArrayTable } from "../../../components/objectArrayTable/objectArrayTable";
import { useHistory } from "react-router-dom";

export const UserNotificationsPage: React.FC = () => {
  let history = useHistory();
  const { data, loading, error } = useUserNotificationsQuery({ fetchPolicy: "network-only" });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }

  if (loading || !data) {
    return <AppLoadingPage />;
  }

  return (
    <AppLayout>
      <PaperWithTitle title="Notifications">
        <ObjectArrayTable
          data={data.userNotifications}
          dataDescription="notifications"
          title="Notification"
          onClick={(id) => {
            history.push(`/notification/${id}`);
          }}
          cellNode={(obj) => <Typography sx={{ fontWeight: obj.wasRead ? undefined : "bold" }}>{obj.body}</Typography>}
        />
      </PaperWithTitle>
    </AppLayout>
  );
};
