import { ApolloClient } from "@apollo/client";
import {
  requestAcceptUserDemoTemplateVersionOfContent,
  requestOverwriteUserDemoTemplateVersionOfContent,
  requestUpdateUserDemoContent,
  requestUserCloseForDemo,
  requestUserConversationForDemo,
  requestUserFeatureSetForDemo,
  requestUserOpeningForDemo,
} from "../../../../generated/graphqlWrappers";
import { contentIdFromKey, contentTypeFromKey } from "../../../../util/demoTypes";
import { ViewDemoState } from "../viewDemoSlice";
import { getEditList } from "../viewDemoSliceUtils";

export const requestContentForDemo = async (
  apolloClient: ApolloClient<object>,
  contentType: string,
  contentId: number,
  onSuccess: (data: any) => void,
  onError: (err: string) => void
): Promise<void> => {
  switch (contentType) {
    case "FeatureSetForDemo":
      return requestUserFeatureSetForDemo(apolloClient, { featureSetId: contentId }, onSuccess, onError);
    case "ConversationForDemo":
      return requestUserConversationForDemo(apolloClient, { conversationId: contentId }, onSuccess, onError);
    case "OpeningForDemo":
      return requestUserOpeningForDemo(apolloClient, { openingId: contentId }, onSuccess, onError);
    case "CloseForDemo":
      return requestUserCloseForDemo(apolloClient, { closeId: contentId }, onSuccess, onError);
    default:
      onError("Unknown content type");
  }
};

export const requestAcceptTemplateVersion = async (
  apolloClient: ApolloClient<object>,
  viewDemoState: ViewDemoState,
  onSuccess: (data: any) => void,
  onError: (err: string) => void
) => {
  const contentType = contentTypeFromKey(viewDemoState.chosenKey);
  const contentId = parseInt(contentIdFromKey(viewDemoState.chosenKey));

  await requestAcceptUserDemoTemplateVersionOfContent(
    apolloClient,
    { demoId: parseInt(viewDemoState.demoId), contentType, contentId },
    () => {
      requestContentForDemo(apolloClient, contentType, contentId, onSuccess, onError);
    },
    onError
  );
};

export const requestOverwriteTemplateVersion = async (
  apolloClient: ApolloClient<object>,
  viewDemoState: ViewDemoState,
  onSuccess: (data: any) => void,
  onError: (err: string) => void
) => {
  const contentType = contentTypeFromKey(viewDemoState.chosenKey);
  const contentId = parseInt(contentIdFromKey(viewDemoState.chosenKey));

  await requestOverwriteUserDemoTemplateVersionOfContent(
    apolloClient,
    { demoId: parseInt(viewDemoState.demoId), contentType, contentId },
    () => {
      requestContentForDemo(apolloClient, contentType, contentId, onSuccess, onError);
    },
    onError
  );
};

export const requestSaveDemoByOwner = async (
  apolloClient: ApolloClient<object>,
  viewDemoState: ViewDemoState,
  onSuccess: () => void,
  onError: (err: string) => void
) => {
  await requestUpdateUserDemoContent(
    apolloClient,
    { demoId: parseInt(viewDemoState.demoId), editList: getEditList(viewDemoState) },
    onSuccess,
    () => {
      const errorMessage = "Error updating demo";
      onError(errorMessage);
      return errorMessage;
    }
  );
};
