import { Box, Button, Dialog, DialogTitle, List, ListItem, ListItemText } from "@mui/material";
import { labelFromPickerData, PickerData } from "./pickerData";

interface Props<T extends PickerData> {
  title: string;
  items: T[];
  open: boolean;
  onClose: (content?: T) => void;
}

export const ContentPickerDialog = <T extends PickerData>({ title, items, open, onClose }: Props<T>) => {
  const close = (content?: T) => {
    onClose(content);
  };

  return (
    <Dialog onClose={() => close()} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <List sx={{ minWidth: 400 }}>
        {items.map((content) => {
          return (
            <ListItem
              key={content.id}
              sx={{ "&:hover": { backgroundColor: "#eee" } }}
              onClick={(e) => {
                close(content);
              }}
            >
              <ListItemText primary={labelFromPickerData(content)} />
            </ListItem>
          );
        })}
      </List>
      <Box flexDirection="row" sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
        <Button
          sx={{ m: 2 }}
          variant="outlined"
          color="error"
          onClick={() => {
            close();
          }}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};
