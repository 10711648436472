import React from "react";

import { AppSidebar } from "../appSidebar";
import { LayoutLeftNav } from "./layoutLeftNav";

interface Props {
  chosen?: string;
}

export const AppLayout: React.FC<Props> = ({ children, chosen }) => {
  return <LayoutLeftNav sidebar={<AppSidebar chosen={chosen} />}>{children}</LayoutLeftNav>;
};
