import React, { useState } from "react";

import { Chip, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { SiteAdminTeamLayout } from "../layouts/siteAdminTeamLayout";
import { AppErrorPage } from "../appError";
import { ChipData, ObjectArrayTable } from "../objectArrayTable/objectArrayTable";
import { useHistory } from "react-router-dom";
import { SiteAdminTeamAppLoadingPage } from "./siteAdminTeamAppLoadingPage";
import { Breadcrumb } from "../breadcrumb";
import { PaperWithTitle } from "../paperWithTitle";

interface Props {
  teamId: string;
  query: (options: any) => any;
  getObjects: (team: any, chosenFilterItemId: string) => any[];
  chipForObj?: (obj: any) => ChipData[];
  onClick: (id: string) => void;
  onNew?: () => void;
  filterLabel?: string;
  filterItems?: (team: any) => { id: string; name: string }[];
  filterError?: React.ReactNode;
  chosen: string;
  title: string;
  dataDescription: string;
  rowLabelProperty: string;
  newButtonText?: string;
  onNewDisabled?: boolean;
  newContentPath: string;
}

export const ViewTeamContentSiteAdminPage: React.FC<Props> = ({
  teamId,
  query,
  getObjects,
  chipForObj,
  onClick,
  onNew,
  filterLabel,
  filterItems,
  filterError,
  chosen,
  title,
  dataDescription,
  rowLabelProperty,
  newButtonText,
  onNewDisabled,
  newContentPath,
}) => {
  const history = useHistory();
  const [canFilterAndAdd, setCanFilterAndAdd] = useState(true);
  const [chosenFilterItemId, setChosenFilterItemId] = useState("");
  const { data, error, loading } = query({
    variables: {
      teamId: parseInt(teamId),
    },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminTeamAppLoadingPage />;
  }
  if (chosenFilterItemId === "" && filterItems) {
    if (filterItems(data.siteTeam).length > 0) {
      setChosenFilterItemId(filterItems(data.siteTeam)[0].id);
      return <SiteAdminTeamAppLoadingPage />;
    } else if (canFilterAndAdd) {
      setCanFilterAndAdd(false);
      return <SiteAdminTeamAppLoadingPage />;
    }
  }

  return (
    <SiteAdminTeamLayout chosen={chosen} teamId={data.siteTeam.id}>
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: data.siteTeam.organization.displayName, url: `/site-admin/org/${data.siteTeam.organization.id}` },
            { text: data.siteTeam.displayName, url: `/site-admin/team/${data.siteTeam.id}` },
          ]}
        />
        <PaperWithTitle title={`${chosen} for ${data.siteTeam.displayName}`}>
          {filterItems && canFilterAndAdd ? (
            <Stack direction="row" spacing={2}>
              <FormControl>
                <InputLabel id={`filter-select-label`}>{filterLabel}</InputLabel>
                <Select
                  labelId={`filter-select-label`}
                  id={`filter-select`}
                  value={chosenFilterItemId}
                  label={filterLabel}
                  onChange={(e) => {
                    setChosenFilterItemId(e.target.value);
                  }}
                >
                  {filterItems(data.siteTeam).map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          ) : (
            <React.Fragment />
          )}
          <ObjectArrayTable
            data={getObjects(data.siteTeam, chosenFilterItemId)}
            dataDescription={dataDescription}
            title={title}
            onClick={onClick}
            cellNode={(obj) => (
              <Stack direction="row">
                <Typography sx={{ flexGrow: 1 }}>{obj[rowLabelProperty]}</Typography>
                {(chipForObj ? chipForObj(obj) : []).map((chip) => (
                  <Chip key={chip.label} size="small" color={chip.color} label={chip.label} />
                ))}
              </Stack>
            )}
            buttons={
              canFilterAndAdd
                ? [
                    {
                      text: newButtonText ?? `New ${title}`,
                      disabled: onNewDisabled,
                      onClick:
                        onNew ??
                        (() => {
                          history.push(`/site-admin/team/${data.siteTeam.id}/${newContentPath}`);
                        }),
                    },
                  ]
                : []
            }
          ></ObjectArrayTable>
          {!canFilterAndAdd && filterError ? filterError : <React.Fragment />}
        </PaperWithTitle>
      </Stack>
    </SiteAdminTeamLayout>
  );
};
