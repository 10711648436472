import React, { useState } from "react";

import { Typography } from "@mui/material";
import { AppErrorPage } from "../../components/appError";
import { useHistory, useParams } from "react-router";
import { useSiteOrganizationQuery } from "../../generated/graphql";
import { ObjectArrayTable } from "../../components/objectArrayTable/objectArrayTable";
import { SiteAdminOrgLayout } from "../../components/layouts/siteAdminOrgLayout";
import { SiteAdminOrgAppLoadingPage } from "../../components/layouts/siteAdminOrgAppLoadingPage";
import { CreateTeamSiteAdminDialog } from "./createTeamSiteAdminDialog";
import { PaperWithTitle } from "../../components/paperWithTitle";

interface Params {
  organizationId: string;
}

export const ViewOrgSiteAdminPage: React.FC = () => {
  let { organizationId } = useParams<Params>();
  let [createTeamDialogOpen, setCreateTeamDialogOpen] = useState(false);

  const history = useHistory();

  const { data, error, loading, refetch } = useSiteOrganizationQuery({
    variables: {
      organizationId: parseInt(organizationId),
    },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminOrgAppLoadingPage />;
  }

  return (
    <SiteAdminOrgLayout chosen={"Teams"} organizationId={organizationId}>
      <PaperWithTitle title={`Teams at ${data.siteOrganization.displayName}`}>
        <ObjectArrayTable
          data={data.siteOrganization.teams}
          dataDescription="teams"
          title="Team"
          onClick={(id) => {
            history.push(`/site-admin/team/${id}`);
          }}
          cellNode={(obj) => <Typography>{obj.displayName}</Typography>}
          buttons={[
            {
              text: "New Team",
              onClick: () => {
                setCreateTeamDialogOpen(true);
              },
            },
          ]}
        />
        <CreateTeamSiteAdminDialog
          organizationId={parseInt(data.siteOrganization.id)}
          open={createTeamDialogOpen}
          onClose={(added) => {
            setCreateTeamDialogOpen(false);
            if (added) {
              refetch();
            }
          }}
        />
      </PaperWithTitle>
    </SiteAdminOrgLayout>
  );
};
