import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

import { useHistory, useParams } from "react-router";
import { AppErrorPage } from "../../../../components/appError";
import { SiteAdminTeamLayout } from "../../../../components/layouts/siteAdminTeamLayout";
import { SiteAdminTeamAppLoadingPage } from "../../../../components/siteAdmin/siteAdminTeamAppLoadingPage";
import { useSiteResourceCategoryQuery } from "../../../../generated/graphql";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { requestUpdateSiteResourceCategory } from "../../../../generated/graphqlWrappers";
import { ResourceCategoryEditor } from "../../../../components/forms/resourceCategoryEditor";
import { Breadcrumb } from "../../../../components/breadcrumb";
import { PaperWithTitle } from "../../../../components/paperWithTitle";

interface Params {
  resourceCategoryId: string;
}

export const EditTeamResourceCategorySiteAdminPage: React.FC = () => {
  let history = useHistory();
  let { resourceCategoryId } = useParams<Params>();

  let [initialized, setInitialized] = useState(false);
  let [name, setName] = useState("");
  let [errorMessage, setErrorMessage] = useState("");

  const { data, error, loading } = useSiteResourceCategoryQuery({
    variables: { resourceCategoryId: parseInt(resourceCategoryId) },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminTeamAppLoadingPage />;
  }

  const resourceCategory = data.siteResourceCategory;
  const team = resourceCategory.team;

  if (!initialized) {
    setName(resourceCategory.name);
    setInitialized(true);
    return <SiteAdminTeamAppLoadingPage />;
  }

  const saveIsDisabled = () => {
    return name === "";
  };

  const onClose = () => {
    history.push(`/site-admin/team/${team.id}/resource-categories`);
  };

  const onSaveAndClose = async (client: ApolloClient<object>) => {
    await save(client, onClose);
  };

  const save = async (client: ApolloClient<object>, onSuccess?: () => void) => {
    setErrorMessage("");
    await requestUpdateSiteResourceCategory(
      client,
      { resourceCategoryId: parseInt(resourceCategoryId), teamId: parseInt(team.id), name },
      () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        setErrorMessage("Error creating Resource Category");
      }
    );
  };

  return (
    <SiteAdminTeamLayout chosen="Resource Categories" teamId={team.id}>
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: team.organization.displayName, url: `/site-admin/org/${team.organization.id}` },
            { text: team.displayName, url: `/site-admin/team/${team.id}` },
            { text: "Resource Categories", url: `/site-admin/team/${team.id}/resource-categories` },
          ]}
        />
        <PaperWithTitle title="Edit Resource Category">
          <ResourceCategoryEditor nameState={[name, setName]} />
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <ApolloConsumer>
              {(client) => (
                <React.Fragment>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={saveIsDisabled()}
                    onClick={() => {
                      save(client);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={saveIsDisabled()}
                    onClick={() => {
                      onSaveAndClose(client);
                    }}
                  >
                    Save and Close
                  </Button>
                </React.Fragment>
              )}
            </ApolloConsumer>
          </Stack>
          {errorMessage === "" ? <React.Fragment /> : <Typography color={red[900]}>{errorMessage}</Typography>}
        </PaperWithTitle>
      </Stack>
    </SiteAdminTeamLayout>
  );
};
