import React, { useState } from "react";

import { useHistory, useParams } from "react-router";
import { useSiteTeamQuery } from "../../../../generated/graphql";

import { ViewTeamContentSiteAdminPage } from "../../../../components/siteAdmin/viewTeamContentSiteAdmin";
import { CreateDemoTemplateDialog } from "../../../../components/dialogs/createDemoTemplateDialog";
import { requestCreateSiteDemoTemplate } from "../../../../generated/graphqlWrappers";

interface Params {
  teamId: string;
}

export const ViewTeamDemoTemplatesSiteAdminPage: React.FC = () => {
  const { teamId } = useParams<Params>();
  const history = useHistory();
  const [createTeamDialogOpen, setCreateTeamDialogOpen] = useState(false);

  return (
    <React.Fragment>
      <ViewTeamContentSiteAdminPage
        teamId={teamId}
        query={useSiteTeamQuery}
        chosen="Stakeholder Templates"
        getObjects={(data) => {
          return data.demoTemplates;
        }}
        dataDescription="stakeholder templates"
        title="Stakeholder Template"
        newContentPath="new-stakeholder-template"
        onClick={(id) => {
          history.push(`/site-admin/template/${id}`);
        }}
        onNew={() => {
          setCreateTeamDialogOpen(true);
        }}
        rowLabelProperty="name"
      />
      <CreateDemoTemplateDialog
        teamId={teamId}
        open={createTeamDialogOpen}
        onClose={(templateId) => {
          if (templateId) {
            history.push(`/site-admin/template/${templateId}`);
          }
          setCreateTeamDialogOpen(false);
        }}
        requestCreateDemoTemplate={requestCreateSiteDemoTemplate}
      />
    </React.Fragment>
  );
};
