import React, { useState } from "react";

import { Button, Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

import { SiteAdminTeamLayout } from "../layouts/siteAdminTeamLayout";
import { DemoContentEditor } from "../forms/demoContentEditor";
import { useSiteTeamQuery } from "../../generated/graphql";
import { AppErrorPage } from "../appError";
import { SiteAdminTeamAppLoadingPage } from "./siteAdminTeamAppLoadingPage";
import { Breadcrumb } from "../breadcrumb";
import { PaperWithTitle } from "../paperWithTitle";

interface Props {
  contentType: string;
  teamId: string;
  createContent: (options: any) => Promise<any>;
  onClose: (teamId: number) => void;
  resultDataProperty: string;
}

export const CreateTeamDemoContentSiteAdmin: React.FC<Props> = ({
  contentType,
  teamId,
  createContent,
  onClose,
  resultDataProperty,
}) => {
  const [name, setName] = useState("");
  const [body, setBody] = useState("");
  const [bulletedBody, setBulletedBody] = useState("");
  const [whatToShow, setWhatToShow] = useState("");
  const [contentIsChanged, setContentIsChanged] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const { data, error, loading } = useSiteTeamQuery({
    variables: {
      teamId: parseInt(teamId),
    },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminTeamAppLoadingPage />;
  }

  const team = data.siteTeam;

  const saveIsDisabled = () => {
    return name === "" || !contentIsChanged;
  };

  const onSaveAndClose = async () => {
    await save(onClose);
  };

  const save = async (onSuccess: (teamId: number) => void) => {
    try {
      setErrorMessage("");
      const response = await createContent({
        variables: { teamId: parseInt(team.id), name, body, bulletedBody, whatToShow },
      });
      if (response && response.data) {
        const resultData = response.data[resultDataProperty];
        if (resultData.success) {
          if (onSuccess) {
            onSuccess(parseInt(team.id));
          }
        } else {
          setErrorMessage(resultData.error);
        }
      } else {
        setErrorMessage(`Error creating ${contentType}`);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(`Error creating ${contentType}`);
    }
  };

  return (
    <SiteAdminTeamLayout chosen={`${contentType}s`} teamId={team.id}>
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: team.organization.displayName, url: `/site-admin/org/${team.organization.id}` },
            { text: team.displayName, url: `/site-admin/team/${team.id}` },
            {
              text: contentType + "s",
              onClick: () => {
                onClose(parseInt(team.id));
              },
            },
          ]}
        />
        <PaperWithTitle title={`New ${contentType}`}>
          <DemoContentEditor
            nameState={[name, setName]}
            bodyState={[body, setBody]}
            bulletedBodyState={[bulletedBody, setBulletedBody]}
            whatToShowState={[whatToShow, setWhatToShow]}
            setContentIsChanged={setContentIsChanged}
          />
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                onClose(parseInt(team.id));
              }}
            >
              Cancel
            </Button>

            <Button variant="contained" color="primary" disabled={saveIsDisabled()} onClick={onSaveAndClose}>
              Add {contentType}
            </Button>
          </Stack>
          {errorMessage === "" ? <React.Fragment /> : <Typography color={red[900]}>{errorMessage}</Typography>}
        </PaperWithTitle>
      </Stack>
    </SiteAdminTeamLayout>
  );
};
