import React, { useState } from "react";

import { useParams } from "react-router";
import { SiteTeamUsersQuery, useAddSiteUserTeamMutation, useSiteTeamUsersQuery } from "../../../../generated/graphql";
import { ViewTeamContentSiteAdminPage } from "../../../../components/siteAdmin/viewTeamContentSiteAdmin";
import { ContentPickerDialog } from "../../../../components/dialogs/contentPickerDialog";
import { SiteDataIdDisplayName } from "../../../../util/demoTypes";
import { gqlMutateWithHook } from "../../../../util/gqlHelpers";
import { AppErrorPage } from "../../../../components/appError";
import { SiteAdminTeamAppLoadingPage } from "../../../../components/siteAdmin/siteAdminTeamAppLoadingPage";
import { AppSnackbar } from "../../../../components/appSnackbar";
import { useAppDispatch } from "../../../../store/hooks";
import { showSnackbarError } from "../../../../components/appSnackbarSlice";
import { objectIdsFromArrayNotInOtherArray } from "../../../../util/arrayUtils";
import { useHistory } from "react-router-dom";

interface Params {
  teamId: string;
}

export const ViewTeamUsersSiteAdminPage: React.FC = () => {
  const { teamId } = useParams<Params>();
  const history = useHistory();
  const [memberDialogOpen, setMemberDialogOpen] = useState(false);

  const [addSiteUserTeam] = useAddSiteUserTeamMutation();

  const dispatch = useAppDispatch();

  const { data, loading, error, refetch } = useSiteTeamUsersQuery({
    variables: { teamId: parseInt(teamId) },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminTeamAppLoadingPage />;
  }

  const membersNotIncluded = (): any[] => {
    if (!data.siteTeam) {
      return [];
    }
    return objectIdsFromArrayNotInOtherArray(data.siteTeam.organization.users, data.siteTeam.users);
  };

  const memberDialogOnClose = async (user?: SiteDataIdDisplayName): Promise<void> => {
    if (user) {
      const message = `Error adding user`;
      await gqlMutateWithHook(
        addSiteUserTeam,
        { userId: parseInt(user.id), teamId: parseInt(teamId) },
        "addSiteUserTeam",
        () => {
          refetch();
        },
        () => {
          dispatch(showSnackbarError(message));
          return message;
        }
      );
    }
    setMemberDialogOpen(false);
  };

  return (
    <React.Fragment>
      <ViewTeamContentSiteAdminPage
        teamId={teamId}
        query={useSiteTeamUsersQuery}
        chosen="Team Members"
        getObjects={(data) => {
          return data.users;
        }}
        chipForObj={(user: SiteTeamUsersQuery["siteTeam"]["users"][0]) => {
          if (user.teamAdminRoles.findIndex((adminRole) => adminRole.team.id === teamId) >= 0) {
            return [{ color: "primary", label: "team admin" }];
          }
          return [];
        }}
        dataDescription="members"
        title="Member"
        newContentPath="new-member"
        onClick={(id) => {
          history.push(`/site-admin/user/${id}`);
        }}
        rowLabelProperty="displayName"
        newButtonText="Include Member"
        onNewDisabled={membersNotIncluded().length === 0}
        onNew={() => {
          setMemberDialogOpen(true);
        }}
      />
      <ContentPickerDialog
        title="Select a Member"
        items={membersNotIncluded()}
        open={memberDialogOpen}
        onClose={memberDialogOnClose}
      />
      <AppSnackbar />
    </React.Fragment>
  );
};
