import React from "react";

import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import AssignmentIcon from "@mui/icons-material/Assignment";
import GroupsIcon from "@mui/icons-material/Groups";
import { NavSidebar } from "./navSidebar";

const sidebar = [
  { text: "My Demos", url: "/", icon: <DesktopMacIcon /> },
  { text: "Shared Demos", url: "/shared-demos", icon: <ScreenShareIcon /> },
  { text: "Resources", url: "/resources", icon: <AssignmentIcon /> },
  { text: "Teams", url: "/teams", icon: <GroupsIcon /> },
];

interface Props {
  chosen?: string;
}

export const AppSidebar: React.FC<Props> = ({ chosen }) => {
  return <NavSidebar chosen={chosen} sidebar={sidebar} />;
};
