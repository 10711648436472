import { DemoContentEdit, DemoContentId } from "../../../generated/graphql";
import { DemoContent, demoContentKey } from "../../../util/demoTypes";
import { DemoScriptMode, demoStateEditableContentArray, shouldSaveViewDemoStateBase } from "./viewDemoBaseReduxUtils";
import { ViewDemoState } from "./viewDemoSlice";

export type EditableDemoContent = { content: DemoContent; isEdited: boolean };
export const makeEditable = (content: DemoContent): EditableDemoContent => {
  return { content, isEdited: false };
};

export const demoStateLength = (ds: ViewDemoState) => {
  return ds.initialized ? ds.draggableParts.length + 2 : 0;
};

const getDemoPropertyEditList = (ds: ViewDemoState): DemoContentEdit[] => {
  return ds.demoPropertiesEdited
    ? [
        {
          contentType: "Demo",
          id: parseInt(ds.demoId),
          property: "name",
          newValue: ds.demoName,
        },
        {
          contentType: "Demo",
          id: parseInt(ds.demoId),
          property: "description",
          newValue: ds.demoDescription,
        },
      ]
    : [];
};

const getReactionFeedbackEditList = (ds: ViewDemoState): DemoContentEdit[] => {
  return ds.chosenReactions
    .filter((r) => r.edited)
    .map((r) => {
      return {
        contentType: r.contentType,
        id: parseInt(r.contentId),
        property: `reaction-feedback-${r.reactionId}`,
        newValue: r.feedbackBody,
      };
    });
};

export const getEditList = (ds: ViewDemoState): DemoContentEdit[] => {
  const contentEdits: DemoContentEdit[] = demoStateEditableContentArray(ds)
    .filter((c) => c.isEdited)
    .flatMap((edc) => {
      return [
        {
          contentType: edc.content.__typename!,
          id: parseInt(edc.content.id),
          property: "name",
          newValue: edc.content.name,
        },
        {
          contentType: edc.content.__typename!,
          id: parseInt(edc.content.id),
          property: "whatToShow",
          newValue: edc.content.whatToShow,
        },
        {
          contentType: edc.content.__typename!,
          id: parseInt(edc.content.id),
          property: "body",
          newValue: edc.content.body,
        },
        {
          contentType: edc.content.__typename!,
          id: parseInt(edc.content.id),
          property: "bulletedBody",
          newValue: edc.content.bulletedBody,
        },
        {
          contentType: edc.content.__typename!,
          id: parseInt(edc.content.id),
          property: "showBulleted",
          newValue: edc.content.showBulleted ? "true" : "false",
        },
      ];
    });
  return [...contentEdits, ...getDemoPropertyEditList(ds), ...getReactionFeedbackEditList(ds)];
};

export const reorderedContentList = (
  ds: ViewDemoState,
  draggedIndex: number,
  droppedIndex: number
): DemoContentId[] => {
  let contentList = draggablePartsDeepCopy(ds);

  if (draggedIndex < 0) {
    throw new Error("Unexpected drag data when moving demo content");
  }
  const draggedContentList = contentList.splice(draggedIndex, 1);
  if (draggedContentList.length !== 1) {
    throw new Error("Unexpected drag data when moving demo content");
  }
  const draggedContent = draggedContentList[0];
  if (droppedIndex < 0) {
    throw new Error("Unexpected drop data when moving demo content");
  }
  contentList.splice(droppedIndex, 0, draggedContent);
  return contentList.map((edc) => {
    return {
      contentType: edc.content.__typename!,
      id: parseInt(edc.content.id),
    };
  });
};

export const editableContentDeepCopy = (edc: EditableDemoContent) => {
  return { content: { ...edc.content }, isEdited: edc.isEdited };
};

export const draggablePartsDeepCopy = (ds: ViewDemoState) => {
  return ds.draggableParts.map((edc) => {
    return editableContentDeepCopy(edc);
  });
};

export const updateStateWithNewContent = (ds: ViewDemoState, edc: EditableDemoContent) => {
  if (edc.content.__typename === "OpeningForDemo") {
    ds.opening = edc;
  } else if (edc.content.__typename === "CloseForDemo") {
    ds.close = edc;
  } else {
    const dpEditable = draggablePartsDeepCopy(ds);
    const index = dpEditable.findIndex((p) => demoContentKey(p.content) === demoContentKey(edc.content));
    if (index >= 0) {
      dpEditable[index] = edc;
      ds.draggableParts = dpEditable;
    }
  }
};

export const scriptModeForTab = (tab: string): DemoScriptMode => {
  return tab === "1" ? DemoScriptMode.FULL : DemoScriptMode.BULLETED;
};

export function shouldSaveViewDemoState(viewDemoState: ViewDemoState) {
  if (shouldSaveViewDemoStateBase(viewDemoState)) {
    return true;
  }
  return viewDemoState.editCount > 0;
}
