import React from "react";
import { useParams } from "react-router-dom";
import { useUserConversationsForTeamQuery } from "../../../generated/graphql";
import { requestCreateConversation, requestUpdateConversationArchived } from "../../../generated/graphqlWrappers";
import { TeamContentTablePageLayout } from "./teamContent/teamContentTablePageLayout";

interface Params {
  teamId: string;
}

export const TeamConversationsPage: React.FC = () => {
  const { teamId } = useParams<Params>();
  return (
    <TeamContentTablePageLayout
      teamId={teamId}
      contentType="Conversation"
      chosen="Conversations"
      useContentListQuery={useUserConversationsForTeamQuery}
      requestCreateContent={requestCreateConversation}
      requestArchiveContent={requestUpdateConversationArchived}
    />
  );
};
