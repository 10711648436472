import React from "react";
import { FormControlLabel, Radio, Typography } from "@mui/material";
import { UserDemoTemplatesForPersonaQuery } from "../../../../generated/graphql";

interface Props {
  templates: UserDemoTemplatesForPersonaQuery["userDemoTemplatesForPersona"];
}

export const TemplateRadioButton: React.FC<Props> = ({ templates }) => {
  if (templates.length > 0) {
    return (
      <React.Fragment>
        {templates.map((template) => {
          return (
            <React.Fragment key={template.id}>
              <FormControlLabel
                sx={{ mb: 2 }}
                value={template.id}
                control={<Radio />}
                label={
                  <React.Fragment>
                    <Typography>{template.name}</Typography>
                    {template.description !== "" ? (
                      <Typography color="GrayText" fontSize="small">
                        {template.description}
                      </Typography>
                    ) : (
                      <React.Fragment />
                    )}
                  </React.Fragment>
                }
              />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  } else {
    return <Typography>There are no templates in your teams.</Typography>;
  }
};
