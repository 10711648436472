import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { AppErrorPage } from "../../components/appError";
import { AppLoadingPage } from "../../components/appLoadingPage";
import { AppLayout } from "../../components/layouts/appLayout";
import { ObjectArrayTable } from "../../components/objectArrayTable/objectArrayTable";
import { PaperWithTitle } from "../../components/paperWithTitle";
import { TitleWithAdminChip } from "../../components/titleWithAdminChip";
import { useUserResourcesByTeamQuery } from "../../generated/graphql";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { isAdminForOrgOrTeam } from "../../util/adminUtils";
import { ResourcesFilter } from "./resources/resourcesFilter";
import {
  categoryAdded,
  initializeResourcesState,
  resourceAdded,
  selectedResources,
  selectResources,
} from "./resourcesSlice";
import { useHistory } from "react-router-dom";
import { CreateContentDialog } from "../../components/dialogs/createContentDialog";
import { requestCreateResource, requestCreateResourceCategory } from "../../generated/graphqlWrappers";

export const ResourcesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const resourcesState = useAppSelector(selectResources);

  const history = useHistory();

  const [createResourceDialogOpen, setCreateResourceDialogOpen] = useState(false);
  const [createCateogryDialogOpen, setCreateCateogryDialogOpen] = useState(false);

  const { data, loading, error } = useUserResourcesByTeamQuery({ fetchPolicy: "network-only" });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <AppLoadingPage chosen="Resources" />;
  }

  if (!resourcesState.initialized) {
    dispatch(initializeResourcesState(data.userResourcesByTeam));
    return <AppLoadingPage chosen="Resources" />;
  }

  const teamIndexIsValid = resourcesState.teamIndex < resourcesState.data.length;
  const categoryIndexIsValid =
    teamIndexIsValid &&
    resourcesState.categoryIndex < resourcesState.data[resourcesState.teamIndex].resourceCategories.length;
  const isAdmin = teamIndexIsValid
    ? isAdminForOrgOrTeam(data.userAccount, resourcesState.data[resourcesState.teamIndex].admins)
    : false;
  const teamId = teamIndexIsValid ? resourcesState.data[resourcesState.teamIndex].id : "0";
  const categoryId = categoryIndexIsValid
    ? resourcesState.data[resourcesState.teamIndex].resourceCategories[resourcesState.categoryIndex].id
    : "0";

  return (
    <AppLayout chosen="Resources">
      <Stack spacing={2}>
        <PaperWithTitle
          title={<TitleWithAdminChip title="Resources" isAdmin={isAdmin} isOrgAdmin={data.userAccount.isOrgAdmin} />}
        >
          <ResourcesFilter />
          <ObjectArrayTable
            buttons={
              isAdmin
                ? [
                    {
                      text: "New Resource",
                      onClick: () => {
                        setCreateResourceDialogOpen(true);
                      },
                    },
                    {
                      text: "New Category",
                      onClick: () => {
                        setCreateCateogryDialogOpen(true);
                      },
                    },
                  ]
                : []
            }
            dataDescription="resources"
            title="Resources"
            data={selectedResources(resourcesState)}
            cellNode={(obj) => {
              return <Typography>{obj.name}</Typography>;
            }}
            onClick={(id) => {
              history.push(`/team/${teamId}/resource/${id}`);
            }}
          />
        </PaperWithTitle>
      </Stack>
      <CreateContentDialog
        open={createResourceDialogOpen}
        onClose={() => {
          setCreateResourceDialogOpen(false);
        }}
        nameLabel="Resource Name"
        buttonLabel="Add Resource"
        onSave={async (client, name, onSuccess, onError) => {
          requestCreateResource(
            client,
            { teamId: parseInt(teamId), name, body: "", categoryId: parseInt(categoryId) },
            (response) => {
              if (response.idInserted) {
                const resourceId = `${response.idInserted}`;
                dispatch(resourceAdded({ id: resourceId, teamId, categoryId, name }));
              }
              onSuccess();
            },
            onError
          );
        }}
      />
      <CreateContentDialog
        open={createCateogryDialogOpen}
        onClose={() => {
          setCreateCateogryDialogOpen(false);
        }}
        nameLabel="Category Name"
        buttonLabel="Add Category"
        onSave={async (client, name, onSuccess, onError) => {
          requestCreateResourceCategory(
            client,
            { teamId: parseInt(teamId), name },
            (response) => {
              if (response.idInserted) {
                const categoryId = `${response.idInserted}`;
                dispatch(categoryAdded({ id: categoryId, teamId, name }));
              }
              onSuccess();
            },
            onError
          );
        }}
      />
    </AppLayout>
  );
};
