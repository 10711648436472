import React, { useState } from "react";
import Cookies from "js-cookie";
import { useHistory, useParams } from "react-router-dom";
import { AppErrorPage } from "../../components/appError";
import { AppLoadingPage } from "../../components/appLoadingPage";
import { useUserSharedDemoQuery } from "../../generated/graphql";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  viewSharedDemoSlice,
  clearViewDemoState,
  initializeViewDemoState,
  selectViewSharedDemo,
  toggleRightDrawer,
} from "./viewDemo/viewSharedDemoSlice";
import { Drawer, Box, Stack, Toolbar, Typography, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AppLogo } from "../../components/appLogo";
import { AppSnackbar } from "../../components/appSnackbar";
import { AppLayoutNoSidebar } from "../../components/layouts/appLayoutNoSidebar";
import { ReactionLoader } from "../../components/reactions/reactionLoader";
import { DemoErrorMessage } from "./viewDemo/demoErrorMessage";
import { DemoNav } from "./viewDemo/demoNav";
import { DemoPresentationView } from "./viewDemo/demoPresentationView";
import { PresentationMenu } from "./viewDemo/menu/presentationMenu";
import { ResourcesNav } from "./viewDemo/resourcesNav";
import {
  viewDemoPageLeftDrawerWidth,
  viewDemoPageAnimationProperties,
  viewDemoPageRightDrawerWidth,
} from "./viewDemo/viewDemoPageUtils";
import {
  shouldSaveViewDemoStateBase,
  ViewDemoContext,
  ViewDemoContextInterface,
} from "./viewDemo/viewDemoBaseReduxUtils";
import { DemoMenu } from "./viewDemo/menu/demoMenu";

interface Params {
  demoId: string;
}

export const ViewSharedDemoPage: React.FC = () => {
  let { demoId } = useParams<Params>();
  const [isWide, setIsWide] = useState(Cookies.get("viewDemo.isWide") === "true");

  const history = useHistory();
  const dispatch = useAppDispatch();
  const viewSharedDemoState = useAppSelector(selectViewSharedDemo);

  const { data, error, loading } = useUserSharedDemoQuery({
    variables: { demoId: parseInt(demoId) },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }

  if (loading || !data) {
    if (viewSharedDemoState.initialized) {
      dispatch(clearViewDemoState());
    }
    return <AppLoadingPage />;
  }

  if (!viewSharedDemoState.initialized) {
    dispatch(initializeViewDemoState(data));
    return <AppLoadingPage />;
  }

  const onToggleRightDrawer = () => {
    dispatch(toggleRightDrawer());
  };

  const viewDemoContext: ViewDemoContextInterface = {
    selectState: selectViewSharedDemo,
    actions: viewSharedDemoSlice.actions,
  };

  return (
    <ViewDemoContext.Provider value={viewDemoContext}>
      <AppLayoutNoSidebar>
        <Drawer
          variant="permanent"
          sx={{
            width: viewDemoPageLeftDrawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": { width: viewDemoPageLeftDrawerWidth, boxSizing: "border-box" },
          }}
        >
          <AppLogo />
          <DemoNav />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Stack sx={{ mb: 2, position: "sticky", top: 0, zIndex: 101, backgroundColor: "#eee" }}>
            <Toolbar>
              <DemoMenu
                onDuplicateDemoSuccess={(demoId) => {
                  history.replace(`/view-demo/${demoId}`);
                }}
                onTeamLibrarySuccess={() => {}}
                inTeamLibary={viewSharedDemoState.inTeamLibrary}
                teamFeatureSets={[]}
                teamConversations={[]}
                sharedDemoVersion={true}
              />
              <PresentationMenu
                isWide={isWide}
                setIsWide={(isWide) => {
                  Cookies.set("viewDemo.isWide", isWide ? "true" : "false");
                  setIsWide(isWide);
                }}
              />
              <Typography sx={{ flexGrow: 1 }}></Typography>
              <DemoErrorMessage />
              <Typography>{shouldSaveViewDemoStateBase(viewSharedDemoState) ? "(unsaved)" : ""}</Typography>
            </Toolbar>
          </Stack>
          <ReactionLoader>
            <DemoPresentationView name={data.userSharedDemo.name} isWide={isWide} showScriptMode={false} />
          </ReactionLoader>
        </Box>
        <Drawer
          variant="permanent"
          anchor="right"
          sx={{
            ...viewDemoPageAnimationProperties,
            flexShrink: 0,
            backgroundColor: "#eee",
            width: viewDemoPageRightDrawerWidth(viewSharedDemoState.rightDrawerOpen),
            "& .MuiDrawer-paper": {
              ...viewDemoPageAnimationProperties,
              width: viewDemoPageRightDrawerWidth(viewSharedDemoState.rightDrawerOpen),
              borderLeft: viewSharedDemoState.rightDrawerOpen ? "solid 1px lightgray" : "none",
              boxSizing: "border-box",
              backgroundColor: "inherit",
            },
          }}
        >
          <Stack sx={{ m: 2 }}>
            <Stack alignItems="flex-end" sx={{ ml: 4 }}>
              <IconButton
                onClick={onToggleRightDrawer}
                size="small"
                sx={{ backgroundColor: "#ddd", "&:hover": { backgroundColor: "lightgray" } }}
              >
                {viewSharedDemoState.rightDrawerOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Stack>
            {viewSharedDemoState.rightDrawerOpen ? (
              <React.Fragment>
                <ResourcesNav teamId={data.userSharedDemo.demoTemplate?.team.id ?? "-1"} />
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </Stack>
        </Drawer>
        <AppSnackbar />
      </AppLayoutNoSidebar>
    </ViewDemoContext.Provider>
  );
};
