import React, { useContext, useState } from "react";
import { ApolloClient } from "@apollo/client";
import { requestDuplicateUserDemo } from "../../../../../generated/graphqlWrappers";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { demoSuccessfullyDuplicated } from "../../../myDemosSlice";
import { CreateContentDialog } from "../../../../../components/dialogs/createContentDialog";
import { ViewDemoContext } from "../../viewDemoBaseReduxUtils";

interface Props {
  onDuplicateDemoSuccess: (newDemoId: string) => void;
  children: (onSaveAs: (client: ApolloClient<object>) => void) => React.ReactChild | null;
}

export const DemoSaveAsMenuHandler: React.FC<Props> = ({ onDuplicateDemoSuccess, children }) => {
  const context = useContext(ViewDemoContext);
  const viewDemoState = useAppSelector(context.selectState);
  const dispatch = useAppDispatch();
  const actions = context.actions;

  const [nameDialogIsOpen, setNameDialogIsOpen] = useState(false);

  const onSaveAs = async (apolloClient: ApolloClient<object>) => {
    setNameDialogIsOpen(true);
  };

  const doSaveAs = async (apolloClient: ApolloClient<object>, name: string): Promise<void> => {
    dispatch(actions.setErrorMessage(""));
    await requestDuplicateUserDemo(
      apolloClient,
      { demoId: parseInt(viewDemoState.demoId), name },
      (response) => {
        if (response.idInserted) {
          dispatch(
            demoSuccessfullyDuplicated({ demoId: viewDemoState.demoId, newDemoId: `${response.idInserted}`, name })
          );
          setNameDialogIsOpen(false);
          onDuplicateDemoSuccess(`${response.idInserted}`);
        }
      },
      (err) => dispatch(actions.setErrorMessage(err))
    );
  };

  return (
    <React.Fragment>
      {children(onSaveAs)}
      <CreateContentDialog
        open={nameDialogIsOpen}
        onClose={function (added: boolean): void {
          setNameDialogIsOpen(false);
        }}
        nameLabel={"Demo Name"}
        buttonLabel={"Save"}
        onSave={async (
          client: ApolloClient<object>,
          name: string,
          onSuccess: () => void,
          onError: (msg: string) => void
        ): Promise<void> => {
          await doSaveAs(client, name);
        }}
      />
    </React.Fragment>
  );
};
