import React from "react";
import { useUserReactionsQuery } from "../../generated/graphql";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { clearReactionBarState, initializeReactionBarState, selectReactionBar } from "./reactionBarSlice";

export const ReactionLoader: React.FC = ({ children }) => {
  const { data, loading, error } = useUserReactionsQuery({ fetchPolicy: "network-only" });

  const reactionBarState = useAppSelector(selectReactionBar);
  const dispatch = useAppDispatch();

  if (error) {
    return <React.Fragment />;
  }
  if (loading || !data) {
    if (reactionBarState.isInitialized) {
      dispatch(clearReactionBarState());
    }
    return <React.Fragment />;
  }

  if (!reactionBarState.isInitialized) {
    dispatch(initializeReactionBarState(data));
    return <React.Fragment />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};
