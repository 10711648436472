import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { clearNewDemoState, selectNewDemo, setConversationIds, toggleConversationId } from "./newDemoSlice";
import { useCreateUserDemoMutation, useUserConversationsForTemplateQuery } from "../../../generated/graphql";
import { newDemoCreated } from "../myDemosSlice";

interface Props {
  onDone: (idInserted?: number) => void;
  back: () => void;
}

export const NewDemoPickConversations: React.FC<Props> = ({ onDone, back }) => {
  const newDemoState = useAppSelector(selectNewDemo);

  const dispatch = useAppDispatch();

  const [errorMessage, setErrorMessage] = useState("");

  const { data, error, loading } = useUserConversationsForTemplateQuery({
    variables: { templateId: newDemoState.templateId ?? 0 },
    fetchPolicy: "network-only",
  });

  const [createDemo] = useCreateUserDemoMutation();

  if (!newDemoState.templateId) {
    return <div>Template not set</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading || !data) {
    dispatch(setConversationIds(undefined));
    return <div />;
  }

  if (newDemoState.conversationIds === undefined) {
    // This is the first load of new data, so check all recommended conversations
    const ids = data.userConversationsForTemplate
      .filter((tc) => {
        return tc.recommended;
      })
      .map((tc) => {
        return tc.conversation.id;
      });
    dispatch(setConversationIds(ids));
  }

  const nextDisabled = () => {
    return newDemoState.templateId === undefined || newDemoState.team === undefined;
  };

  const idStringToNumber = (s: string): number => {
    return parseInt(s);
  };

  const onNext = async () => {
    if (!nextDisabled()) {
      setErrorMessage("");
      try {
        const response = await createDemo({
          variables: {
            templateId: newDemoState.templateId!,
            name: newDemoState.name,
            description: newDemoState.description,
            featureSetIds: newDemoState.featureSetIds?.map(idStringToNumber) ?? [],
            conversationIds: newDemoState.conversationIds?.map(idStringToNumber) ?? [],
          },
        });
        if (response && response.data && newDemoState.team) {
          if (response.data.createUserDemo.success && response.data.createUserDemo.idInserted) {
            const demoId = response.data.createUserDemo.idInserted;
            dispatch(clearNewDemoState());
            dispatch(
              newDemoCreated({
                __typename: "Demo",
                id: `${demoId}`,
                name: newDemoState.name,
                description: newDemoState.description,
                archived: false,
                team: newDemoState.team,
                modifiedDate: new Date().toISOString(),
              })
            );
            onDone(demoId);
          } else {
            setErrorMessage(response.data.createUserDemo.error);
          }
        } else {
          setErrorMessage("Error sending create demo request to the server");
        }
      } catch (error) {
        console.log(error);
        setErrorMessage("Error sending the request");
      }
    } else {
    }
  };

  const onBack = () => {
    back();
  };

  const onConversationChanged = (conversationId: string) => {
    return () => {
      dispatch(toggleConversationId(conversationId));
    };
  };

  const isChecked = (conversationId: string): boolean => {
    if (newDemoState.conversationIds === undefined) {
      return false;
    }
    return newDemoState.conversationIds.indexOf(conversationId) > -1;
  };

  return (
    <React.Fragment>
      <DialogTitle>Select Conversations</DialogTitle>

      <Box flexDirection="column" sx={{ my: 2, mx: 2, display: "flex", gap: 2 }}>
        <FormControl component="fieldset">
          <FormGroup aria-label="conversations">
            {data.userConversationsForTemplate.map((tc) => {
              return (
                <FormControlLabel
                  key={tc.conversation.id}
                  onChange={onConversationChanged(tc.conversation.id)}
                  checked={isChecked(tc.conversation.id)}
                  value={tc.conversation.id}
                  control={<Checkbox />}
                  label={tc.conversation.name}
                />
              );
            })}
          </FormGroup>
        </FormControl>
        {errorMessage === "" ? <React.Fragment /> : <Typography color={red[900]}>{errorMessage}</Typography>}
        <Box flexDirection="row" sx={{ my: 2, display: "flex", gap: 2, justifyContent: "flex-end" }}>
          <Button size="large" variant="outlined" color="primary" onClick={onBack}>
            Back
          </Button>

          <Button size="large" variant="contained" color="primary" disabled={nextDisabled()} onClick={onNext}>
            Launch Demo
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};
