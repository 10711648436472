import React from "react";
import { Stack, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectViewDemo, setViewMode } from "../viewDemoSlice";
import { DemoViewMode } from "../viewDemoBaseReduxUtils";

export const DemoViewModeSelector: React.FC = () => {
  const viewDemoState = useAppSelector(selectViewDemo);
  const dispatch = useAppDispatch();

  return (
    <Stack alignItems="center">
      <ToggleButtonGroup value={viewDemoState.viewMode} sx={{ mt: 2 }}>
        <ToggleButton
          value={DemoViewMode.EDIT}
          size="small"
          onClick={() => {
            dispatch(setViewMode(DemoViewMode.EDIT));
          }}
        >
          <Tooltip title="Edit Mode">
            <ModeEditIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          value={DemoViewMode.VIEW}
          size="small"
          onClick={() => {
            dispatch(setViewMode(DemoViewMode.VIEW));
          }}
        >
          <Tooltip title="Presentation Mode">
            <ViewAgendaIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
