import React from "react";

import { CreateTeamDialog } from "../../components/dialogs/createTeamDialog";
import { requestCreateSiteTeam } from "../../generated/graphqlWrappers";

interface Props {
  organizationId: number;
  open: boolean;
  onClose: (added: boolean) => void;
}

export const CreateTeamSiteAdminDialog: React.FC<Props> = ({ organizationId, open, onClose }) => {
  return (
    <CreateTeamDialog
      organizationId={organizationId}
      open={open}
      onClose={onClose}
      onSave={async (client, organizationId, displayName, onSuccess, onError) => {
        await requestCreateSiteTeam(client, { organizationId, displayName }, onSuccess, onError);
      }}
    />
  );
};
