import React from "react";
import { Stack } from "@mui/material";

import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  clearViewDemoTemplateState,
  initializeViewDemoTemplateState,
  selectViewDemoTemplate,
} from "../../../shared/viewDemoTemplate/store/viewDemoTemplateSlice";

import { SiteAdminTeamLayout } from "../../../../components/layouts/siteAdminTeamLayout";
import { SiteAdminTeamAppLoadingPage } from "../../../../components/siteAdmin/siteAdminTeamAppLoadingPage";
import { AppErrorPage } from "../../../../components/appError";
import { AppSnackbar } from "../../../../components/appSnackbar";
import { Breadcrumb } from "../../../../components/breadcrumb";

import { useSiteDemoTemplateQuery } from "../../../../generated/graphql";
import {
  requestUpdateSiteDemoTemplateClose,
  requestUpdateSiteDemoTemplateContentOrder,
  requestUpdateSiteDemoTemplateProperties,
  requestUpdateSiteDemoTemplateOpening,
  requestUpdateSiteDemoTemplatePersonaType,
  requestAddSiteDemoTemplateFeatureSets,
  requestAddSiteDemoTemplateConversations,
  requestSiteDemoTemplateFeatureSets,
  requestSiteDemoTemplateConversations,
  requestRemoveSiteDemoTemplateConversation,
  requestUpdateSiteDemoTemplateConversationRecommended,
  requestRemoveSiteDemoTemplateFeatureSet,
  requestUpdateSiteDemoTemplateFeatureSetRecommended,
} from "../../../../generated/graphqlWrappers";
import { ViewDemoTemplateEditor } from "../../../shared/viewDemoTemplate/components/viewDemoTemplateEditor";

interface Params {
  demoTemplateId: string;
}

export const ViewDemoTemplateSiteAdminPage: React.FC = () => {
  let { demoTemplateId } = useParams<Params>();

  const dispatch = useAppDispatch();
  const viewDemoTemplateState = useAppSelector(selectViewDemoTemplate);

  const { data, error, loading } = useSiteDemoTemplateQuery({
    variables: { demoTemplateId: parseInt(demoTemplateId) },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    if (viewDemoTemplateState.initialized) {
      dispatch(clearViewDemoTemplateState());
    }
    return <SiteAdminTeamAppLoadingPage />;
  }

  if (!viewDemoTemplateState.initialized) {
    dispatch(initializeViewDemoTemplateState(data.siteDemoTemplate));
    return <SiteAdminTeamAppLoadingPage />;
  }

  const teamId = data.siteDemoTemplate.team.id;
  return (
    <SiteAdminTeamLayout chosen={"Stakeholder Templates"} teamId={teamId}>
      <Stack spacing={2} sx={{ pb: 2 }}>
        <Breadcrumb
          crumbs={[
            {
              text: data.siteDemoTemplate.team.organization.displayName,
              url: `/site-admin/org/${data.siteDemoTemplate.team.organization.id}`,
            },
            { text: data.siteDemoTemplate.team.displayName, url: `/site-admin/team/${data.siteDemoTemplate.team.id}` },
          ]}
        />
        <ViewDemoTemplateEditor
          teamId={teamId}
          demoTemplateId={demoTemplateId}
          openings={data.siteDemoTemplate.team.openings}
          closes={data.siteDemoTemplate.team.closes}
          urlRoot={"/site-admin"}
          requestUpdateDemoTemplateClose={requestUpdateSiteDemoTemplateClose}
          requestUpdateDemoTemplateContentOrder={requestUpdateSiteDemoTemplateContentOrder}
          requestUpdateDemoTemplateProperties={requestUpdateSiteDemoTemplateProperties}
          requestUpdateDemoTemplateOpening={requestUpdateSiteDemoTemplateOpening}
          requestUpdateDemoTemplatePersonaType={requestUpdateSiteDemoTemplatePersonaType}
          requestAddDemoTemplateFeatureSets={requestAddSiteDemoTemplateFeatureSets}
          requestAddDemoTemplateConversations={requestAddSiteDemoTemplateConversations}
          requestDemoTemplateFeatureSets={requestSiteDemoTemplateFeatureSets}
          requestDemoTemplateConversations={requestSiteDemoTemplateConversations}
          requestUpdateDemoTemplateConversationRecommended={requestUpdateSiteDemoTemplateConversationRecommended}
          requestRemoveDemoTemplateConversation={requestRemoveSiteDemoTemplateConversation}
          requestUpdateDemoTemplateFeatureSetRecommended={requestUpdateSiteDemoTemplateFeatureSetRecommended}
          requestRemoveDemoTemplateFeatureSet={requestRemoveSiteDemoTemplateFeatureSet}
        />
      </Stack>
      <AppSnackbar />
    </SiteAdminTeamLayout>
  );
};
