import React, { ChangeEvent } from "react";
import { Box, Button, DialogTitle, FormControl, RadioGroup } from "@mui/material";

import { useUserDemoTemplatesForPersonaQuery } from "../../../generated/graphql";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { selectNewDemo, setTeam, setTemplateId } from "./newDemoSlice";
import { TemplateRadioButton } from "./newDemoPickTemplate/templateRadioButton";

interface Props {
  next: () => void;
  back: () => void;
}

export const NewDemoPickTemplate: React.FC<Props> = ({ next, back }) => {
  const newDemoState = useAppSelector(selectNewDemo);
  const dispatch = useAppDispatch();

  const { data, error, loading } = useUserDemoTemplatesForPersonaQuery({
    variables: {
      onlyValid: true,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading || !data) {
    return <div />;
  }

  const nextDisabled = () => {
    return newDemoState.templateId === undefined;
  };

  const onNext = () => {
    if (!nextDisabled()) {
      next();
    } else {
    }
  };

  const onBack = () => {
    back();
  };

  const onTemplateChanged = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(setTemplateId(parseInt(value)));
    const templateIndex = data.userDemoTemplatesForPersona.findIndex((t) => t.id === value);
    if (templateIndex >= 0) {
      dispatch(setTeam(data.userDemoTemplatesForPersona[templateIndex].team));
    }
  };

  const title = data.userDemoTemplatesForPersona.length > 0 ? "Select Stakeholder" : "No templates";

  return (
    <React.Fragment>
      <DialogTitle>{title}</DialogTitle>
      <Box flexDirection="column" sx={{ my: 2, mx: 2, display: "flex", gap: 2 }}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="template"
            name="radio-buttons-group"
            onChange={onTemplateChanged}
            value={newDemoState.templateId}
          >
            <TemplateRadioButton templates={data.userDemoTemplatesForPersona} />
          </RadioGroup>
        </FormControl>
        <Box flexDirection="row" sx={{ my: 2, display: "flex", gap: 2, justifyContent: "flex-end" }}>
          <Button size="large" variant="outlined" color="primary" onClick={onBack}>
            Back
          </Button>
          <Button size="large" variant="contained" color="primary" disabled={nextDisabled()} onClick={onNext}>
            Next
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};
