export const formatDateMonthName = (date: Date) => {
  return `${date.getFullYear()}-${date.toLocaleString("default", { month: "short" })}-${date.getDate()}`;
};

export const formatDateSimple = (date: Date) => {
  return date.toLocaleDateString();
};

export const formatDateTimeSimple = (date: Date) => {
  return formatDateSimple(date) + " " + date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });
};

export const formatSecondsAsTime = (seconds: number) => {
  let date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substr(11, 8);
};

export const formatSecondsAsTimeCompact = (seconds: number) => {
  try {
    let date = new Date(0);
    date.setSeconds(seconds);
    var time = date.toISOString().substr(11, 8);
    if (!time.startsWith("00:")) {
      return time;
    }
    time = time.substring(3);
    if (!time.startsWith("0")) {
      return time;
    }
    time = time.substring(1);
    return time;
  } catch {
    return `unknown time`;
  }
};
