import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { AppErrorPage } from "../../../components/appError";
import { AppTeamLoadingPage } from "../../../components/appTeamLoadingPage";
import { demoContentIcon } from "../../../components/demoContentIcon";
import { AppTeamLayout } from "../../../components/layouts/appTeamLayout";
import { reactionButtonIconByName } from "../../../components/reactions/reactionBarSlice";
import { useUserTeamDashboardQuery } from "../../../generated/graphql";

interface Params {
  teamId: string;
}

export const TeamDashboardPage: React.FC = () => {
  const { teamId } = useParams<Params>();
  const { data, loading, error } = useUserTeamDashboardQuery({
    variables: { teamId: parseInt(teamId) },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <AppTeamLoadingPage />;
  }

  return (
    <AppTeamLayout teamId={teamId} teamName={data.userTeamDashboard.team.displayName} chosen={"Dashboard"}>
      <Grid container item xs={12}>
        <Grid item xs={12} sx={{ mx: 4 }} justifyContent="center">
          <Typography variant="h4">
            {data.userTeamDashboard.team.organization.displayName} | {data.userTeamDashboard.team.displayName} Dashboard
          </Typography>
        </Grid>

        {data.userTeamDashboard.mostReactedContent.map((r) => (
          <Grid item xs={4} sx={{ mt: 4, mx: 2 }}>
            <Paper>
              <Typography sx={{ p: 2 }}>
                <strong>
                  Most
                  {React.cloneElement(reactionButtonIconByName(r.reaction.iconName), {
                    style: { transform: "translate(0px, 6.5px)", paddingLeft: 4 },
                  })}
                </strong>
                <ol style={{ listStyleType: "none", paddingLeft: 0 }}>
                  {r.content.slice(0, 3).map((dt) => (
                    <li>
                      {React.cloneElement(demoContentIcon(dt.contentType), {
                        style: { transform: "translate(0px, 5px)", paddingRight: 2 },
                      })}
                      {dt.name}
                    </li>
                  ))}
                </ol>
              </Typography>
            </Paper>
          </Grid>
        ))}
        <Grid item xs={4} sx={{ mt: 4, mx: 2 }}>
          <Paper>
            <Typography sx={{ p: 2 }}>
              <strong>Most used templates</strong>
              <ol>
                {data.userTeamDashboard.mostUsedDemoTemplates.map((dt) => (
                  <li>{dt.name}</li>
                ))}
              </ol>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </AppTeamLayout>
  );
};
