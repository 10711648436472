import React from "react";
import { Stack, Typography } from "@mui/material";
import { formatSecondsAsTimeCompact } from "../../util/dateUtils";

interface Props {
  wordCount: number;
  showWordCountLabel?: string;
  bold?: boolean;
  timeOnly?: boolean;
  cumulativeWordCount?: number;
}

export const WordCount: React.FC<Props> = ({ wordCount, bold, timeOnly, showWordCountLabel, cumulativeWordCount }) => {
  const wordCountText = () => {
    return wordCount.toLocaleString() + " word" + (wordCount === 1 ? "" : "s") + " ";
  };

  return (
    <Stack>
      <Typography sx={{ py: 1, fontSize: bold ?? false ? 14 : 13, fontWeight: bold ?? false ? "bold" : undefined }}>
        {timeOnly ?? false ? "" : wordCountText()}
        {"spoken in " + formatSecondsAsTimeCompact((wordCount / 140) * 60)}
        {cumulativeWordCount === undefined
          ? ""
          : " ending at " + formatSecondsAsTimeCompact((cumulativeWordCount / 140) * 60)}
        {showWordCountLabel === undefined ? "" : " " + showWordCountLabel}
      </Typography>
    </Stack>
  );
};
