import React from "react";
import { Typography } from "@mui/material";
import { ContentDialog } from "./contentDialog";

interface Props {
  open: boolean;
  title: string;
  message: string;
  close: () => void;
}

/**
 * This dialog is to show the user a message
 * @param open if true, the dialog will be shown
 * @param title text shown in the title
 * @param message text shown in the main part of the dialog
 * @param close a function to call if the dialog is closed. It should typically set open to false.
 */
export const MessageDialog: React.FC<Props> = ({ open, title, message, close }) => {
  return (
    <ContentDialog open={open} close={close} title={title}>
      <Typography>{message}</Typography>
    </ContentDialog>
  );
};
