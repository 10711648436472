import React from "react";
import { showSnackbarError } from "../../../../../components/appSnackbarSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { DemoContentIdName } from "../../../../../util/demoTypes";
import { FeatureSetPicker } from "../../featureSetPicker";
import { addFeatureSets, selectViewDemo, setFeatureSetPickerOpen } from "../../viewDemoSlice";
import { getTeamFeatureSetsNotIncludedInDemo } from "../../viewDemoUtils";

interface Props {
  teamFeatureSets: DemoContentIdName[];
  children: (disabled: boolean, onFeatureAdd: () => void) => React.ReactChild | null;
}

export const AddFeatureMenuHandler: React.FC<Props> = ({ teamFeatureSets, children }) => {
  const viewDemoState = useAppSelector(selectViewDemo);
  const dispatch = useAppDispatch();

  const teamFeatureSetsNotIncluded = getTeamFeatureSetsNotIncludedInDemo(teamFeatureSets, viewDemoState);

  const featureSetPickerOnClose = async (fsList?: DemoContentIdName[]): Promise<void> => {
    dispatch(setFeatureSetPickerOpen(false));
  };

  return (
    <React.Fragment>
      {children(teamFeatureSetsNotIncluded.length === 0, () => {
        dispatch(setFeatureSetPickerOpen(true));
      })}
      <FeatureSetPicker
        items={teamFeatureSetsNotIncluded}
        open={viewDemoState.featureSetPickerOpen}
        onClose={featureSetPickerOnClose}
        demoId={viewDemoState.demoId}
        onError={(message) => {
          dispatch(showSnackbarError(message));
        }}
        onPicked={(userFeatureSetForDemo) => {
          dispatch(addFeatureSets(userFeatureSetForDemo));
        }}
      />
    </React.Fragment>
  );
};
