import React, { useState } from "react";

import { Checkbox, Stack, TextField, Typography } from "@mui/material";
import { FormDialog } from "../../components/dialogs/formDialog";
import { isValidEmail } from "../../util/dataValidators";
import { requestInviteSiteUser } from "../../generated/graphqlWrappers";
import { ApolloClient, ApolloConsumer } from "@apollo/client";

interface Props {
  organizationId: number;
  open: boolean;
  onClose: (added: boolean) => void;
}

export const InviteUserSiteAdminDialog: React.FC<Props> = ({ organizationId, open, onClose }) => {
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const close = (added: boolean) => {
    setErrorMessage("");
    setEmail("");
    setDisplayName("");
    setIsOrgAdmin(false);
    onClose(added);
  };

  const saveIsDisabled = () => {
    return !isValidEmail(email) || displayName === "";
  };

  const saveAndClose = async (client: ApolloClient<object>) => {
    await save(client, close);
  };

  const save = async (client: ApolloClient<object>, onSuccess: (added: boolean) => void) => {
    setErrorMessage("");
    requestInviteSiteUser(
      client,
      { email, displayName, isOrgAdmin, organizationId },
      () => {
        onSuccess(true);
      },
      (err) => setErrorMessage(err)
    );
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <FormDialog
          open={open}
          cancel={() => {
            close(false);
          }}
          errorMessage={errorMessage}
          saveAndClose={() => saveAndClose(client)}
          saveIsDisabled={saveIsDisabled}
          saveButtonLabel="Invite User"
        >
          <TextField
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            label="E-mail"
            variant="outlined"
            sx={{ minWidth: 350 }}
            required
            color="primary"
          />
          <TextField
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
            value={displayName}
            label="Display Name"
            variant="outlined"
            sx={{ minWidth: 350 }}
            required
            color="primary"
          />
          <Stack direction="row" alignItems="center">
            <Checkbox
              checked={isOrgAdmin}
              onChange={(event) => {
                setIsOrgAdmin(event.target.checked);
              }}
            />
            <Typography>Organization Administrator</Typography>
          </Stack>
        </FormDialog>
      )}
    </ApolloConsumer>
  );
};
