import React from "react";
import { Box, Button, Dialog, DialogTitle, Typography } from "@mui/material";

interface QuestionDialogButton {
  key: string;
  text: string;
  prominent: boolean;
  destructive: boolean;
  onClick: () => void;
}

interface Props {
  open: boolean;
  title: string;
  message: string;
  buttons: QuestionDialogButton[];
  cancel: () => void;
}

/**
 * This dialog is to ask the user a question (e.g. Are you sure?) to confirm an action
 * @param open if true, the dialog will be shown
 * @param title text shown in the title
 * @param message text shown in the main part of the dialog
 * @param buttons buttons, shown left to right, under the question.
 * @param cancel a function to call if the dialog is cancelled. It should typically set open to false.
 */
export const QuestionDialog: React.FC<Props> = ({ open, title, message, buttons, cancel }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        cancel();
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Box flexDirection="column" sx={{ my: 2, mx: 2, display: "flex", gap: 2 }}>
        <Typography>{message}</Typography>
        <Box flexDirection="row" sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
          {buttons.map((b) => (
            <Button
              key={b.key}
              variant={b.prominent ? "contained" : "outlined"}
              color={b.destructive ? "error" : "primary"}
              onClick={() => b.onClick()}
            >
              {b.text}
            </Button>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};
