import React from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";

import { selectViewDemoTemplate, setTemplateFeatureSets } from "../store/viewDemoTemplateSlice";
import { DemoContentCell } from "./demoContentCell";

import { SuccessResponse } from "../../../../generated/graphql";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { showSnackbarError } from "../../../../components/appSnackbarSlice";

interface Props {
  demoTemplateId: number;
  obj: any;
  readOnly: boolean;
  requestUpdateDemoTemplateFeatureSetRecommended: (
    client: ApolloClient<object>,
    variables: { demoTemplateFeatureSetId: number; recommended: boolean },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestRemoveDemoTemplateFeatureSet: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; demoTemplateFeatureSetId: number },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
}

export const FeatureSetCell: React.FC<Props> = ({
  demoTemplateId,
  obj,
  readOnly,
  requestUpdateDemoTemplateFeatureSetRecommended,
  requestRemoveDemoTemplateFeatureSet,
}) => {
  const dispatch = useAppDispatch();
  const viewDemoTemplateState = useAppSelector(selectViewDemoTemplate);

  const reportError = (message: string) => {
    dispatch(showSnackbarError(message));
  };

  const updateFeatureSetRecommended = async (
    client: ApolloClient<object>,
    demoTemplateFeatureSetId: string,
    recommended: boolean
  ) => {
    const message = `Error updating feature recommended`;
    const index = viewDemoTemplateState.templateFeatureSets.findIndex((tfs) => tfs.id === demoTemplateFeatureSetId);

    if (index < 0) {
      reportError(message);
      return;
    }
    let templateFeatureSetsCopy = viewDemoTemplateState.templateFeatureSets.map((tfs) =>
      tfs.id === demoTemplateFeatureSetId
        ? {
            __typename: tfs.__typename,
            id: tfs.id,
            order: tfs.order,
            recommended,
            featureSet: tfs.featureSet,
          }
        : tfs
    );
    return await requestUpdateDemoTemplateFeatureSetRecommended(
      client,
      {
        demoTemplateFeatureSetId: parseInt(demoTemplateFeatureSetId),
        recommended,
      },
      () => {
        dispatch(setTemplateFeatureSets(templateFeatureSetsCopy));
      },
      () => {
        reportError(message);
        return message;
      }
    );
  };

  const removeCell = async (client: ApolloClient<object>, demoTemplateFeatureSetId: string) => {
    const message = `Error removing feature`;
    const index = viewDemoTemplateState.templateFeatureSets.findIndex((x) => x.id === demoTemplateFeatureSetId);

    if (index < 0) {
      reportError(message);
      return;
    }

    return await requestRemoveDemoTemplateFeatureSet(
      client,
      {
        demoTemplateId,
        demoTemplateFeatureSetId: parseInt(demoTemplateFeatureSetId),
      },
      () => {
        let templateFeatureSetsCopy = [...viewDemoTemplateState.templateFeatureSets];
        templateFeatureSetsCopy.splice(index, 1);
        dispatch(setTemplateFeatureSets(templateFeatureSetsCopy));
      },
      () => {
        reportError(message);
        return message;
      }
    );
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <DemoContentCell
          obj={obj}
          icon={<ViewQuiltIcon sx={{ mt: 0.5, mr: 1 }} />}
          setRecommended={(id, recommended) => updateFeatureSetRecommended(client, id, recommended)}
          removeCell={(id) => removeCell(client, id)}
          readOnly={readOnly}
        />
      )}
    </ApolloConsumer>
  );
};
