import { Stack } from "@mui/material";
import React from "react";
import { OrgAdminChip, TeamAdminChip } from "./adminChips";

interface Props {
  title: string;
  isAdmin: boolean;
  isOrgAdmin: boolean;
}

export const TitleWithAdminChip: React.FC<Props> = ({ title, isAdmin, isOrgAdmin }) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{ flexGrow: 1 }}>
      <div style={{ flexGrow: 1 }}>{title}</div>
      {isOrgAdmin ? <OrgAdminChip /> : isAdmin ? <TeamAdminChip /> : <React.Fragment />}
    </Stack>
  );
};
