export const viewDemoPageLeftDrawerWidth = 240;
export const viewDemoPageRightDrawerWidth = (open: boolean) => {
  return open ? viewDemoPageLeftDrawerWidth : 50;
};

export const viewDemoPageAnimationProperties = {
  transition: "ease-out",
  transitionDuration: "100ms",
  transitionProperty: "width",
};
