import React from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";

interface Props {
  disabled: boolean;
  onClick: () => void;
}

export const AddConversationMenuItem: React.FC<Props> = ({ disabled, onClick }) => {
  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      <ListItemIcon>
        <AddBoxRoundedIcon fontSize="small" color={disabled ? "disabled" : "primary"} />
      </ListItemIcon>
      <ListItemText>Add Conversation</ListItemText>
    </MenuItem>
  );
};
