import React from "react";

import { SiteAdminOrgSidebar } from "../siteAdmin/siteAdminOrgSidebar";
import { LayoutLeftNav } from "./layoutLeftNav";

interface Props {
  chosen?: string;
  organizationId: string;
}

export const SiteAdminOrgLayout: React.FC<Props> = ({ children, chosen, organizationId }) => {
  return (
    <LayoutLeftNav sidebar={<SiteAdminOrgSidebar chosen={chosen} organizationId={organizationId} />}>
      {children}
    </LayoutLeftNav>
  );
};
