import React from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";

import { useAppSelector } from "../../../../../store/hooks";
import { selectViewDemo } from "../../viewDemoSlice";
import { shouldSaveViewDemoState } from "../../viewDemoSliceUtils";

interface Props {
  onClick: () => void;
}

export const DemoSaveMenuItem: React.FC<Props> = ({ onClick }) => {
  const viewDemoState = useAppSelector(selectViewDemo);

  return (
    <MenuItem disabled={!shouldSaveViewDemoState(viewDemoState)} onClick={onClick}>
      <ListItemIcon>
        <CloudUploadRoundedIcon
          fontSize="small"
          color={shouldSaveViewDemoState(viewDemoState) ? "primary" : "disabled"}
        />
      </ListItemIcon>
      <ListItemText>Save</ListItemText>
    </MenuItem>
  );
};
