import React, { useContext } from "react";
import { ApolloClient } from "@apollo/client";
import { requestUpdateUserDemoInTeamLibrary } from "../../../../../generated/graphqlWrappers";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { ViewDemoContext } from "../../viewDemoBaseReduxUtils";
import { demoSuccessfullyChangedInTeamLibrary } from "../../viewDemoSlice";

interface Props {
  onTeamLibrarySuccess: (inTeamLibrary: boolean) => void;
  children: (doChangeInTeamLibrary: (client: ApolloClient<object>) => void) => React.ReactChild | null;
}

export const DemoTeamLibraryMenuHandler: React.FC<Props> = ({ onTeamLibrarySuccess, children }) => {
  const context = useContext(ViewDemoContext);
  const viewDemoState = useAppSelector(context.selectState);
  const dispatch = useAppDispatch();
  const actions = context.actions;

  const doChangeInTeamLibrary = async (apolloClient: ApolloClient<object>): Promise<void> => {
    dispatch(actions.setErrorMessage(""));
    const newInTeamLibrary = !viewDemoState.inTeamLibrary;
    await requestUpdateUserDemoInTeamLibrary(
      apolloClient,
      { demoId: parseInt(viewDemoState.demoId), inTeamLibrary: newInTeamLibrary },
      (response) => {
        dispatch(demoSuccessfullyChangedInTeamLibrary(newInTeamLibrary));
        onTeamLibrarySuccess(true);
      },
      (err) => dispatch(actions.setErrorMessage(err))
    );
  };

  return <React.Fragment>{children(doChangeInTeamLibrary)}</React.Fragment>;
};
