import React, { useState } from "react";

import { TextField } from "@mui/material";
import { useCreateSiteUserMutation } from "../../generated/graphql";
import { gqlMutateWithHook } from "../../util/gqlHelpers";
import { FormDialog } from "../../components/dialogs/formDialog";
import { isValidEmail } from "../../util/dataValidators";

interface Props {
  organizationId: number;
  open: boolean;
  onClose: (added: boolean) => void;
}

export const CreateUserSiteAdminDialog: React.FC<Props> = ({ organizationId, open, onClose }) => {
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [createSiteUser] = useCreateSiteUserMutation();

  const close = (added: boolean) => {
    setErrorMessage("");
    setEmail("");
    setDisplayName("");
    setPassword("");
    setPasswordConfirm("");
    onClose(added);
  };

  const saveIsDisabled = () => {
    return (
      !isValidEmail(email) ||
      displayName === "" ||
      password === "" ||
      passwordConfirm === "" ||
      password !== passwordConfirm
    );
  };

  const saveAndClose = async () => {
    await save(close);
  };

  const save = async (onSuccess: (added: boolean) => void) => {
    const msg = "Error creating user";
    setErrorMessage("");
    await gqlMutateWithHook(
      createSiteUser,
      { email, displayName, password, organizationId },
      "createSiteUser",
      () => {
        onSuccess(true);
      },
      () => {
        setErrorMessage(msg);
        return msg;
      }
    );
  };

  return (
    <FormDialog
      open={open}
      cancel={() => {
        close(false);
      }}
      errorMessage={errorMessage}
      saveAndClose={saveAndClose}
      saveIsDisabled={saveIsDisabled}
      saveButtonLabel="Add User"
    >
      <TextField
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        value={email}
        label="E-mail"
        variant="outlined"
        sx={{ minWidth: 350 }}
        required
        color="primary"
      />
      <TextField
        onChange={(e) => {
          setDisplayName(e.target.value);
        }}
        value={displayName}
        label="Display Name"
        variant="outlined"
        sx={{ minWidth: 350 }}
        required
        color="primary"
      />
      <TextField
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        value={password}
        label="Password"
        variant="outlined"
        type="password"
        sx={{ minWidth: 350 }}
        required
        color="primary"
      />
      <TextField
        onChange={(e) => {
          setPasswordConfirm(e.target.value);
        }}
        value={passwordConfirm}
        label="Confirm Password"
        variant="outlined"
        type="password"
        sx={{ minWidth: 350 }}
        required
        color="primary"
      />
    </FormDialog>
  );
};
