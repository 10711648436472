import { configureStore } from "@reduxjs/toolkit";
import snackbarReducers from "../components/appSnackbarSlice";
import userAppBarReducers from "../components/userAppBarSlice";
import myDemosReducers from "../pages/userApp/myDemosSlice";
import newDemoReducers from "../pages/userApp/newDemo/newDemoSlice";
import viewDemoReducers from "../pages/userApp/viewDemo/viewDemoSlice";
import viewSharedDemoReducers from "../pages/userApp/viewDemo/viewSharedDemoSlice";
import viewDemoTemplateReducers from "../pages/shared/viewDemoTemplate/store/viewDemoTemplateSlice";
import reactionBarReducers from "../components/reactions/reactionBarSlice";
import teamTemplatesReducers from "../pages/userApp/team/teamDemoTemplatesSlice";
import resourcesReducers from "../pages/userApp/resourcesSlice";
import teamContentNotificationReducers from "../pages/userApp/team/teamContent/teamContentNotificationsSlice";

export const store = configureStore({
  reducer: {
    myDemos: myDemosReducers,
    newDemo: newDemoReducers,
    viewDemo: viewDemoReducers,
    viewSharedDemo: viewSharedDemoReducers,
    viewDemoTemplate: viewDemoTemplateReducers,
    snackbar: snackbarReducers,
    reactionBar: reactionBarReducers,
    teamTemplates: teamTemplatesReducers,
    resources: resourcesReducers,
    userAppBar: userAppBarReducers,
    teamContentNotification: teamContentNotificationReducers,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
