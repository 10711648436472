import { SharedUserDemoData, UserDemoData } from "../../util/demoTypes";

export type SortColumnDescriptor = { index: number; ascending: boolean };

export const compareOrder = (fn: (a: any, b: any) => number, ascending: boolean) => {
  if (ascending) {
    return fn;
  } else {
    return (a: any, b: any) => fn(b, a);
  }
};

export const compareUserDisplayName = (a: SharedUserDemoData[0], b: SharedUserDemoData[0]) => {
  if (a.user.displayName < b.user.displayName) {
    return -1;
  }
  if (a.user.displayName > b.user.displayName) {
    return 1;
  }
  return 0;
};

export const compareName = (a: UserDemoData[0] | SharedUserDemoData[0], b: UserDemoData[0] | SharedUserDemoData[0]) => {
  const aLowerName = a.name.toLocaleLowerCase();
  const bLowerName = b.name.toLocaleLowerCase();
  return aLowerName.localeCompare(bLowerName);
};

export const compareModifiedDate = (
  a: UserDemoData[0] | SharedUserDemoData[0],
  b: UserDemoData[0] | SharedUserDemoData[0]
) => {
  const aDate = new Date(a.modifiedDate);
  const bDate = new Date(b.modifiedDate);
  if (aDate < bDate) {
    return -1;
  }
  if (aDate > bDate) {
    return 1;
  }
  return 0;
};
