import React from "react";
import { ConfirmDestructiveActionDialog } from "../../../components/dialogs/confirmDestructiveActionDialog";

interface Props {
  open: boolean;
  closeDialog: (confirmed: boolean) => void;
}

export const ConfirmOverwriteTemplateSectionDialog: React.FC<Props> = ({ open, closeDialog }) => {
  return (
    <ConfirmDestructiveActionDialog
      open={open}
      message="Are you sure you want to overwrite the template version of this section? This cannot be undone."
      yesText="Overwrite Template Section"
      closeDialog={closeDialog}
    />
  );
};
