import React from "react";

import { useHistory, useParams } from "react-router";
import { EditTeamDemoContentSiteAdmin } from "../../../../components/siteAdmin/editTeamDemoContentSiteAdmin";
import { useSiteOpeningQuery, useUpdateSiteOpeningMutation } from "../../../../generated/graphql";

interface Params {
  openingId: string;
}

export const EditTeamOpeningSiteAdminPage: React.FC = () => {
  let history = useHistory();
  let { openingId } = useParams<Params>();
  const [updateSiteOpening] = useUpdateSiteOpeningMutation();

  return (
    <EditTeamDemoContentSiteAdmin
      id={parseInt(openingId)}
      contentType="Opening"
      idArgName="openingId"
      query={useSiteOpeningQuery}
      update={updateSiteOpening}
      onClose={(teamId) => {
        history.push(`/site-admin/team/${teamId}/openings`);
      }}
      queryDataProperty="siteOpening"
      resultDataProperty="updateSiteOpening"
    ></EditTeamDemoContentSiteAdmin>
  );
};
