import React, { createElement } from "react";
import { RemirrorJSON } from "@remirror/core";
import { MarkMap, TextHandler } from "@remirror/react";
import postcss from "postcss";
import transform from "css-to-react-native";
import postcssJs from "postcss-js";

import { CSSProperties } from "react";
import { kebabCase } from "remirror";

export const TextColor: React.FC<{
  node: RemirrorJSON;
  color: string;
  markMap: MarkMap;
}> = (props) => {
  if (!props.children) {
    return null;
  }
  return createElement(`span`, { style: { color: props.color } }, props.children);
};

export const TextHighlight: React.FC<{
  node: RemirrorJSON;
  highlight: string;
  markMap: MarkMap;
}> = (props) => {
  if (!props.children) {
    return null;
  }
  return createElement(`span`, { style: { backgroundColor: props.highlight } }, props.children);
};

function cssToJss(cssText: string): CSSProperties {
  const root = postcss.parse(cssText);
  const cssObject = postcssJs.objectify(root);
  return transform(Object.entries(cssObject));
}

// Fix bug with paragraphs handling Nodes and textAlign
// based on this issue: https://github.com/remirror/remirror/issues/1285
export function ParagraphHandler({
  node: { attrs = {} },
  children,
}: React.PropsWithChildren<{
  node: RemirrorJSON;
}>) {
  const { style = "", ...rest } = attrs;
  const restAttrs = Object.entries(rest).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [`data-${kebabCase(key)}`]: value,
    };
  }, {});

  // Fix bug handling textAlign
  const parsedStyle = cssToJss(String(style)) || undefined;
  parsedStyle["textAlign"] = rest["nodeTextAlignment"] as "left" | "center" | "right";
  const p = (
    <p style={parsedStyle} {...restAttrs}>
      {children}
    </p>
  );
  return p;
}

// Fix bug in Heading that doesn't handle textAlign
export const HeadingHandler: React.FC<{
  node: RemirrorJSON;
  markMap: MarkMap;
}> = (props) => {
  const content = props.node.content;
  if (!content) {
    return null;
  }

  const children = content.map((node, ii) => {
    return <TextHandler key={ii} {...{ ...props, node }} />;
  });

  const level = (props.node.attrs?.level as number) ?? 1;

  // Fix bug handling textAlign
  let attrs = null;
  if (props.node.attrs && props.node.attrs.nodeTextAlignment) {
    attrs = { style: { textAlign: props.node.attrs.nodeTextAlignment } };
  }
  return createElement(`h${level}`, attrs, children);
};
