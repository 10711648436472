import React from "react";

import { List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { DemoTemplate } from "../../generated/graphql";

interface Props {
  demoTemplates: DemoTemplate[];
}

export const SiteAdminDemoTemplateSidebar: React.FC<Props> = ({ demoTemplates }) => {
  return (
    <Stack>
      {demoTemplates.length === 0 ? (
        <React.Fragment />
      ) : (
        <React.Fragment>
          <Typography variant="h6">Stakeholder Templates</Typography>
          <List dense={true}>
            {demoTemplates.map((template) => {
              return (
                <ListItem key={template.id}>
                  <Link to={`/site-admin/template/${template.id}`} style={{ color: "inherit" }}>
                    <ListItemText primary={template.name} />
                  </Link>
                </ListItem>
              );
            })}
          </List>
        </React.Fragment>
      )}
    </Stack>
  );
};
