import React, { useEffect, useState } from "react";

import { Box, Drawer, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import { AppErrorPage } from "../../components/appError";
import { AppLoadingPage } from "../../components/appLoadingPage";
import { AppLayoutNoSidebar } from "../../components/layouts/appLayoutNoSidebar";
import { AppSnackbar } from "../../components/appSnackbar";
import { useUserDemoQuery } from "../../generated/graphql";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import {
  clearViewDemoState,
  initializeViewDemoState,
  selectViewDemo,
  setLastTappedDemoNav,
  toggleRightDrawer,
  viewDemoSlice,
} from "./viewDemo/viewDemoSlice";
import { DemoNav } from "./viewDemo/demoNav";
import { ResourcesNav } from "./viewDemo/resourcesNav";
import { DemoContentEditView } from "./viewDemo/demoContentEditView";
import { UpdatedTemplateContentActions } from "./viewDemo/updatedTemplateContentActions";
import { RemoveSectionAction } from "./viewDemo/removeSectionAction";
import { DemoErrorMessage } from "./viewDemo/demoErrorMessage";
import { DemoPresentationView } from "./viewDemo/demoPresentationView";
import { ReactionLoader } from "../../components/reactions/reactionLoader";
import { demoContentKey } from "../../util/demoTypes";
import { AppLogo } from "../../components/appLogo";
import { OverwriteTemplateSectionAction } from "./viewDemo/overwriteTemplateSectionAction";
import { DemoMenu } from "./viewDemo/menu/demoMenu";
import { PresentationMenu } from "./viewDemo/menu/presentationMenu";
import { DemoViewMode, ViewDemoContext, ViewDemoContextInterface } from "./viewDemo/viewDemoBaseReduxUtils";
import { shouldSaveViewDemoState } from "./viewDemo/viewDemoSliceUtils";
import {
  viewDemoPageAnimationProperties,
  viewDemoPageLeftDrawerWidth,
  viewDemoPageRightDrawerWidth,
} from "./viewDemo/viewDemoPageUtils";

interface Params {
  demoId: string;
}

export const ViewDemoPage: React.FC = () => {
  let { demoId } = useParams<Params>();

  const [isWide, setIsWide] = useState(Cookies.get("viewDemo.isWide") === "true");
  const viewDemoState = useAppSelector(selectViewDemo);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(
    () => {
      if (viewDemoState.viewMode === DemoViewMode.EDIT) {
        window.scrollTo({ top: 0 });
      } else {
        dispatch(setLastTappedDemoNav(demoContentKey(viewDemoState.opening.content)));
      }
    },
    // We only want to change the scroll here when changing modes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewDemoState.viewMode]
  );

  const { data, error, loading } = useUserDemoQuery({
    variables: { demoId: parseInt(demoId) },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }

  if (loading || !data) {
    if (viewDemoState.initialized) {
      dispatch(clearViewDemoState());
    }
    return <AppLoadingPage />;
  }

  if (!viewDemoState.initialized) {
    dispatch(initializeViewDemoState(data));
    return <AppLoadingPage />;
  }

  const viewDemoContext: ViewDemoContextInterface = {
    selectState: selectViewDemo,
    actions: viewDemoSlice.actions,
  };

  const onToggleRightDrawer = () => {
    dispatch(toggleRightDrawer());
  };

  return (
    <ViewDemoContext.Provider value={viewDemoContext}>
      <AppLayoutNoSidebar>
        <Drawer
          variant="permanent"
          sx={{
            width: viewDemoPageLeftDrawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": { width: viewDemoPageLeftDrawerWidth, boxSizing: "border-box" },
          }}
        >
          <AppLogo />
          <DemoNav />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Stack sx={{ position: "sticky", top: 0, zIndex: 101, backgroundColor: "#eee" }}>
            <Toolbar>
              <DemoMenu
                onDuplicateDemoSuccess={(demoId) => {
                  history.replace(`/view-demo/${demoId}`);
                }}
                onTeamLibrarySuccess={(inTeamLibrary) => {}}
                inTeamLibary={viewDemoState.inTeamLibrary}
                teamFeatureSets={data.userDemo.demoTemplate?.team.featureSets ?? []}
                teamConversations={data.userDemo.demoTemplate?.team.conversations ?? []}
              />
              <PresentationMenu
                isWide={isWide}
                setIsWide={(isWide) => {
                  Cookies.set("viewDemo.isWide", isWide ? "true" : "false");
                  setIsWide(isWide);
                }}
              />
              <Typography sx={{ flexGrow: 1 }}></Typography>
              <DemoErrorMessage />
              <Typography>{shouldSaveViewDemoState(viewDemoState) ? "(unsaved)" : ""}</Typography>
            </Toolbar>
          </Stack>
          <ReactionLoader>
            {viewDemoState.viewMode === DemoViewMode.EDIT ? (
              <DemoContentEditView name={data.userDemo.name} />
            ) : (
              <DemoPresentationView name={data.userDemo.name} isWide={isWide} />
            )}
          </ReactionLoader>
        </Box>
        <Drawer
          variant="permanent"
          anchor="right"
          sx={{
            ...viewDemoPageAnimationProperties,
            flexShrink: 0,
            backgroundColor: "#eee",
            width: viewDemoPageRightDrawerWidth(viewDemoState.rightDrawerOpen),
            "& .MuiDrawer-paper": {
              ...viewDemoPageAnimationProperties,
              width: viewDemoPageRightDrawerWidth(viewDemoState.rightDrawerOpen),
              borderLeft: viewDemoState.rightDrawerOpen ? "solid 1px lightgray" : "none",
              boxSizing: "border-box",
              backgroundColor: "inherit",
            },
          }}
        >
          <Stack sx={{ m: 2 }}>
            <Stack alignItems="flex-end" sx={{ ml: 4 }}>
              <IconButton
                onClick={onToggleRightDrawer}
                size="small"
                sx={{ backgroundColor: "#ddd", "&:hover": { backgroundColor: "lightgray" } }}
              >
                {viewDemoState.rightDrawerOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Stack>
            {viewDemoState.rightDrawerOpen ? (
              <React.Fragment>
                {viewDemoState.viewMode === DemoViewMode.EDIT ? (
                  <React.Fragment>
                    <UpdatedTemplateContentActions />
                    <RemoveSectionAction />
                    <OverwriteTemplateSectionAction />
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                <ResourcesNav teamId={data.userDemo.demoTemplate?.team.id ?? "-1"} />
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </Stack>
        </Drawer>
        <AppSnackbar />
      </AppLayoutNoSidebar>
    </ViewDemoContext.Provider>
  );
};
