import React from "react";

import { useHistory, useParams } from "react-router";
import { useSiteTeamResourcesQuery } from "../../../../generated/graphql";
import { ViewTeamContentSiteAdminPage } from "../../../../components/siteAdmin/viewTeamContentSiteAdmin";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface Params {
  teamId: string;
}

export const ViewTeamResourcesSiteAdminPage: React.FC = () => {
  let { teamId } = useParams<Params>();
  const history = useHistory();

  return (
    <ViewTeamContentSiteAdminPage
      teamId={teamId}
      query={useSiteTeamResourcesQuery}
      chosen="Resources"
      getObjects={(data, categoryId) => {
        return data.resourceCategories.filter((c: any) => c.id === categoryId).flatMap((rc: any) => rc.resources);
      }}
      dataDescription="resources"
      title="Resource"
      newContentPath="new-resource"
      onClick={(id) => {
        history.push(`/site-admin/resource/${id}`);
      }}
      rowLabelProperty="name"
      filterItems={(team) => {
        return team.resourceCategories;
      }}
      filterLabel="Category"
      filterError={
        <React.Fragment>
          <Typography sx={{ mt: 2 }}>You cannot create Resources until you create Resource Categories.</Typography>
          <Typography>
            <Link to={`/site-admin/team/${teamId}/resource-categories`}>View Resource Categories</Link>
          </Typography>
        </React.Fragment>
      }
    />
  );
};
