import { ApolloClient } from "@apollo/client";
import React from "react";
import { SuccessResponse } from "../../../../generated/graphql";
import { ConversationCell } from "./conversationCell";
import { FeatureSetCell } from "./featureSetCell";

interface Props {
  demoTemplateId: string;
  obj: any;
  index: number;
  readOnly: boolean;
  requestUpdateDemoTemplateConversationRecommended: (
    client: ApolloClient<object>,
    variables: { demoTemplateConversationId: number; recommended: boolean },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestRemoveDemoTemplateConversation: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; demoTemplateConversationId: number },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestUpdateDemoTemplateFeatureSetRecommended: (
    client: ApolloClient<object>,
    variables: { demoTemplateFeatureSetId: number; recommended: boolean },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
  requestRemoveDemoTemplateFeatureSet: (
    client: ApolloClient<object>,
    variables: { demoTemplateId: number; demoTemplateFeatureSetId: number },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
}

export const DemoTemplateContentCellNode: React.FC<Props> = ({
  demoTemplateId,
  obj,
  index,
  readOnly,
  requestUpdateDemoTemplateConversationRecommended,
  requestRemoveDemoTemplateConversation,
  requestUpdateDemoTemplateFeatureSetRecommended,
  requestRemoveDemoTemplateFeatureSet,
}) => {
  if (obj.__typename === "DemoTemplateFeatureSetForTeam") {
    return (
      <FeatureSetCell
        obj={{
          ...obj.featureSet,
          order: obj.order,
          recommended: obj.recommended,
          parentId: obj.id,
          tableIndex: index,
        }}
        demoTemplateId={parseInt(demoTemplateId)}
        readOnly={readOnly}
        requestUpdateDemoTemplateFeatureSetRecommended={requestUpdateDemoTemplateFeatureSetRecommended}
        requestRemoveDemoTemplateFeatureSet={requestRemoveDemoTemplateFeatureSet}
      />
    );
  } else {
    return (
      <ConversationCell
        obj={{
          ...obj.conversation,
          order: obj.order,
          recommended: obj.recommended,
          parentId: obj.id,
          tableIndex: index,
        }}
        demoTemplateId={parseInt(demoTemplateId)}
        readOnly={readOnly}
        requestUpdateDemoTemplateConversationRecommended={requestUpdateDemoTemplateConversationRecommended}
        requestRemoveDemoTemplateConversation={requestRemoveDemoTemplateConversation}
      />
    );
  }
};
