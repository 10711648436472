import React from "react";
import { showSnackbarError } from "../../../../../components/appSnackbarSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { DemoContentIdName } from "../../../../../util/demoTypes";
import { ConversationPicker } from "../../conversationPicker";
import { addConversations, selectViewDemo, setConversationPickerOpen } from "../../viewDemoSlice";
import { getTeamConversationsNotIncludedInDemo } from "../../viewDemoUtils";

interface Props {
  teamConversations: DemoContentIdName[];
  children: (disabled: boolean, onConversationAdd: () => void) => React.ReactChild | null;
}

export const AddConversationMenuHandler: React.FC<Props> = ({ teamConversations, children }) => {
  const viewDemoState = useAppSelector(selectViewDemo);
  const dispatch = useAppDispatch();

  const teamConversationsNotIncluded = getTeamConversationsNotIncludedInDemo(teamConversations, viewDemoState);

  const conversationPickerOnClose = async (convList?: DemoContentIdName[]): Promise<void> => {
    dispatch(setConversationPickerOpen(false));
  };

  return (
    <React.Fragment>
      {children(teamConversationsNotIncluded.length === 0, () => {
        dispatch(setConversationPickerOpen(true));
      })}
      <ConversationPicker
        items={teamConversationsNotIncluded}
        open={viewDemoState.conversationPickerOpen}
        onClose={conversationPickerOnClose}
        demoId={viewDemoState.demoId}
        onError={(message) => {
          dispatch(showSnackbarError(message));
        }}
        onPicked={(userConversationsForDemo) => {
          dispatch(addConversations(userConversationsForDemo));
        }}
      />
    </React.Fragment>
  );
};
