import React from "react";
import { useParams } from "react-router-dom";
import { useUserOpeningQuery } from "../../../generated/graphql";
import { requestUpdateOpening } from "../../../generated/graphqlWrappers";
import { TeamContentPageLayout } from "./teamContent/teamContentPageLayout";

interface Params {
  teamId: string;
  openingId: string;
}

export const TeamOpeningPage: React.FC = () => {
  const { teamId, openingId } = useParams<Params>();
  return (
    <TeamContentPageLayout
      teamId={teamId}
      contentType="Opening"
      chosen="Openings"
      contentId={openingId}
      useUserContentQuery={useUserOpeningQuery}
      requestUpdateContent={requestUpdateOpening}
    />
  );
};
