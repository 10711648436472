import React from "react";

import BarChartIcon from "@mui/icons-material/BarChart";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import AssignmentIcon from "@mui/icons-material/Assignment";

import GroupsIcon from "@mui/icons-material/Groups";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import StopIcon from "@mui/icons-material/Stop";

import { NavSidebar } from "./navSidebar";

interface Props {
  teamId: string;
  teamName: string;
  chosen?: string;
}

export const AppTeamSidebar: React.FC<Props> = ({ teamId, teamName, chosen }) => {
  const sidebar = [
    { text: "My Demos", url: "/", icon: <DesktopMacIcon /> },
    { text: "Shared Demos", url: "/shared-demos", icon: <ScreenShareIcon /> },
    { text: "Resources", url: "/resources", icon: <AssignmentIcon /> },
    { text: teamName },
    { text: "Team", url: `/team/${teamId}`, icon: <GroupsIcon /> },
    { text: "Dashboard", url: `/team/${teamId}/dashboard`, icon: <BarChartIcon /> },
    { text: "Demo Content" },
    { text: "Stakeholder Templates", url: `/team/${teamId}/templates`, icon: <ContentCopyIcon /> },
    { text: "Openings", url: `/team/${teamId}/openings`, icon: <PlayArrowIcon /> },
    { text: "Features", url: `/team/${teamId}/feature-sets`, icon: <ViewQuiltIcon /> },
    { text: "Conversations", url: `/team/${teamId}/conversations`, icon: <QuestionAnswerIcon /> },
    { text: "Closes", url: `/team/${teamId}/closes`, icon: <StopIcon /> },
  ];

  return <NavSidebar chosen={chosen} sidebar={sidebar} />;
};
