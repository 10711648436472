import React from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface Props {
  onClick: () => void;
}

export const DemoSaveAsMenuItem: React.FC<Props> = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <ContentCopyIcon fontSize="small" color="primary" />
      </ListItemIcon>
      <ListItemText>Save As...</ListItemText>
    </MenuItem>
  );
};
