import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserResourcesByTeamQuery } from "../../generated/graphql";
import { RootState } from "../../store/store";

export type UserResourcesByTeamData = UserResourcesByTeamQuery["userResourcesByTeam"][0];
type ResourceCategoriesData = UserResourcesByTeamData["resourceCategories"];
type ResourceArgument = { id: string; teamId: string; categoryId: string; name: string; originalCategoryId?: string };
type ResourceCategoryArgument = { id: string; teamId: string; name: string };
export interface ResourcesState {
  initialized: boolean;
  data: UserResourcesByTeamData[];
  teamIndex: number;
  categoryIndex: number;
}

const initialState: ResourcesState = {
  initialized: false,
  data: [],
  teamIndex: 0,
  categoryIndex: 0,
};

export const selectedResourceCategories = (rs: ResourcesState): ResourceCategoriesData => {
  if (rs.teamIndex < rs.data.length) {
    return rs.data[rs.teamIndex].resourceCategories;
  }
  return [];
};

export const selectedResources = (
  rs: ResourcesState
): UserResourcesByTeamData["resourceCategories"][0]["resources"] => {
  const categories = selectedResourceCategories(rs);
  if (rs.categoryIndex < categories.length) {
    return categories[rs.categoryIndex].resources;
  }
  return [];
};

const addResource = (state: ResourcesState, r: ResourceArgument, pos?: number) => {
  const teams = [...state.data];
  const ti = teams.findIndex((t) => t.id === r.teamId);
  if (ti >= 0) {
    const categories = teams[ti].resourceCategories;
    const ci = categories.findIndex((c) => c.id === r.categoryId);
    if (ci >= 0) {
      let newResources = [...categories[ci].resources];
      newResources.splice(pos ?? newResources.length, 0, r);
      teams[ti].resourceCategories[ci].resources = newResources;
    }
    state.data = teams;
  }
};

const addCategory = (state: ResourcesState, rc: ResourceCategoryArgument) => {
  const teams = [...state.data];
  const ti = teams.findIndex((t) => t.id === rc.teamId);
  if (ti >= 0) {
    const categories = [...teams[ti].resourceCategories, { ...rc, resources: [] }];
    teams[ti].resourceCategories = categories;

    state.data = teams;
  }
};

const removeResource = (state: ResourcesState, r: ResourceArgument): number | undefined => {
  if (!r.originalCategoryId) {
    return;
  }
  const teams = [...state.data];
  const ti = teams.findIndex((t) => t.id === r.teamId);
  if (ti >= 0) {
    const categories = teams[ti].resourceCategories;
    const ci = categories.findIndex((c) => c.id === r.originalCategoryId);
    if (ci >= 0) {
      let newResources = [...categories[ci].resources];
      let ri = newResources.findIndex((rs) => rs.id === r.id);
      if (ri >= 0) {
        newResources.splice(ri, 1);
        teams[ti].resourceCategories[ci].resources = newResources;
        state.data = teams;
        if (r.categoryId === r.originalCategoryId) {
          // This is where the new one should be inserted if an update
          return ri;
        }
      }
    }
  }
  return undefined;
};

export const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {
    clearResourcesState: (state) => {
      state.initialized = false;
      state.data = initialState.data;
      state.teamIndex = initialState.teamIndex;
      state.categoryIndex = initialState.categoryIndex;
    },
    initializeResourcesState: (state, action: PayloadAction<UserResourcesByTeamData[]>) => {
      state.initialized = true;
      state.data = action.payload;
    },
    setTeamIndex: (state, action: PayloadAction<number>) => {
      state.teamIndex = action.payload;
      state.categoryIndex = 0;
    },
    setCategoryIndex: (state, action: PayloadAction<number>) => {
      state.categoryIndex = action.payload;
    },
    resourceAdded: (state, action: PayloadAction<ResourceArgument>) => {
      addResource(state, action.payload);
    },
    resourceUpdated: (state, action: PayloadAction<ResourceArgument>) => {
      const index = removeResource(state, action.payload);
      addResource(state, action.payload, index);
    },
    categoryAdded: (state, action: PayloadAction<ResourceCategoryArgument>) => {
      addCategory(state, action.payload);
    },
  },
});

export const {
  clearResourcesState,
  initializeResourcesState,
  setTeamIndex,
  setCategoryIndex,
  resourceAdded,
  resourceUpdated,
  categoryAdded,
} = resourcesSlice.actions;

export const selectResources = (state: RootState) => state.resources as ResourcesState;

export default resourcesSlice.reducer;
