import React from "react";
import { useParams } from "react-router-dom";
import { useUserOpeningsForTeamQuery } from "../../../generated/graphql";
import { requestCreateOpening, requestUpdateOpeningArchived } from "../../../generated/graphqlWrappers";
import { TeamContentTablePageLayout } from "./teamContent/teamContentTablePageLayout";

interface Params {
  teamId: string;
}

export const TeamOpeningsPage: React.FC = () => {
  const { teamId } = useParams<Params>();

  return (
    <TeamContentTablePageLayout
      teamId={teamId}
      contentType="Opening"
      chosen="Openings"
      useContentListQuery={useUserOpeningsForTeamQuery}
      requestCreateContent={requestCreateOpening}
      requestArchiveContent={requestUpdateOpeningArchived}
    />
  );
};
