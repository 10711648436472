import React from "react";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import CircleIcon from "@mui/icons-material/Circle";

export enum NotificationsFilterMode {
  ALL = "all",
  UNREAD = "unread",
}

interface Props {
  filter: NotificationsFilterMode;
  onChangeMode: (mode: NotificationsFilterMode) => void;
}

export const NotificationsFilter: React.FC<Props> = ({ filter, onChangeMode }) => {
  return (
    <ToggleButtonGroup value={filter} sx={{ mt: 2 }}>
      <ToggleButton
        value={NotificationsFilterMode.ALL}
        size="small"
        onClick={() => {
          onChangeMode(NotificationsFilterMode.ALL);
        }}
      >
        <Tooltip title="All">
          <ListIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton
        value={NotificationsFilterMode.UNREAD}
        size="small"
        onClick={() => {
          onChangeMode(NotificationsFilterMode.UNREAD);
        }}
      >
        <Tooltip title="Unread">
          <CircleIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
