import React from "react";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const AppLogo: React.FC = () => {
  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center">
        <img src="/logo.png" width={26} height={52} alt="SalesNV logo" style={{ marginLeft: 8 }} />
        <Typography variant="h5" sx={{ ml: 1 }}>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            DemoWizard
          </Link>
        </Typography>
      </Stack>
    </React.Fragment>
  );
};
