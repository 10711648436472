import React from "react";

import { SiteAdminTeamSidebar } from "../siteAdmin/siteAdminTeamSidebar";
import { LayoutLeftNav } from "./layoutLeftNav";

interface Props {
  chosen?: string;
  teamId: string;
  rightDrawerWidth?: number;
}

export const SiteAdminTeamLayout: React.FC<Props> = ({ children, chosen, teamId, rightDrawerWidth }) => {
  return (
    <LayoutLeftNav
      sidebar={<SiteAdminTeamSidebar chosen={chosen} teamId={teamId} />}
      rightDrawerWidth={rightDrawerWidth}
    >
      {children}
    </LayoutLeftNav>
  );
};
