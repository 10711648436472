import { createContext } from "react";
import { WritableDraft } from "immer/dist/types/types-external";
import { UserDemoQuery, UserSharedDemoQuery } from "../../../generated/graphql";
import { emptyDemoContent } from "../../../util/demoTypes";
import { EditableDemoContent, makeEditable } from "./viewDemoSliceUtils";
import { viewDemoSlice } from "./viewDemoSlice";
import { DemoContent, demoContentKey, demoContentTypeIdKey } from "../../../util/demoTypes";
import { editorContentWordCount, parseToDocument } from "../../../components/textEditor/textEditorUtils";
import { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import { viewSharedDemoSlice } from "./viewSharedDemoSlice";

type DemoBaseQuery = UserDemoQuery["userDemo"] | UserSharedDemoQuery["userSharedDemo"];
export type ViewDemoStateActions = typeof viewDemoSlice["actions"] | typeof viewSharedDemoSlice["actions"];

export interface ViewDemoContextInterface {
  selectState: (state: RootState) => ViewDemoBaseState;
  actions: ViewDemoStateActions;
}

export const ViewDemoContext = createContext<ViewDemoContextInterface>(null!);

export enum DemoViewMode {
  EDIT = "edit",
  VIEW = "view",
}

export enum DemoScriptMode {
  FULL = "full",
  BULLETED = "bulleted",
}

export enum DemoPlayState {
  NOTSTARTED = "notstarted",
  PLAYING = "playing",
  PAUSED = "paused",
  ENDED = "ended",
}

export interface ViewDemoBaseState {
  initialized: boolean;
  reactionEditCount: number;

  demoId: string;
  demoName: string;
  demoDescription: string;

  inTeamLibrary: boolean;

  teamId: string;
  demoTemplateId?: string;

  opening: EditableDemoContent;
  draggableParts: EditableDemoContent[];
  close: EditableDemoContent;

  wordCounts: { [key: string]: number };
  chosenReactions: ChosenReaction[];

  chosenKey: string;
  lastTappedDemoNav: string;

  viewMode: DemoViewMode;
  scriptMode: DemoScriptMode;

  errorMessage: string;
  rightDrawerOpen: boolean;
  demoPlayState: DemoPlayState;
  demoTimerSeconds: number;
}

export const initialViewDemoBaseState: ViewDemoBaseState = {
  initialized: false,
  reactionEditCount: 0,
  demoId: "",
  demoName: "",
  demoDescription: "",
  inTeamLibrary: false,
  teamId: "",
  chosenKey: "",
  lastTappedDemoNav: "",
  opening: makeEditable(emptyDemoContent),
  draggableParts: [],
  close: makeEditable(emptyDemoContent),
  wordCounts: {},
  chosenReactions: [],
  errorMessage: "",
  viewMode: DemoViewMode.EDIT,
  scriptMode: DemoScriptMode.FULL,
  rightDrawerOpen: true,
  demoPlayState: DemoPlayState.NOTSTARTED,
  demoTimerSeconds: 0,
};

export const forDemoId = (ds: ViewDemoBaseState, forTeam: { __typename?: string; id: string }): string => {
  if (forTeam.__typename === "OpeningForTeam" && ds.opening.content["openingForTeam"].id === forTeam.id) {
    return ds.opening.content.id;
  } else if (forTeam.__typename === "CloseForTeam" && ds.close.content["closeForTeam"].id === forTeam.id) {
    return ds.close.content.id;
  } else {
    for (const dp of ds.draggableParts) {
      if (forTeam.__typename === "FeatureSetForTeam" && dp.content["featureSetForTeam"]?.id === forTeam.id) {
        return dp.content.id;
      }

      if (forTeam.__typename === "ConversationForTeam" && dp.content["conversationForTeam"]?.id === forTeam.id) {
        return dp.content.id;
      }
    }
  }
  return "0";
};

export const demoChosenReactions = (ds: ViewDemoBaseState, data: DemoBaseQuery) => {
  return [
    ...data.featureSetReactions.map((r) => {
      return {
        contentType: "FeatureSetForDemo",
        contentId: forDemoId(ds, r.featureSetForTeam),
        reactionId: r.reaction.id,
        feedbackBody: r?.feedbackBody ?? "",
        edited: false,
      };
    }),
    ...data.conversationReactions.map((r) => {
      return {
        contentType: "ConversationForDemo",
        contentId: forDemoId(ds, r.conversationForTeam),
        reactionId: r.reaction.id,
        feedbackBody: r?.feedbackBody ?? "",
        edited: false,
      };
    }),
    ...data.openingReactions.map((r) => {
      return {
        contentType: "OpeningForDemo",
        contentId: forDemoId(ds, r.openingForTeam),
        reactionId: r.reaction.id,
        feedbackBody: r?.feedbackBody ?? "",
        edited: false,
      };
    }),
    ...data.closeReactions.map((r) => {
      return {
        contentType: "CloseForDemo",
        contentId: forDemoId(ds, r.closeForTeam),
        reactionId: r.reaction.id,
        feedbackBody: r?.feedbackBody ?? "",
        edited: false,
      };
    }),
  ];
};

export const clearViewDemoBaseState = (state: WritableDraft<ViewDemoBaseState>): void => {
  state.initialized = initialViewDemoBaseState.initialized;
  state.reactionEditCount = initialViewDemoBaseState.reactionEditCount;
  state.demoId = initialViewDemoBaseState.demoId;
  state.demoName = initialViewDemoBaseState.demoName;
  state.demoDescription = initialViewDemoBaseState.demoDescription;
  state.inTeamLibrary = initialViewDemoBaseState.inTeamLibrary;
  state.teamId = initialViewDemoBaseState.teamId;
  state.demoTemplateId = initialViewDemoBaseState.demoTemplateId;

  state.chosenKey = initialViewDemoBaseState.chosenKey;
  state.lastTappedDemoNav = initialViewDemoBaseState.lastTappedDemoNav;
  state.opening = initialViewDemoBaseState.opening;
  state.draggableParts = initialViewDemoBaseState.draggableParts;
  state.close = initialViewDemoBaseState.close;
  state.wordCounts = initialViewDemoBaseState.wordCounts;
  state.errorMessage = initialViewDemoBaseState.errorMessage;
  state.viewMode = initialViewDemoBaseState.viewMode;
  state.scriptMode = initialViewDemoBaseState.scriptMode;
  state.rightDrawerOpen = initialViewDemoBaseState.rightDrawerOpen;
  state.demoPlayState = initialViewDemoBaseState.demoPlayState;
  state.demoTimerSeconds = initialViewDemoBaseState.demoTimerSeconds;
};

export const initializeViewDemoBaseState = (state: WritableDraft<ViewDemoBaseState>, demoQuery: DemoBaseQuery) => {
  state.reactionEditCount = 0;
  state.demoId = demoQuery.id;
  state.demoName = demoQuery.name;
  state.demoDescription = demoQuery.description;
  state.inTeamLibrary = demoQuery.inTeamLibrary;
  state.teamId = demoQuery.demoTemplate!.team.id; // TODO: DW-25: move team to top level
  state.demoTemplateId = demoQuery.demoTemplate?.id;

  state.opening = makeEditable(demoQuery.opening);
  state.close = makeEditable(demoQuery.close);
  let sortedDraggableParts = [...demoQuery.featureSets, ...demoQuery.conversations];
  sortedDraggableParts.sort((lhs, rhs) => {
    return lhs.order - rhs.order;
  });
  state.draggableParts = sortedDraggableParts.map((c) => makeEditable(c));

  var draggableWordCounts: { [key: string]: number } = {};
  for (const draggable of sortedDraggableParts) {
    draggableWordCounts[demoContentKey(draggable)] = editorContentWordCount(parseToDocument(draggable.body));
  }

  state.wordCounts = {
    [demoContentKey(state.opening.content)]: editorContentWordCount(parseToDocument(state.opening.content.body)),
    [demoContentKey(state.close.content)]: editorContentWordCount(parseToDocument(state.close.content.body)),
    ...draggableWordCounts,
  };

  state.chosenKey = demoContentKey(state.opening.content);
  state.chosenReactions = demoChosenReactions(state, demoQuery);

  state.initialized = true;
};

export const setChosenKeyBase = (state: WritableDraft<ViewDemoBaseState>, action: PayloadAction<string>) => {
  state.chosenKey = action.payload;
};

export const setLastTappedDemoNavBase = (state: WritableDraft<ViewDemoBaseState>, action: PayloadAction<string>) => {
  state.chosenKey = action.payload;
  state.lastTappedDemoNav = action.payload;
};

export const chooseReactionForContentBase = (
  state: WritableDraft<ViewDemoBaseState>,
  action: PayloadAction<ChosenReaction>
) => {
  const chosenReaction = action.payload;
  if (
    state.chosenReactions.findIndex(
      (cr) =>
        cr.contentType === chosenReaction.contentType &&
        cr.contentId === chosenReaction.contentId &&
        cr.reactionId === chosenReaction.reactionId
    ) < 0
  ) {
    const content = demoStateEditableContentForKey(
      state,
      demoContentTypeIdKey(chosenReaction.contentType, chosenReaction.contentId)
    );
    if (content.content.__typename !== "") {
      state.chosenReactions.splice(0, 0, chosenReaction);
    }
  }
};

export const unChooseReactionForContentBase = (
  state: WritableDraft<ViewDemoBaseState>,
  action: PayloadAction<ChosenReaction>
) => {
  const chosenReaction = action.payload;
  const index = state.chosenReactions.findIndex(
    (cr) =>
      cr.contentType === chosenReaction.contentType &&
      cr.contentId === chosenReaction.contentId &&
      cr.reactionId === chosenReaction.reactionId
  );
  if (index >= 0) {
    state.chosenReactions.splice(index, 1);
  }
};

export const updateReactionFeedbackBase = (
  state: WritableDraft<ViewDemoBaseState>,
  action: PayloadAction<ChosenReaction>
) => {
  const chosenReaction = { ...action.payload, edited: true };
  const index = state.chosenReactions.findIndex(
    (cr) =>
      cr.contentType === chosenReaction.contentType &&
      cr.contentId === chosenReaction.contentId &&
      cr.reactionId === chosenReaction.reactionId
  );
  if (index >= 0) {
    state.chosenReactions.splice(index, 1, chosenReaction);
    state.reactionEditCount += 1;
  }
};

export const demoStateEditsSuccessfullySentBase = (state: ViewDemoBaseState) => {
  state.reactionEditCount = 0;
};

export const demoSuccessfullyChangedInTeamLibraryBase = (state: ViewDemoBaseState, action: PayloadAction<boolean>) => {
  state.inTeamLibrary = action.payload;
};

export const setScriptModeBase = (state: ViewDemoBaseState, action: PayloadAction<DemoScriptMode>) => {
  state.scriptMode = action.payload;
};

export const toggleRightDrawerBase = (state: ViewDemoBaseState) => {
  state.rightDrawerOpen = !state.rightDrawerOpen;
};

export const setErrorMessageBase = (state: ViewDemoBaseState, action: PayloadAction<string>) => {
  state.errorMessage = action.payload;
};

export const setDemoPlayStateBase = (state: ViewDemoBaseState, action: PayloadAction<DemoPlayState>) => {
  state.demoPlayState = action.payload;
  if (action.payload === DemoPlayState.NOTSTARTED) {
    state.demoTimerSeconds = 0;
  }
};

export const demoTimerTickBase = (state: ViewDemoBaseState) => {
  state.demoTimerSeconds = state.demoTimerSeconds + 1;
};

export const demoStateEditableContentForKey = (ds: ViewDemoBaseState, key: string): EditableDemoContent => {
  if (!ds.initialized) {
    return makeEditable(emptyDemoContent);
  }
  if (key === demoContentKey(ds.opening.content)) {
    return ds.opening;
  } else if (key === demoContentKey(ds.close.content)) {
    return ds.close;
  }
  const index = ds.draggableParts.findIndex((dp) => demoContentKey(dp.content) === key);
  return index >= 0 ? ds.draggableParts[index] : makeEditable(emptyDemoContent);
};

export const demoStateChosenEditableContent = (ds: ViewDemoBaseState): EditableDemoContent => {
  return demoStateEditableContentForKey(ds, ds.chosenKey);
};

export const demoStateChosenContent = (ds: ViewDemoBaseState): DemoContent => {
  return demoStateChosenEditableContent(ds).content;
};

export function demoStateEditableContentArray(ds: ViewDemoBaseState): EditableDemoContent[] {
  if (!ds.initialized) {
    return [];
  }

  return [ds.opening, ...ds.draggableParts, ds.close];
}

function demoContentOfType(ds: ViewDemoBaseState, contentType: string): DemoContent[] {
  return ds.draggableParts.filter((c) => c.content.__typename === contentType).map((c) => c.content);
}

export function demoFeatureSets(ds: ViewDemoBaseState): DemoContent[] {
  return demoContentOfType(ds, "FeatureSetForDemo");
}

export function demoConversations(ds: ViewDemoBaseState): DemoContent[] {
  return demoContentOfType(ds, "ConversationForDemo");
}

export function mapDemoStateContent<T>(ds: ViewDemoBaseState, fn: (dc: DemoContent, i: number) => T): T[] {
  return mapDemoStateEditableContent(ds, (edc, i) => {
    return fn(edc.content, i);
  });
}

export function mapDemoStateEditableContent<T>(
  ds: ViewDemoBaseState,
  fn: (dc: EditableDemoContent, i: number) => T
): T[] {
  return demoStateEditableContentArray(ds).map(fn);
}

export function shouldSaveViewDemoStateBase(viewDemoState: ViewDemoBaseState) {
  return viewDemoState.reactionEditCount > 0;
}

export const wordCountForContentKey = (ds: ViewDemoBaseState, key: string): number => {
  return ds.wordCounts[key];
};

export const cumulativeWordCountForContentKey = (ds: ViewDemoBaseState, key: string): number | undefined => {
  let wordCount = wordCountForContentKey(ds, demoContentKey(ds.opening.content));
  if (key === demoContentKey(ds.opening.content)) {
    return wordCount;
  }
  const index = ds.draggableParts.findIndex((dp) => demoContentKey(dp.content) === key);
  if (index < 0) {
    if (key === demoContentKey(ds.close.content)) {
      return demoContentTotalWordCount(ds);
    }
    return undefined;
  }
  for (let i = 0; i <= index; i++) {
    wordCount += wordCountForContentKey(ds, demoContentKey(ds.draggableParts[i].content));
  }
  return wordCount;
};

export const demoContentTotalWordCount = (ds: ViewDemoBaseState): number => {
  var totWordCount = 0;
  for (let key in ds.wordCounts) {
    totWordCount += ds.wordCounts[key];
  }
  return totWordCount;
};

export const tabForScriptMode = (ds: ViewDemoBaseState): string => {
  return ds.scriptMode === DemoScriptMode.FULL ? "1" : "2";
};

export interface ChosenReaction {
  contentType: string;
  contentId: string;
  reactionId: string;
  feedbackBody: string;
  edited: boolean;
}

export const chosenReactionIDsForContent = (ds: ViewDemoBaseState, key: string): string[] => {
  return chosenReactionsForContent(ds, key).map((r) => r.reactionId);
};

export const chosenReactionsForContent = (ds: ViewDemoBaseState, key: string): ChosenReaction[] => {
  const [contentType, contentId] = key.split("-");
  return ds.chosenReactions.filter((r) => r.contentType === contentType && r.contentId === contentId);
};
