import React, { useState } from "react";
import { AppErrorPage } from "../../components/appError";
import { AppLoadingPage } from "../../components/appLoadingPage";
import { AppLayout } from "../../components/layouts/appLayout";
import { PaperWithTitle } from "../../components/paperWithTitle";
import { useTeamsAndAccountQuery } from "../../generated/graphql";
import { TeamsTable } from "./team/teamsTable";
import { CreateTeamDialog } from "../../components/dialogs/createTeamDialog";
import { requestCreateTeamAsOrgAdmin } from "../../generated/graphqlWrappers";

export const TeamsPage: React.FC = () => {
  const [createTeamOpen, setCreateTeamOpen] = useState(false);
  const { data, loading, error, refetch } = useTeamsAndAccountQuery({ fetchPolicy: "network-only" });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <AppLoadingPage />;
  }

  return (
    <AppLayout chosen={"Teams"}>
      <PaperWithTitle title={`${data.userAccount.organization.displayName} Teams`}>
        <TeamsTable
          teams={data.teams}
          chipForTeam={(teamId) => {
            const team = data.teams.find((t) => t.id === teamId);
            if (team && team.admins.findIndex((a) => a.user.id === data.userAccount.id) >= 0) {
              return [{ label: "team admin", color: "primary" }];
            }
            return [];
          }}
          buttons={
            data.userAccount.isOrgAdmin
              ? [
                  {
                    text: "New Team",
                    onClick: () => {
                      setCreateTeamOpen(true);
                    },
                  },
                ]
              : undefined
          }
        />
        <CreateTeamDialog
          organizationId={parseInt(data.userAccount.organization.id)}
          open={createTeamOpen}
          onClose={() => {
            setCreateTeamOpen(false);
          }}
          onSave={async (client, organizationId, displayName, onSuccess, onError) => {
            await requestCreateTeamAsOrgAdmin(
              client,
              { organizationId, displayName },
              () => {
                onSuccess();
                refetch();
              },
              onError
            );
          }}
        />
      </PaperWithTitle>
    </AppLayout>
  );
};
