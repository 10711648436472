import { TableCell, TableRow } from "@mui/material";
import React, { ReactNode } from "react";
import { Draggable } from "react-beautiful-dnd";

interface Props {
  obj: any;
  index: number;
  cellNode: () => ReactNode;
  onClick: (key: string) => void;
}

export const ObjectTableRowWithDnd: React.FC<Props> = ({ obj, index, cellNode, onClick }) => {
  const key = `${obj.__typename}-${obj.id}`;
  return (
    <Draggable draggableId={key} index={index} key={key}>
      {(provided, snapshot) => {
        const draggableObject = { provided, snapshot };
        return (
          <TableRow
            sx={
              snapshot.isDragging
                ? { display: "table" }
                : { "&:hover": { backgroundColor: "#f8f8f8" }, cursor: "pointer" }
            }
            ref={provided.innerRef}
            {...draggableObject}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TableCell
              onClick={() => {
                onClick(key);
              }}
            >
              {cellNode()}
            </TableCell>
          </TableRow>
        );
      }}
    </Draggable>
  );
};
