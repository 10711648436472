import React from "react";
import { AppTeamLayout } from "./layouts/appTeamLayout";

interface Props {
  teamId?: string;
}

export const AppTeamLoadingPage: React.FC<Props> = ({ teamId }) => {
  return <AppTeamLayout teamId={teamId ?? ""} teamName={""} />;
};
