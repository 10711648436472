import React, { useContext, useEffect, useRef, useState } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { TextEditor } from "../../../components/textEditor/textEditor";
import { DemoContent, demoContentKey, demoContentKeyVersioned } from "../../../util/demoTypes";
import { DemoReactions } from "./demoReactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useVerticalScrollTracker } from "../../../components/hooks/useScrollTracker";
import { chosenReactionsForContent, cumulativeWordCountForContentKey } from "./viewDemoBaseReduxUtils";
import { selectReactionBar } from "../../../components/reactions/reactionBarSlice";
import { WordCount } from "../../../components/textEditor/wordCount";
import { ViewDemoContext, wordCountForContentKey } from "./viewDemoBaseReduxUtils";
import { isEditableViewDemoActions, isEditableViewDemoState } from "./viewDemoSlice";
import { DemoPresentationScriptViewModeSelector } from "./demoPresentationScriptViewModeSelector";

interface Props {
  content: DemoContent;
  scrollToTop: (top: number) => void;
  bodyForContent: (content: DemoContent) => string;
  isWide: boolean;
  showScriptMode?: boolean;
}

/// This is the view for a single content item in presentation view
export const ContentPresentationView: React.FC<Props> = ({
  content,
  scrollToTop,
  bodyForContent,
  isWide,
  showScriptMode,
}) => {
  const context = useContext(ViewDemoContext);
  const viewDemoState = useAppSelector(context.selectState);
  const actions = context.actions;
  const reactionBarState = useAppSelector(selectReactionBar);
  const dispatch = useAppDispatch();

  const scrollToRef = useRef<null | HTMLDivElement>(null);
  const scrollTop = useVerticalScrollTracker(25);

  const contentRef = useRef<null | HTMLDivElement>(null);

  const [respondedToLastTap, setRespondedToLastTap] = useState(viewDemoState.lastTappedDemoNav);

  const headerTop = 60;
  const appBarHeight = 80;
  const scrollFixOffset = appBarHeight - headerTop;

  const isEditable = isEditableViewDemoState(viewDemoState) && isEditableViewDemoActions(actions);

  useEffect(
    () => {
      const inTopOfView = () => {
        if (scrollToRef.current) {
          const top = scrollToRef.current.offsetTop - scrollFixOffset;
          return top === scrollTop;
        }
        return false;
      };

      const hasTopOfView = () => {
        if (scrollToRef.current) {
          const top = scrollToRef.current.offsetTop - headerTop;
          const bottom = scrollToRef.current.offsetHeight + top;
          return scrollTop >= top && scrollTop < bottom - 10;
        }
        return false;
      };

      if (respondedToLastTap !== viewDemoState.lastTappedDemoNav) {
        if (viewDemoState.lastTappedDemoNav === demoContentKey(content)) {
          // When the user taps the navigation, this will scroll this content into view
          if (!inTopOfView() && scrollToRef.current) {
            if (content.__typename === "OpeningForDemo") {
              scrollToTop(0);
            } else {
              scrollToTop(scrollToRef.current.offsetTop + scrollFixOffset);
            }
          }
        }
        setRespondedToLastTap(viewDemoState.lastTappedDemoNav);
        return;
      }

      if (viewDemoState.chosenKey !== demoContentKey(content)) {
        // When the user scrolls this content into view, this will select it in the demo nav
        if (hasTopOfView()) {
          dispatch(actions.setChosenKey(demoContentKey(content)));
        }
      }
    },
    // We are intentionally only responding to nav and scroll changes. We lookup
    // the current value of the other deps, but don't want to recheck when they change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollTop, viewDemoState.lastTappedDemoNav]
  );

  const chosenReactions = chosenReactionsForContent(viewDemoState, demoContentKey(content));
  const reactionTop = (contentRef.current ? headerTop + contentRef.current.offsetHeight : headerTop + 100) + 8;

  return (
    <Stack ref={scrollToRef} direction="column" spacing={2}>
      {/** Header bar with title and reaction icons. It sticks to the top of the view */}
      <Stack
        ref={contentRef}
        direction="column"
        sx={{ mx: -2, backgroundColor: "#ddd", position: "sticky", top: headerTop, zIndex: 100 }}
      >
        <Stack direction="row" sx={{ pl: 1 }}>
          <Typography sx={{ flexGrow: 1, mt: 1 }} variant="h5">
            {content.name}
          </Typography>
          {isEditable ? <DemoReactions contentKey={demoContentKey(content)} /> : <React.Fragment />}
        </Stack>
        <Stack direction="row" sx={{ pl: 1, pr: 2 }}>
          <Typography sx={{ flexGrow: 1 }} variant="h6">
            Show: {content.whatToShow}
          </Typography>
          <WordCount
            timeOnly={true}
            wordCount={wordCountForContentKey(viewDemoState, demoContentKey(content))}
            cumulativeWordCount={cumulativeWordCountForContentKey(viewDemoState, demoContentKey(content))}
          />
        </Stack>
      </Stack>
      {/** Content with reaction descriptions */}
      <Stack direction="column" sx={{ display: "flex", pl: 1 }}>
        <Stack direction="row">
          {/* Scripted or Outlined */}
          {showScriptMode ?? true ? (
            <Stack sx={{ mb: 1, mr: 2 }}>
              <Stack sx={{ mb: 1 }}>
                <DemoPresentationScriptViewModeSelector contentKey={demoContentKey(content)} />
              </Stack>
            </Stack>
          ) : (
            <React.Fragment />
          )}

          {/* Content with columns for wide/narrow padding */}
          <Stack sx={{ flexGrow: isWide ? undefined : 1 }}></Stack>
          <Stack sx={{ flexGrow: 1, maxWidth: isWide ? undefined : 400 }}>
            <TextEditor
              contentKeyVersioned={demoContentKeyVersioned(content)}
              initialContent={bodyForContent(content)}
              setContentJSON={() => {}}
              readOnly={true}
              showWordCount={false}
            />
          </Stack>
          <Stack sx={{ flexGrow: isWide ? undefined : 1 }}></Stack>

          {/** Reaction descriptions that stick inside content area under header */}
          <Stack sx={{ mb: 1, ml: 3, minWidth: 250 }}>
            {chosenReactions.length > 0 ? (
              <Stack sx={{ mb: 1, minWidth: 250, position: "sticky", top: reactionTop, zIndex: 90 }}>
                {chosenReactions.map((reaction) => (
                  <TextField
                    key={reaction.reactionId}
                    value={reaction.feedbackBody ?? ""}
                    sx={{ mb: 1, backgroundColor: "#ffeeaa" }}
                    label={reactionBarState.reactionButtons.find((r) => r.id === reaction.reactionId)?.name ?? ""}
                    variant="outlined"
                    fullWidth
                    multiline={true}
                    rows={3}
                    color="primary"
                    onChange={(e) => {
                      dispatch(actions.updateReactionFeedback({ ...reaction, feedbackBody: e.target.value }));
                    }}
                  />
                ))}
              </Stack>
            ) : (
              <React.Fragment />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
