import React from "react";

import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import StopIcon from "@mui/icons-material/Stop";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BentoIcon from "@mui/icons-material/Bento";
import { NavSidebar } from "../navSidebar";

interface Props {
  chosen?: string;
  teamId: string;
}

export const SiteAdminTeamSidebar: React.FC<Props> = ({ chosen, teamId }) => {
  const sidebar = [
    { text: "Organizations", url: "/site-admin", icon: <BusinessIcon /> },
    { text: "Team Members", url: `/site-admin/team/${teamId}/users`, icon: <GroupsIcon /> },
    { text: "Resources", url: `/site-admin/team/${teamId}/resources`, icon: <AssignmentIcon /> },
    { text: "Resource Categories", url: `/site-admin/team/${teamId}/resource-categories`, icon: <BentoIcon /> },
    { text: "Demo Content" },
    { text: "Stakeholder Templates", url: `/site-admin/team/${teamId}`, icon: <ContentCopyIcon /> },
    { text: "Openings", url: `/site-admin/team/${teamId}/openings`, icon: <PlayArrowIcon /> },
    { text: "Features", url: `/site-admin/team/${teamId}/feature-sets`, icon: <ViewQuiltIcon /> },
    { text: "Conversations", url: `/site-admin/team/${teamId}/conversations`, icon: <QuestionAnswerIcon /> },
    { text: "Closes", url: `/site-admin/team/${teamId}/closes`, icon: <StopIcon /> },
  ];

  return <NavSidebar title="Site Admin" chosen={chosen} sidebar={sidebar} />;
};
