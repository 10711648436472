import React, { useState } from "react";

import { Button, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useLoginMutation } from "../generated/graphql";
import { setAccessToken, setSiteAdmin } from "../util/auth";
import { useHistory } from "react-router";
import { AppLogo } from "../components/appLogo";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const LoginPage: React.FC = () => {
  const history = useHistory();

  const [loginInput, setLoginInput] = useState({
    email: "",
    password: "",
  });

  const { email, password } = loginInput;

  const [login] = useLoginMutation();

  const [loginError, setLoginError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onLoginClick = async () => {
    try {
      const response = await login({
        variables: {
          email,
          password,
        },
      });
      if (response && response.data) {
        setLoginError(false);
        setAccessToken(response.data.login.accessToken);
        setSiteAdmin(response.data.login.isSiteAdmin);
        history.push("/");
        return;
      }
    } catch {
      setLoginError(true);
      return;
    }
  };

  return (
    <React.Fragment>
      <Stack alignItems="center" spacing={2} width="100%" sx={{ mt: 12 }}>
        <Stack alignItems="center" spacing={2} maxWidth={400} sx={{ mx: 5 }}>
          {loginError ? <Typography>Login incorrect</Typography> : <React.Fragment />}
          <AppLogo />
          <TextField
            onChange={(e) => setLoginInput({ ...loginInput, email: e.target.value })}
            value={email}
            label="e-mail"
            variant="outlined"
            required
            color="primary"
            id="user-text-field"
            type="email"
            autoComplete="username"
            fullWidth={true}
          />
          <TextField
            onChange={(e) => setLoginInput({ ...loginInput, password: e.target.value })}
            value={password}
            label="password"
            variant="outlined"
            required
            type={showPassword ? "text" : "password"}
            color="primary"
            id="password-text-field"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={showPassword ? "Hide password" : "Show password"}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <Button size="large" variant="contained" color="primary" onClick={onLoginClick}>
            Login
          </Button>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};
