import React from "react";

import { Box, Button, Dialog, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

interface Props {
  open: boolean;
  errorMessage: string;
  saveIsDisabled: () => boolean;
  cancel: () => void;
  saveAndClose: () => void;
  saveButtonLabel: string;
}

export const FormDialog: React.FC<Props> = ({
  children,
  open,
  errorMessage,
  saveIsDisabled,
  cancel,
  saveAndClose,
  saveButtonLabel,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        cancel();
      }}
    >
      <Box flexDirection="column" sx={{ my: 2, mx: 2, display: "flex", gap: 2 }}>
        {children}
        {errorMessage === "" ? <React.Fragment /> : <Typography color={red[900]}>{errorMessage}</Typography>}
        <Box flexDirection="row" sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              cancel();
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" disabled={saveIsDisabled()} onClick={saveAndClose}>
            {saveButtonLabel}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
