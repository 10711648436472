import React, { useState } from "react";

import { Drawer, Stack, Switch, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

import { AppErrorPage } from "../appError";
import { SiteAdminTeamLayout } from "../layouts/siteAdminTeamLayout";
import { SiteAdminDemoTemplateSidebar } from "./siteAdminDemoTemplateSidebar";
import { SiteAdminTeamAppLoadingPage } from "./siteAdminTeamAppLoadingPage";
import { DemoContentEditor } from "../forms/demoContentEditor";
import { InfoPanel } from "../infoPanel";
import { Breadcrumb } from "../breadcrumb";
import { PaperWithTitle } from "../paperWithTitle";
import { EditorSaveButtons } from "../forms/editorSaveButtons";

interface Props {
  id: number;
  contentType: string;
  idArgName: string;
  query: (options: any) => any;
  update: (options: any) => Promise<any>;
  onClose: (teamId: number) => void;
  getDemoTemplates?: (queryData: any) => any[];
  queryDataProperty: string;
  resultDataProperty: string;
}

export const EditTeamDemoContentSiteAdmin: React.FC<Props> = ({
  id,
  contentType,
  idArgName,
  query,
  update,
  onClose,
  getDemoTemplates,
  queryDataProperty,
  resultDataProperty,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [name, setName] = useState("");
  const [body, setBody] = useState("");
  const [bulletedBody, setBulletedBody] = useState("");
  const [whatToShow, setWhatToShow] = useState("");
  const [contentIsChanged, setContentIsChanged] = useState(false);
  const [archived, setArchived] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { data, error, loading } = query({
    variables: { [idArgName]: id },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminTeamAppLoadingPage />;
  }
  const queryData = data[queryDataProperty];
  const demoTemplates = getDemoTemplates ? getDemoTemplates(queryData) : queryData.demoTemplates;
  const canArchive = demoTemplates.filter((t: any) => !t.archived).length === 0;
  const rightDrawerWidth = 240;
  if (!initialized) {
    setName(queryData.name);
    setWhatToShow(queryData.whatToShow);
    setBody(queryData.body);
    setBulletedBody(queryData.bulletedBody);
    setContentIsChanged(false);
    setArchived(queryData.archived);
    setInitialized(true);
    return <SiteAdminTeamAppLoadingPage />;
  }

  const saveIsDisabled = () => {
    return name === "";
  };

  const onSaveAndClose = async () => {
    if (!contentIsChanged) {
      onClose(queryData.team.id);
      return;
    }
    await save(onClose);
  };

  const onSave = async () => {
    await save();
  };

  const save = async (onSuccess?: (teamId: number) => void) => {
    try {
      setErrorMessage("");
      const response = await update({
        variables: { [idArgName]: id, name, body, bulletedBody, whatToShow, archived },
      });
      if (response && response.data) {
        const resultData = response.data[resultDataProperty];
        if (resultData.success) {
          setContentIsChanged(false);
          if (onSuccess) {
            onSuccess(queryData.team.id);
          }
        } else {
          setErrorMessage(resultData.error);
        }
      } else {
        setErrorMessage(`Error updating ${contentType}`);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(`Error updating ${contentType}`);
    }
  };

  return (
    <SiteAdminTeamLayout chosen={`${contentType}s`} teamId={queryData.team.id} rightDrawerWidth={rightDrawerWidth}>
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: queryData.team.organization.displayName, url: `/site-admin/org/${queryData.team.organization.id}` },
            { text: queryData.team.displayName, url: `/site-admin/team/${queryData.team.id}` },
          ]}
        />

        <PaperWithTitle title={`Edit ${contentType}`}>
          <DemoContentEditor
            nameState={[name, setName]}
            bodyState={[body, setBody]}
            bulletedBodyState={[bulletedBody, setBulletedBody]}
            whatToShowState={[whatToShow, setWhatToShow]}
            setContentIsChanged={setContentIsChanged}
          />
          <EditorSaveButtons
            objectId={queryData.team.id}
            saveIsDisabled={saveIsDisabled()}
            onSave={onSave}
            onSaveAndClose={onSaveAndClose}
            onClose={onClose}
            contentIsChanged={contentIsChanged}
          />
          {errorMessage === "" ? <React.Fragment /> : <Typography color={red[900]}>{errorMessage}</Typography>}
        </PaperWithTitle>
      </Stack>
      <Drawer
        variant="permanent"
        anchor="right"
        sx={{
          flexShrink: 0,
          width: rightDrawerWidth,
          [`& .MuiDrawer-paper`]: {
            width: rightDrawerWidth,
            borderLeft: "solid 1px lightgray",
            boxSizing: "border-box",
            backgroundColor: "inherit",
          },
        }}
      >
        <Stack spacing={1} direction="column" sx={{ flexGrow: 1, mx: 4, pt: 3 }}>
          <Typography variant="h5">Settings</Typography>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography sx={{ flexGrow: 1 }}>Archived</Typography>
            <Switch
              onChange={async () => {
                setArchived(!archived);
              }}
              checked={archived}
              disabled={!canArchive}
            />
          </Stack>
          {!canArchive ? (
            <InfoPanel
              text={`You cannot archive this ${contentType.toLowerCase()} because it's being used in stakeholder templates.`}
            />
          ) : (
            <React.Fragment />
          )}
          <SiteAdminDemoTemplateSidebar demoTemplates={demoTemplates} />
        </Stack>
      </Drawer>
    </SiteAdminTeamLayout>
  );
};
