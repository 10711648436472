import React from "react";

import { useHistory, useParams } from "react-router";
import { EditTeamDemoContentSiteAdmin } from "../../../../components/siteAdmin/editTeamDemoContentSiteAdmin";
import { useSiteCloseQuery, useUpdateSiteCloseMutation } from "../../../../generated/graphql";

interface Params {
  closeId: string;
}

export const EditTeamCloseSiteAdminPage: React.FC = () => {
  let history = useHistory();
  let { closeId } = useParams<Params>();
  const [updateSiteClose] = useUpdateSiteCloseMutation();

  return (
    <EditTeamDemoContentSiteAdmin
      id={parseInt(closeId)}
      contentType="Close"
      idArgName="closeId"
      query={useSiteCloseQuery}
      update={updateSiteClose}
      onClose={(teamId) => {
        history.push(`/site-admin/team/${teamId}/closes`);
      }}
      queryDataProperty="siteClose"
      resultDataProperty="updateSiteClose"
    ></EditTeamDemoContentSiteAdmin>
  );
};
