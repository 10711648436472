import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";

interface Props {
  text: string;
}

export const InfoPanel: React.FC<Props> = ({ text }) => {
  const [open, setOpen] = useState(false);
  return (
    <Stack
      onMouseEnter={() => {
        setOpen(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
      spacing={1}
      direction="row"
      sx={{ p: 1 }}
      // This color is the same as the navigation selection color.
      style={{ backgroundColor: "rgba(25, 118, 210, 0.08)", borderRadius: 10 }}
    >
      <InfoIcon fontSize="small" color="primary" />
      <Typography fontSize={14}>{open ? text : text.substr(0, text.indexOf(" ", 15)) + "..."}</Typography>
    </Stack>
  );
};
