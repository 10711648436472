import React from "react";

import { useHistory, useParams } from "react-router";
import { useSiteTeamConversationsQuery } from "../../../../generated/graphql";
import { ViewTeamContentSiteAdminPage } from "../../../../components/siteAdmin/viewTeamContentSiteAdmin";

interface Params {
  teamId: string;
}

export const ViewTeamConversationsSiteAdminPage: React.FC = () => {
  const { teamId } = useParams<Params>();
  const history = useHistory();

  return (
    <ViewTeamContentSiteAdminPage
      teamId={teamId}
      query={useSiteTeamConversationsQuery}
      chosen="Conversations"
      getObjects={(data) => {
        return data.conversations;
      }}
      dataDescription="conversations"
      title="Conversation"
      newContentPath="new-conversation"
      onClick={(id) => {
        history.push(`/site-admin/conversation/${id}`);
      }}
      rowLabelProperty="name"
    />
  );
};
