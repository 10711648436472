import React from "react";
import { List, ListItem, ListItemIcon, ListItemText, Skeleton, Stack, Typography } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import { Link, useLocation } from "react-router-dom";

import { isSiteAdmin } from "../util/auth";

interface Nav {
  text: string;
  url?: string;
  icon?: React.ReactNode;
}

interface ItemProps {
  chosen?: string;
  nav: Nav;
}

export const NavItem: React.FC<ItemProps> = ({ chosen, nav }) => {
  const color = chosen === nav.text ? "#3874CB" : "#666";
  return (
    <ListItem selected={chosen === nav.text} sx={{ "&:hover": { backgroundColor: nav.url ? "#f8f8f8" : "inherit" } }}>
      {nav.icon ? <ListItemIcon sx={{ color }}>{nav.icon}</ListItemIcon> : <React.Fragment />}
      {nav.url ? (
        <Link to={nav.url} style={{ textDecoration: "none", color }}>
          <ListItemText sx={{ mx: -2 }} primary={nav.text} />
        </Link>
      ) : (
        <ListItemText
          primary={nav.text}
          sx={{ mx: -1 }}
          primaryTypographyProps={{ color: "#666", fontWeight: "bold" }}
        />
      )}
    </ListItem>
  );
};

interface Props {
  chosen?: string;
  title?: string;
  sidebar: Nav[];
}

export const NavSidebar: React.FC<Props> = ({ chosen, title, sidebar }) => {
  const location = useLocation();

  return (
    <Stack>
      {title !== undefined ? (
        title !== "" ? (
          <Typography sx={{ ml: 1, color: "#666" }} fontWeight="bold">
            {title}
          </Typography>
        ) : (
          <Typography>
            <Skeleton variant="text" />
          </Typography>
        )
      ) : (
        <React.Fragment />
      )}
      <List dense={true}>
        {sidebar.map((nav) => (
          <NavItem key={nav.text} chosen={chosen} nav={nav} />
        ))}
        {isSiteAdmin() && !location.pathname.startsWith("/site-admin") ? (
          <React.Fragment>
            <NavItem nav={{ text: "Administration" }} />
            <NavItem nav={{ text: "Site Admin", url: "/site-admin", icon: <AdminPanelSettingsIcon /> }} />
          </React.Fragment>
        ) : isSiteAdmin() ? (
          <React.Fragment>
            <NavItem nav={{ text: "Application" }} />
            <NavItem nav={{ text: "My Demos", url: "/", icon: <DesktopMacIcon /> }} />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </List>
    </Stack>
  );
};
