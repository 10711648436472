import React, { useState } from "react";
import { useParams } from "react-router";
import { AppErrorPage } from "../../../components/appError";
import { AppTeamLoadingPage } from "../../../components/appTeamLoadingPage";
import { AppTeamLayout } from "../../../components/layouts/appTeamLayout";
import { PaperWithTitle } from "../../../components/paperWithTitle";
import { useUserDemoTemplatesForTeamQuery } from "../../../generated/graphql";
import { TeamDemoTemplatesTable } from "./teamDemoTemplatesTable";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import { ApolloConsumer, ApolloClient } from "@apollo/client";
import { requestCreateDemoTemplate, requestUpdateUserDemoTemplateArchive } from "../../../generated/graphqlWrappers";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  clearTeamDemoTemplatesState,
  demoTemplateAdded,
  initializeTeamDemoTemplatesState,
  selectTeamDemoTemplates,
  tabChanged,
  updateTeamDemoTemplateArchived,
} from "./teamDemoTemplatesSlice";
import { AppSnackbar } from "../../../components/appSnackbar";
import { showSnackbarError, showSnackbarSuccess } from "../../../components/appSnackbarSlice";
import { Breadcrumb } from "../../../components/breadcrumb";
import { Stack, Tab, Typography } from "@mui/material";
import { isAdminForOrgOrTeam } from "../../../util/adminUtils";
import { TitleWithAdminChip } from "../../../components/titleWithAdminChip";
import { CreateDemoTemplateDialog } from "../../../components/dialogs/createDemoTemplateDialog";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Link } from "react-router-dom";

interface Params {
  teamId: string;
}

export const TeamDemoTemplatesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const teamDemoTemplatesState = useAppSelector(selectTeamDemoTemplates);

  const { teamId } = useParams<Params>();
  const [createTemplateDialogOpen, setCreateTemplateDialogOpen] = useState(false);
  const { data, loading, error } = useUserDemoTemplatesForTeamQuery({
    variables: { teamId: parseInt(teamId), archived: "all" },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    if (teamDemoTemplatesState.initialized) {
      dispatch(clearTeamDemoTemplatesState());
    }
    return <AppTeamLoadingPage teamId={teamId} />;
  }

  if (!teamDemoTemplatesState.initialized) {
    dispatch(initializeTeamDemoTemplatesState(data.userDemoTemplatesForTeam));
    return <AppTeamLoadingPage teamId={teamId} />;
  }

  const handleTabChange = async (event: any, newValue: string) => {
    dispatch(tabChanged(newValue));
  };

  const hasOpenings = data.userOpeningsForTeam.length > 0;
  const hasCloses = data.userClosesForTeam.length > 0;

  const onUpdateArchiveOnDemoTemplate = async (
    client: ApolloClient<object>,
    demoTemplateId: number,
    archived: boolean
  ) => {
    requestUpdateUserDemoTemplateArchive(
      client,
      { demoTemplateId, archived },
      () => {
        dispatch(updateTeamDemoTemplateArchived({ demoTemplateId: `${demoTemplateId}`, archived }));
        dispatch(showSnackbarSuccess("Stakeholder template archived"));
      },
      (message) => {
        dispatch(showSnackbarError(`Could not archive stakeholder template: ${message}`));
      }
    );
  };

  const teamName = data.userDemoTemplatesForTeam.displayName;
  const isAdmin = isAdminForOrgOrTeam(data.userAccount, data.userDemoTemplatesForTeam.admins);

  return (
    <AppTeamLayout teamId={teamId} teamName={teamName} chosen={"Stakeholder Templates"}>
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: "Home", url: "/" },
            { text: "Teams", url: `/teams` },
            { text: teamName, url: `/team/${teamId}` },
          ]}
        />
        <PaperWithTitle
          title={
            <TitleWithAdminChip
              title={`Stakeholder Templates for ${data.userDemoTemplatesForTeam.displayName}`}
              isAdmin={isAdmin}
              isOrgAdmin={data.userAccount.isOrgAdmin}
            />
          }
        >
          <ApolloConsumer>
            {(client) => (
              <TabContext value={teamDemoTemplatesState.activeTab}>
                <TabList onChange={handleTabChange} aria-label="demo tabs">
                  <Tab label="Active" value="1" />
                  <Tab label="Archive" value="2" />
                </TabList>

                <TabPanel value="1">
                  <TeamDemoTemplatesTable
                    teamId={teamId}
                    templates={teamDemoTemplatesState.team?.demoTemplates.filter((t) => !t.archived) ?? []}
                    buttons={(template) => {
                      return isAdmin
                        ? [
                            {
                              icon: <ArchiveOutlinedIcon />,
                              text: "Archive stakeholder template",
                              onClick: (demoId) => {
                                onUpdateArchiveOnDemoTemplate(client, demoId, true);
                              },
                            },
                          ]
                        : [];
                    }}
                    headerButtons={
                      isAdmin
                        ? [
                            {
                              text: "New Template",
                              onClick: () => {
                                setCreateTemplateDialogOpen(true);
                              },
                              disabled: !hasOpenings || !hasCloses,
                            },
                          ]
                        : undefined
                    }
                  />
                  {isAdmin ? (
                    <Typography textAlign="right">
                      {!hasOpenings ? "This team has no openings. " : !hasCloses ? "This team has no closes. " : ""}
                      Add a new <Link to={`/team/${teamId}/openings`}>opening</Link> or{" "}
                      <Link to={`/team/${teamId}/closes`}>close</Link> to the team library
                    </Typography>
                  ) : (
                    <React.Fragment />
                  )}
                </TabPanel>
                <TabPanel value="2">
                  <TeamDemoTemplatesTable
                    teamId={teamId}
                    templates={teamDemoTemplatesState.team?.demoTemplates.filter((t) => t.archived) ?? []}
                    buttons={(template) => {
                      return isAdmin
                        ? [
                            {
                              icon: <UnarchiveOutlinedIcon />,
                              text: "Unarchive stakeholder template",
                              onClick: (demoId) => {
                                onUpdateArchiveOnDemoTemplate(client, demoId, false);
                              },
                            },
                          ]
                        : [];
                    }}
                  />
                </TabPanel>
              </TabContext>
            )}
          </ApolloConsumer>
        </PaperWithTitle>
        <CreateDemoTemplateDialog
          teamId={teamId}
          open={createTemplateDialogOpen}
          onClose={(templateId?: string, name?: string): void => {
            setCreateTemplateDialogOpen(false);
            if (templateId && name) {
              dispatch(demoTemplateAdded({ templateId, name }));
            }
          }}
          requestCreateDemoTemplate={requestCreateDemoTemplate}
        />
        <AppSnackbar />
      </Stack>
    </AppTeamLayout>
  );
};
