import React from "react";

import { SiteAdminSidebar } from "../siteAdmin/siteAdminSidebar";
import { LayoutLeftNav } from "./layoutLeftNav";

interface Props {
  chosen?: string;
}

export const SiteAdminLayout: React.FC<Props> = ({ children, chosen }) => {
  return <LayoutLeftNav sidebar={<SiteAdminSidebar chosen={chosen} />}>{children}</LayoutLeftNav>;
};
