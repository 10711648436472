import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { NewDemoName } from "./newDemoName";
import { NewDemoPickTemplate } from "./newDemoPickTemplate";
import { NewDemoPickConversations } from "./newDemoPickConversations";
import { NewDemoPickFeatureSets } from "./newDemoPickFeatureSets";
import { useAppDispatch } from "../../../store/hooks";
import { clearNewDemoState } from "./newDemoSlice";

interface Props {
  open: boolean;
  onClose: (demoId?: number) => void;
}

export const NewDemoDialog: React.FC<Props> = ({ open, onClose }) => {
  const [wizardStep, setWizardStep] = useState(0);
  const dispatch = useAppDispatch();

  const close = (demoId?: number) => {
    dispatch(clearNewDemoState());
    onClose(demoId);
    setWizardStep(0);
  };
  return (
    <Dialog open={open} onClose={() => close()}>
      {wizardStep === 0 ? (
        <NewDemoName
          next={() => {
            setWizardStep(wizardStep + 1);
          }}
          cancel={() => {
            close();
          }}
        />
      ) : (
        <React.Fragment />
      )}

      {wizardStep === 1 ? (
        <NewDemoPickTemplate
          next={() => {
            setWizardStep(wizardStep + 1);
          }}
          back={() => {
            setWizardStep(wizardStep - 1);
          }}
        />
      ) : (
        <React.Fragment />
      )}

      {wizardStep === 2 ? (
        <NewDemoPickFeatureSets
          next={() => {
            setWizardStep(wizardStep + 1);
          }}
          back={() => {
            setWizardStep(wizardStep - 1);
          }}
        />
      ) : (
        <React.Fragment />
      )}

      {wizardStep === 3 ? (
        <NewDemoPickConversations
          onDone={close}
          back={() => {
            setWizardStep(wizardStep - 1);
          }}
        />
      ) : (
        <React.Fragment />
      )}
    </Dialog>
  );
};
