import React, { useState } from "react";
import { TextField } from "@mui/material";
import { ApolloClient, ApolloConsumer } from "@apollo/client";

import { SuccessResponse } from "../../generated/graphql";
import { FormDialog } from "./formDialog";

interface Props {
  teamId: string;
  open: boolean;
  onClose: (templateId?: string, name?: string) => void;
  requestCreateDemoTemplate: (
    client: ApolloClient<object>,
    variables: { teamId: number; name: string },
    onSuccess: (response: SuccessResponse) => void,
    onError: (message: string) => void
  ) => Promise<SuccessResponse>;
}

export const CreateDemoTemplateDialog: React.FC<Props> = ({ teamId, open, onClose, requestCreateDemoTemplate }) => {
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const close = (templateId?: string, name?: string) => {
    setName("");
    setErrorMessage("");
    onClose(templateId, name);
  };

  const createIsDisabled = () => {
    return name === "";
  };

  const createTemplate = async (client: ApolloClient<object>) => {
    setErrorMessage("");
    await requestCreateDemoTemplate(
      client,
      { teamId: parseInt(teamId), name },
      (response: SuccessResponse) => {
        if (response.idInserted) {
          setName("");
          close(`${response.idInserted}`, name);
        } else {
          setErrorMessage(`Error creating stakeholder template`);
        }
      },
      (message) => {
        setErrorMessage(`Error creating stakeholder template: ${message}`);
      }
    );
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <FormDialog
          open={open}
          cancel={() => {
            close();
          }}
          errorMessage={errorMessage}
          saveAndClose={() => {
            createTemplate(client);
          }}
          saveIsDisabled={createIsDisabled}
          saveButtonLabel="Add Template"
        >
          <TextField
            value={name}
            label="Stakeholder Template Name"
            variant="outlined"
            required
            sx={{ width: 350 }}
            color="primary"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </FormDialog>
      )}
    </ApolloConsumer>
  );
};
