import React from "react";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from "@mui/material";
import Crop169Icon from "@mui/icons-material/Crop169";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface Props {
  isWide: boolean;
  setIsWide: (isWide: boolean) => void;
}

export const PresentationMenu: React.FC<Props> = ({ isWide, setIsWide }) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const buttonIsOpen = Boolean(menuAnchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Stack direction="row">
      <Button
        id="presentation-button"
        aria-controls={buttonIsOpen ? "presentation-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={buttonIsOpen ? "true" : undefined}
        onClick={handleClick}
      >
        Presentation
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="presentation-menu"
        anchorEl={menuAnchorEl}
        open={buttonIsOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "presentation-button",
        }}
      >
        <MenuItem
          disabled={isWide}
          onClick={() => {
            handleClose();
            setIsWide(true);
          }}
        >
          <ListItemIcon>
            <Crop169Icon fontSize="small" color={isWide ? "disabled" : "primary"} />
          </ListItemIcon>
          <ListItemText>Wide Column</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={!isWide}
          onClick={() => {
            handleClose();
            setIsWide(false);
          }}
        >
          <ListItemIcon>
            <CropPortraitIcon fontSize="small" color={!isWide ? "disabled" : "primary"} />
          </ListItemIcon>
          <ListItemText>Narrow Column</ListItemText>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
