import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router";
import { AppErrorPage } from "../../../components/appError";
import { AppSnackbar } from "../../../components/appSnackbar";
import { showSnackbarError } from "../../../components/appSnackbarSlice";
import { AppTeamLoadingPage } from "../../../components/appTeamLoadingPage";
import { Breadcrumb } from "../../../components/breadcrumb";
import { AppTeamLayout } from "../../../components/layouts/appTeamLayout";
import { PaperWithTitle } from "../../../components/paperWithTitle";
import { useUserTeamQuery } from "../../../generated/graphql";
import { requestUpdateTeamAsAdmin } from "../../../generated/graphqlWrappers";
import { useAppDispatch } from "../../../store/hooks";
import { objectIdsFromArrayNotInOtherArray } from "../../../util/arrayUtils";
import { TeamUsersTable } from "./teamUsersTable";

interface Params {
  teamId: string;
}

export const TeamAndUsersPage: React.FC = () => {
  const { teamId } = useParams<Params>();
  const dispatch = useAppDispatch();
  const [displayName, setDisplayName] = useState("");
  const [lastSavedDisplayName, setLastSavedDisplayName] = useState("");

  const [initialized, setInitialized] = useState(false);
  const { data, loading, error, refetch } = useUserTeamQuery({
    variables: { teamId: parseInt(teamId) },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <AppTeamLoadingPage />;
  }

  if (!initialized) {
    setDisplayName(data.userTeam.displayName);
    setLastSavedDisplayName(data.userTeam.displayName);
    setInitialized(true);
    return <AppTeamLoadingPage />;
  }
  const isOrgAdmin = data.userAccount.isOrgAdmin;
  const isAdmin = isOrgAdmin || data.userTeam.admins.findIndex((a) => a.user.id === data.userAccount.id) >= 0;

  const dataChanged = () => {
    return displayName !== lastSavedDisplayName;
  };

  const saveIsDisabled = () => {
    return !dataChanged() || displayName === "";
  };
  const revert = () => {
    setDisplayName(lastSavedDisplayName);
  };

  const membersNotIncluded = (): any[] => {
    return objectIdsFromArrayNotInOtherArray(data.userTeam.organization.users, data.userTeam.users);
  };

  const save = (client: ApolloClient<object>) => {
    requestUpdateTeamAsAdmin(
      client,
      { teamId: parseInt(teamId), displayName },
      () => {
        setLastSavedDisplayName(displayName);
      },
      () => {
        dispatch(showSnackbarError("Could not udpate team"));
      }
    );
  };

  return (
    <AppTeamLayout chosen={"Team"} teamId={teamId} teamName={data.userTeam.displayName}>
      <Stack spacing={2}>
        <Breadcrumb crumbs={[{ text: "Teams", url: "/teams" }, { text: displayName }]} />
        <PaperWithTitle title={isAdmin ? "Edit Team" : data.userTeam.displayName}>
          {isAdmin ? (
            <Stack direction="row" spacing={2}>
              <TextField
                onChange={(e) => {
                  setDisplayName(e.target.value);
                }}
                value={displayName}
                label="Display Name"
                variant="outlined"
                sx={{ width: 350 }}
                required
                color="primary"
              />
              <Button variant="outlined" color="error" sx={{ my: 2 }} disabled={!dataChanged()} onClick={revert}>
                Revert
              </Button>
              <ApolloConsumer>
                {(client) => (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ my: 2, ml: 2 }}
                    disabled={saveIsDisabled()}
                    onClick={() => {
                      save(client);
                    }}
                  >
                    Save
                  </Button>
                )}
              </ApolloConsumer>
            </Stack>
          ) : (
            <React.Fragment />
          )}
          <TeamUsersTable
            teamId={data.userTeam.id}
            isAdmin={isAdmin}
            isOrgAdmin={isOrgAdmin}
            users={data.userTeam.users}
            usersNotIncluded={membersNotIncluded()}
            admins={data.userTeam.admins.map((a) => a.user)}
            onRefetch={refetch}
          />
        </PaperWithTitle>
        <AppSnackbar />
      </Stack>
    </AppTeamLayout>
  );
};
