import React from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { Button, Typography } from "@mui/material";

import { requestIgnoreUserDemoTemplateVersionOfContent } from "../../../generated/graphqlWrappers";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { contentIdFromKey, contentTypeFromKey, teamContentIsNewer } from "../../../util/demoTypes";

import { ConfirmReplaceWithTemplateDialog } from "./confirmReplaceWithTemplateDialog";
import { requestAcceptTemplateVersion } from "./graphql/viewDemoRequests";
import {
  acceptTemplateVersionOfContent,
  ignoreTemplateVersionOfContent,
  selectViewDemo,
  setConfirmReplaceSectionOpen,
  setErrorMessage,
} from "./viewDemoSlice";
import { demoStateChosenContent } from "./viewDemoBaseReduxUtils";

export const UpdatedTemplateContentActions: React.FC = () => {
  const viewDemoState = useAppSelector(selectViewDemo);
  const dispatch = useAppDispatch();

  const demoId = viewDemoState.demoId;
  const contentType = contentTypeFromKey(viewDemoState.chosenKey);
  const contentId = parseInt(contentIdFromKey(viewDemoState.chosenKey));

  const ignoreTemplateVersion = async (apolloClient: ApolloClient<object>) => {
    const errorMessage = "Error accessing template";
    dispatch(setErrorMessage(""));
    await requestIgnoreUserDemoTemplateVersionOfContent(
      apolloClient,
      { demoId: parseInt(demoId), contentType, contentId },
      () => {
        dispatch(ignoreTemplateVersionOfContent(viewDemoState.chosenKey));
      },
      () => {
        dispatch(setErrorMessage(errorMessage));
        return errorMessage;
      }
    );
  };

  const acceptTemplateVersion = async (apolloClient: ApolloClient<object>) => {
    dispatch(setErrorMessage(""));
    await requestAcceptTemplateVersion(
      apolloClient,
      viewDemoState,
      (content) => {
        dispatch(acceptTemplateVersionOfContent({ key: viewDemoState.chosenKey, content }));
      },
      (err) => dispatch(setErrorMessage(err))
    );
  };

  return (
    <ApolloConsumer>
      {(client) =>
        teamContentIsNewer(demoStateChosenContent(viewDemoState)) ? (
          <React.Fragment>
            <Typography variant="h5">Notification</Typography>
            <Typography>The template version of this content has been updated.</Typography>
            <Button onClick={() => ignoreTemplateVersion(client)} sx={{ my: 1 }} color="primary" variant="outlined">
              Ignore
            </Button>
            <Button
              onClick={() => dispatch(setConfirmReplaceSectionOpen(true))}
              sx={{ mb: 1 }}
              color="error"
              variant="outlined"
            >
              Replace my version
            </Button>

            <ConfirmReplaceWithTemplateDialog
              open={viewDemoState.confirmReplaceSectionOpen}
              closeDialog={(confirmed) => {
                dispatch(setConfirmReplaceSectionOpen(false));
                if (confirmed) {
                  acceptTemplateVersion(client);
                }
              }}
            />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )
      }
    </ApolloConsumer>
  );
};
