import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { UserNotification } from "../../../../generated/graphql";
import { DemoContentType, labelForContentType } from "../../../../util/demoTypes";

interface Props {
  onClick: () => void;
  contentType: DemoContentType;
  notifications: UserNotification[];
}

export const TeamContentNotificationButton: React.FC<Props> = ({ onClick, contentType, notifications }) => {
  return notifications.length > 0 ? (
    <Tooltip title={`Notifications for this ${labelForContentType(contentType)}`}>
      <IconButton
        onClick={(e) => {
          onClick();
        }}
      >
        <CircleIcon color={notifications.filter((n) => !n.wasRead).length ? "error" : undefined} />
      </IconButton>
    </Tooltip>
  ) : (
    <React.Fragment />
  );
};
