import React from "react";
import { Stack, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";

interface Crumb {
  text: string;
  url?: string;
  onClick?: () => void;
}

interface Props {
  crumbs: Crumb[];
}

export const Breadcrumb: React.FC<Props> = ({ crumbs }) => {
  return (
    <Stack direction="row" alignItems="center">
      {crumbs.map((crumb, i) => {
        return (
          <React.Fragment key={i}>
            {i !== 0 ? <ChevronRightIcon htmlColor="#666" /> : <React.Fragment />}
            <Typography key={crumb.text} style={{ color: "#666" }}>
              {crumb.url ? (
                <Link to={crumb.url} style={{ color: "#666" }}>
                  {crumb.text}
                </Link>
              ) : crumb.onClick ? (
                <Link to="#" onClick={crumb.onClick} style={{ color: "#666" }}>
                  {crumb.text}
                </Link>
              ) : (
                crumb.text
              )}
            </Typography>
          </React.Fragment>
        );
      })}
    </Stack>
  );
};
