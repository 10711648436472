import React from "react";
import { Box, Button, Checkbox, DialogTitle, FormControl, FormControlLabel, FormGroup } from "@mui/material";

import { useUserFeatureSetsForTemplateQuery } from "../../../generated/graphql";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { selectNewDemo, setFeatureSetIds, toggleFeatureSetId } from "./newDemoSlice";

interface Props {
  next: () => void;
  back: () => void;
}

export const NewDemoPickFeatureSets: React.FC<Props> = ({ next, back }) => {
  const newDemoState = useAppSelector(selectNewDemo);
  const dispatch = useAppDispatch();

  const { data, error, loading } = useUserFeatureSetsForTemplateQuery({
    variables: { templateId: newDemoState.templateId ?? 0 },
    fetchPolicy: "network-only",
  });

  if (!newDemoState.templateId) {
    return <div>Template not set</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading || !data) {
    dispatch(setFeatureSetIds(undefined));
    return <div />;
  }

  if (newDemoState.featureSetIds === undefined) {
    // This is the first load of new data, so check all recommended features
    const ids = data.userFeatureSetsForTemplate
      .filter((tfs) => {
        return tfs.recommended;
      })
      .map((tfs) => {
        return tfs.featureSet.id;
      });
    dispatch(setFeatureSetIds(ids));
  }

  const nextDisabled = () => {
    return newDemoState.templateId === undefined;
  };

  const onNext = () => {
    if (!nextDisabled()) {
      next();
    } else {
    }
  };

  const onBack = () => {
    back();
  };

  const onFeatureSetChanged = (featureSetId: string) => {
    return () => {
      dispatch(toggleFeatureSetId(featureSetId));
    };
  };

  const isChecked = (featureSetId: string): boolean => {
    if (newDemoState.featureSetIds === undefined) {
      return false;
    }
    return newDemoState.featureSetIds.indexOf(featureSetId) > -1;
  };

  return (
    <React.Fragment>
      <DialogTitle>Select Features</DialogTitle>

      <Box flexDirection="column" sx={{ my: 2, mx: 2, display: "flex", gap: 2 }}>
        <FormControl component="fieldset">
          <FormGroup aria-label="features">
            {data.userFeatureSetsForTemplate.map((tfs) => {
              return (
                <FormControlLabel
                  key={tfs.featureSet.id}
                  onChange={onFeatureSetChanged(tfs.featureSet.id)}
                  checked={isChecked(tfs.featureSet.id)}
                  value={tfs.featureSet.id}
                  control={<Checkbox />}
                  label={tfs.featureSet.name}
                />
              );
            })}
          </FormGroup>
        </FormControl>
        <Box flexDirection="row" sx={{ my: 2, display: "flex", gap: 2, justifyContent: "flex-end" }}>
          <Button size="large" variant="outlined" color="primary" onClick={onBack}>
            Back
          </Button>
          <Button size="large" variant="contained" color="primary" disabled={nextDisabled()} onClick={onNext}>
            Next
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};
