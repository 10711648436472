import React from "react";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";

import { NavSidebar } from "../navSidebar";

interface Props {
  chosen?: string;
  organizationId: string;
}

export const SiteAdminOrgSidebar: React.FC<Props> = ({ chosen, organizationId }) => {
  const sidebar = [
    { text: "Organizations", url: "/site-admin", icon: <BusinessIcon /> },
    { text: "Teams", url: `/site-admin/org/${organizationId}`, icon: <GroupsIcon /> },
    { text: "Users", url: `/site-admin/org/${organizationId}/users`, icon: <PersonIcon /> },
  ];

  return <NavSidebar title="Site Admin" chosen={chosen} sidebar={sidebar} />;
};
