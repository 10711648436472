import React from "react";
import { useParams } from "react-router-dom";
import { useUserFeatureSetQuery } from "../../../generated/graphql";
import { requestUpdateFeatureSet } from "../../../generated/graphqlWrappers";
import { TeamContentPageLayout } from "./teamContent/teamContentPageLayout";

interface Params {
  teamId: string;
  featureSetId: string;
}

export const TeamFeatureSetPage: React.FC = () => {
  const { teamId, featureSetId } = useParams<Params>();
  return (
    <TeamContentPageLayout
      teamId={teamId}
      contentType="FeatureSet"
      chosen="Features"
      contentId={featureSetId}
      useUserContentQuery={useUserFeatureSetQuery}
      requestUpdateContent={requestUpdateFeatureSet}
    />
  );
};
