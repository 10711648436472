import React from "react";

import { useHistory, useParams } from "react-router";
import { CreateTeamDemoContentSiteAdmin } from "../../../../components/siteAdmin/createTeamDemoContentSiteAdmin";
import { useCreateSiteOpeningMutation } from "../../../../generated/graphql";

interface Params {
  teamId: string;
}

export const CreateTeamOpeningSiteAdminPage: React.FC = () => {
  let history = useHistory();
  const { teamId } = useParams<Params>();
  const [newSiteOpening] = useCreateSiteOpeningMutation();

  return (
    <CreateTeamDemoContentSiteAdmin
      contentType="Opening"
      teamId={teamId}
      createContent={newSiteOpening}
      onClose={(teamId) => {
        history.push(`/site-admin/team/${teamId}/openings`);
      }}
      resultDataProperty="createSiteOpening"
    ></CreateTeamDemoContentSiteAdmin>
  );
};
