import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { TextEditor } from "../textEditor/textEditor";
import { isChangedBody } from "../textEditor/textEditorUtils";

interface ResourceCategory {
  id: string;
  name: string;
}

interface Props {
  categories: ResourceCategory[];
  nameState: [string, React.Dispatch<React.SetStateAction<string>>];
  bodyState: [string, React.Dispatch<React.SetStateAction<string>>];
  categoryIdState: [number, React.Dispatch<React.SetStateAction<number>>];
  setContentIsChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ResourceEditor: React.FC<Props> = ({
  categories,
  nameState,
  bodyState,
  categoryIdState,
  setContentIsChanged,
}) => {
  const [name, setName] = nameState;
  const [body, setBody] = bodyState;
  const [categoryId, setCategoryId] = categoryIdState;

  return (
    <React.Fragment>
      <Stack direction="row">
        <FormControl>
          <InputLabel id={`category-select-label`}>Category</InputLabel>
          <Select
            labelId={`category-select-label`}
            id={`category-select`}
            value={categoryId}
            label="Category"
            onChange={(e) => {
              setContentIsChanged(true);
              setCategoryId(parseInt(e.target.value as string));
            }}
          >
            {categories.map((c) => {
              return (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
      <TextField
        value={name}
        label="Name"
        variant="outlined"
        required
        sx={{ width: 350 }}
        color="primary"
        onChange={(e) => {
          setContentIsChanged(true);
          setName(e.target.value);
        }}
      ></TextField>

      <TextEditor
        contentKeyVersioned="key"
        initialContent={body}
        setContentJSON={(bodyJSON) => {
          if (isChangedBody(body, bodyJSON)) {
            setContentIsChanged(true);
            setBody(bodyJSON);
          }
        }}
      />
    </React.Fragment>
  );
};
