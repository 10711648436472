import React from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { QuestionDialog } from "../../../components/dialogs/questionDialog";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectMyDemos, setDeleteConfirmOpen, setDemoToDelete } from "../myDemosSlice";

interface Props {
  onRemoveDemo: (client: ApolloClient<object>, demoId: number) => void;
}

export const ConfirmRemoveDemoDialog: React.FC<Props> = ({ onRemoveDemo }) => {
  const myDemosState = useAppSelector(selectMyDemos);
  const dispatch = useAppDispatch();

  return (
    <ApolloConsumer>
      {(client) => (
        <QuestionDialog
          open={myDemosState.deleteConfirmOpen}
          title="Are you sure?"
          message="If you remove this demo, it will be permanently deleted. This cannot be undone."
          buttons={[
            {
              key: "no",
              text: "Cancel",
              prominent: false,
              destructive: false,
              onClick: () => dispatch(setDeleteConfirmOpen(false)),
            },
            {
              key: "yes",
              text: "Remove Demo",
              prominent: true,
              destructive: true,
              onClick: () => {
                if (myDemosState.demoToDelete) {
                  onRemoveDemo(client, myDemosState.demoToDelete);
                  dispatch(setDemoToDelete(undefined));
                }
                dispatch(setDeleteConfirmOpen(false));
              },
            },
          ]}
          cancel={() => {
            dispatch(setDeleteConfirmOpen(false));
          }}
        />
      )}
    </ApolloConsumer>
  );
};
