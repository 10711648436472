import { useEffect, useState } from "react";

export const useVerticalScrollTracker = (granularity: number) => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      if (Math.abs(window.scrollY - scrollTop) > granularity) {
        setScrollTop(window.scrollY);
      }
    };

    document.addEventListener("scroll", onScroll);

    // This is called when the component unmounts
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [scrollTop, setScrollTop, granularity]);

  return scrollTop;
};
