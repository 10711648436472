import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useHistory, useParams } from "react-router";

import { AppErrorPage } from "../../../../components/appError";
import { SiteAdminTeamLayout } from "../../../../components/layouts/siteAdminTeamLayout";
import { ResourceEditor } from "../../../../components/forms/resourceEditor";
import { SiteAdminTeamAppLoadingPage } from "../../../../components/siteAdmin/siteAdminTeamAppLoadingPage";
import { useSiteTeamResourceCategoriesQuery } from "../../../../generated/graphql";
import { requestCreateSiteResource } from "../../../../generated/graphqlWrappers";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { PaperWithTitle } from "../../../../components/paperWithTitle";
import { Breadcrumb } from "../../../../components/breadcrumb";

interface Params {
  teamId: string;
}

export const CreateTeamResourceSiteAdminPage: React.FC = () => {
  let history = useHistory();
  const { teamId } = useParams<Params>();
  const [name, setName] = useState("");
  const [body, setBody] = useState("");
  const [categoryId, setCategoryId] = useState(-1);
  const [errorMessage, setErrorMessage] = useState("");
  const [contentIsChanged, setContentIsChanged] = useState(false);

  useEffect(() => {
    if (contentIsChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [contentIsChanged]);

  const { data, error, loading } = useSiteTeamResourceCategoriesQuery({
    variables: {
      teamId: parseInt(teamId),
    },
    fetchPolicy: "network-only",
  });
  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    return <SiteAdminTeamAppLoadingPage />;
  }
  if (data.siteTeam.resourceCategories.length === 0) {
    return <AppErrorPage errorMessage="There are no resource categories" />;
  }

  if (categoryId === -1) {
    setCategoryId(parseInt(data.siteTeam.resourceCategories[0].id));
    return <SiteAdminTeamAppLoadingPage />;
  }

  const team = data.siteTeam;

  const onClose = () => {
    history.push(`/site-admin/team/${teamId}/resources`);
  };

  const saveIsDisabled = () => {
    return name === "";
  };

  const onSaveAndClose = async (client: ApolloClient<object>) => {
    await save(client, onClose);
  };

  const save = async (client: ApolloClient<object>, onSuccess: () => void) => {
    setErrorMessage("");
    await requestCreateSiteResource(
      client,
      { teamId: parseInt(team.id), name, body, categoryId },
      () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        setErrorMessage("Error creating Resource");
      }
    );
  };

  return (
    <SiteAdminTeamLayout chosen="Resources" teamId={teamId}>
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: team.organization.displayName, url: `/site-admin/org/${team.organization.id}` },
            { text: team.displayName, url: `/site-admin/team/${team.id}` },
            { text: "Resources", url: `/site-admin/team/${team.id}/resources` },
          ]}
        />
        <PaperWithTitle title="New Resource">
          <ResourceEditor
            nameState={[name, setName]}
            bodyState={[body, setBody]}
            categoryIdState={[categoryId, setCategoryId]}
            categories={data.siteTeam.resourceCategories}
            setContentIsChanged={setContentIsChanged}
          />
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <ApolloConsumer>
              {(client) => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={saveIsDisabled()}
                  onClick={() => {
                    onSaveAndClose(client);
                  }}
                >
                  Add Resource
                </Button>
              )}
            </ApolloConsumer>
          </Stack>
          {errorMessage === "" ? <React.Fragment /> : <Typography color={red[900]}>{errorMessage}</Typography>}
        </PaperWithTitle>
      </Stack>
    </SiteAdminTeamLayout>
  );
};
