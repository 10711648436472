import React from "react";
import { useParams } from "react-router-dom";
import { useUserFeatureSetsForTeamQuery } from "../../../generated/graphql";
import { requestCreateFeatureSet, requestUpdateFeatureSetArchived } from "../../../generated/graphqlWrappers";
import { TeamContentTablePageLayout } from "./teamContent/teamContentTablePageLayout";

interface Params {
  teamId: string;
}

export const TeamFeatureSetsPage: React.FC = () => {
  const { teamId } = useParams<Params>();
  return (
    <TeamContentTablePageLayout
      teamId={teamId}
      contentType="FeatureSet"
      chosen="Features"
      useContentListQuery={useUserFeatureSetsForTeamQuery}
      requestCreateContent={requestCreateFeatureSet}
      requestArchiveContent={requestUpdateFeatureSetArchived}
    />
  );
};
