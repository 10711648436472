import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSharedDemosForUserQuery } from "../../../generated/graphql";
import { DemoTable } from "../../../components/demoTable/demoTable";
import { compareOrder, SortColumnDescriptor } from "../../../components/demoTable/demoTableSortUtils";
import { sharedDemosCompareFn } from "./sharedDemoUtils";

export const MySharedDemosTable: React.FC = () => {
  const history = useHistory();
  const [sortColumn, setSortColumn] = useState<SortColumnDescriptor>({ index: 1, ascending: true });
  const { data, error, loading } = useSharedDemosForUserQuery({
    fetchPolicy: "network-only",
  });
  if (error) {
    return <Typography>{error.message}</Typography>;
  }
  if (loading || !data) {
    return <React.Fragment />;
  }

  const sortFn = compareOrder(sharedDemosCompareFn(sortColumn.index), sortColumn.ascending);
  const demos = [...data.sharedDemosForUser].sort(sortFn);

  return (
    <DemoTable
      demos={demos}
      dataDescription="shared demos"
      onNewDemo={() => {}}
      showNewDemo={false}
      onLaunchDemo={(demoId) => {
        history.push(`/view-shared-demo/${demoId}`);
      }}
      buttons={(demoId) => {
        return [];
      }}
      onHeaderClick={(colIndex) => {
        if (sortColumn.index === colIndex) {
          setSortColumn({ index: colIndex, ascending: !sortColumn.ascending });
        } else {
          setSortColumn({ index: colIndex, ascending: true });
        }
      }}
      headerSort={sortColumn}
    />
  );
};
