import { TableCell, TableRow } from "@mui/material";
import React from "react";

interface Props {
  dataDescription: string;
}

export const EmptyObjectTableRow: React.FC<Props> = ({ dataDescription }) => {
  return (
    <TableRow>
      <TableCell>No {dataDescription}</TableCell>
    </TableRow>
  );
};
