import React from "react";

import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { AppErrorPage } from "../../../components/appError";
import { AppTeamLoadingPage } from "../../../components/appTeamLoadingPage";
import { AppTeamLayout } from "../../../components/layouts/appTeamLayout";
import { Breadcrumb } from "../../../components/breadcrumb";
import { useUserDemoTemplateQuery } from "../../../generated/graphql";
import {
  requestAddDemoTemplateConversations,
  requestAddDemoTemplateFeatureSets,
  requestDemoTemplateConversations,
  requestDemoTemplateFeatureSets,
  requestRemoveDemoTemplateConversation,
  requestRemoveDemoTemplateFeatureSet,
  requestUpdateDemoTemplateClose,
  requestUpdateDemoTemplateContentOrder,
  requestUpdateDemoTemplateConversationRecommended,
  requestUpdateDemoTemplateFeatureSetRecommended,
  requestUpdateDemoTemplateOpening,
  requestUpdateDemoTemplatePersonaType,
  requestUpdateDemoTemplateProperties,
} from "../../../generated/graphqlWrappers";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { isAdminForOrgOrTeam } from "../../../util/adminUtils";
import { ViewDemoTemplateEditor } from "../../shared/viewDemoTemplate/components/viewDemoTemplateEditor";
import {
  clearViewDemoTemplateState,
  initializeViewDemoTemplateState,
  selectViewDemoTemplate,
} from "../../shared/viewDemoTemplate/store/viewDemoTemplateSlice";

interface Params {
  teamId: string;
  demoTemplateId: string;
}

export const TeamDemoTemplatePage: React.FC = () => {
  const { teamId, demoTemplateId } = useParams<Params>();
  const dispatch = useAppDispatch();
  const viewDemoTemplateState = useAppSelector(selectViewDemoTemplate);

  const { data, error, loading } = useUserDemoTemplateQuery({
    variables: { teamId: parseInt(teamId), demoTemplateId: parseInt(demoTemplateId) },
    fetchPolicy: "network-only",
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !data) {
    if (viewDemoTemplateState.initialized) {
      dispatch(clearViewDemoTemplateState());
    }
    return <AppTeamLoadingPage />;
  }

  if (!viewDemoTemplateState.initialized) {
    dispatch(initializeViewDemoTemplateState(data.userDemoTemplate));
    return <AppTeamLoadingPage />;
  }

  const teamName = data.userDemoTemplate.team.displayName;
  const isAdmin = isAdminForOrgOrTeam(data.userAccount, data.userTeam.admins);

  return (
    <AppTeamLayout teamId={teamId} teamName={teamName} chosen={"Stakeholder Templates"}>
      <Stack spacing={2}>
        <Breadcrumb
          crumbs={[
            { text: "Home", url: "/" },
            { text: "Teams", url: `/teams` },
            { text: teamName, url: `/team/${teamId}` },
            { text: "Stakeholder Templates", url: `/team/${teamId}/templates` },
          ]}
        />
        <ViewDemoTemplateEditor
          teamId={teamId}
          demoTemplateId={demoTemplateId}
          openings={data.userDemoTemplate.team.openings}
          closes={data.userDemoTemplate.team.closes}
          urlRoot=""
          readOnly={!isAdmin}
          requestUpdateDemoTemplateClose={requestUpdateDemoTemplateClose}
          requestUpdateDemoTemplateContentOrder={requestUpdateDemoTemplateContentOrder}
          requestUpdateDemoTemplateProperties={requestUpdateDemoTemplateProperties}
          requestUpdateDemoTemplateOpening={requestUpdateDemoTemplateOpening}
          requestUpdateDemoTemplatePersonaType={requestUpdateDemoTemplatePersonaType}
          requestAddDemoTemplateFeatureSets={requestAddDemoTemplateFeatureSets}
          requestAddDemoTemplateConversations={requestAddDemoTemplateConversations}
          requestDemoTemplateFeatureSets={requestDemoTemplateFeatureSets}
          requestDemoTemplateConversations={requestDemoTemplateConversations}
          requestUpdateDemoTemplateConversationRecommended={requestUpdateDemoTemplateConversationRecommended}
          requestRemoveDemoTemplateConversation={requestRemoveDemoTemplateConversation}
          requestUpdateDemoTemplateFeatureSetRecommended={requestUpdateDemoTemplateFeatureSetRecommended}
          requestRemoveDemoTemplateFeatureSet={requestRemoveDemoTemplateFeatureSet}
        />
      </Stack>
    </AppTeamLayout>
  );
};
