import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { LoginPage } from "./loginPage";
import { MyDemosPage } from "./userApp/myDemosPage";
import { ViewDemoPage } from "./userApp/viewDemoPage";
import { ResourcesPage } from "./userApp/resourcesPage";
import { TeamDashboardPage } from "./userApp/team/teamDashboardPage";
import { TeamAndUsersPage } from "./userApp/team/teamAndUsersPage";
import { TeamsPage } from "./userApp/teamsPage";

import { OrganizationsSiteAdminPage } from "./siteAdmin/organizationsSiteAdmin";
import { ViewDemoTemplateSiteAdminPage } from "./siteAdmin/teamData/view/viewDemoTemplateSiteAdminPage";
import { ViewOrgSiteAdminPage } from "./siteAdmin/viewOrgSiteAdmin";
import { ViewTeamClosesSiteAdminPage } from "./siteAdmin/teamData/view/viewTeamClosesSiteAdminPage";
import { ViewTeamOpeningsSiteAdminPage } from "./siteAdmin/teamData/view/viewTeamOpeningsSiteAdminPage";
import { ViewTeamDemoTemplatesSiteAdminPage } from "./siteAdmin/teamData/view/viewTeamDemoTemplatesSiteAdminPage";
import { ViewTeamFeatureSetsSiteAdminPage } from "./siteAdmin/teamData/view/viewTeamFeatureSetsSiteAdminPage";
import { ViewTeamConversationsSiteAdminPage } from "./siteAdmin/teamData/view/viewTeamConversationsSiteAdminPage";
import { EditTeamOpeningSiteAdminPage } from "./siteAdmin/teamData/edit/editTeamOpeningSiteAdmin";
import { EditTeamCloseSiteAdminPage } from "./siteAdmin/teamData/edit/editTeamCloseSiteAdmin";
import { EditTeamFeatureSetSiteAdminPage } from "./siteAdmin/teamData/edit/editTeamFeatureSetSiteAdmin";
import { EditTeamConversationSiteAdminPage } from "./siteAdmin/teamData/edit/editTeamConversationSiteAdmin";
import { CreateTeamOpeningSiteAdminPage } from "./siteAdmin/teamData/create/createTeamOpeningSiteAdmin";
import { CreateTeamCloseSiteAdminPage } from "./siteAdmin/teamData/create/createTeamCloseSiteAdmin";
import { CreateTeamFeatureSetSiteAdminPage } from "./siteAdmin/teamData/create/createTeamFeatureSetSiteAdmin";
import { CreateTeamConversationSiteAdminPage } from "./siteAdmin/teamData/create/createTeamConversationSiteAdminPage";
import { ViewOrgUsersSiteAdminPage } from "./siteAdmin/viewOrgUsersSiteAdminPage";
import { ViewTeamUsersSiteAdminPage } from "./siteAdmin/teamData/view/viewTeamUsersSiteAdminPage";
import { EditUserSiteAdminPage } from "./siteAdmin/editUserSiteAdmin";
import { TeamDemoTemplatesPage } from "./userApp/team/teamDemoTemplatesPage";
import { ViewTeamResourcesSiteAdminPage } from "./siteAdmin/teamData/view/viewTeamResourcesSiteAdminPage";
import { CreateTeamResourceSiteAdminPage } from "./siteAdmin/teamData/create/createTeamResourceSiteAdminPage";
import { EditTeamResourceSiteAdminPage } from "./siteAdmin/teamData/edit/editTeamResourceSiteAdmin";
import { ViewTeamResourceCategoriesSiteAdminPage } from "./siteAdmin/teamData/view/viewTeamResourceCategoriesSiteAdminPage";
import { CreateTeamResourceCategorySiteAdminPage } from "./siteAdmin/teamData/create/createTeamResourceCategorySiteAdminPage";
import { EditTeamResourceCategorySiteAdminPage } from "./siteAdmin/teamData/edit/editTeamResourceCategorySiteAdminPage";
import { UserAccountPage } from "./userAccountPage";
import { TeamOpeningsPage } from "./userApp/team/teamOpeningsPage";
import { TeamFeatureSetsPage } from "./userApp/team/teamFeatureSetsPage";
import { TeamConversationsPage } from "./userApp/team/teamConversationsPage";
import { TeamClosesPage } from "./userApp/team/teamClosesPage";
import { TeamOpeningPage } from "./userApp/team/teamOpeningPage";
import { TeamClosePage } from "./userApp/team/teamClosePage";
import { TeamConversationPage } from "./userApp/team/teamConversationPage";
import { TeamFeatureSetPage } from "./userApp/team/teamFeatureSetPage";
import { TeamDemoTemplatePage } from "./userApp/team/teamDemoTemplatePage";
import { ResourcePage } from "./userApp/resources/resourcePage";
import { ChangePasswordPage } from "./changePasswordPage";
import { UserNotificationsPage } from "./userApp/notifications/userNotificationsPage";
import { UserNotificationPage } from "./userApp/notifications/userNotificationPage";
import { UserContentNotificationsPage } from "./userApp/notifications/userContentNotificationsPage";
import { InvitePage } from "./invitePage";
import { SharedDemosPage } from "./userApp/sharedDemosPage";
import { ViewSharedDemoPage } from "./userApp/viewSharedDemoPage";

export const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact>
          <LoginPage />
        </Route>
        <Route path="/invite/:inviteCode" exact>
          <InvitePage />
        </Route>
        <Route path="/site-admin" exact>
          <OrganizationsSiteAdminPage />
        </Route>
        <Route path="/site-admin/org/:organizationId" exact>
          <ViewOrgSiteAdminPage />
        </Route>
        <Route path="/site-admin/org/:organizationId/users" exact>
          <ViewOrgUsersSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId" exact>
          <ViewTeamDemoTemplatesSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/users" exact>
          <ViewTeamUsersSiteAdminPage />
        </Route>
        <Route path="/site-admin/user/:userId" exact>
          <EditUserSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/resources" exact>
          <ViewTeamResourcesSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/new-resource" exact>
          <CreateTeamResourceSiteAdminPage />
        </Route>
        <Route path="/site-admin/resource/:resourceId" exact>
          <EditTeamResourceSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/resource-categories" exact>
          <ViewTeamResourceCategoriesSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/new-resource-category" exact>
          <CreateTeamResourceCategorySiteAdminPage />
        </Route>
        <Route path="/site-admin/resource-category/:resourceCategoryId" exact>
          <EditTeamResourceCategorySiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/openings" exact>
          <ViewTeamOpeningsSiteAdminPage />
        </Route>
        <Route path="/site-admin/opening/:openingId" exact>
          <EditTeamOpeningSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/new-opening" exact>
          <CreateTeamOpeningSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/feature-sets" exact>
          <ViewTeamFeatureSetsSiteAdminPage />
        </Route>
        <Route path="/site-admin/feature-set/:featureSetId" exact>
          <EditTeamFeatureSetSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/new-feature-set" exact>
          <CreateTeamFeatureSetSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/conversations" exact>
          <ViewTeamConversationsSiteAdminPage />
        </Route>
        <Route path="/site-admin/conversation/:conversationId" exact>
          <EditTeamConversationSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/new-conversation" exact>
          <CreateTeamConversationSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/closes" exact>
          <ViewTeamClosesSiteAdminPage />
        </Route>
        <Route path="/site-admin/close/:closeId" exact>
          <EditTeamCloseSiteAdminPage />
        </Route>
        <Route path="/site-admin/team/:teamId/new-close" exact>
          <CreateTeamCloseSiteAdminPage />
        </Route>
        <Route path="/site-admin/template/:demoTemplateId" exact>
          <ViewDemoTemplateSiteAdminPage />
        </Route>

        <Route path="/" exact>
          <MyDemosPage />
        </Route>
        <Route path="/shared-demos" exact>
          <SharedDemosPage />
        </Route>
        <Route path="/account" exact>
          <UserAccountPage />
        </Route>
        <Route path="/notifications" exact>
          <UserNotificationsPage />
        </Route>
        <Route path="/notification/:notificationId" exact>
          <UserNotificationPage />
        </Route>
        <Route path="/content-notifications" exact>
          <UserContentNotificationsPage />
        </Route>
        <Route path="/change-password" exact>
          <ChangePasswordPage />
        </Route>
        <Route path="/view-demo/:demoId" exact>
          <ViewDemoPage />
        </Route>
        <Route path="/view-shared-demo/:demoId" exact>
          <ViewSharedDemoPage />
        </Route>
        <Route path="/resources" exact>
          <ResourcesPage />
        </Route>
        <Route path="/team/:teamId/resource/:resourceId" exact>
          <ResourcePage />
        </Route>
        <Route path="/teams" exact>
          <TeamsPage />
        </Route>
        <Route path="/team/:teamId" exact>
          <TeamAndUsersPage />
        </Route>
        <Route path="/team/:teamId/dashboard" exact>
          <TeamDashboardPage />
        </Route>
        <Route path="/team/:teamId/templates" exact>
          <TeamDemoTemplatesPage />
        </Route>
        <Route path="/team/:teamId/template/:demoTemplateId" exact>
          <TeamDemoTemplatePage />
        </Route>
        <Route path="/team/:teamId/openings" exact>
          <TeamOpeningsPage />
        </Route>
        <Route path="/team/:teamId/opening/:openingId" exact>
          <TeamOpeningPage />
        </Route>
        <Route path="/team/:teamId/feature-sets" exact>
          <TeamFeatureSetsPage />
        </Route>
        <Route path="/team/:teamId/feature-set/:featureSetId" exact>
          <TeamFeatureSetPage />
        </Route>
        <Route path="/team/:teamId/conversations" exact>
          <TeamConversationsPage />
        </Route>
        <Route path="/team/:teamId/conversation/:conversationId" exact>
          <TeamConversationPage />
        </Route>
        <Route path="/team/:teamId/closes" exact>
          <TeamClosesPage />
        </Route>
        <Route path="/team/:teamId/close/:closeId" exact>
          <TeamClosePage />
        </Route>
      </Switch>
    </Router>
  );
};
