import React, { useState } from "react";
import { ApolloClient } from "@apollo/client";
import { requestMarkUserNotificationAsRead } from "../../../generated/graphqlWrappers";
import { showSnackbarError } from "../../../components/appSnackbarSlice";
import { useAppDispatch } from "../../../store/hooks";

interface Props {
  client: ApolloClient<object>;
  notificationId: string;
}

export const MarkNotificationAsRead: React.FC<Props> = ({ client, notificationId }) => {
  const [markRequested, setMarkRequested] = useState(false);
  const dispatch = useAppDispatch();

  if (!markRequested) {
    requestMarkUserNotificationAsRead(
      client,
      { notificationId: parseInt(notificationId) },
      () => {},
      (err) => {
        dispatch(showSnackbarError(err));
      }
    ).then(() => {
      setMarkRequested(true);
    });
  }
  return <React.Fragment />;
};
