import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import React from "react";
import { TextEditor } from "./textEditor/textEditor";

interface Props {
  contentKey?: string;
  body: string;
  bodyChanged: (body: string, wordCount: number) => void;
  bulletedBody: string;
  bulletedBodyChanged: (bulletedBody: string) => void;
  tab: string;
  tabChanged: (tab: string) => void;
  cumulativeWordCount?: number;
}

export const TabbedContentEditor: React.FC<Props> = ({
  contentKey,
  body,
  bodyChanged,
  bulletedBody,
  bulletedBodyChanged,
  tab,
  tabChanged,
  cumulativeWordCount,
}) => {
  const [wordCountOnPage, setWordCountOnPage] = React.useState(0);

  return (
    <TabContext value={tab}>
      <TabList
        TabIndicatorProps={{
          style: { transition: "none", visibility: "hidden" },
        }}
        sx={{ borderBottom: 1, borderColor: "divider" }}
        onChange={(event, t) => {
          tabChanged(t);
        }}
        aria-label="script tabs"
        centered
      >
        <Tab
          label="Scripted"
          value="1"
          sx={{
            borderBottom: tab === "1" ? 2 : 0,
            borderColor: "Highlight",
          }}
        />
        <Tab
          label="Outlined"
          value="2"
          sx={{
            borderBottom: tab === "2" ? 2 : 0,
            borderColor: "Highlight",
          }}
        />
      </TabList>
      <TabPanel value="1" sx={{ py: 0 }}>
        <TextEditor
          contentKeyVersioned={(contentKey ?? "key") + "body"}
          initialContent={body}
          setContentJSON={(json, wordCount) => {
            bodyChanged(json, wordCount);
          }}
          setWordCountForPage={(wordCount) => {
            setWordCountOnPage(wordCount);
          }}
          cumulativeWordCount={cumulativeWordCount}
        />
      </TabPanel>
      <TabPanel value="2" sx={{ py: 0 }}>
        <TextEditor
          showWordCount={wordCountOnPage}
          showWordCountLabel="(based on full script)"
          contentKeyVersioned={(contentKey ?? "key") + "body"}
          initialContent={bulletedBody}
          setContentJSON={(json) => {
            bulletedBodyChanged(json);
          }}
          cumulativeWordCount={cumulativeWordCount}
        />
      </TabPanel>
    </TabContext>
  );
};
