import React from "react";

import { Stack, Typography } from "@mui/material";
import { ObjectArrayTable, ObjectTableButtonData } from "../../../components/objectArrayTable/objectArrayTable";
import { ObjectTableCellButton } from "../../../components/objectArrayTable/objectTableCellButton";
import { TableHeaderButton } from "../../../components/objectArrayTable/objectTableHeader";
import { useHistory } from "react-router-dom";

type TemplateRow = { id: string; name: string; archived: boolean };

interface Props {
  teamId: string;
  templates: TemplateRow[];
  buttons?: (template: TemplateRow) => ObjectTableButtonData[];
  headerButtons?: TableHeaderButton[];
}

export const TeamDemoTemplatesTable: React.FC<Props> = ({ teamId, templates, buttons, headerButtons }) => {
  const history = useHistory();

  return (
    <ObjectArrayTable
      dataDescription="stakeholder templates"
      title="Stakeholder Template"
      data={templates}
      buttons={headerButtons}
      cellNode={(template) => {
        return (
          <React.Fragment>
            <Stack direction="row" alignItems="center">
              <Stack
                display="flex"
                sx={{ flexGrow: 1 }}
                onClick={() => {
                  history.push(`/team/${teamId}/template/${template.id}`);
                }}
              >
                <Typography variant="body1">{template.name}</Typography>
              </Stack>
              {buttons ? (
                buttons(template).map((b) => <ObjectTableCellButton key={b.text} objectId={template.id} button={b} />)
              ) : (
                <React.Fragment />
              )}
            </Stack>
          </React.Fragment>
        );
      }}
      onClick={(id) => {}}
    />
  );
};
